import React, { useContext, useEffect, useState } from 'react'
import InstaFeedProvider from './InstaFeedProvider'
import { Button, Card, Col, Modal, Row, Spinner } from 'react-bootstrap'
import InstaFeedContent from './useable-components/InstaFeedContent'
import APIService from 'http/api_service'
import { useSelector, useDispatch } from "react-redux"
import { getLoggedInUser } from 'redux/slices/user_slice'
import InstaPostsSetup from './useable-components/InstaPostsSetup'
import InstaPostConnector from './useable-components/InstaPostConnector'
import MessagingAccountsConnector from '../chat/setup/MessagingAccountsConnector'
import { toast } from 'react-toastify'
import { InstaFeedContext } from 'context/Context'
import InfiniteScroll from 'react-infinite-scroll-component'
import Flex from 'components/common/Flex'
import ReactLoading from "react-loading";
import InstaCommentsHeader from './useable-components/InstaCommentsHeader'
import { findPlatformByIdentifier } from '../platforms'
import { PlatformIdentifier } from 'constants'
import InstaCommentPlatformHeader from './useable-components/InstaCommentPlatformHeader'
import { getActiveWorkSpace } from 'redux/slices/workspaceslice'
import { getAccountUserId, setPageId } from 'redux/slices/analytics_slice'
import { useNavigate } from 'react-router-dom'
import { getInstagramUserId, getSelectedPageForPostCommentsView, setInstagramAccountPageId } from 'redux/slices/instagramComments_slice'



const InstaPostTab = (props) => {
  const { feeds, instaFeedDispatch } = useContext(InstaFeedContext);
  const user = useSelector(getLoggedInUser);
  const workSpace = useSelector(getActiveWorkSpace);
  const { account, platform } = props;
  const [openAccountsConnector, setOpenAccountsConnector] = useState(false);
  const [isUserConnected, setIsUserConnected] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [nextCursor, setNextCursor] = useState(null);
  const [pages, setPages] = useState([]);
  const selectedPageForPostCommentsView = useSelector(getSelectedPageForPostCommentsView);





  const connectAccounts = () => {
    setOpenAccountsConnector(true);
  };

  const reloadPage = () => {
    postData()
    // window.location.reload();
    setOpenAccountsConnector(false);
  };


  const postData = (next = "") => {
    let { ig_user_id, accessToken, name, picture } = selectedPageForPostCommentsView;
    if (!ig_user_id || !accessToken) {
      return;
    }
    setIsFetching(true)
    APIService.getUserInstagramPosts(ig_user_id, accessToken, name, picture, next, (data, error) => {
      if (error) {
        toast.error(error, { theme: "colored" })
        console.log("🚀 ~ APIService.getUserInstagramPosts ~ error:", error);
        setIsUserConnected(false);
        setIsFetching(false);
        return;
      } else {
        instaFeedDispatch({ type: 'ADD', payload: data?.data });
        setIsFetching(false)
        if (data?.next) {
          setNextCursor(data?.next)
          setCanLoadMore(true)
        } else {
          setCanLoadMore(false)
        }
        return;
      }
    });
  }

  useEffect(() => {
    if (selectedPageForPostCommentsView?.id) {
      postData()
    }
  }, [selectedPageForPostCommentsView?.id])

  useEffect(() => {
    APIService.fetchPagesAndGroups(workSpace, account.identifier, account.user_id, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      const { data } = response;
      let facebookPages = [];
      for (let page of data) {
        if (page.is_page) {
          facebookPages.push(page);
        }
      }
      setPages(facebookPages);
    });
  }, [account]);

  const loadMore = () => {
    if (canLoadMore && feeds.length > 0) {
      postData(nextCursor);
    }
  };
  useEffect(() => {
    loadMore()
  }, [canLoadMore])


  return (
    <>
      <InstaCommentPlatformHeader pages={pages} platform={platform} account={account} instaFeedDispatch={instaFeedDispatch} />
      {(isFetching && feeds?.length < 1) ? (
        <>
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            direction={"column"}
          >
            <Flex
              className={"vh-70"}
              alignItems={"center"}
              justifyContent={"center"}
              direction={"column"}
            >
              <ReactLoading type={"bubbles"} color={"#0052cc"} />
            </Flex>
          </Flex>
        </>
      ) : (
        <Row>
          <Col style={{ position: "relative" }}>
            <InfiniteScroll
              dataLength={feeds.length}
              next={loadMore}
              hasMore={canLoadMore}
            >
              <InstaFeedContent />
            </InfiniteScroll>
          </Col>
          {!isFetching && feeds?.length === 0 && (
            <InstaPostsSetup connectAccounts={connectAccounts} />
          )}
          {feeds?.length > 0 && canLoadMore && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Spinner animation="border" size={40} />
            </div>
          )}
        </Row>
      )}
      <Modal
        show={openAccountsConnector}
        onHide={() => {
          reloadPage();
        }}
        onExit={() => {
          reloadPage();
        }}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Connect a Instagram Account to Inbox</Modal.Title>
        </Modal.Header>
        <InstaPostConnector reloadPage={reloadPage} />
      </Modal>
    </>
  );
};



const PostsAndComments = () => {
  const dispatch = useDispatch();
  const platform = findPlatformByIdentifier(PlatformIdentifier.INSTAGRAM);
  const [fetchingAccounts, setFetchingAccounts] = useState(false);
  const [connectedAccounts, setConnectedAccounts] = useState();
  const workSpace = useSelector(getActiveWorkSpace);
  const userId = useSelector(getInstagramUserId);



  const fetchConnectedAccounts = () => {
    // if (WorkSpaceManager.isFreeWorkspace()) {
    //     dispatch(setPopupUpgradeAlert(true))
    //     return;
    // }
    setFetchingAccounts(true);
    APIService.fetchConnectedAccounts(workSpace['_id'], platform.identifier, (response, error) => {
      setFetchingAccounts(false);
      if (error) {
        toast.error(error);
        return;
      }
      let { data } = response;
      if (!data.length) {
        toast.error(`Please connect ${platform.identifier} account to view instagram post comments`, { theme: 'colored' });
        return;
      }
      if (data && data.length > 0) {
        let refinedData = data.filter((x) => x?.user_id === userId);
        refinedData = refinedData.map((account, index) => {
          let { user_id, user_name, user_photo, ...platform } = findPlatformByIdentifier(account.target);
          let newAccount = { ...account, ...platform };
          return newAccount;
        })
        setConnectedAccounts(refinedData);
      }
    })
  }

  useEffect(() => {
    if (platform) {
      fetchConnectedAccounts();
      dispatch(setInstagramAccountPageId(null));
    }
  }, [userId]);


  return (
    <InstaFeedProvider>
      <Row>
        <Col>
          <InstaCommentsHeader platform={platform} />
          {fetchingAccounts &&
            <Flex
              alignItems={'center'}
              justifyContent={'center'}
              className={'vh-100'}
            >
              <Spinner animation='border' size={40} />
            </Flex>}
          {connectedAccounts && !fetchingAccounts && (
            (() => {
              const account = connectedAccounts.find(account => account.user_id === userId);
              return account ? (
                <div key={account._id} id={account._id}>
                  <InstaPostTab
                    account={account}
                    platform={platform} />
                </div>
              ) : null;
            })()
          )}
        </Col>
      </Row>
    </InstaFeedProvider>
  )
}

export default PostsAndComments