import React, { useEffect, useState } from 'react';
import { LuUploadCloud } from "react-icons/lu";
import { MdCloudDone } from "react-icons/md";
import './addOns.css';

const RenderFilePreviewView = (props) => {
    const {
        file,
        fileType,
        fileUrl,
        uploadingFile,
        filesUploaded,
        currentFileUploading,
    } = props

    const [uploaded, setUploaded] = useState(false);

    useEffect(() => {
        const isAmongFilesUploaded = filesUploaded.find(fileUploaded => fileUploaded === file?.file)
        if (isAmongFilesUploaded) {
            setUploaded(true)
        }
    }, [filesUploaded])


    function convertBytesToKBMB(fileSizeInBytes) {
        const fileSizeInKilobytes = fileSizeInBytes / 1024;
        const fileSizeInMegabytes = fileSizeInKilobytes / 1024;

        if (fileSizeInMegabytes > 1) {
            return fileSizeInMegabytes.toFixed(2) + " MB";
        } else if (fileSizeInKilobytes > 10) {
            return fileSizeInKilobytes.toFixed(2) + " KB";
        } else {
            return fileSizeInBytes + " bytes";
        }
    }


    const displayUploadStatus = () => {
        if (!uploadingFile) return
        if (currentFileUploading === file?.file) {
            return <div
                style={{
                    flex: 1
                }}
            >
                <LuUploadCloud
                    style={{ cursor: 'pointer' }}
                    size={20}
                    className={'pulse'}
                />
            </div>
        } else if (uploaded) {
            return <div
                style={{
                    flex: 1
                }}
            >
                <MdCloudDone
                    style={{ cursor: 'pointer' }}
                    size={20}
                />
            </div>
        } else {
            return <div
                style={{
                    flex: 1
                }}
            >
                <LuUploadCloud
                    style={{ cursor: 'pointer' }}
                    size={20}
                />
            </div>
        }
    }

    return (
        <div style={{ width: "100%", height: 70, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 5 }}>
            <div
                style={{
                    height: '100%',
                    width: 100,
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {
                    fileType?.includes("image") ?
                        <img src={fileUrl} alt="preview" style={{ width: 100, height: "100%" }} />
                        : fileType?.includes("video") ?
                            <video src={fileUrl} alt="preview" style={{ width: 100, height: "100%" }} controls />
                            :
                            <span>Preview not available</span>
                }
            </div>
            <div
                style={{
                    flex: 3
                }}
            >
                <div>File Name: {file?.file?.name?.length > 20 ? `${file?.file?.name?.substring(0, 20)}...` : file?.file?.name}</div>
                <div>File Size: {convertBytesToKBMB(file?.file?.size)}</div>

            </div>
            {displayUploadStatus()}
        </div>
    )
}

export default RenderFilePreviewView