import React from 'react'
import { getUserBillingUpdate, setUserBillingUpdate } from 'redux/slices/user_slice';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Strings } from 'constants';

const BillingUpdateModal = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userBillingUpdate = useSelector(getUserBillingUpdate);

    const displayMessages = () => {
        if (userBillingUpdate?.type === 'subscription_successful') {
            return (
                <Flex
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    style={{ gap: 10 }}
                >
                    <p className="fs--1">
                        Your subscription was successful.
                    </p>
                    <p className="fs--1">
                        Have fun using Postly!
                    </p>
                </Flex>
            )
        }
        if (userBillingUpdate?.type === 'subscription_updated') {
            if (userBillingUpdate?.data?.subReason) {
                return <Flex
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    style={{ gap: 10 }}
                >
                    <p className="fs--1">
                        Your subscription was updated.
                    </p>
                    <p className="fs--1 text-center">
                        {userBillingUpdate?.data?.subReason}
                    </p>
                    <p className="fs--1">
                        Have fun using Postly!
                    </p>
                </Flex>
            }
            return (
                <Flex
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    style={{ gap: 10 }}
                >
                    <p className="fs--1">
                        Your subscription was updated.
                    </p>
                    <p className="fs--1">
                        You are now on the {userBillingUpdate?.data?.plan} plan
                    </p>
                    <p className="fs--1">
                        Have fun using Postly!
                    </p>
                </Flex>
            )
        }
        if (userBillingUpdate?.type === 'subscription_cancelled') {
            return (
                <Flex
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    style={{ gap: 10 }}
                >
                    <p className="fs--1">
                        Your subscription was cancelled.
                    </p>
                    <p className="fs--1">
                        {
                            userBillingUpdate?.data?.subReason ?? "You can still access your account until the end of your billing period."
                        }
                    </p>
                </Flex>
            )
        }
        if (userBillingUpdate?.type === 'trialing_cancelled') {
            return (
                <Flex
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    style={{ gap: 10 }}
                >
                    <p className="fs--1">
                        You have successfully cancelled your trial.
                    </p>
                    <p className="fs--1">
                        You can still access your account until the end of your trial period, after which your account will be downgraded to the free plan.
                    </p>
                    <p className="fs--1">
                        You can still renew your subscription before your trail period ends by visiting the billing page.
                    </p>
                    <p className="fs--1">
                        If you have any questions, our support team is here to help.
                    </p>

                </Flex>
            )
        }
        if (userBillingUpdate?.type === 'trialing_cancelled') {
            return (
                <Flex
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    style={{ gap: 10 }}
                >
                    <p className="fs--1">
                        You have successfully cancelled your trial.
                    </p>
                    <p className="fs--1">
                        You can still access your account until the end of your trial period, after which your account will be downgraded to the free plan.
                    </p>
                    <p className="fs--1">
                        You can still renew your subscription before your trail period ends by visiting the billing page.
                    </p>
                    <p className="fs--1">
                        If you have any questions, our support team is here to help.
                    </p>

                </Flex>
            )
        }
    }

    const displayHeading = () => {
        if (userBillingUpdate?.type === 'subscription_successful') {
            return "Subscription successful 🎉"
        }
        if (userBillingUpdate?.type === 'subscription_updated') {
            return "Subscription updated 🎉"
        }
        if (userBillingUpdate?.type === 'subscription_cancelled') {
            return "Subscription cancelled 😭"
        }
        if (userBillingUpdate?.type === 'trialing_renewed') {
            return "Hurray, trial renewed 🎉"
        }
        if (userBillingUpdate?.type === 'trialing_cancelled') {
            return "Oops! Trial cancelled 😭"
        }
    }

    return (
        <Modal
            size="md"
            centered
            show={userBillingUpdate?.newUpdate}
            onHide={() => {
                if (userBillingUpdate?.type === 'subscription_successful' || userBillingUpdate?.type === "subscription_cancelled" || userBillingUpdate?.type === "subscription_updated") {
                    dispatch(setUserBillingUpdate({
                        newUpdate: false,
                        type: null,
                        data: null
                    }))
                    localStorage.setItem(Strings.REFRESH_USER_AND_WORKSPACE_DATA, true);
                    navigate('/');
                    return;
                }
                dispatch(setUserBillingUpdate({
                    newUpdate: false,
                    type: null,
                    data: null
                }))
            }}
        >
            <Modal.Header>
                <span
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        <Modal.Title>
                            {displayHeading()}
                        </Modal.Title>
                    </div>
                </span>
            </Modal.Header>
            <Modal.Body>
                <Flex
                    alignItems={"center"}
                    direction={"column"}
                    style={{ gap: 10 }}
                >
                    {/* <img src={InstagramHero} alt="copy" height="250" width="300px" /> */}
                    {displayMessages()}
                </Flex>
            </Modal.Body>
            <Modal.Footer style={{ flexDirection: "column" }}>
                <Flex justifyContent={"flex-start"} alignItems={"center"} direction={'column'} gap={2}>
                    <Flex
                        justifyContent={"between"}
                        alignItems={"start"}
                        style={{ gap: 20 }}
                    >
                        {/* <Button
                            style={{
                                minWidth: 150
                            }}
                            variant="danger"
                            onClick={() => {

                            }}>
                            No, thanks
                        </Button> */}
                        <Button style={{
                            backgroundColor: '#2569c3',
                            borderColor: '#2569c3',
                            minWidth: 150
                        }} onClick={() => {
                            if (userBillingUpdate?.type === 'subscription_successful' || userBillingUpdate?.type === "subscription_cancelled" || userBillingUpdate?.type === "subscription_updated") {
                                dispatch(setUserBillingUpdate({
                                    newUpdate: false,
                                    type: null,
                                    data: null
                                }))
                                localStorage.setItem(Strings.REFRESH_USER_AND_WORKSPACE_DATA, true);
                                navigate('/');
                                return;
                            }
                            dispatch(setUserBillingUpdate({
                                newUpdate: false,
                                type: null,
                                data: null
                            }))
                        }}>
                            Okay, thanks
                        </Button>
                    </Flex>
                </Flex>
            </Modal.Footer>
        </Modal>
    )
}

export default BillingUpdateModal