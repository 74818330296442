import { setPopupUpgradeAlert } from 'redux/slices/alertSlice'
import Flex from '../../../common/Flex'
import { Card, FloatingLabel, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { getActiveWorkSpace } from 'redux/slices/workspaceslice'
import { useSelector } from 'react-redux'
import { setCollectMetric } from 'redux/slices/analytics_slice'
import { MetricsProps } from 'constants'

const FineTuner = (props) => {
    const { languages, writingStyles, toneStyle, outputFormat, setFineTunning, fineTunning, popFineTuner, setOpenUpgradeDialog, userOnFreePlan } = props
    const dispatch = useDispatch();
    const workSpace = useSelector(getActiveWorkSpace);

    if (!popFineTuner) return <></>

    return (
        <Flex
            className={'mb-2'}
            justifyContent={'around'}
            alignItems={'center'}
        >
            <FloatingLabel controlId="floatingSelect" label="Output format">
                <Form.Select size="sm" aria-label="Floating label select example" onChange={(e) => {
                    if (userOnFreePlan() === true) {
                        let metricInitializer = {
                            action: MetricsProps.AI_CHAT_TOOL,
                            detailedAction: `Upgrade notice: Attempted to use AI fine-tunning feature`,
                            timestamp: new Date().toISOString(),
                            route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
                        }
                        dispatch(setCollectMetric(metricInitializer))
                        dispatch(setPopupUpgradeAlert(true))
                        // Reset the form select value to the previous value
                        e.target.value = "Default";
                        return;
                    }
                    setFineTunning({
                        ...fineTunning,
                        outputFormat: e.target.value,
                    })

                }}>
                    {
                        outputFormat?.map(x => {
                            return <option value={x}>{x}</option>
                        })
                    }
                </Form.Select>
            </FloatingLabel>
            <FloatingLabel controlId="floatingSelect" label="Tone">
                <Form.Select size="sm" aria-label="Floating label select example" onChange={(e) => {
                    if (userOnFreePlan() === true) {
                        let metricInitializer = {
                            action: MetricsProps.AI_CHAT_TOOL,
                            detailedAction: `Upgrade notice: Attempted to use AI fine-tunning feature`,
                            timestamp: new Date().toISOString(),
                            route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
                        }
                        dispatch(setCollectMetric(metricInitializer))
                        dispatch(setPopupUpgradeAlert(true))
                        // Reset the form select value to the previous value
                        e.target.value = "Default";
                        return;
                    }
                    setFineTunning({
                        ...fineTunning,
                        tone: e.target.value,
                    })
                }}>
                    {
                        toneStyle?.map(x => {
                            return <option value={x}>{x}</option>
                        })
                    }
                </Form.Select>
            </FloatingLabel>
            <FloatingLabel controlId="floatingSelect" label="Writing style">
                <Form.Select size="sm" aria-label="Floating label select example" onChange={(e) => {
                    if (userOnFreePlan() === true) {
                        let metricInitializer = {
                            action: MetricsProps.AI_CHAT_TOOL,
                            detailedAction: `Upgrade notice: Attempted to use AI fine-tunning feature`,
                            timestamp: new Date().toISOString(),
                            route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
                        }
                        dispatch(setCollectMetric(metricInitializer))
                        dispatch(setPopupUpgradeAlert(true))
                        // Reset the form select value to the previous value
                        e.target.value = "Default";
                        return;
                    }
                    setFineTunning({
                        ...fineTunning,
                        writingStyle: e.target.value,
                    })
                }}>
                    {
                        writingStyles?.map(w => {
                            return <option value={w}>{w}</option>
                        })
                    }
                </Form.Select>
            </FloatingLabel>
            <FloatingLabel controlId="floatingSelect" label="Language">
                <Form.Select size="sm" aria-label="Floating label select example" onChange={(e) => {
                    if (userOnFreePlan() === true) {
                        let metricInitializer = {
                            action: MetricsProps.AI_CHAT_TOOL,
                            detailedAction: `Upgrade notice: Attempted to use AI fine-tunning feature`,
                            timestamp: new Date().toISOString(),
                            route: window.location.pathname.replace(workSpace._id, '').replace('//', '/'),
                        }
                        dispatch(setCollectMetric(metricInitializer))
                        dispatch(setPopupUpgradeAlert(true))
                        // Reset the form select value to the previous value
                        e.target.value = "Default";
                        return;
                    }
                    setFineTunning({
                        ...fineTunning,
                        language: e.target.value,
                    })
                }}>
                    {
                        languages?.map(language => {
                            return <option value={language}>{language}</option>
                        })
                    }
                </Form.Select>
            </FloatingLabel>
        </Flex>
    )
}

export default FineTuner