import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findPlatformByIdentifier } from "components/app/platforms";
import FalconCardHeader from "components/common/FalconCardHeader";
import React, { useState, useEffect } from "react";
import { Card, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setBloggerTitle,
  setBloggerUrl,
  setBloggerLabel,
  getBloggerTitle,
  getBloggerLabel, 
  getBloggerUrl,
  getRSSFeedTriggered, 
  getArticleTitleSelect
} from "redux/slices/postslice";
import { PlatformIdentifier } from "../../../../constants";
import EditableSection from "./debounceable_edit_box";
import Flex from "components/common/Flex";
import PrePublishErrorResolver from "./PrePublishErrorResolver";
import { WithContext as ReactTags } from "react-tag-input";
import { Button, Row } from "react-bootstrap";
import { faPlus, faClose } from "@fortawesome/free-solid-svg-icons";

export default function BloggerOptionsField(props) {
  const dispatch = useDispatch();
  const postTitle = useSelector(getBloggerTitle);
  const postUrl = useSelector(getBloggerUrl);
  const feedTriggered = useSelector(getRSSFeedTriggered); 
  const bloggerPostLabels = useSelector(getBloggerLabel);
  const articleTitle = useSelector(getArticleTitleSelect); 
  const [customizeUrl, setCustomizeUrl] = useState(false);
  const [postInitialUrl, setPostInitialUrl] = useState();
  const bloggerPlatform = findPlatformByIdentifier(PlatformIdentifier.BLOGGER);
  const [bloggerErrorMessage, setBloggerErrorMessage] = useState(
    props.bloggerErrorMessage
  );
  const [openLabelView, setOpenLabelView] = useState(false);
  const [bloggerLabels, setBloggerLabels] = useState([]);

  useEffect(() => {
    setBloggerErrorMessage(props.bloggerErrorMessage);
  }, [props.bloggerErrorMessage]);

  useEffect(() => {
    if(feedTriggered) { 
      dispatch(setBloggerTitle(articleTitle)); 
    }
  }, [feedTriggered]);
  
  const addNewBloggerLabels = (label) => {
    setBloggerLabels([...bloggerLabels, label]);
  };

  const deleteExistingBloggerLabels = (i) => {
    let newLabels = bloggerLabels.filter((label, index) => index !== i);
    setBloggerLabels(newLabels);
  };

  const handleLabelDrag = (label, currPos, newPos) => {
    const newLabels = bloggerPostLabels.slice();
    newLabels.splice(currPos, 1);
    newLabels.splice(newPos, 0, label);
    dispatch(setBloggerLabel(newLabels));
  };

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  return (
    <Card>
      <FalconCardHeader
        title={"For Blogger"}
        endEl={
          <div
            style={{
              width: 20,
              height: 20,
              borderRadius: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: bloggerPlatform.color,
            }}
          >
            <FontAwesomeIcon
              icon={bloggerPlatform.icon}
              style={{
                color: "white",
                height: 12,
                width: 12,
              }}
            />
          </div>
        }
      />
      <Card.Body className="bg-light">
        <Flex direction={"column"} className={"gap-3"}>
          <Col>
            <EditableSection
              title={"Title"}
              placeholder={"Enter title for Blogger"}
              style={{
                width: "100%",
              }}
              value={postTitle}
              onChange={(value) => {
                if (!value) {
                  value = " ";
                }
                let _title = value.toLowerCase();
                let _postUrl = _title.split(/ /g).join("-");
                setPostInitialUrl(_postUrl);
                dispatch(setBloggerTitle(value));
                dispatch(setBloggerUrl(_postUrl));
              }}
            />
            {bloggerErrorMessage && (
              <PrePublishErrorResolver error={bloggerErrorMessage} />
            )}
          </Col>

          <Flex justifyContent={"space-around"} alignItems={"center"}>
            <Col>
              {postUrl && (
                <>
                  <p style={{ fontSize: "13px" }}>
                    <span style={{ fontWeight: 700 }}>Generated Url: </span>
                    <span>{postInitialUrl}</span>
                  </p>
                </>
              )}
            </Col>

            <Form.Check
              type={"checkbox"}
              id={"custom-url"}
              label={"Customize Url?"}
              onChange={(e) => {
                let value = e.target.checked;
                setCustomizeUrl(value);
              }}
            />
          </Flex>

          <Flex
            justifyContent="center"
            alignItems={"center"}
            className="gap-3"
            style={{
              fontSize: "13px",
            }}
          >
            {/* <span>Permalink preview:</span>
            <a href="#!">{`https://www.mysite.com/${permalink}`}</a> */}
          </Flex>

          {customizeUrl && (
            <Col>
              <EditableSection
                title={"Post url"}
                placeholder={"Enter post url"}
                value={postUrl}
                onChange={(value) => {
                  if (!value) {
                    value = " ";
                  }
                  let _title = value.toLowerCase();
                  let _postUrl = _title.split(/ /g).join("-").trim();
                  dispatch(setBloggerUrl(_postUrl));
                }}
              />
            </Col>
          )}

          <Row className="mt-4">
            <Col>
              <Flex direction={"column"}>
                <h6>Labels</h6>
                {!openLabelView && (
                  <Button
                    variant="falcon-light"
                    size="sm"
                    onClick={() => {
                      setOpenLabelView(!openLabelView);
                    }}
                    style={{
                      fontSize: 13,
                      border: "1px dashed gray",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 2,
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <span>New Label</span>
                    </div>
                  </Button>
                )}
                {openLabelView && (
                  <Flex alignItems={"center"} className={"gap-3"}>
                    <ReactTags
                      placeholder="Type and press enter or comma to add a new post label"
                      tags={bloggerLabels}
                      delimiters={delimiters}
                      handleDelete={deleteExistingBloggerLabels}
                      handleAddition={addNewBloggerLabels}
                      handleDrag={handleLabelDrag}
                      inputFieldPosition="bottom"
                      autocomplete
                    />
                    <FontAwesomeIcon
                      style={{
                        cursor: "pointer",
                      }}
                      icon={faClose}
                      onClick={() => {
                        setOpenLabelView(!openLabelView);
                      }}
                    />
                  </Flex>
                )}
              </Flex>
            </Col>
          </Row>
        </Flex>
      </Card.Body>
    </Card>
  );
}
