import React, { useEffect, useState } from "react";
import {
  faBullhorn,
  faFileVideo,
  faHashtag,
  faLayerGroup,
  faLightbulb,
  faMagnifyingGlass,
  faMouse,
  faPen,
  faPenClip,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-slidedown/lib/slidedown.css";
import PageHeader from "components/common/PageHeader";
import { Alert, Col, Row } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function VideoEditor(props) {
    return (
        <>
          <PageHeader
            title=""
          >
          </PageHeader>
          <Row className="g-3 mb-3">
            <Col xxl={6}>
              <Row className="g-3">
                <Col xs={12}></Col>
              </Row>
            </Col>
            <Col xxl={3}>
              <Row className="g-3">
                <Col md xxl={12}>
                  <Card>
                    <Card.Body className="p-4">
                      
                    </Card.Body>
                    
                  </Card>
                </Col>
                <Col md xxl={12}></Col>
              </Row>
            </Col>
          </Row>
        </>
      );
}