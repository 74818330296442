import { PlatformIdentifier } from 'constants';
import APIService from 'http/api_service';
import usePubSub from 'pubsub';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { onPageSocketRegistryDestroyer, onPageSocketRegistryInitializer } from 'utils/on_page_socket_registry';
import { v4 as uuidv4 } from 'uuid';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'react-bootstrap';
import YoutubePage from './youtubePage';
import { dispatchHandler } from 'utils/post_utils';
import { setPopupCustomAlert } from 'redux/slices/alertSlice';


const YoutubePages = (props) => {
    const { selectedProfile, setProfilePages, profilePages, setShowLess, showLess, selectAll, managePagesOrGroupIdsSelectedForAction, addOrRemoveAllPagesOrGroupIdsSelectedForAction, clearAllChecksTrigger } = props;

    const loggedInUser = useSelector(getLoggedInUser);
    const [res_id, setRes_id] = useState(`${loggedInUser?._id}_facebook_page_request_job_update_${uuidv4()}`);
    const { addPubSubEventListener, removePubSubEventListener, socket } = usePubSub();
    const dispatch = useDispatch();
    const platform = props.platform;
    const [dataCopy, setDataCopy] = useState([]);
    const [pages, setPages] = useState([]);
    const [doneWithFetch, setDoneWithFetch] = useState(false);
    const workSpace = useSelector(getActiveWorkSpace);
    const [lastGroupCursor, setLastGroupCursor] = useState();
    const [lastPageCursor, setLastPageCursor] = useState();
    const [statement, setStatement] = useState();
    const [constantPages, setConstantPages] = useState([]);
    const [allPages, setAllPages] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [pageTokens, setPageTokens] = useState([]);
    const [popedTokens, setPopedTokens] = useState([]);
    const [reducePageCount, setReducePageCount] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [initialRender, setInitialRender] = useState(false);
    const [canLoadMore, setCanLoadMore] = useState(false);

    let limit = 4

    const mergePages = (data, prevPages) => {
        let updatedPages = [...prevPages];
        for (let page of data) {
            let existingPage = prevPages.find((x) => x.id === page.id);
            if (!existingPage) {
                updatedPages.push(page);
            }
        }
        return updatedPages;
    }

    useEffect(() => {
        if (selectAll) {
            let selectedPages = profilePages[selectedProfile?._id]
            addOrRemoveAllPagesOrGroupIdsSelectedForAction(selectedPages, true);
        } else {
            addOrRemoveAllPagesOrGroupIdsSelectedForAction(null, false);
        }
    }, [selectAll])

    const fetchLocations = (platform) => {

        APIService.fetchPagesAndGroups(
            workSpace,
            platform.identifier, platform.user_id, (response, error) => {
                // setFetching(false);
                if (error) {
                    console.log("🚀 ~ fetchLocations ~ error:", error)
                    if (!props?.reconnectionOngoing) {
                        dispatchHandler(setPopupCustomAlert({
                            type: 'error',
                            message: error,
                        }));
                    }
                    return;
                }
                let responseData = response['data'];
                console.log("🚀 ~ fetchLocations ~ responseData:", responseData)
                responseData.sort((a, b) => {
                    return b['connected'] - a['connected'];
                });
                if (pages.length > 0 && responseData.length > 0) {
                    let alreadyExistingPageIds = [];
                    for (let pageInResponse of responseData) {
                        let existingPage = pages.find(x => x.id === pageInResponse.id);
                        if (existingPage) {
                            alreadyExistingPageIds.push(pageInResponse.id);
                        }
                    }
                    responseData = responseData.filter(x => !alreadyExistingPageIds.includes(x.id));
                }
                let retrievedTokens = responseData.filter(x => x.nextPageToken).map(x => {
                    return {
                        token: x.nextPageToken,
                        accountId: x.accountId
                    }
                });
                let tokens = [];
                for (let token of retrievedTokens) {
                    let existingToken = tokens.find(x => x.token == token.token && x.accountId === token.accountId);
                    if (!existingToken) {
                        tokens.push(token);
                    }
                }
                setPages((prevPages) => {
                    prevPages = prevPages ?? [];
                    for (let page of responseData) {
                        let existingPage = prevPages.find(x => x.id === page.id);
                        if (!existingPage) {
                            prevPages.push(page);
                        }
                    }
                    let updatedPages = [...prevPages];
                    setDataCopy(updatedPages);
                    return updatedPages;
                });
                setAllPages((prevPages) => {
                    prevPages = prevPages ?? [];
                    for (let page of responseData) {
                        let existingPage = prevPages.find(x => x.id === page.id);
                        if (!existingPage) {
                            prevPages.push(page);
                        }
                    }
                    let updatedPages = [...prevPages];
                    setDataCopy(updatedPages);
                    return updatedPages;
                })
                setProfilePages({
                    ...profilePages,
                    [platform._id]: mergePages(responseData, profilePages[platform._id] ?? [])
                })
                setPageTokens((prevTokens) => {
                    prevTokens = prevTokens ?? [];
                    if (tokens.length) {
                        for (let token of tokens) {
                            if (!prevTokens.includes(token) && !popedTokens.includes(token)) {
                                prevTokens.push(token);
                            }
                        }
                        return [...prevTokens]
                    } else {
                        return [];
                    }
                    // return [...prevTokens];
                });
                if (reducePageCount) {
                    if (pageTokens.length > 0) {
                        setPopedTokens([...popedTokens, pageTokens[0]]);
                        let [_, ...rest] = pageTokens;
                        setPageTokens(rest);
                    }
                }
            }, `?nextPageToken=${encodeURIComponent(JSON.stringify({
                nextPageToken: pageTokens.length > 0 ? pageTokens[pageTokens.length - 1].token : undefined
            }))}&nextPageTokenAccountId=${encodeURIComponent(JSON.stringify({
                nextPageTokenAccountId: pageTokens.length > 0 ? pageTokens[pageTokens.length - 1].accountId : undefined
            }))}`);

        // APIService.fetchPagesAndGroups(
        //     workSpace,
        //     p.target ?? p.identifier,
        //     p.user_id,
        //     () => { },
        //     `?cursors=${encodeURIComponent(
        //         JSON.stringify({
        //             pagesCursor: lastPageCursor,
        //             groupsCursor: lastGroupCursor,
        //         })
        //     )}&res_id=${res_id}`
        // );
    };

    useEffect(() => {
        setCanLoadMore(pageTokens.length > 0 && !fetching);
        if (pageTokens.length > 0) {
            loadMore()
        } else {
            setAllPages([...pages])
            setConstantPages([...pages]);
            if (!initialRender) {
                setInitialRender(true);
            } else {
                setFetching(false);
            }
        }
    }, [pageTokens]);


    const loadMore = () => {
        fetchLocations(selectedProfile);
    }

    useEffect(() => {
        if (selectedProfile?._id) {
            fetchLocations(selectedProfile);
        }
    }, [selectedProfile])

    if (statement && !profilePages[selectedProfile?._id]?.length) {
        return <SoftBadge bg="info" className="h-2">
            <p className="m-0">{statement}</p>
        </SoftBadge>
    }

    if (!profilePages[selectedProfile?._id]?.length) {
        return <></>
    }


    const cutPagesSizeBasedOnShowLessStatus = () => {
        if (showLess) {
            return profilePages[selectedProfile?._id]?.slice(0, limit)?.map((page, index) => {
                return <YoutubePage
                    key={index}
                    page={page}
                    selectedProfile={selectedProfile}
                    selectAll={selectAll}
                    managePagesOrGroupIdsSelectedForAction={managePagesOrGroupIdsSelectedForAction}
                    clearAllChecksTrigger={clearAllChecksTrigger}
                />
            }
            )
        }
        return profilePages[selectedProfile?._id]?.map((page, index) => {
            return <YoutubePage
                key={index}
                page={page}
                selectedProfile={selectedProfile}
                selectAll={selectAll}
                managePagesOrGroupIdsSelectedForAction={managePagesOrGroupIdsSelectedForAction}
                clearAllChecksTrigger={clearAllChecksTrigger}
            />
        }
        )
    }

    return (
        <>
            <InfiniteScroll
                dataLength={pages.length}
                next={loadMore}
                hasMore={canLoadMore}
            >
                {
                    cutPagesSizeBasedOnShowLessStatus()
                }
                {
                    profilePages[selectedProfile?._id]?.length > limit &&
                    <Flex
                        alignItems={'center'}
                        className={'gap-2'}
                    >
                        <SoftBadge
                            onClick={() => setShowLess(!showLess)}
                            bg="success cursor-pointer" className="h-2">
                            {
                                showLess ? `Show +${profilePages[selectedProfile?._id]?.length - limit} more` : `Show less`
                            }
                        </SoftBadge>
                    </Flex>
                }
            </InfiniteScroll>
            {
                profilePages[selectedProfile?._id]?.length > 0 && canLoadMore &&
                <Spinner animation='border' size={40} />
            }
        </>
    )
}

export default YoutubePages