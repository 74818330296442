import Flex from 'components/common/Flex';
import PageHeader from 'components/common/PageHeader';
import APIService from 'http/api_service';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getLoggedInUser } from 'redux/slices/user_slice';
import PreviewResult from './PreviewResult'
import { getActiveWorkSpace, getBilledYearly, getSearchTerm, setActiveWorkspace } from 'redux/slices/workspaceslice';

const LanguageTranslator = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const workSpace = useSelector(getActiveWorkSpace);
    const loggedInUser = useSelector(getLoggedInUser);
    const [currentChars, setCurrentChars] = useState(workSpace?.used_ai_writer_characters || 0)
    const [apiKey, setApiKey] = useState({ geminiKey: null, claudeAIKey: null, openAIKey: null });
    const [isLoading, setIsLoading] = useState(true)
    const [language, setLanguage] = useState("English")
    const [languageToTranslateFrom, setLanguageToTranslateFrom] = useState('auto')
    const [textToTranslate, setTextToTranslate] = useState('')
    const [buttonText, setButtonText] = useState('Translate')
    const [translatedText, setTranslatedText] = useState(null)
    const [generatingText, setGeneratingText] = useState(false)

    const languages = [
        "English",
        "Spanish",
        "French",
        "German",
        "Italian",
        "Portuguese",
        "Dutch",
        "Russian",
        "Chinese (Simplified)",
        "Chinese (Traditional)",
        "Japanese",
        "Korean",
        "Arabic",
        "Hindi",
        "Bengali",
        "Punjabi",
        "Urdu",
        "Turkish",
        "Hebrew",
        "Greek",
        "Vietnamese",
        "Thai",
        "Swahili",
        "Polish",
        "Romanian",
        "Czech",
        "Hungarian"
    ];

    const checkAIWriterUsageResumption = () => {
        APIService.checkAIWriterUsageResumption(workSpace["_id"], (res, err) => {
            if (err) {
                toast.error(err, { theme: 'colored' })
                return;
            }
            let apikeys = {}
            apikeys.geminiKey = res?.data?.ai_writer_model_settings?.geminiKey
            apikeys.claudeAIKey = res?.data?.ai_writer_model_settings?.claudeAIKey
            apikeys.openAIKey = res?.data?.ai_writer_model_settings?.openAIKey
            setApiKey(apikeys)
            APIService.fetchWorkSpace(workSpace["_id"], (response, error) => {
                if (error) {
                    return;
                }
                if (response) {
                    let responseData = response["data"];
                    let receivedWorkspace = responseData["workspace"];
                    receivedWorkspace["role"] = responseData["role"];
                    receivedWorkspace["value"] = receivedWorkspace["name"];
                    receivedWorkspace["title"] = responseData["title"];
                    receivedWorkspace["label"] = receivedWorkspace["name"];
                    setCurrentChars(response?.data?.workspace?.used_ai_writer_characters)
                    dispatch(setActiveWorkspace({ ...receivedWorkspace }));
                }
                setIsLoading(false)
            });
        });
    };

    const translateText = async () => {
        setGeneratingText(true)
        APIService.translateTextV2(workSpace["_id"], textToTranslate, languageToTranslateFrom, language, (response, error) => {
            if (error) {
                toast.error(error, {
                    theme: 'coloured'
                })
                setGeneratingText(false)
                return;
            }
            const { data } = response
            setGeneratingText(false)
            setTranslatedText(data);
        });
    }

    useEffect(() => {
        checkAIWriterUsageResumption()
    }, [])


    return (
        <>
            <PageHeader
                title=""
            >
                {loggedInUser?.active_ai_writer_subscription ? (
                    <>
                        <h5 className="text-600 fs-0 mb-3">
                            Credits Used:{" "}
                            <span className="text-primary fw-semi-bold">
                                {" "}
                                {loggedInUser?.used_ai_writer_characters.toLocaleString()}/Unlimited
                            </span>{" "}
                            Characters
                        </h5>
                        <h5 className="text-600 fs-0 mb-3">
                            Your credits will reset on:{" "}
                            <span className="text-primary fw-semi-bold">
                                {loggedInUser?.active_ai_writer_subscription.next_bill_date}{" "}
                            </span>{" "}
                        </h5>
                    </>
                ) : (
                    <>
                        <h5 className="text-600 fs-0 mb-3">
                            Credits Used:{" "}
                            <span className="text-primary fw-semi-bold">
                                {" "}
                                {loggedInUser?.used_ai_writer_characters.toLocaleString()}/
                                {loggedInUser?.active_plan["AI_Writer_Chars"].toLocaleString() || loggedInUser?.active_plan["AI_Writer_Words"].toLocaleString()}{" "}
                            </span>
                            Characters
                        </h5>
                        {loggedInUser?.active_plan?.next_bill_date && (
                            <h5 className="text-600 fs-0 mb-3">
                                Monthly credits will reset on:{" "}
                                <span className="text-primary fw-semi-bold">
                                    {loggedInUser?.active_plan.next_bill_date}{" "}
                                </span>{" "}
                            </h5>
                        )}
                    </>
                )}
            </PageHeader>

            <Row>
                <Col lg={6}>
                    <Flex
                        direction={'column'}
                        gap={3}
                        justifyContent={'center'}
                        alignItems={'center'}
                        className={'mt-3'}
                    >
                        <Card
                            style={{
                                width: '100%',
                                padding: 25,
                                borderRadius: 5,
                            }}>
                            <Form.Group>
                                <Form.Label>Translate from</Form.Label>
                                <Form.Select
                                    value={languageToTranslateFrom}
                                    onChange={(e) => {
                                        setLanguageToTranslateFrom(e.target.value);
                                    }}>
                                    <option value={'auto'}>{'AUTO DETECT'}</option>
                                    {
                                        languages.map(x => {
                                            return <option value={x}>{x}</option>
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Card>
                        <Card
                            style={{
                                width: '100%',
                                padding: 25,
                                borderRadius: 5
                            }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 10
                                }}>
                                <Form.Group>
                                    <Form.Label>
                                        Text to translate
                                    </Form.Label>
                                    <Form.Control as={'textarea'}
                                        type="textarea"
                                        value={textToTranslate}
                                        rows={4}
                                        onChange={(e) => setTextToTranslate(e.target.value)}
                                    />
                                </Form.Group>
                                {/* {
                                    !(referenceTag ? referenceTag.navigationDescription : referenceTool.navigationDescription) &&
                                    <span
                                        style={{
                                            textAlign: 'end',
                                            color: '#009688',
                                            paddingTop: 5, fontSize: 13
                                        }}>{`${title.length}/${maximumAllowedDescriptionLength}`}
                                    </span>
                                } */}
                            </div>
                        </Card>
                        <Card
                            style={{
                                width: '100%',
                                padding: 25,
                                borderRadius: 5,
                            }}>
                            <Form.Group>
                                <Form.Label>Translate to</Form.Label>
                                <Form.Select
                                    value={language}
                                    onChange={(e) => {
                                        setLanguage(e.target.value);
                                    }}>
                                    {
                                        languages.map(x => {
                                            return <option value={x}>{x}</option>
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Card>
                        <Button
                            variant={'primary'}
                            isWorking={generatingText}
                            disabled={generatingText || !textToTranslate}
                            style={{ width: '100%' }}
                            onClick={() => {
                                translateText()
                            }}>
                            {
                                generatingText ? 'Translating...' : buttonText
                            }
                        </Button>
                    </Flex>
                </Col>
                <Col lg={6}>
                    {
                        generatingText &&
                        <Flex className={'mt-3'} alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
                            <Flex alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
                                <Spinner animation="border" />
                            </Flex>
                        </Flex>
                    }
                    {
                        !generatingText && translatedText &&
                        // <ResultsPreview
                        //     generatedCopiesTitle={generatedCopiesTitle}
                        //     fetchSavedCopies={fetchSavedCopies}
                        //     referenceTag={referenceTag}
                        //     referenceTool={referenceTool}
                        //     workSpace={workSpace}
                        //     results={results}
                        // />
                        <PreviewResult
                            textGenerated={translatedText}
                        />
                    }
                </Col>
            </Row>
        </>
    )
}

export default LanguageTranslator