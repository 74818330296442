import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from 'components/common/Flex'
import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import WhiteLabelMonthlyYearlySwitch from './WhiteLabelMonthlyYearlySwitch'
import { useSelector } from 'react-redux'
import { getBillYearlyForWhiteLabelStripeConnect } from 'redux/slices/whitelabel_slice'

const PlanCard = (props) => {
    const { product, initProductCheckoutSession } = props;
    const billedYearly = useSelector(getBillYearlyForWhiteLabelStripeConnect);
    const [currentPrice, setCurrentPrice] = useState(null);
    const [selectedForCheckout, setSelectedForCheckout] = useState(false)

    const getSubscriptionPrice = () => {
        let prices = [...product?.prices];
        let filteredPrice;
        if (billedYearly) {
            filteredPrice = prices.find(price => price?.recurring?.interval === 'year');
        } else {
            filteredPrice = prices.find(price => price?.recurring?.interval === 'month');
        }
        setCurrentPrice(filteredPrice);
    }

    useEffect(() => {
        getSubscriptionPrice();
    }, [billedYearly])

    const formatPrice = (unitAmount) => {
        const price = unitAmount / 100;
        let finalPrice = Number.isInteger(price)
            ? price
            : price.toFixed(2)
        return `$${finalPrice}/${billedYearly ? 'year' : 'month'}`
    }


    return (
        <Card
            className='w-100'
            style={{
                minWidth: 300,
                minHeight: 350,
                maxWidth: 300,
            }}
        >
            <Card.Header>
                <Flex
                    direction={'column'}
                    gap={2}
                >
                    <Flex
                        justifyContent={'between'}
                    >
                        <h5>{product?.name}</h5>
                        <h6>{formatPrice(currentPrice?.unit_amount)}</h6>
                    </Flex>
                    <h6>
                        {product?.description}
                    </h6>
                </Flex>
            </Card.Header>
            <Card.Body>
                {
                    product?.features.map((feature, index) => (
                        <Flex
                            key={index}
                            className={``}
                            alignItems={'center'}
                            gap={2}
                        >
                            <FontAwesomeIcon icon='check' color='green' />
                            <span
                                className="fs--1"
                            >{feature?.name}</span>
                        </Flex>
                    ))
                }
            </Card.Body>
            <Card.Footer>
                <Flex
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Button
                        variant="falcon-primary"
                        className="w-100"
                        disabled={props?.processing}
                        onClick={() => {
                            setSelectedForCheckout(true)
                            initProductCheckoutSession(currentPrice?.id)
                        }}
                    >
                        {
                            selectedForCheckout ? `Processing...` : `Choose plan`
                        }
                    </Button>
                </Flex>
            </Card.Footer>
        </Card>
    )
}

export default PlanCard