import Divider from 'components/common/Divider';
import FalconCardHeader from 'components/common/FalconCardHeader'
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import APIService from 'http/api_service';
import React, { useContext, useState } from 'react'
import { Button, Card, Col, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { getActiveWorkSpace, setActiveWorkspace, setActiveWorkspacePlannerSettings } from 'redux/slices/workspaceslice';

const LanguageAndRegion = () => {
    const dispatch = useDispatch();
    const workSpace = useSelector(getActiveWorkSpace);
    const { config } = useContext(AppContext);



    const [savingChanges, setSavingChanges] = useState(false);
    const [changesSaved, setChangesSaved] = useState(false);
    const [requestBody, setRequestBody] = useState({
        language: workSpace?.languageAndRegionSettings?.language,
        dateFormat: workSpace?.languageAndRegionSettings?.dateFormat,
        timeFormat: workSpace?.languageAndRegionSettings?.timeFormat,
        // disableWeekend: workSpace?.schedulingAndCalendarSettings?.disableWeekend,
        // applyToAllworkspaces: workSpace?.schedulingAndCalendarSettings?.applyToAllworkspaces,
        // displayWeeknumber: workSpace?.schedulingAndCalendarSettings?.displayWeeknumber,

    })


    const year = new Date().getFullYear();

    const updateWorkspaceLanguageAndRegionSettingsHandler = (newRequestBody) => {
        setSavingChanges(true);
        setChangesSaved(false);
        APIService.updateWorkspaceLanguageAndRegionSettings(workSpace?._id, newRequestBody, async (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                setSavingChanges(false);
                return;
            }
            const { message, data } = response;
            APIService.fetchWorkSpace(workSpace?._id, (response, error) => {
                if (error) {
                    return;
                }
                if (response) {
                    let responseData = response['data'];
                    let workSpace = responseData['workspace'];
                    workSpace['role'] = responseData['role'];
                    workSpace['value'] = workSpace['name'];
                    workSpace['title'] = responseData['title'];
                    workSpace['label'] = workSpace['name'];
                    workSpace['workspace_owner'] = responseData['workspace_owner'];
                    dispatch(setActiveWorkspace({ ...workSpace }));
                    setSavingChanges(false);
                    setChangesSaved(true);
                }
            });

        })
    }


    const applyToAllWorkspaceSettingsHandler = () => {
        setSavingChanges(true);
        setChangesSaved(false);
        APIService.applyToAllWorkspaces(workSpace?._id, requestBody, "language_and_region_settings", async (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                setSavingChanges(false);
                return;
            }
            const { message, data } = response;
            APIService.fetchWorkSpace(workSpace?._id, (response, error) => {
                if (error) {
                    return;
                }
                if (response) {
                    let responseData = response['data'];
                    let workSpace = responseData['workspace'];
                    workSpace['role'] = responseData['role'];
                    workSpace['value'] = workSpace['name'];
                    workSpace['title'] = responseData['title'];
                    workSpace['label'] = workSpace['name'];
                    workSpace['workspace_owner'] = responseData['workspace_owner'];
                    dispatch(setActiveWorkspace({ ...workSpace }));
                    setSavingChanges(false);
                    setChangesSaved(true);
                    toast.success(message, { theme: "colored" })
                }
            });
        })
    }
    return (
        <Card id="language_and_region_setting" className="mb-3">
            <FalconCardHeader title="Language and region" endEl={
                <>
                    {
                        savingChanges &&
                        <Flex className={'gap-2'}>
                            <Spinner
                                animation='border'
                                size={'sm'}
                                variant={'primary'}
                            />
                            <p
                                style={{
                                    fontSize: 12,
                                    color: '#B0BEC5'
                                }}
                            >
                                Saving changes...
                            </p>
                        </Flex>
                    }
                    {
                        changesSaved &&
                        <p
                            style={{
                                fontSize: 12,
                                color: '#9CCC65',
                            }}
                        >
                            Changes Saved
                        </p>
                    }
                </>
            } />
            <Card.Body className="bg-light">
                <Form.Group>
                    <Col>
                        <>
                            <FloatingLabel controlId="floatingSelect" label="Language" style={{ background: config.isDark ? "#121e2d" : "#f1f3f4" }}>
                                <Form.Select value={requestBody?.language ?? "English (US)"} size="sm" aria-label="Floating label select example" onChange={(e) => {
                                    const newRequestBody = { ...requestBody, language: e.target.value };
                                    setRequestBody(newRequestBody);
                                    updateWorkspaceLanguageAndRegionSettingsHandler(newRequestBody);
                                }}
                                    disabled={savingChanges}
                                >
                                    <option value={"English (US)"}>{"English (US)"}</option>
                                </Form.Select>
                            </FloatingLabel>
                        </>
                        <br />
                        <>
                            <FloatingLabel controlId="floatingSelect" label="Date format" style={{ background: config.isDark ? "#121e2d" : "#f1f3f4" }}>
                                <Form.Select value={requestBody?.dateFormat ?? "European"} size="sm" aria-label="Floating label select example" onChange={(e) => {
                                    const newRequestBody = { ...requestBody, dateFormat: e.target.value };
                                    setRequestBody(newRequestBody);
                                    updateWorkspaceLanguageAndRegionSettingsHandler(newRequestBody);
                                }}
                                    disabled={savingChanges}
                                >
                                    <option value="American">12/31/{year}</option>
                                    <option value="European">31/12/{year}</option>
                                    <option value="Backward">{year}-12-31</option>
                                </Form.Select>
                            </FloatingLabel>
                        </>
                        <br />
                        <>
                            <FloatingLabel controlId="floatingSelect" label="Time format" style={{ background: config.isDark ? "#121e2d" : "#f1f3f4" }}>
                                <Form.Select value={requestBody?.timeFormat ?? "12-hour"} size="sm" aria-label="Floating label select example" onChange={(e) => {
                                    const newRequestBody = { ...requestBody, timeFormat: e.target.value };
                                    setRequestBody(newRequestBody);
                                    updateWorkspaceLanguageAndRegionSettingsHandler(newRequestBody);
                                }}
                                    disabled={savingChanges}
                                >
                                    <option value="12-hour">1:00pm</option>
                                    <option value="24-hour">13:00</option>
                                </Form.Select>
                            </FloatingLabel>
                        </>
                        <Divider />
                        <Flex style={{ width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                            <p>Apply this change to all workspaces</p>
                            <Button onClick={applyToAllWorkspaceSettingsHandler}
                                disabled={savingChanges}
                            >{"Apply"}</Button>
                        </Flex>
                    </Col>
                </Form.Group>
            </Card.Body>
        </Card>
    )
}

export default LanguageAndRegion