import Flex from "components/common/Flex";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useOrganizationStore from "state/organization_store";

export default function OrganizationAndWorkspaceSetupReview({ handleNext }) {

    const { t } = useTranslation();
    const organization = useOrganizationStore(state => state.organizationBeingCreated);
    const workSpace = useOrganizationStore(state => state.workspaceForOrganizationBeingCreated);

    return (
        <Flex direction={'column'}>
            <span
                className="text-center p-3 pt-1"
                dangerouslySetInnerHTML={{
                    __html: t('before_org_and_workspace_creation_prompt', { workspace_name: `<b>${workSpace?.workspace_name ?? workSpace.name}</b>`, organization_name: `<b>${organization.name}</b>`, action_text: `<b>${t('create')}</b>`, verb: workSpace?.existing ? t('moved').toLowerCase() : t('created').toLowerCase().replace("()", ""), preposition: workSpace?.existing ? t('to').toLowerCase() : t('under').toLowerCase() })
                }}
            />
            <Button onClick={handleNext} className="rounded-pill">{t('next')}</Button>
        </Flex>
    );
}