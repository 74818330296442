import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FeedContainer = styled.div`
  border: 1px solid #000;
  border-radius: 2px;
`;

export const FeedListDisplay = styled.div`
  position: fixed;
  min-height: 100vh;
  background: ${props => props.config ? "rgb(11, 23, 39)" : "#fff"};
  width: 18rem;
  padding: 10px;
`;

export const FeedContentFooter = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
`;

export const SelectedPlatformIcon = styled.div`
  position: relative;
  border: 1px solid #000;
  border-radius: 50%;
  padding: 10px 15px;
`;

export const SelectedPlatformCloseButton = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
`;

export const PreviewExistingPlatform = styled.div`
  border: ${(props) => props.color && `1px solid ${props.color}`};
  padding: 5px 10px;
  border-radius: 30px;

  span {
    font-size: 12px;
    color: ${(props) => props.color && props.color};
  }
`;
