import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { RoutePaths } from 'constants';
import APIService from 'http/api_service';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getActiveMetric, getCollectMetric, setActiveMetric } from 'redux/slices/analytics_slice';
import { getPopupUpgradeAlert, getPopupUpgradeAlertForLTD, getPopupUpgradeAlertGoBackOnCancel, setPopupUpgradeAlert, setPopupUpgradeAlertForLTD } from 'redux/slices/alertSlice';
import { getLoggedInUser, setActionForBillingUpgradeModal, setShowFreeUserBillingUpgradeModal } from 'redux/slices/user_slice';
import { getActiveWorkSpace, searchWorkSpace } from 'redux/slices/workspaceslice';
import { forcefullyLogout } from 'utils/auth_utils';
import StringUtils from 'utils/string';
import { isFreeWorkspace } from 'utils/workspace_utils';
import { v4 as uuid } from 'uuid';
import Flex from '../Flex';
import IconButton from '../IconButton';

const FeatureBlockModal = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const workSpace = useSelector(getActiveWorkSpace);
    const loggedInUser = useSelector(getLoggedInUser);
    const popupUpgradeAlert = useSelector(getPopupUpgradeAlert)
    const popupUpgradeAlertForLTD = useSelector(getPopupUpgradeAlertForLTD);
    const popupUpgradeAlertGoBackOnCancel = useSelector(getPopupUpgradeAlertGoBackOnCancel);
    const activeMetric = useSelector(getActiveMetric)
    const collectMetric = useSelector(getCollectMetric)
    const [blockFreeUsersCompletely, setBlockFreeUsersCompletely] = useState(false)
    const currentPath = window.location.pathname;

    const pushOutMetricToServer = (metric) => {
        if (!metric) return;
        APIService.newMetric(metric, (response, error) => {
            if (error) {
                console.log(error)
                return;
            }
        })
    }

    const collectMetrics = (userResponse = false, destroyActiveMetric = false) => {
        if (!userResponse) {
            if (!collectMetric) return;
            const initMetric = {
                metricId: uuid(),
                data: {
                    workspace_id: workSpace?._id,
                    userId: loggedInUser?._id,
                    userEmail: loggedInUser?.email,
                    timestamp: collectMetric?.timestamp || new Date().toISOString(),
                    ...collectMetric
                }
            }
            pushOutMetricToServer(initMetric);
            dispatch(setActiveMetric(initMetric))
            return
        }
        if (!activeMetric) return;
        const updatedMetric = {
            ...activeMetric,
            data: {
                ...activeMetric.data,
                responses: activeMetric?.data?.responses?.length ? [...activeMetric?.data?.responses, userResponse] : [userResponse]
            }
        }
        pushOutMetricToServer(updatedMetric);
        if (destroyActiveMetric) {
            dispatch(setActiveMetric(null))
        } else {
            dispatch(setActiveMetric(updatedMetric))
        }
    }


    const closeModal = () => {
        let response = {
            responseId: uuid(),
            timestamp: new Date().toISOString(),
            response: "Closed upgrade notice",
            buttonClicked: 'CLOSE'
        }
        collectMetrics(response, true)
        if (popupUpgradeAlertGoBackOnCancel) {
            navigate(-1)
        }
        dispatch(setPopupUpgradeAlert(false))
        dispatch(setPopupUpgradeAlertForLTD(false));
        if (activeMetric?.data?.action) {
            let action = activeMetric?.data?.action;
            action = StringUtils.convertSnakeCaseToUpperCase(action);
            dispatch(setActionForBillingUpgradeModal(action))
        }
        dispatch(setShowFreeUserBillingUpgradeModal(true))
    }

    const displayMessages = () => {
        if (popupUpgradeAlertForLTD) {
            return <Modal.Body>
                <p>
                    This feature isn't available on your current plan. You can upgrade with a one-time fee or switch to one of our subscription plans to access it.
                </p>
                <p>
                    Thank you for your understanding and support!
                </p>
            </Modal.Body>
        }
        return <Modal.Body>
            <p>
                To access this feature, please upgrade your account. Upgrading unlocks additional benefits and enhanced functionality.
            </p>

            <p>
                If you have any questions, our support team is here to help.
            </p>
            <p>
                Thank you!
            </p>
        </Modal.Body>
    }

    const displayNoFreePlanMessage = () => {
        return <Modal.Body>
            <p>
                This feature isn't available on your current plan. You can upgrade to one of our subscription plans to access it.
            </p>
            <p>
                Thank you for your understanding and support!
            </p>
        </Modal.Body>
    }

    useEffect(() => {
        collectMetrics()
    }, [collectMetric])

    // useEffect(() => {
    //     if (currentPath?.includes(RoutePaths.BILLING_STRIPE)) {
    //         setBlockFreeUsersCompletely(false);
    //         return;
    //     }
    //     let timeout = currentPath?.endsWith(RoutePaths.POSTS) || currentPath?.endsWith(RoutePaths.POSTS + '/') || currentPath === '/' ? 5000 : 0;
    //     if (isFreeWorkspace(workSpace)) {
    //         setTimeout(() => {
    //             setBlockFreeUsersCompletely(true)
    //         }, timeout);
    //     }
    // }, [currentPath]);

    return (
        <div>
            <Modal
                size="md"
                centered
                show={popupUpgradeAlert || blockFreeUsersCompletely}
                onHide={() => {
                    if (blockFreeUsersCompletely) {
                        return
                    }
                    closeModal()
                }}
            >
                <Modal.Header>
                    <span
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <Modal.Title>
                                Hi there 👋
                            </Modal.Title>
                            {
                                blockFreeUsersCompletely ?
                                    <Modal.Title>
                                        <IconButton
                                            onClick={() => {
                                                dispatch(searchWorkSpace(true));
                                            }}
                                            variant="falcon-default"
                                            size="sm"
                                            icon={faArrowRightArrowLeft}
                                            transform="shrink-3"
                                            className="mx-2"
                                        >
                                            <span>Switch workspace</span>
                                        </IconButton>
                                    </Modal.Title> : null
                            }
                        </div>
                    </span>
                </Modal.Header>
                {/* {displayMessages()} */}
                {displayNoFreePlanMessage()}
                <Modal.Footer style={{ flexDirection: "column" }}>
                    <Flex justifyContent={"flex-start"} alignItems={"center"} gap={10}>
                        <Button
                            variant="danger"
                            onClick={() => {
                                if (blockFreeUsersCompletely) {
                                    forcefullyLogout()
                                } else {
                                    closeModal()
                                }
                            }}>
                            {
                                blockFreeUsersCompletely ? 'Logout' : `No thanks`
                            }
                        </Button>
                        <Button onClick={() => {
                            let response = {
                                responseId: uuid(),
                                timestamp: new Date().toISOString(),
                                response: `Clicked on upgrade button for ${popupUpgradeAlertForLTD ? 'LTD' : 'Subscription'} plan`,
                                buttonClicked: 'UPGRADE'
                            }
                            collectMetrics(response, false)
                            dispatch(setPopupUpgradeAlert(false))
                            dispatch(setPopupUpgradeAlertForLTD(false))
                            navigate(RoutePaths.BILLING_STRIPE)
                        }}>Yes, upgrade</Button>
                    </Flex>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default FeatureBlockModal