import React, { useEffect, useState } from 'react'
import FeatureBlockModal from './FeatureBlockModal'
import ReminderModal from './ReminderModal'
import LoaderModal from './LoaderModal'
import AlertModal from './AlertModal'

const GlobalModals = () => {
    return (
        <>
            <LoaderModal />
            <FeatureBlockModal />
            <ReminderModal />
            <AlertModal />
        </>
    )
}

export default GlobalModals