import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    freshNotificationPopup: {
        show: false,
        message: ``,
        header: ``,
        optionToDisableFutureNotifications: false,
        type: 'info'
    },
    showSideNotificationPanel: false,
    sideNotifications: [],
    overlayLoader: null,
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setFreshNotificationPopup: (state, action) => {
            state.freshNotificationPopup = action.payload;
        },
        setSideNotificationPanel: (state, action) => {
            state.showSideNotificationPanel = action.payload;
        },
        setSideNotifications: (state, action) => {
            state.sideNotifications = action.payload;
        },
        setOverlayLoader: (state, action) => {
            state.overlayLoader = action.payload;
        },
        
    }
});

export const getFreshNotificationPopup = (state) => state.notification.freshNotificationPopup;
export const getSideNotificationPanel = (state) => state.notification.showSideNotificationPanel;
export const getSideNotifications = (state) => state.notification.sideNotifications;
export const getOverlayLoader = (state) => state.notification.overlayLoader;

export let {
    setFreshNotificationPopup,
    setSideNotificationPanel,
    setSideNotifications,
    setOverlayLoader
} = notificationSlice.actions;
export default notificationSlice.reducer;