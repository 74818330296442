export const instaFeedReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
      case 'ADD':
          // Ensure payload is an array before spreading
          if (!Array.isArray(payload) || payload.length === 0) {
              return state; // Return current state if payload is not valid
          }
          return [...state, ...payload];

      case 'UPDATE':
          // Ensure payload contains the necessary data
          if (!payload || !payload.id) {
              return state; // Return current state if payload is not valid
          }
          return state.map(feed =>
              feed.id === payload.id ? { ...feed, ...payload.feed } : feed
          );

          case 'REMOVE':
            return payload

      default:
          return state; // Return current state for unrecognized action types
  }
};
