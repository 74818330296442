import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import APIService from "http/api_service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getFeedPublishInterval,
  getFeedSelectedPlatforms,
  getTimeZoneToPostFeedContent,
  setFeedPublishInterval,
  setFeedSelectedPlatforms,
  setOpenFeedSettings,
} from "redux/slices/postslice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { PlatformIdentifier } from "../../../constants";
import {
  platforms as AppPlatforms,
  findPlatformByIdentifier,
} from "../platforms";
import ConfigureFeedTimezone from "../platforms/youtube/configure_feed_timezone";
import PostAudienceListItem from "../social/feed/audience/audience_list_item";
import { generateTimeClause } from "../social/feed/time_to_post_configurer";
import PublishFeedPopOver from "./publish_feed_popover";
import { PreviewExistingPlatform } from "./styles/feed";

const FeedSettings = (props) => {
  const {
    showSettings,
    hideSettings,
    feedData,
    updateFeedData,
    toggleSettings,
    only = [],
    refreshFeed,
    includesImage,
  } = props;
  const feedMetaData = feedData?.metadata;
  const platformsWithExclusions = (data) => {
    if (only.length > 0) {
      data = data.filter((x) => only.includes(x.identifier));
    }
    return data;
  };

  const dispatch = useDispatch();
  const workSpace = useSelector(getActiveWorkSpace);
  const timeZoneToPost = useSelector(getTimeZoneToPostFeedContent);
  const feedPublishInterval = useSelector(getFeedPublishInterval);
  const [feedName, setFeedName] = useState(feedData?.user_name);
  const [feedUrl, setFeedUrl] = useState(feedMetaData?.url);
  const [updatingFeed, setUpdatingFeed] = useState(false);
  const [clickableIds, setClickableIds] = useState([]);
  const [existingSelectedPlatforms, setExistingSelectedPlatforms] = useState(feedMetaData?.platform ?? []);
  const [showSelectPlatform, setShowSelectPlatform] = useState(false);
  const [selectedPlatformsToPreview, setSelectedPlatformsToPreview] = useState([]);
  const [previewPlatforms, setPreviewPlatforms] = useState(false);
  const [previewOtherOptions, setPreviewOtherOptions] = useState(false);
  const [titleAsCaption, setTitleAsCaption] = useState(feedMetaData["title_as_caption"] ?? false);
  const [descriptionAsCaption, setDescriptionAsCaption] = useState(feedMetaData["description_as_caption"] ?? false);
  const [postMediaOption, setPostMediaOption] = useState(feedMetaData?.media_option ?? null);
  const [postAction, setPostAction] = useState(feedMetaData?.post_action);
  const [publishingInterval, setPublishInterval] = useState(feedMetaData["publish_interval"]?.type ?? "default_period");
  const [includeArticleLink, setIncludeArticleLink] = useState(feedMetaData["include_article_link"] ?? false);
  const [timeToPost, setTimeToPost] = useState(new Date());
  const [timeChanged, setTimeChanged] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [stopRunningCampaign, setStopRunningCampaign] = useState(false);
  const [settingsText, setSettingsText] = useState("");
  const [previewExistingPlatforms, setPreviewExistingPlatforms] = useState([]);
  const [platforms, setPlatforms] = useState(localStorage.getItem("cached_platforms") ? platformsWithExclusions(JSON.parse(localStorage.getItem("cached_platforms"))) : []);
  const feedSelectedPlatforms = useSelector(getFeedSelectedPlatforms);
  const [showPublishInterval, setShowPublishInterval] = useState(false);
  const [publishDuration, setPublishDuration] = useState();
  const [feedUpdated, setFeedUpdated] = useState(false);

  const postActions = [
    {
      id: "no_action",
      action: "No action",
    },
    {
      id: "auto_post",
      action: "Auto post",
    },
    // {
    //   id: "auto_schedule",
    //   action: "Auto schedule",
    // },
  ];

  const publishInterval = [
    {
      id: "interval_period",
      name: "Publish period"
    },
    {
      id: "default_period",
      name: "Default (every 10 minutes)"
    }
  ];

  const postMediaPref = [
    {
      id: "post_as_link",
      name: "Post new article as links",
    },
    {
      id: "post_as_photo",
      name: "Post new article as photo",
    },
  ];

  useEffect(() => {
    if (postAction === "auto_post") {
      setSettingsText("Publish");
    } else if (postAction === "auto_schedule") {
      setSettingsText("Schedule");
    } else {
      setSettingsText("Continue");
    }
  }, [postAction]);

  const platformIsDisabled = (platform) => {
    return AppPlatforms.find((x) => x?.identifier === platform?.identifier)
      ?.disabled;
  };

  const handleSavedChanges = () => {
    setShowSelectPlatform(false);
    setPreviewPlatforms(true);
  };

  useEffect(() => {
    let _newPlatforms = [];
    for (let platform of existingSelectedPlatforms) {
      let platformIdentifier = findPlatformByIdentifier(platform.identifier);
      _newPlatforms.push(platformIdentifier);
    }
    setSelectedPlatformsToPreview(_newPlatforms);
  }, [existingSelectedPlatforms]);

  useEffect(() => {
    targetedPlatforms(platforms);
  }, [platforms]);

  useEffect(() => {
    if (publishingInterval === "default_period") {
      const duration = {
        time_difference: 10,
        period: "minutes",
      }
      let defaultPublishDelay = moment().add(10, "minutes").toDate().getTime();
      dispatch(setFeedPublishInterval({ time: defaultPublishDelay, type: publishingInterval, duration }));
    }
  }, [publishingInterval]);

  useEffect(() => {
    if (postAction) {
      switch (postAction) {
        case "auto_post":
        case "auto_schedule":
          setShowSelectPlatform(true);
          setPreviewOtherOptions(true);
          setStopRunningCampaign(false);
          setShowPublishInterval(true);
          break;
        default:
          setShowSelectPlatform(false);
          setPreviewPlatforms(false);
          setExistingSelectedPlatforms([]);
          setPreviewOtherOptions(false);
          setTitleAsCaption(false);
          setDescriptionAsCaption(false);
          setPostMediaOption(null);
          setIncludeArticleLink(false);
          setStopRunningCampaign(true);
          setShowPublishInterval(false);
          break;
      }
    }
  }, [postAction]);

  const fetchConnectedPlatforms = () => {
    setDataFetched(true);
    APIService.fetchWorkSpacePlatforms(workSpace["_id"], (response, error) => {
      if (error) {
        setDataFetched(false);
        if (error?.toLowerCase() === `WorkSpace ID is required`.toLowerCase()) {
          return;
        }
        toast.error(error, { theme: "colored" });
        return;
      }
      if (response) {
        setDataFetched(false);
        let connectedAccounts = response["data"];
        organizePlatforms(connectedAccounts);
      }
    });
  };

  useEffect(() => {
    fetchConnectedPlatforms();
  }, []);

  const organizePlatforms = (connectedAccountsInWorkSpace) => {
    let data = connectedAccountsInWorkSpace.reduce((acc, current) => {
      let platformIdentifier = current.identifier;
      let platform = acc.find(
        (platform) => platform.identifier === platformIdentifier
      );
      platform = platform ?? {
        name: current.name,
        identifier: platformIdentifier,
      };
      let accounts = platform.accounts ?? [];
      if (current.identifier === PlatformIdentifier.FACEBOOK) {
        let connectedPagesUnderCurrent =
          current.connected_pages_and_groups ?? [];
        current.connected_pages_and_groups = connectedPagesUnderCurrent.filter(
          (x) => x.target === PlatformIdentifier.FACEBOOK
        );
      }
      if (current.identifier === PlatformIdentifier.TELEGRAM) {
        if (current.connected_pages_and_groups) {
          for (let connectedPageOrChannel of current.connected_pages_and_groups) {
            connectedPageOrChannel.target = PlatformIdentifier.TELEGRAM;
          }
        }
      }
      accounts.push(current);
      platform.accounts = accounts;
      let existingPlatform = acc.find(
        (platform) => platform.identifier === platformIdentifier
      );
      if (!existingPlatform) {
        acc.push(platform);
      }
      return acc;
    }, []);
    setPlatforms(platformsWithExclusions([...data]));
  };

  const targetedPlatforms = (platforms) => {
    let platformsCopy = JSON.parse(JSON.stringify(platforms));
    let selectedPlatformsCopy = [...feedSelectedPlatforms];
    for (let platform of platformsCopy) {
      let accounts = platform.accounts;
      for (let account of accounts) {
        let alreadyPushedPlatform = selectedPlatformsCopy.find((entry) => entry.identifier === platform.identifier);
        let indexOfAlreadyPushedPlatform = selectedPlatformsCopy.indexOf(alreadyPushedPlatform);
        if (account.connected_pages_and_groups) {
          if (account.connected_pages_and_groups.some((item) => item.selected)) {
            account.selected = true;
            platform.selected = true;
            if (!alreadyPushedPlatform) {
              selectedPlatformsCopy.push(platform);
            } else {
              selectedPlatformsCopy[indexOfAlreadyPushedPlatform] = platform;
            }
          }
          if (account.connected_pages_and_groups.every((item) => !item.selected)) {
            if (alreadyPushedPlatform) {
              let otherAccounts = alreadyPushedPlatform.accounts.filter(
                (otherAccount) => otherAccount._id !== account._id
              );
              if (otherAccounts.length > 0) {
                let canBeRemoved = true;
                for (let otherAccount of otherAccounts) {
                  if (otherAccount.connected_pages_and_groups) {
                    let someItemsWereSelected =
                      otherAccount.connected_pages_and_groups.some(
                        (itemInOtherAccount) => itemInOtherAccount.selected
                      );
                    if (someItemsWereSelected) {
                      canBeRemoved = false;
                      break;
                    }
                  }
                }
                if (canBeRemoved) {
                  let indexOfAlreadyPushedPlatform =
                    selectedPlatformsCopy.indexOf(alreadyPushedPlatform);
                  selectedPlatformsCopy.splice(indexOfAlreadyPushedPlatform, 1);
                }
              } else {
                let indexOfAlreadyPushedPlatform =
                  selectedPlatformsCopy.indexOf(alreadyPushedPlatform);
                selectedPlatformsCopy.splice(indexOfAlreadyPushedPlatform, 1);
              }
            }
          }
        } else {
          if (account.selected) {
            platform.selected = true;
            if (!alreadyPushedPlatform) {
              selectedPlatformsCopy.push(platform);
            } else {
              selectedPlatformsCopy[indexOfAlreadyPushedPlatform] = platform;
            }
          }
          if (accounts.every((item) => !item.selected)) {
            platform.selected = false;
            if (alreadyPushedPlatform) {
              let indexOfAlreadyPushedPlatform = selectedPlatformsCopy.indexOf(
                alreadyPushedPlatform
              );
              selectedPlatformsCopy.splice(indexOfAlreadyPushedPlatform, 1);
            }
          }
        }
      }
    }
    dispatch(setFeedSelectedPlatforms(selectedPlatformsCopy));
    // setExistingSelectedPlatforms(selectedPlatformsCopy);
  };

  const updateFeedSettings = () => {
    setUpdatingFeed(true);
    const requestBody = {
      displayName: feedName,
      user_id: feedData?.user_id,
      url: feedUrl,
      platforms: feedSelectedPlatforms,
      post_action: postAction ?? "no_action",
      ["title_as_caption"]: titleAsCaption ?? false,
      ["description_as_caption"]: descriptionAsCaption ?? false,
      ["include_article_link"]: true,
      ["publish_interval"]: feedPublishInterval,
    };
    if (postAction === "auto_schedule") {
      requestBody.timeZone = timeZoneToPost;
      requestBody["time_to_post"] = timeToPost;
    }
    if (postAction !== "no_action") {
      requestBody["media_option"] = postMediaOption;
    }
    APIService.upsertExistingRSSFeed(workSpace["_id"], requestBody, (response, error) => {
      if (error) {
        setUpdatingFeed(false);
        toast.error(error, { theme: "colored" });
        return;
      }
      const updatedFeed = response["data"];
      setFeedUpdated(true);
      setUpdatingFeed(false);
      toast.success(response?.message, { theme: "colored" });
      dispatch(setOpenFeedSettings(false));
      toggleSettings(false);
      if (updatedFeed) {
        APIService.postRSSFeedContent(workSpace["_id"], feedData?.user_id, (response, error) => {
          if (error) {
            return;
          }
          window.location.reload();
        });
      }
    }
    );
  };

  const showSelectedPlatformsForPreview = (existingPlatforms) => {
    let previewPlatform = [];
    if (existingPlatforms?.length) {
      for (let platform of existingPlatforms) {
        let accounts = platform?.accounts;
        for (let account of accounts) {
          if (account?.identifier === PlatformIdentifier.TWITTER) {
            if (account?.selected) {
              let platformByIdentifier = findPlatformByIdentifier(account?.identifier);
              let selectedAccount = { ...platformByIdentifier, account };
              previewPlatform.push(selectedAccount);
            }
          }
          let connectedPagesAndGroups =
            account?.connected_pages_and_groups ?? [];
          connectedPagesAndGroups = connectedPagesAndGroups.filter(
            (x) => x.selected
          );
          if (connectedPagesAndGroups)
            for (let page of connectedPagesAndGroups) {
              let platformByIdentifier = findPlatformByIdentifier(page?.target);
              let selectedAccount = { ...platformByIdentifier, page };
              previewPlatform.push(selectedAccount);
            }
        }
      }
      setPreviewExistingPlatforms(previewPlatform);
    }
  };

  useEffect(() => {
    showSelectedPlatformsForPreview(existingSelectedPlatforms);
  }, [existingSelectedPlatforms]);

  const saveTargetedPlatforms = () => (
    <div
      style={{
        padding: 10,
        display: "flex",
        flex: 1,
        alignSelf: "flex-end",
        justifyContent: "flex-end",
        gap: 10,
        flexWrap: "wrap",
      }}
    >
      <Button
        onClick={() => {
          handleSavedChanges();
        }}
        autoFocus
        variant={"primary"}
        style={{
          fontSize: 12,
        }}
      >
        Preview
      </Button>
    </div>
  );

  const selectPlatformBtn = (
    <Flex justifyContent={"center"} alignItems={"center"}>
      <Button
        onClick={() => {
          setShowSelectPlatform(true);
        }}
        variant="primary"
      >
        Select platform
      </Button>
    </Flex>
  );

  const scheduleTimeToPost = () => {
    return (
      <ConfigureFeedTimezone
        timeZoneToPost={timeZoneToPost}
        timeToPost={timeToPost}
        setTimeToPost={setTimeToPost}
        generateTimeClause={generateTimeClause}
        timeChanged={timeChanged}
        setTimeChanged={setTimeChanged}
      />
    );
  };

  const settingsForm = (
    <Modal
      show={showSettings}
      onHide={hideSettings}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Feed Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3 g-3 px-2">
          {previewOtherOptions && (
            <Alert variant="info" className="p-2 mb-0">
              <Flex>
                <FontAwesomeIcon icon="info-circle" className="fs-2" />
                <div className="ms-3 flex-1">
                  <p>
                    1. Pinterest & Instagram platforms do not allow text-only posts
                  </p>
                  <p>2. To stop a running campaign, select No action</p>
                </div>
              </Flex>
            </Alert>
          )}

          {stopRunningCampaign && (
            <Alert variant="info" className="p-2 mb-0">
              <Flex>
                <FontAwesomeIcon icon="info-circle" className="fs-2" />
                <div className="ms-3 flex-1">
                  To stop a running campaign, select No action
                </div>
              </Flex>
            </Alert>
          )}
          <Form.Group as={Col} lg={12}>
            <Form.Label>{"Feed Name"}</Form.Label>
            <Form.Control
              type={"text"}
              value={feedName}
              placeholder={"Feed name"}
              onChange={(e) => {
                setFeedName(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} lg={12}>
            <Form.Label>{"Feed URL"}</Form.Label>
            <Form.Control
              type={"text"}
              value={feedUrl}
              placeholder={"Feed URL"}
              disabled={true}
              onChange={(e) => {
                setFeedUrl(e.target.value);
              }}
            />
          </Form.Group>
          <h5>Post action</h5>
          <Flex alignItems={"center"} justifyContent={"flex-start"}>
            {postActions.map(({ id, action }) => (
              <Form.Check
                inline
                key={action}
                type="radio"
                name="post-actions"
                label={action}
                id={id}
                checked={postAction === id}
                onChange={(e) => {
                  let action = e.target.id;
                  setPostAction(action);
                }}
              />
            ))}
          </Flex>

          {previewOtherOptions && (
            <>
              <Form.Group>
                <Form.Check
                  type={"checkbox"}
                  checked={titleAsCaption}
                  label={`Use the article title as caption`}
                  id={`title_as_caption`}
                  onChange={(e) => {
                    setTitleAsCaption(e.target.checked);
                  }}
                />

                <Form.Check
                  type={"checkbox"}
                  checked={descriptionAsCaption}
                  label={"Use the article description as caption"}
                  id={`description_as_caption`}
                  onChange={(e) => {
                    setDescriptionAsCaption(e.target.checked);
                  }}
                />
              </Form.Group>

              {showPublishInterval &&
                <>
                  <h5>Publish interval</h5>
                  <Flex justifyContent={"flex-start"} direction={"column"}>
                    {publishInterval.map(({ id, name }) => (
                      <>
                        <Form.Check
                          inline
                          key={name}
                          type="radio"
                          name="publish-interval"
                          label={name}
                          id={id}
                          checked={publishingInterval === id}
                          onChange={(e) => {
                            let interval = e.target.id;
                            setPublishInterval(interval);
                          }} />
                      </>
                    ))}
                  </Flex>
                  {
                    publishingInterval === "interval_period" &&
                    <PublishFeedPopOver
                      setPublishDuration={setPublishDuration}
                      publishingInterval={publishingInterval}
                    />
                  }
                </>
              }

              {/* <Form.Group>
                {includesImage && postMediaPref.map(({ id, name }) => (
                  <Form.Check
                    key={id}
                    type={"radio"}
                    id={id}
                    checked={postMediaOption === id}
                    label={name}
                    name={"post-media-option"}
                    onChange={(e) => {
                      setPostMediaOption(e.target.id);
                    }}
                  />
                ))}
              </Form.Group> */}
            </>
          )}
          {showSelectPlatform && dataFetched && (
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              className={"vh-100"}
              style={{ width: "100vh" }}
            >
              <Spinner animation="border" size={40} />
            </Flex>
          )}
          {!dataFetched && showSelectPlatform && (
            <>
              <Flex alignItems={"center"} gap={3} wrap={"wrap"}>
                {previewExistingPlatforms?.length > 0 &&
                  previewExistingPlatforms.map((platform) => (
                    <PreviewExistingPlatform color={platform?.color}>
                      <Flex
                        alignItems={"center"}
                        gap={2}
                        justifyContent={"center"}
                      >
                        <FontAwesomeIcon
                          icon={platform?.icon}
                          color={platform?.color}
                        />
                        {platform?.identifier === PlatformIdentifier.TWITTER ?
                          <span>{platform?.account?.user_name}</span> :
                          <span>{platform?.page?.name}</span>}
                      </Flex>
                    </PreviewExistingPlatform>
                  ))}
              </Flex>
              {platforms
                .filter(
                  (x) =>
                    x.identifier !== PlatformIdentifier.RSS_FEED &&
                    x.identifier !== PlatformIdentifier.YOUTUBE &&
                    x.identifier !== PlatformIdentifier.TIKTOK
                )
                .map((platform, index) => {
                  if (!platformIsDisabled(platform)) {
                    return (
                      <>
                        <PostAudienceListItem
                          key={index}
                          platform={platform}
                          platformChangedHandler={(changedPlatform) => {
                            setPlatforms((prevPlatforms) => {
                              let existingChangedPlatform = prevPlatforms.find(
                                (item) =>
                                  item.identifier === changedPlatform.identifier
                              );
                              let indexOfChangedPlatform =
                                prevPlatforms.indexOf(existingChangedPlatform);
                              if (indexOfChangedPlatform !== -1) {
                                prevPlatforms[indexOfChangedPlatform] =
                                  JSON.parse(JSON.stringify(changedPlatform));
                              }
                              return JSON.parse(
                                JSON.stringify([...prevPlatforms])
                              );
                            });
                          }}
                        />
                      </>
                    );
                  }
                })}
            </>
          )}
          {/* {showSelectPlatform && saveTargetedPlatforms()} */}
          <div>{postAction === "auto_schedule" && scheduleTimeToPost()}</div>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          style={{
            border: "1px solid rgb(30, 99, 254)",
            minHeight: "35px",
            fontSize: "14px",
          }}
          onClick={hideSettings}
        >
          Cancel
        </Button>
        <Button
          disabled={updatingFeed}
          style={{
            backgroundColor: "rgb(30, 99, 254)",
            minHeight: "35px",
            padding: "5px 25px",
          }}
          onClick={updateFeedSettings}
        >
          {updatingFeed ? (
            <Spinner
              as={"span"}
              role="status"
              animation="border"
              size="sm"
              aria-hidden="true"
            />
          ) : (
            <span
              style={{
                fontSize: "14px",
              }}
            >
              {settingsText}
            </span>
            // `${
            //   postAction === "auto_post"
            //     ? <span>"Publish"</span>
            //     : postAction === "auto_schedule"
            //     ? "Schedule"
            //     : "Continue"
            // }`
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (feedData) {
    return settingsForm;
  }
};

export default FeedSettings;
