import React, { useState, useReducer, useRef } from "react";
import PropTypes from "prop-types";
import { ChatContext } from "context/Context";
import { messageReducer } from "reducers/arrayReducer";
import { useSelector } from "react-redux";
import { groups } from "./data/chat/groups";
import { onNewUser, useSocketState } from "./chatSocket";
import { useEffect } from "react";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import { getLoggedInUser } from "redux/slices/user_slice";

const ChatProvider = ({ children }) => {
  const currentUser = useSelector(getLoggedInUser);
  console.log("🚀 ~ ChatProvider ~ currentUser:", currentUser?._id)
  const [hideSidebar, setHideSidebar] = useState(false);
  const [conversations, conversationsDispatch] = useReducer(messageReducer, []);
  const [currentChats, currentChatsDispatch] = useReducer(messageReducer, []);
  const [currentConversation, setCurrentConversation] = useState([]);
  const [activeConversationId, setActiveConversationId] = useState([]);
  const [activeRoomId, setActiveRoomId] = useState(null);
  const [userChats, setUserChats] = useState([]);
  const [senderId, setSenderId] = useState(null);
  const [textAreaInitialHeight, setTextAreaInitialHeight] = useState(32);
  const [isOpenThreadInfo, setIsOpenThreadInfo] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const isMountedRef = useRef(true);
  const [users, setUsers] = useState([]);
  const [guests, setGuests] = useState([]);

  const { _id } = currentUser ?? {};

  const socketState = null
  // const socketState = useSocketState();
  useEffect(() => {
    console.log("Socket is", socketState);
    if (socketState !== "connected") {
      // toast.warning("Your are offline", { theme: 'colored' });

      return; // Exit early if socket is not connected
    }

    onNewUser(_id);
    // toast.success("Your are back online", { theme: 'colored' });
  }, [_id, socketState]);

  const getAllUser = async () => {
    try {
      if (!isMountedRef?.current) {
        return;
      }

      // setIsLoading(true);

      APIService.getAllSocialInboxUsers((data, error) => {
        if (!isMountedRef?.current) {
          return;
        }

        // setIsLoading(false);

        if (error) {
          toast.error(error, { theme: "colored" });
          return;
        } else {
          if (!isMountedRef?.current) {
            return;
          }

          let message = data["message"];
          toast.success(message, { theme: "colored" });
          setUsers(data);
        }
      });
    } catch (error) {
      if (!isMountedRef?.current) {
        return;
      }

      // setIsLoading(false);
    }
  };

  useEffect(() => {
    isMountedRef.current = true;

    getAllUser();

    return () => {
      isMountedRef.current = false;
    };
  }, [_id]);

  const getUser = (thread) => {
    if (thread?.room_type === "group") {
      // Assuming 'groups' and 'users' are defined somewhere
      const group = groups.find(({ id }) => id === thread.groupId);
      if (group) {
        const user = {
          name: group.name,
          avatarSrc: group.members.map((member) => {
            const matchingUser = users.find(({ id }) => id === member.userId);
            return matchingUser ? matchingUser.avatarSrc : null;
          }),
        };
        return user;
      }
    } else {
      const roomChat =
        conversations?.find(({ room_id }) => room_id === thread) || {};

      if (roomChat?.messages) {
        const userMessage = roomChat.messages.find(
          (msg) => msg.sender !== roomChat.user_id
        );
        if (userMessage) {
          return userMessage.message;
        }
      }
    }

    return {};
  };

  const getAccount = (thread) => {
    const roomChat =
      conversations?.find(({ roomId }) => roomId === thread) || {};

    if (roomChat?.messages) {
      const userMessage = roomChat.messages.find(
        (msg) => msg.sender === roomChat.userId
      );
      if (userMessage) {
        return userMessage.message;
      }
    }

    return {};
  };

  const value = {
    users,
    setUsers,
    guests,
    groups,
    getAccount,
    getUser,
    hideSidebar,
    setHideSidebar,
    activeRoomId,
    setActiveRoomId,
    senderId,
    setSenderId,
    conversations,
    userChats,
    setUserChats,
    conversationsDispatch,
    currentChats,
    currentChatsDispatch,
    currentConversation,
    setCurrentConversation,
    activeConversationId,
    setActiveConversationId,
    textAreaInitialHeight,
    setTextAreaInitialHeight,
    isOpenThreadInfo,
    setIsOpenThreadInfo,
    scrollToBottom,
    setScrollToBottom,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

ChatProvider.propTypes = { children: PropTypes.node.isRequired };

export default ChatProvider;