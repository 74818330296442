import React from "react";
import { Button } from "react-bootstrap";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddMoreFeeds = ({ connectFeedUrl }) => {
  return (
    <>
      <Button
        onClick={() => connectFeedUrl()}
        variant={"primary"}
        size="sm"
        type="button"
        style={{
          width: "100%",
          padding: "5px 0",
        }}
      >
        <Flex
          className={"gap-2"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <FontAwesomeIcon icon="plus" className="fs--1" />
          <span className="fs--1">Connect more feed</span>
        </Flex>
      </Button>
      <hr />
    </>
  );
};

export default AddMoreFeeds;
