import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import SoftBadge from "components/common/SoftBadge";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setRedirectBack } from "redux/slices/platformSlice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { getTagForPages } from "utils/gen";
import { findPlatformByIdentifier } from "..";
import APIService from "../../../../http/api_service";
import { Dropdown } from "react-bootstrap";
import { Plugs, UserCheck } from "@phosphor-icons/react";
import TelegramGroupsAndChannels from ".";

const TelegramProfile = (props) => {
    const { platform,
        setSelectedProfile,
        selectedProfile,
        refreshPlatformAccounts,
        setRefreshPlatformAccounts,
        refreshProfiles,
        setRefreshProfiles,
    } = props
    const [connectedAccounts, setConnectedAccounts] = useState([]);
    const [displayNameAsAvatar, setDisplayNameAsAvatar] = useState(false);
    const [fetchingAccounts, setFetchingAccounts] = useState(true);
    const [fetchError, setFetchError] = useState(null);
    const workSpace = useSelector(getActiveWorkSpace);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchConnectedAccounts = (redirectToPagesAndGroups = false, redirectFurtherBack = false, reselectDefaultProfile = false) => {
        setFetchingAccounts(true);
        setFetchError(null);
        APIService.fetchConnectedAccounts(
            workSpace["_id"],
            platform.target ?? platform.identifier,
            (response, error) => {
                setFetchingAccounts(false);
                if (error) {
                    setFetchError(error);
                    return;
                }
                let { data } = response;
                setFetchError(null);
                if (data && data.length > 0) {
                    data = data.map((account, index) => {
                        let { user_id, user_name, user_photo, ...platform } =
                            findPlatformByIdentifier(account.target);
                        let newAccount = { ...account, ...platform };
                        return newAccount;
                    });
                }
                setConnectedAccounts(data);
                if ((!selectedProfile || reselectDefaultProfile) && data?.length) {
                    setSelectedProfile(data[0])
                } else if (reselectDefaultProfile && !data?.length) {
                    setSelectedProfile(null)
                }
                // dispatch(setRefreshConnectedFeed(false));
                if (redirectToPagesAndGroups) {
                    let lastIndex = data.length - 1;
                    let latestAccount = data[lastIndex];
                    if (latestAccount) {
                        const hexString = Buffer.from(JSON.stringify(latestAccount)).toString("hex")
                        if (hexString) {
                            if (redirectFurtherBack) {
                                dispatch(setRedirectBack(true))
                            }
                            navigate(`./${hexString}`);
                        }
                    }
                }
            }
        );
    };

    useEffect(() => {
        if (refreshProfiles) {
            setRefreshProfiles(false)
            fetchConnectedAccounts(false, false, true);
        }
    }, [refreshProfiles]);

    useEffect(() => {
        if (platform) {
            if (fetchingAccounts) {
                fetchConnectedAccounts();
            }
        }
    }, []);

    useEffect(() => {
        if (refreshPlatformAccounts === (platform?.identifier || platform?.target)) {
            fetchConnectedAccounts();
            setRefreshPlatformAccounts(null);
        }
    }, [refreshPlatformAccounts])



    if (!connectedAccounts || connectedAccounts?.length === 0) {
        return <></>
    }

    const displayAvatar = (account) => {
        return displayNameAsAvatar ?
            <span
                style={{
                    fontSize: 20,
                    color: 'black',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    alignSelf: 'center',
                }
                } > {account.user_name.charAt(0).toString().toUpperCase()}
            </span >
            : account.user_photo ? <img
                src={account.user_photo}
                alt={account.user_name}
                onError={() => {
                    setDisplayNameAsAvatar(true);
                }}
                style={{
                    width: 20,
                    height: 20,
                    borderRadius: 40,
                }}
            /> : <span
                style={{
                    fontSize: 20,
                    color: 'black',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    alignSelf: 'center',
                }}>{account.user_name.charAt(0).toString().toUpperCase()}
            </span>

    }

    const displayConnectedPageCount = (account) => {
        let connectedPagesAndGroups = account.connected_pages_and_groups;
        if (connectedPagesAndGroups) {
            connectedPagesAndGroups = connectedPagesAndGroups.filter((x) => x.target === account.target);
        }
        let connectedPagesLength = (connectedPagesAndGroups ?? []).length;
        if (connectedPagesLength === 0) {
            return null;
        }
        return `${connectedPagesAndGroups.length} connected ${getTagForPages(account, connectedPagesAndGroups.length > 1)}`;
    }

    return (
        <>
            <Flex
                direction={'column'}
            >
                <Flex>
                    {
                        connectedAccounts.map((account, index) => (
                            <SoftBadge
                                key={index}
                                bg={selectedProfile?._id === account?._id ? 'primary' : 'secondary'}
                                className="me-2 mb-2 cursor-pointer rounded-pill mt-2 hover-bg-red-100"
                                onClick={() => {
                                    setSelectedProfile(account);
                                }}
                                style={{
                                    minHeight: 10
                                }}
                            >
                                <Flex
                                    alignItems={'start'}
                                    className={'gap-2'}
                                >
                                    {
                                        displayAvatar(account)
                                    }
                                    <Flex
                                        alignItems={'start'}
                                        direction={'column'}
                                        className={'me-2'}
                                    >
                                        <p className="mb-1"
                                            style={{
                                                margin: 0,
                                                marginLeft: 7
                                            }}
                                        >
                                            {account.user_name ?? ''} {
                                                selectedProfile?._id === account?._id && <FontAwesomeIcon icon="check-circle" className="text-success" />
                                            }
                                        </p>
                                        {
                                            <SoftBadge bg="success" className="">
                                                {displayConnectedPageCount(account)}
                                            </SoftBadge>
                                        }
                                    </Flex>
                                </Flex>
                            </SoftBadge>
                        ))
                    }
                </Flex>
                <Flex>
                    {
                        selectedProfile &&
                        <TelegramGroupsAndChannels
                            platform={selectedProfile}
                            selectedProfile={selectedProfile}
                            setSelectedProfile={setSelectedProfile}
                            refreshProfiles={refreshProfiles}
                            setRefreshProfiles={setRefreshProfiles}
                        />
                    }
                </Flex>
            </Flex>

        </>
    )
}

export default TelegramProfile