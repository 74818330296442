import Comments from 'components/app/social/feed/Comments'
import FeedActionButtons from 'components/app/social/feed/FeedActionButtons'
import FeedReactions from 'components/app/social/feed/FeedReactions'
import Avatar from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import { InstaFeedContext } from 'context/Context'
import React, { useContext, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { v4 as uuid } from 'uuid';
import av3 from 'assets/img/team/3.jpg';
import InstaFeedActionButtons from './InstaFeedActionButtons'
import InstaComments from './InstaComments'
import APIService from 'http/api_service'
import { toast } from 'react-toastify'
import { getSelectedPageForPostCommentsView } from 'redux/slices/instagramComments_slice'
import { useSelector } from 'react-redux';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

const InstaFeedCardFooter = ({
  user,
  id,
  countLCS,
  reactions,
  comments,
  otherComments
}) => {
  dayjs.extend(relativeTime);
  const { feeds, instaFeedDispatch } = useContext(InstaFeedContext);
  const selectedPageForPostCommentsView = useSelector(getSelectedPageForPostCommentsView);

  //state hook that'll handle display comment reply input field.
  const [showReplyInput, setShowReplyInput] = useState(false);
  //write state to handle the reply comment input field
  const [comment, setComment] = useState('');
  //write state for if coment is being submited.
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateNewComments = async (commentId) => {
    const newComment = {
      id: commentId || "dfghj75d5fg",
      avatarSrc: user?.avatarSrc,
      name: user?.name,
      content: comment,
      postTime: dayjs().to(dayjs(new Date()))
      // postTime: new Date().toISOString()
    };
 

    const feed = feeds.find(feed => feed.id === id);
    feed.details.reactions.comment = true;
    if(feed.details.comments?.length > 0){
      feed.details.comments = [newComment, ...comments];
    } else {
      feed.details.comments = [newComment];
    }
    instaFeedDispatch({ type: 'UPDATE', payload: { id, feed } });
    console.log("🚀 ~ updateNewComments ~ feed:", feed)
    instaFeedDispatch({ type: 'UPDATE', payload: { id, feed } });
    setComment('');
  }

  const submitComment = () => {
    setIsSubmitting(true)
    const requestBody = {
      mediaId: id,
      message: comment,
      accessToken: selectedPageForPostCommentsView?.accessToken,
    };
    //write an if condition that'll check if the request body object keys has value.
    if (!requestBody.message) {
      toast.error('Please enter a comment', { theme: "colored" });
      setIsSubmitting(false);
      return;
    }

    APIService.createInstagramComment(requestBody, async (data, error) => {
      setIsSubmitting(false);
      if (error) {
        toast.error(error, { theme: "colored" })
        console.log("🚀 ~ APIService.getUserInstagramPosts ~ error:", error);
        return;
      } else if(data) {
        console.log("🚀 ~ APIService.getUserInstagramPosts ~ data:", data)
        let returnedData = data;
        if(returnedData?.id) {
          updateNewComments(returnedData.id);
        }
        toast.success("Comment added successfully...", { theme: "colored" })
        setComment("")
        return;
      }
    });

  };
  return (
    <Card.Footer className="bg-light pt-0">
      {/* <FeedReactions {...countLCS} /> */}
      <InstaFeedActionButtons id={id} reactions={reactions} />
      <Form onSubmit={(e) => {
        e.preventDefault();
        submitComment()
      }}>
        <Flex alignItems="center" className="border-top border-200 pt-3">
          <Avatar src={user?.avatarSrc || av3} size="xl" />
          <Form.Control
            type="text"
            className="rounded-pill ms-2 fs--1"
            placeholder="Write a comment..."
            value={comment}
            onChange={e => setComment(e.target.value)}
            disabled={isSubmitting}
          />
        </Flex>
      </Form>
      {!!comments && (
        <InstaComments user={user} comments={comments} loadComment={otherComments} />
      )}
    </Card.Footer>
  )
}

export default InstaFeedCardFooter