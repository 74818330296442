import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import { getUsersSignup } from 'redux/slices/admin_slice';
import { useSelector } from 'react-redux';
import FalconCardHeader from 'components/common/FalconCardHeader';

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    CanvasRenderer
]);

const getOptions = (data, label) => ({
    tooltip: {
        trigger: 'axis',
        formatter: '<strong>{b0}</strong> : {c0}'
    },
    xAxis: {
        data: label,
    },
    series: [
        {
            type: 'line',
            data,
            smooth: true,
            lineStyle: {
                width: 3
            },

            areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color: rgbaColor(getColor('primary'), 0.25)
                        },
                        {
                            offset: 1,
                            color: rgbaColor(getColor('primary'), 0)
                        }
                    ]
                }
            }
        }
    ],
    grid: {
        bottom: '2%',
        top: '2%',
        right: '10px',
        left: '10px'
    }
});
const NewUsers = (props) => {
    const usersSignup = useSelector(getUsersSignup);
    const [dataLabel, setDataLabel] = useState({
        data: [{ count: 0, day: "Sunday" },
        { count: 0, day: "Monday" },
        { count: 0, day: "Tuesday" },
        { count: 0, day: "Wednesday" },
        { count: 0, day: "Thursday" },
        { count: 0, day: "Friday" },
        { count: 0, day: "Saturday" }], label: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    });
    const [filterOptions, setFilterOptions] = useState(['daily', 'weekly']);
    const [selectedOption, setSelectedOption] = useState(0);
    const [totalNewUsers, setTotalNewUsers] = useState(0);
    const fallBackValues = [
        { count: 0, day: "Sunday" },
        { count: 0, day: "Monday" },
        { count: 0, day: "Tuesday" },
        { count: 0, day: "Wednesday" },
        { count: 0, day: "Thursday" },
        { count: 0, day: "Friday" },
        { count: 0, day: "Saturday" }
    ]

    function reorderArrayByToday(arr, ignore = false) {
        if (ignore) return arr;
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const todayIndex = new Date().getDay(); // 0 (Sunday) to 6 (Saturday)
        const todayDay = daysOfWeek[todayIndex];
        if (!todayDay) return arr;
        const index = arr?.findIndex(item => item?.day === todayDay);
        const beforeToday = arr.slice(index + 1);
        const fromToday = arr.slice(0, index + 1);
        return beforeToday.concat(fromToday);
    }

    function formatNumber(num) {
        // if (num >= 1000) {
        //     return (num / 1000).toFixed(1) + 'k';
        // }
        return num?.toString();
    }

    const convertSnakeCaseToSentence = (str) => {
        return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    }

    const constructWeeklyReturningUsersData = () => {
        let _data = [];
        let label = [];
        let index = parseInt(selectedOption)
        let indexValue = filterOptions[index]
        // let usersData = usersSignup[indexValue] || fallBackValues
        let usersData = usersSignup[indexValue] ? reorderArrayByToday(usersSignup[indexValue]) : reorderArrayByToday(fallBackValues, true)
        let currentWeek = 1
        // console.log("🚀 ~ constructWeeklyReturningUsersData ~ _returningUser:", _returningUser, 'selectedOption', selectedOption)
        for (const user of usersData) {
            _data.push(user.count)
            if (indexValue === 'daily') {
                label.push(user?.day)
            } else {
                label.push(`Week ${currentWeek}`)
            }
            currentWeek++
        }
        setDataLabel({ data: _data, label })
        if (indexValue === 'daily') {
            const total = _data.reduce((a, b) => a + b, 0)
            setTotalNewUsers(formatNumber(total))
        } else {
            const total = _data.reduce((a, b) => a + b, 0)
            setTotalNewUsers(formatNumber(total))
        }
    }

    useEffect(() => {
        if (!usersSignup) return;
        constructWeeklyReturningUsersData();
    }, [usersSignup, selectedOption])
    return (
        <Card className="h-md-100">
            <FalconCardHeader
                title="New users"
                titleTag="h6"
                className="pb-0"
                endEl={
                    <Flex>
                        <Form.Select
                            size="sm"
                            value={selectedOption}
                            onChange={(e) => {
                                setSelectedOption(e.target.value)
                            }}
                            className="me-2"
                        >
                            {filterOptions.map((entry, index) => (
                                <option value={index} key={entry}>
                                    {convertSnakeCaseToSentence(entry)}
                                </option>
                            ))}
                        </Form.Select>
                        {/* <CardDropdown /> */}
                    </Flex>
                }
            />

            <Card.Body
                as={Flex}
                alignItems="end"
                justifyContent="between"
                className="pt-0"
            >
                <div>
                    <h2 className="fw-normal text-700 mb-1 lh-1 fs-2">{totalNewUsers}</h2>
                    <Badge pill bg="200" className="text-primary fs--2">
                        <FontAwesomeIcon icon="caret-up" className="me-1" />
                        {/* 13.6% */}
                        weekly
                    </Badge>
                </div>
                <div className="ps-0">
                    <BasicECharts
                        echarts={echarts}
                        options={getOptions(dataLabel?.data, dataLabel?.label)}
                        style={{ width: '8.5rem', height: 87 }}
                    />
                </div>
            </Card.Body>
        </Card>
    );
};

NewUsers.propTypes = { data: PropTypes.array.isRequired };

export default NewUsers;
