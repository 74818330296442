import styled from "styled-components";

export const OverviewContainer = styled.div`
  background: #fff;
  padding: 1.3rem;
  border-radius: 10px;
  width: 15%;
`;

export const ProfileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ProfileText = styled.div`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight ?? "700"};
`;
