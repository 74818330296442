import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CopyLinkButton from 'components/common/copylinkbutton';
import React, { useState } from 'react'
import { Alert, Button, Modal, Spinner } from 'react-bootstrap';
import { copyToClipboard } from 'utils/browser';
import CopyIcon from "../../../assets/svgs/CopyIcon.svg";
import { faCheckCircle, faClose } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import APIService from 'http/api_service';
import ConfirmModal from 'components/common/ConfirmModal';
import { AiOutlineClose } from 'react-icons/ai';
import IconAlert from 'components/common/IconAlert';
import ActionButton from 'components/common/ActionButton';



const DomainItem = ({ x, getAllDomain }) => {
    const [removingDomain, setRemovingDomain] = useState(false);
    const [removableDomain, setRemovableDomain] = useState();
    const [verifiableDomain, setVerifiableDomain] = useState();
    const [openRemoveDomainPrompt, setOpenRemoveDomainPrompt] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);





    //removing custom domain functionality
    const removeDomain = async () => {
        setRemovingDomain(true);
        // remove domain with id: removableDomain
        APIService.removeDomainFromWorkSpace(
            { custom_domain_id: removableDomain?.result?.id },
            (response, error) => {
                if (error) {
                    console.log(error);
                    setRemovingDomain(false);
                    return;
                }
                setRemovingDomain(false);
                const { message } = response;
                toast.success(message);
                getAllDomain();
            }
        );
    };

    return (
        <>
            <tr>
                <td>
                    {x?.result?.hostname}{" "}
                </td>
                <td>
                    <span style={{ fontSize: "12px" }}>
                        {x?.result?.ownership_verification?.name}{" "}
                    </span>
                    {/* {x?.result?.ownership_verification?.name}{" "} */}
                    <CopyLinkButton
                        defaultText={<img src={CopyIcon} alt="copy" />}
                        variant={"outline-info"}
                        handleCopy={() => {
                            copyToClipboard(x?.result?.ownership_verification?.name);
                        }}
                        style={{
                            fontSize: "12px",
                            padding: "5px",
                            backgroundColor: "inherit",
                            border: "none",
                        }}
                    />
                    <span>
                        {x?.result?.status !== "pending" ? (
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={{
                                    color: "green",
                                }}
                            />
                        ) : (
                            <div
                                style={{ color: "red", fontSize: "10px" }}
                            >
                                Pending verification
                            </div>
                        )}
                    </span>
                </td>
                <td>TXT</td>
                {/* <td>CNAME</td> */}
                <td style={{ fontSize: "12px" }}>
                    {x?.result?.ownership_verification?.value}{" "}
                    <CopyLinkButton
                        defaultText={<img src={CopyIcon} alt="copy" />}
                        variant={"outline-info"}
                        handleCopy={() => {
                            copyToClipboard(x?.result?.ownership_verification?.value);
                        }}
                        style={{
                            fontSize: "12px",
                            padding: "5px",
                            backgroundColor: "inherit",
                            border: "none",
                        }}
                    />
                </td>
                {/* <td>
                    <Button style={{ fontSize: "12px" }} onClick={() => setShowSaveModal(true)}>Set up Instruction</Button>
                </td> */}
                <td>
                    {" "}
                    {(removingDomain &&
                        removableDomain?.result?.id === x?.result?.id) ? (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : (
                        <ActionButton icon="trash-alt" title="Delete" variant="action" className="p-0"  onClick={() => {
                            setRemovableDomain(x);
                            setOpenRemoveDomainPrompt(true);
                        }} />
                        // <FontAwesomeIcon
                        //     style={{ cursor: "pointer" }}
                        //     icon={faClose}
                        //     onClick={() => {
                        //         setRemovableDomain(x);
                        //         setOpenRemoveDomainPrompt(true);
                        //     }}
                        // />
                    )}
                </td>
            </tr>
            <ConfirmModal
                open={openRemoveDomainPrompt}
                message={"Are you sure about removing this domain?"}
                title={`Remove ${removableDomain?.result?.hostname}?`}
                onConfirm={() => {
                    setOpenRemoveDomainPrompt(false);
                    removeDomain();
                }}
                onCancel={() => {
                    setOpenRemoveDomainPrompt(false);
                }}
                cancelText={"CANCEL"}
                confirmText={"REMOVE"}
            />
            <Modal
                size="lg"
                centered
                show={showSaveModal}
                onHide={() => setShowSaveModal(false)}
                onExit={() => setShowSaveModal(false)}
            >
                <div style={{ padding: "0px" }}>
                    <div style={{ border: "1px solid #bfc9d7" }}>
                        <Modal.Header>
                            <span
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: "10px",
                                    }}
                                >
                                    <Modal.Title>Custom domain set up Instruction</Modal.Title>
                                </div>
                                <AiOutlineClose
                                    cursor={"pointer"}
                                    onClick={() => setShowSaveModal(false)}
                                />
                            </span>
                        </Modal.Header>
                        <Modal.Body>
                            <IconAlert
                                variant={"info"}
                                dismissible={false}
                                style={{ backgroundColor: "inherit" }}
                            >
                                For now we only support domains hosted on CloudFlare.
                            </IconAlert>
                            {/* <p className="text-danger">For now we only support domains hosted on CloudFlare.</p> */}
                            <u><p>Setting Up Custom Domain: Quick Steps</p></u>
                            <ol>
                                <li>
                                    Create a CNAME record that points <strong>{x?.result?.hostname}</strong> to <strong>link-shortener.postly.ai</strong>
                                </li>
                                <li>
                                    Edit the SSL/TLS settings for <strong>{x?.result?.hostname}</strong> to Full not Full(Strict), then show the image I shared in the group here so they have a visual clue of how the settings page look like.
                                </li>
                                <li>
                                    Copy and add the generated TXT record here to your domain DNS setting.
                                </li>
                            </ol>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    gap: "10px",
                                    marginTop: "20px",
                                }}
                            >
                                <Button
                                    variant='danger'
                                    style={{
                                        alignSelf: "flex-end",
                                        // backgroundColor: "#fafbfd",
                                        minHeight: 35,
                                        // color: "#7f8894",
                                    }}
                                    onClick={() => setShowSaveModal(false)}
                                    size="sm"
                                    className={`px-3 px-sm-5`}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Body>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default DomainItem