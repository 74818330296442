import APIService from "http/api_service";
import { dispatchHandler } from "./post_utils";
import { setLoggedInUser } from "redux/slices/user_slice";
import { setActiveWorkspace } from "redux/slices/workspaceslice";

const forcefullyLogout = () => {
    APIService.logOut((res, err) => { });
    dispatchHandler(setLoggedInUser(null));
    dispatchHandler(setActiveWorkspace(null));
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
}

export {
    forcefullyLogout
}