
import { store } from 'redux/store';
import { getManuallyEditedPlatforms, getSelectedPlatforms } from 'redux/slices/postslice';
import { getSideNotifications, setSideNotifications } from 'redux/slices/notification_slice';

const dispatchSideNotification = (notification) => {
    const sideNotificationsFromRedux = getSideNotifications(store.getState());
    if (Array.isArray(notification)) {
        const newNotifications = notification.filter((n) => {
            return !sideNotificationsFromRedux.some((sn) => sn.message === n.message);
        });
        if (newNotifications.length > 0) {
            store.dispatch(setSideNotifications([...newNotifications, ...sideNotificationsFromRedux]));
        }
    } else {
        if (!sideNotificationsFromRedux.some((sn) => sn.message === notification.message)) {
            store.dispatch(setSideNotifications([notification, ...sideNotificationsFromRedux]));
        }
    }
}

const thereIsUnreadSideNotification = () => {
    const sideNotifications = getSideNotifications(store.getState());
    if (!sideNotifications || !sideNotifications.length) {
        return false;
    }
    return sideNotifications.some((sn) => !sn.read);
}

const markAllSideNotificationsAsRead = () => {
    const sideNotifications = getSideNotifications(store.getState());
    if (!sideNotifications || !sideNotifications.length) {
        return;
    }
    const updatedNotifications = sideNotifications.map((sn) => {
        return {
            ...sn,
            read: true
        }
    });
    store.dispatch(setSideNotifications(updatedNotifications));
}

const markSingleSideNotificationAsRead = (notification) => {
    const sideNotifications = getSideNotifications(store.getState());
    if (!sideNotifications || !sideNotifications.length) {
        return;
    }
    const updatedNotifications = sideNotifications.map((sn) => {
        if (sn.message === notification.message) {
            return {
                ...sn,
                read: true
            }
        }
        return sn;
    });
    store.dispatch(setSideNotifications(updatedNotifications));
}

const preparePreScheduledPostMetadata = (post) => {
    const sideNotifications = getSideNotifications(store.getState());
    const manuallyEditedPlatforms = getManuallyEditedPlatforms(store.getState());
    const selectedPlatforms = getSelectedPlatforms(store.getState())
    let newNotifications = [];
    for (const n of sideNotifications) {
        let eligible = selectedPlatforms.find(
            (x) => x.identifier === n.platform && x.selected
        );
        if (!eligible) continue;
        newNotifications.push({
            ...n,
            style: 'side_notification'
        })
    }
    return newNotifications;
}

export {
    dispatchSideNotification,
    thereIsUnreadSideNotification,
    markAllSideNotificationsAsRead,
    preparePreScheduledPostMetadata,
    markSingleSideNotificationAsRead
}