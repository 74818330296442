import React from 'react'
import { Card } from 'react-bootstrap'
import InstaFeedCardHeader from './InstaFeedCardHeader'
import InstaFeedCardContent from './InstaFeedCardContent'
import InstaFeedCardFooter from './InstaFeedCardFooter'
import PropTypes from 'prop-types';

const InstaFeedCard = ({ feed, ...rest }) => {
    const { id, user, content, details } = feed;
    console.log("🚀 ~ InstaFeedCard ~ id:", id)
  return (
    <Card {...rest} style={{width: "50%"}}>
    {!!user && <InstaFeedCardHeader {...user} />}
    {!!content && <InstaFeedCardContent {...content} />}
    {!!details && <InstaFeedCardFooter user={user} id={id} {...details} />}
  </Card>
  )
}

InstaFeedCard.Header = InstaFeedCardHeader;

InstaFeedCard.propTypes = {
  feed: PropTypes.object
};

export default InstaFeedCard