import Flex from 'components/common/Flex'
import SoftBadge from 'components/common/SoftBadge'
import { RoutePaths } from 'constants'
import APIService from 'http/api_service'
import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getLoggedInUser } from 'redux/slices/user_slice'
import { getActiveWorkSpace } from 'redux/slices/workspaceslice'
import { isAccountUnderAppSumo, isLoggedInUserOwnerOfWorkspace } from 'utils/user_utils'
import { platforms } from '.'
import SocialPlatformItems from './SocialPlatformItems'

const PlatformPageNew = () => {
  const [socialPlatforms] = useState(platforms);
  const navigate = useNavigate();
  const loggedInUser = useSelector(getLoggedInUser);
  const [originalSocialPlatforms, setOriginalSocialPlatforms] = useState(platforms);
  const [showLess, setShowLess] = useState(true);
  const [platformCountStatementData, setPlatformCountStatementData] = useState(null);
  const workSpace = useSelector(getActiveWorkSpace);
  const workSpaceId = workSpace?._id;

  const getPlatformCountStatement = () => {
    APIService.fetchUserPlatformsCountStatement(workSpaceId, (response, error) => {
      if (error) {
        // toast.error(error, { theme: 'colored' });
        return;
      }
      const { data } = response;
      setPlatformCountStatementData(data);
    });
  }

  useEffect(() => {
    getPlatformCountStatement()
  }, [])

  const callToActionButton = () => {
    if (!isLoggedInUserOwnerOfWorkspace(loggedInUser, workSpace)) return;
    
    if (isAccountUnderAppSumo(loggedInUser)) {
      return 'Buy extra LTD codes'
    }
    let planKeyInLowerCase = loggedInUser?.active_plan?.Key?.toLowerCase();
    if (planKeyInLowerCase !== 'free' && !planKeyInLowerCase?.includes('agency')) {
      if (loggedInUser?.active_plan?.revision === 3) {
        return 'Upgrade for more channels'
      } else {
        return 'Upgrade for more social accounts'
      }
    }
    return null
  }


  return (
    <>
      {/* <PageHeader
        title=""
        titleTag="h5"
        className="mb-3 w-100"
      > */}
      <Card
        className="mb-3"
      >
        <Card.Body>
          <Flex
            alignItems={'center'}
            justifyContent={'between'}
            gap={2}
            className="w-100"
          >
            {
              platformCountStatementData &&
              <SoftBadge pill bg={platformCountStatementData?.color} className='me-2 cursor-pointer'>
                {platformCountStatementData?.statement}
              </SoftBadge>
            }
            {
              callToActionButton() ? <Button
                className="fs--1"
                onClick={() => {
                  navigate(RoutePaths.BILLING_STRIPE);
                }}
              >
                {callToActionButton()}
              </Button> : null
            }
          </Flex>
        </Card.Body>
      </Card>
      {/* </PageHeader> */}
      {
        socialPlatforms.map((platform, index) => (
          <SocialPlatformItems
            platform={platform}
            key={index}
            showLess={showLess}
            setShowLess={setShowLess}
          />
        ))
      }
    </>
  )
}

export default PlatformPageNew