import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    popupUpgradeAlert: false,
    popupUpgradeAlertForLTD: false,
    popupUpgradeAlertGoBackOnCancel: false,
    popupCustomAlert: [],
}
export const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setPopupUpgradeAlert: (state, action) => {
            state.popupUpgradeAlert = action.payload;
        },
        setPopupUpgradeAlertForLTD: (state, action) => {
            state.popupUpgradeAlertForLTD = action.payload;
        },
        setPopupUpgradeAlertGoBackOnCancel: (state, action) => {
            state.popupUpgradeAlertGoBackOnCancel = action.payload;
        },
        setPopupCustomAlert: (state, action) => {
            state.popupCustomAlert.push(action.payload);
        },
        clearPopupCustomAlert: (state) => {
            state.popupCustomAlert = []; 
        }
    }
});
export const getPopupUpgradeAlert = (state) => state.alert.popupUpgradeAlert;
export const getPopupUpgradeAlertForLTD = (state) => state.alert.popupUpgradeAlertForLTD;
export const getPopupUpgradeAlertGoBackOnCancel = (state) => state.alert.popupUpgradeAlertGoBackOnCancel;
export const getPopupCustomAlert = (state) => state.alert.popupCustomAlert;

export let {
    setAiArtResults,
    setPopupUpgradeAlert,
    setPopupUpgradeAlertForLTD,
    setPopupUpgradeAlertGoBackOnCancel,
    setPopupCustomAlert,
    clearPopupCustomAlert
} = alertSlice.actions;
export default alertSlice.reducer;