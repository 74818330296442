/* eslint-disable react-hooks/exhaustive-deps */
import { RoutePaths } from 'constants';
import React, { useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getGoogleDriveManager, setPostlyCloudPickerMediaType } from 'redux/slices/postslice';
import { getLoggedInUser } from 'redux/slices/user_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import PostlyLogo from '../../assets/images/postly symbol brand.png';
import ConfirmModal from './ConfirmModal';

export default function PostlyCloudImagePicker(props) {
    const { fileType } = props;
    const navigate = useNavigate();
    const [initiateLoader, setInitiateLoader] = useState(false);
    const workSpace = useSelector(getActiveWorkSpace);
    const workSpaceId = workSpace._id;
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const dispatch = useDispatch();


    return (

        <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}
            onClick={() => {
                dispatch(setPostlyCloudPickerMediaType(fileType))
                navigate(`${RoutePaths.POSTLY_CLOUD}`)
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    cursor: 'pointer',
                    gap: "5px"
                }}>
                <img
                    src={PostlyLogo}
                    style={{ width: 40, height: 40 }}
                    alt="Postly Logo"
                />

                <span
                    style={{
                        fontSize: "10px",
                        width: 'auto',
                        // textAlign: 'center',
                        color: 'inherit'
                    }}>
                    {
                        'Postly Cloud'
                    }

                </span>

            </div>
            <ConfirmModal
                open={openUpgradeDialog}
                title={"Action requires Upgrade"}
                message={"Sorry, GDrive Download and Upload is not avaliable on free plan. Upgrade your plan to use this feature. Thank you!"}
                confirmText={"UPGRADE"}
                cancelText={"CANCEL"}
                onCancel={() => {
                    setOpenUpgradeDialog(false);
                }}
                onConfirm={() => {
                    setOpenUpgradeDialog(false);
                    navigate(`${RoutePaths.BILLING_STRIPE}`);
                }}
            />

        </div>
    );
}
