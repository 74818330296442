import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import APIService from 'http/api_service';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { IoMdCopy } from "react-icons/io";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClose, faPencil } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from 'components/common/ConfirmModal';
import InfoBar from 'components/common/InfoBar';

const EmailSetup = (props) => {
    const { domain } = props;
    const { config } = useContext(AppContext);
    const activeWorkspace = useSelector(getActiveWorkSpace);
    const [preparingDomain, setPreparingDomain] = useState(false);
    const [activeDomain, setActiveDomain] = useState(domain)
    const [customEmailVerification, setCustomEmailVerification] = useState(null)
    const [activeDomainVerificationArray, setActiveDomainVerificationArray] = useState([]);
    const [openEmailRemovalModal, setOpenEmailRemovalModal] = useState(false);
    const [usernameInEditMode, setUsernameInEditMode] = useState(false)
    const [emailUsername, setEmailUsername] = useState('')
    const [updatingUsername, setUpdatingUsername] = useState(false)
    const [openTable, setOpenTable] = useState(false)
    const [showVerifyButton, setShowVerifyButton] = useState(false)

    useEffect(() => {
        setActiveDomain(domain)
        setCustomEmailVerification(domain?.customEmailVerification || null)
        setEmailUsername(domain?.customEmailDomainUsername ?? 'team')
    }, [domain])

    const domainVerificationStatementColumn = [
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: "Name",
            selector: row => <div style={{ display: 'flex', flexDirection: 'column', gap: 6, marginTop: 2, marginBottom: 2, color: config.isDark ? '#9da9bb' : 'black' }}>
                <IoMdCopy
                    size={20}
                    cursor={'pointer'}
                    title={`Click to copy ${row.name}`}
                    onClick={() => {
                        navigator.clipboard.writeText(row.name)
                        toast.success(`${row.name} copied to clipboard.`, {
                            theme: 'colored',
                        })
                    }}
                />
                <div>{row.name}</div>
            </div>,
            sortable: true,
        },
        {
            name: "Value",
            selector: row => <div style={{ display: 'flex', flexDirection: 'column', gap: 6, marginTop: 2, marginBottom: 2, color: config.isDark ? '#9da9bb' : 'black' }}>
                <IoMdCopy
                    size={20}
                    cursor={'pointer'}
                    title={`Click to copy ${row.value}`}
                    onClick={() => {
                        navigator.clipboard.writeText(row.value)
                        toast.success(`${row.name} copied to clipboard.`, {
                            theme: 'colored',
                        })
                    }}
                />
                <div>{row.value}</div>
            </div>,
            sortable: true,
        },
    ];

    const constructTableArrayFromCustomEmailVerification = () => {
        let newArray = [];
        let domainIdentity = customEmailVerification?.domainIdentity.VerificationToken
        let DkimTokens = customEmailVerification?.domainDkimResponse?.DkimTokens
        if (!domainIdentity) {
            setActiveDomainVerificationArray([]);
            return;
        }
        let defaultObject = {
            type: 'TXT',
            name: `_dmarc.${activeDomain?.apexName}`,
            value: `v=DMARC1; p=none;`
        }
        newArray.push(defaultObject);
        for (const token of DkimTokens) {
            let newObject = {
                type: 'CNAME',
                name: `${token}._domainkey.${activeDomain?.apexName}`,
                value: `${token}.dkim.amazonses.com`
            }
            newArray.push(newObject);
        }
        setActiveDomainVerificationArray(newArray);
    }

    useEffect(() => {
        constructTableArrayFromCustomEmailVerification()
    }, [customEmailVerification])

    const destroyCustomDomainForEmailSupportHandler = async (domain) => {
        setPreparingDomain(true);
        APIService.destroyCustomDomainForEmailSupport(activeWorkspace._id, domain?._id, (response, error) => {
            if (error) {
                console.log(error);
                setPreparingDomain(false);
                return;
            }
            const { data } = response;
            setActiveDomain(data)
            setCustomEmailVerification(data?.customEmailVerification)
            setPreparingDomain(false);
        });
    }

    const prepareCustomDomainForEmailSupportHandler = async (domain) => {
        setPreparingDomain(true);
        APIService.prepareCustomDomainForEmailSupport(activeWorkspace._id, domain?._id, (response, error) => {
            if (error) {
                console.log(error);
                setPreparingDomain(false);
                return;
            }
            const { data } = response;
            setActiveDomain(data)
            setCustomEmailVerification(data?.customEmailVerification)
            setPreparingDomain(false);
            setOpenTable(true);
            setShowVerifyButton(true)
        });
    }

    const updateCustomEmailUsername = async () => {
        setUpdatingUsername(true);
        let requestBody = {
            customEmailDomainUsername: emailUsername
        }
        APIService.updateCustomEmailUsername(activeWorkspace._id, domain?._id, requestBody, (response, error) => {
            if (error) {
                console.log(error);
                setUpdatingUsername(false);
                setUsernameInEditMode(false)
                toast.error(error, {
                    theme: 'colored'
                })
                return;
            }
            const { data } = response;
            setActiveDomain(data)
            setCustomEmailVerification(data?.customEmailVerification)
            setEmailUsername(data?.customEmailDomainUsername ?? 'team')
            setUpdatingUsername(false);
            setUsernameInEditMode(false)
        });
    }

    const controlSetUpDisplay = () => {
        if (activeDomain?.customEmailVerified) {
            return <>
                <Flex
                    justifyContent={'between'}
                    alignItems={'center'}
                >
                    {
                        usernameInEditMode &&
                        <Flex
                            justifyContent={'center'}
                            alignItems={'center'}
                            gap={2}
                        >
                            <Form.Control
                                type="text"
                                placeholder="Username"
                                aria-label="username"
                                aria-describedby="username"
                                value={emailUsername}
                                name="username"
                                onChange={(e) => {
                                    setEmailUsername(e.target.value)
                                }}
                            />
                            <Button
                                disabled={updatingUsername}
                                variant="primary"
                                type="submit"
                                onClick={updateCustomEmailUsername}
                            >
                                {
                                    updatingUsername ? 'Updating...' : 'Update'
                                }
                            </Button>
                        </Flex>
                    }
                    {
                        !usernameInEditMode &&
                        <Flex
                            justifyContent={'center'}
                            alignItems={'center'}
                            gap={2}
                        >
                            username: <b>{emailUsername}</b>

                            <FontAwesomeIcon
                                icon={faPencil}
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    setUsernameInEditMode(true)
                                }}
                            />
                        </Flex>
                    }
                    {
                        !usernameInEditMode &&
                        <Flex
                            justifyContent={'center'}
                            alignItems={'center'}
                            gap={2}
                        >
                            domain: <b>@{activeDomain?.apexName}</b>

                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={{
                                    color: 'green'
                                }}
                            />
                        </Flex>
                    }
                    {
                        !usernameInEditMode &&
                        <FontAwesomeIcon
                            style={{ cursor: 'pointer' }}
                            icon={faClose}
                            onClick={() => {
                                setOpenEmailRemovalModal(true)
                            }}
                        />
                    }
                </Flex>
            </>
        }
        if (!activeDomain?.customEmailTriggered) {
            return <Flex
                justifyContent={'between'}
                alignItems={'center'}
            >
                <div>
                    Set up custom email for {activeDomain?.apexName}
                </div>
                <Button
                    disabled={preparingDomain}
                    variant="primary"
                    type="submit"
                    onClick={() => {
                        prepareCustomDomainForEmailSupportHandler(activeDomain)
                    }}
                >
                    {
                        preparingDomain ? 'Setting up' : 'Set up'
                    }
                </Button>
            </Flex>
        }
        if (activeDomain?.customEmailTriggered) {
            return <>
                <Flex
                    justifyContent={'between'}
                    alignItems={'center'}
                >
                    <div>
                        Finish set up custom email for <b>{activeDomain?.apexName}</b> {!openTable ? <span
                            style={{
                                color: 'red',
                                marginLeft: 5,
                                cursor:'pointer'
                            }}
                            onClick={() => setOpenTable(true)}
                        >(Click to see details)</span> : null}
                    </div>
                    <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        icon={faClose}
                        onClick={() => {
                            setOpenEmailRemovalModal(true)
                        }}
                    />
                </Flex>
                <hr />
            </>

        }
    }

    return (
        <Flex
            direction={'column'}
            gap={3}

        >
            {controlSetUpDisplay()}

            {
                openTable && !activeDomain?.customEmailVerified && activeDomainVerificationArray?.length ?
                    <div>
                        <h6>1. Head over to the DNS settings for <span style={{
                            color: 'green'
                        }}>{activeDomain?.apexName}</span></h6>
                        <h6>2. Add the data below to finish setting up your custom email.</h6>
                    </div>
                    : null
            }
            {
                openTable && !activeDomain?.customEmailVerified && activeDomainVerificationArray?.length ?
                    <DataTable
                        columns={domainVerificationStatementColumn}
                        data={activeDomainVerificationArray}
                        theme={config.isDark ? "solarized" : null}
                    /> : null
            }
            {
                showVerifyButton ?
                    <Button
                        variant="primary"
                        onClick={() => {
                            window.location.reload()
                        }}
                    >
                        Verify
                    </Button> : <InfoBar
                        info={`After completing these steps, please allow up to 72 hours for verification of <b>${activeDomain?.apexName}</b> to be completed. Your patience during this process is greatly appreciated.`}
                    />
            }
            <ConfirmModal
                open={openEmailRemovalModal}
                message={"Are you sure about removing this domain for custom email address?"}
                title={`Remove ${activeDomain?.apexName}?`}
                onConfirm={() => {
                    setOpenEmailRemovalModal(false);
                    destroyCustomDomainForEmailSupportHandler(activeDomain)
                }}
                onCancel={() => {
                    setOpenEmailRemovalModal(false);
                }}
                cancelText={"CANCEL"}
                confirmText={"REMOVE"}
            />
        </Flex>
    )
}

export default EmailSetup