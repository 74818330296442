import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Flex from 'components/common/Flex'
import SoftBadge from 'components/common/SoftBadge'
import APIService from "http/api_service"
import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux"
import { toast } from 'react-toastify'
import { getLoggedInUser } from "redux/slices/user_slice"
import {
    getActiveWorkSpace,
    getBilledYearly,
    getTeam,
    setTeam
} from "redux/slices/workspaceslice"
import { isWorkSpaceUnderAppSumo } from 'utils/user_utils'
import { isFreeWorkspace } from 'utils/workspace_utils'
import './AddOnsStyles.css'
import InfoBar from 'components/common/InfoBar'
import RichInfoBar from 'components/common/RichInfoBar'

const PlanCardAdvance = (props) => {
    const { data, priceToggle, updatableUrl, hasActiveSubscription, userHasExtraUserPlan } = props
    const { ...others } = data;
    const dispatch = useDispatch()
    const loggedInUser = useSelector(getLoggedInUser);
    const workspace = useSelector(getActiveWorkSpace);
    const numberOfValidTeamMembers = workspace?.active_plan?.Users || null
    const team = useSelector(getTeam);
    const [connectedPlatformsAccountStat, setConnectedPlatformsAccountStat] = useState();
    const billedYearly = useSelector(getBilledYearly);
    const [loading, setLoading] = useState(false);

    const fetchConnectedSocialsCount = () => {
        APIService.fetchConnectedSocialsCount(workspace._id, (response, error) => {
            if (response) {
                let { data } = response;
                let { connected, total } = data;
                let stats = { total, connected };
                stats["true_connected"] = connected;
                connected = Math.max(connected, 1);
                stats["completed"] = Math.max(connected, 10);
                if (typeof total !== "number") {
                    stats["max"] = connected;
                } else {
                    stats["max"] = Math.min(total, 100);
                }
                setConnectedPlatformsAccountStat(stats);
            }
        });
    };

    useEffect(() => {
        fetchConnectedSocialsCount();
    }, [])


    const fetchTeams = async () => {
        APIService.fetchTeam(workspace['_id'], (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let data = response['data'];
            data = data.filter(x => x.user_id);
            data = data.map((x) => {
                if (!x.user_id.first_name || !x.user_id.last_name) {
                    x.user_id.first_name = 'Postly';
                    x.user_id.last_name = 'User'
                }
                let name = `${x.user_id.first_name} ${x.user_id.last_name}`;
                x.member = name;
                if (x.user_id._id === loggedInUser['_id']) {
                    let member = x.member;
                    member = `${member}(You)`;
                    x.member = member;
                }
                x.email = x.user_id.email;
                return x;
            });
            dispatch(setTeam(data));
        });
    }

    useEffect(() => {
        fetchTeams();
    }, []);

    const getPrice = () => {
        const price = data.price
        if (data?.one_off) {
            return <><span
                style={{
                    fontSize: 25,
                    fontWeight: 700
                }}
            >
                {price?.Month}
            </span><span
                style={{
                    fontSize: 13
                }}
            >
                    (one-time fee)
                </span></>
        }
        if (!billedYearly) {
            return <><span
                style={{
                    fontSize: 25,
                    fontWeight: 700
                }}
            >
                {price?.Month}
            </span><span
                style={{
                    fontSize: 13
                }}
            >/mo</span></>
        }
        return <><span
            style={{
                fontSize: 25,
                fontWeight: 700
            }}
        >
            {price?.Year}
        </span><span
            style={{
                fontSize: 13
            }}
        >/yr</span></>
    }

    const displaySubTitle = (text) => {
        if (text === 'prepare user details') {
            return `Your users may be assigned across all your workspaces.`
        }
        if (text === 'prepare social account details') {
            return <div>
                {/* ou have used <b>{connectedPlatformsAccountStat?.connected}</b> out
                of <b>{connectedPlatformsAccountStat?.total}</b> Social Accounts.  */}
                Your social accounts may be distributed across all your workspaces.</div>
        }
        return text
    }

    const displayFeatures = data?.features?.map((feature, index) => {
        return <Flex
            key={index}
            style={{
                fontSize: 12
            }}
        >
            <FontAwesomeIcon icon={feature?.icon ?? 'check'} className="me-1 text-success" />
            <p
                style={{
                    fontSize: 12,
                    width: 180
                }}
            >
                {feature?.title}
            </p>
        </Flex>
    })

    if (data?.plan === 'AddOrRemoveSocialAccount' && (isWorkSpaceUnderAppSumo(workspace))) {
        return <></>
    }

    return (
        <Card
            className={data?.highlighted ? 'bg-[#E8F1F2]' : ''}
            style={{
                'position': 'relative'
            }}>
            <Card.Header
                className={!data?.highlighted ? 'bg-light' : ''}
                style={{
                    minWidth: 235,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Flex
                    gap={2}
                >
                    <Flex
                        direction={'column'}
                    >
                        <h5>
                            {data?.title.replace(new RegExp('\\b' + 'Scheduler' + '\\b', 'g'), '')}
                            {
                                props?.addOns &&
                                <span
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    <SoftBadge
                                        pill
                                        bg="success"
                                        className="ms-2"
                                        style={{
                                            fontSize: 10,
                                        }}
                                    >
                                        {/* <FontAwesomeIcon icon={tag.icon} className="me-2" /> */}
                                        Add-Ons
                                    </SoftBadge>
                                </span>
                            }
                        </h5>
                        <p
                            style={{
                                fontSize: 12,
                                color: '#777',
                                fontWeight: 400,
                                width: 200
                            }}
                        >
                            {displaySubTitle(data?.subTitle)}
                        </p>

                        {
                            // hasActiveSubscription ? <Button
                            //     disabled={!data?.cancelText}
                            //     onClick={() => {
                            //         if (others.plan) {
                            //             props?.handleSubscriptionCancellation()
                            //         }
                            //     }}
                            //     variant={data?.cancelText ? "danger" : "success"}
                            //     className="d-block w-70"
                            //     style={{
                            //         fontSize: 11,
                            //         padding: 5
                            //     }}
                            // >
                            //     {data?.cancelText ? data?.cancelText : 'Active'}
                            // </Button> :
                            hasActiveSubscription ? <Flex
                                direction={'column'}
                            >
                                <Button
                                    disabled={true}
                                    variant={"success"}
                                    className="d-block w-70"
                                    style={{
                                        fontSize: 11,
                                        padding: 5
                                    }}
                                >
                                    {'Active'}
                                </Button>
                                <h5 className="fs--2 mt-2">
                                    <a href={`https://billing.stripe.com/p/login/3cs5lX8oidsOdLqcMM?prefilled_email=${loggedInUser.email}`} className="hover-primary stretched-link" target='_blank' rel="noreferrer">
                                        Manage your subscription
                                    </a>
                                </h5>
                            </Flex> :
                                <Button
                                    onClick={() => {
                                        setLoading(true)
                                        props?.handleSubscribe()
                                    }}
                                    disabled={loading}
                                    variant="primary"
                                    className="d-block w-70"
                                    style={{
                                        fontSize: 11,
                                        padding: 5
                                    }}
                                >
                                    {userHasExtraUserPlan ? data?.activeButtonText : data?.buttonText}
                                </Button>
                        }
                    </Flex>
                    {
                        !data?.disablePrice &&
                        <Flex>
                            <div>
                                <span
                                    style={{
                                        color: data?.highlighted && 'black'
                                    }}
                                >
                                    <span
                                        style={{
                                            position: 'relative',
                                            top: '-10px',

                                        }}
                                    >
                                        {data?.currencySign}
                                    </span>
                                    {getPrice()}
                                </span>
                            </div>
                        </Flex>
                    }
                </Flex>
            </Card.Header>
            <Card.Body>
                <Flex
                    direction={'column'}
                    style={{
                        fontSize: 12
                    }}
                >
                    {displayFeatures}
                    <Flex>
                        {/* <InfoBar info={"Polls are currently only supported on Twitter and LinkedIn. More platforms support coming soon..."} /> */}

                        {/* {
                            data?.plan === 'LTDExtraCode' &&
                            <RichInfoBar
                                title="Special offer"
                                message={
                                    `Buy a voucher, review us on Trustpilot, and get a free ....`
                                }
                            />
                        } */}
                    </Flex>
                </Flex>
            </Card.Body>
        </Card>
    )
}

export default PlanCardAdvance