import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import CircularButton from "components/common/circularbutton";
import { Form, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";

const AudienceGroupListItem = (props) => {
    const { selectedAudienceGroup, processActiveGroupEdit, item, setActiveGroupData, canDisplayDeletePrompt, setGroupToDelete, setOpenDeleteAudienceGroupPrompt, deleteAudienceGroup, groupsUnderDelete, clearingAllGroups } = props
    return (
        <Flex alignItems={'center'}>
            <Form.Check
                id={`${item?.name}-group-checkbox`}
                type="radio"
                name='groups'
                label={item?.name}
                value={item?.name}
                checked={selectedAudienceGroup?.name === item?.name}
                className={'mt-2'}
            />
            <div
                style={{
                    flex: 1,
                    marginRight: 50,
                }}></div>
            <Flex alignItems={'center'}
                justifyContent={'center'}
                className={'gap-3'}
            >
                <OverlayTrigger
                    placement='top'
                    className="mr-5"
                    overlay={
                        <Tooltip>
                            Delete Audience Group
                        </Tooltip>
                    }>
                    <span
                    >
                        <CircularButton onClick={() => {
                            const { name, _id, ...others } = item;
                            if (canDisplayDeletePrompt) {
                                setGroupToDelete({ name, _id, ...others });
                                setOpenDeleteAudienceGroupPrompt(true);
                            } else {
                                deleteAudienceGroup({ name, _id, ...others });
                            }
                        }}>
                            {
                                (clearingAllGroups || groupsUnderDelete[item?._id])
                                    ? <Spinner
                                        animation='border'
                                        size={'sm'}
                                    /> :
                                    <>
                                        <FontAwesomeIcon className='text-danger' icon={faTrash} />
                                    </>
                            }
                        </CircularButton>
                    </span>
                </OverlayTrigger>
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip>
                            Edit Audience Group
                        </Tooltip>
                    }>
                    <span>
                        <CircularButton onClick={() => {
                            const { name, _id, ...others } = item;
                            setActiveGroupData(prev => ({ name, _id, ...others }));
                            processActiveGroupEdit(item);
                        }}>
                            <FontAwesomeIcon className='primary' icon={faPencil} />
                        </CircularButton>
                    </span>
                </OverlayTrigger>
            </Flex>
        </Flex >
    )
}

export default AudienceGroupListItem