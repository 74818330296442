import Flex from "components/common/Flex";
import moment from "moment";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setFeedPublishInterval } from "redux/slices/postslice";

const PublishFeedPopOver = (props) => {
  const { setPublishDuration, publishingInterval } = props;
  const dispatch = useDispatch();

  const [durationRange, setDurationRange] = useState(2);
  const [publishFeedPeriod, setPublishFeedPeriod] = useState("minutes");
  const publishPeriod = ["minutes", "hours", "days", "weeks"];

  useEffect(() => {
    let publishDelay;
    switch (publishFeedPeriod) {
      case "minutes":
        publishDelay = moment().add(durationRange, publishFeedPeriod).toDate().getTime();
      case "hours":
        publishDelay = moment().add(durationRange, publishFeedPeriod).toDate().getTime();
      case "days":
        publishDelay = moment().add(durationRange, publishFeedPeriod).toDate().getTime();
      case "weeks":
        publishDelay = moment().add(durationRange, publishFeedPeriod).toDate().getTime();
    }
    
    if (publishingInterval === "interval_period") {
      const duration = { 
        time_difference: durationRange, 
        period: publishFeedPeriod,
      }
      dispatch(setFeedPublishInterval({ publish_time: publishDelay, type: publishingInterval, duration }));
    }
  }, [durationRange, publishFeedPeriod, publishingInterval]);

  return (
    <>
      <Flex justifyContent={"space-between"} direction={"row"} gap={2}>
        <Form.Control
          type="number"
          min={0}
          max={100}
          value={durationRange}
          onChange={(e) => setDurationRange(e.target.value)}
        />

        <Form.Select
          onChange={(e) => {
            let selectedValue = e.target.value;
            setPublishFeedPeriod(selectedValue);
          }}
        >
          {publishPeriod.map((period) => {
            return (
              <option value={period} className="text-capitalize">
                {period}
              </option>
            );
          })}
        </Form.Select>
      </Flex>
    </>
  );
};

export default PublishFeedPopOver;
