import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { getTagForPages } from 'utils/gen';

const BloggerPage = (props) => {
    const { page, selectedProfile, setSelectedProfile, selectAll, managePagesOrGroupIdsSelectedForAction, clearAllChecksTrigger } = props;

    const [displayNameAsAvatar, setDisplayNameAsAvatar] = useState(false);
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        setSelected(selectAll);
    }, [selectAll])
    useEffect(() => {
        if (clearAllChecksTrigger) {
            setSelected(false);
        }
    }, [clearAllChecksTrigger])


    const displayAvatar = (page) => {
        return displayNameAsAvatar ?
            <span
                style={{
                    fontSize: 20,
                    color: 'black',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    alignSelf: 'center',
                }
                } > {page.name.charAt(0).toString().toUpperCase()}
            </span >
            : page.picture ? <img
                src={page.picture}
                alt={page.name}
                onError={() => {
                    setDisplayNameAsAvatar(true);
                }}
                style={{
                    width: 20,
                    height: 20,
                    borderRadius: 40,
                }}
            /> : <span
                style={{
                    fontSize: 20,
                    color: 'black',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    alignSelf: 'center',
                }}>{page.name.charAt(0).toString().toUpperCase()}
            </span>
    }

    return (
        <SoftBadge
            bg={page?.connected ? 'success' : 'secondary'}
            // bg={page?.connected ? 'primary' : 'warning'}
            className="me-2 mb-2 cursor-pointer rounded-pill mt-2 hover-bg-red-100"
            onClick={() => {
                setSelected(!selected);
                managePagesOrGroupIdsSelectedForAction(page, !selected);
            }}
            style={{
                minHeight: 10
            }}
        >
            <Flex alignItems={'start'} className={'gap-2'}>
                {
                    displayAvatar(page)
                }
                <Flex
                    alignItems={'start'}
                    direction={'column'}
                    className={'me-2'}
                >
                    <Flex>
                        <p className="mb-1"
                            style={{
                                margin: 0,
                                marginLeft: 7
                            }}
                        >
                            {page.name ?? ''}
                        </p>
                        <Form.Check
                            type="checkbox"
                            style={{
                                cursor: 'pointer',
                                marginTop: -3,
                                marginLeft: 5

                            }}
                            // label="Select all"
                            // onChange={() => {
                            //     setSelected(!selected);
                            //     managePagesOrGroupIdsSelectedForAction(page, !selected);
                            // }}
                            checked={selected}
                        // ref={checkboxRef}
                        />
                    </Flex>
                    {
                        page.connected && <FontAwesomeIcon icon="check" className="text-success" style={{ marginLeft: 5 }} />
                        // <SoftBadge bg="success" className="">
                        //     {/* Connected */}
                        // </SoftBadge>
                    }
                </Flex>
            </Flex>
        </SoftBadge>
    )
}

export default BloggerPage