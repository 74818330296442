import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    instagramAccountPageId: null,
    userId: null,
    selectedPage: {},
    postFeeds: []
    
}
export const instagramCommentsSlice = createSlice({
    name: 'instagram_comments',
    initialState,
    reducers: {
        setInstagramAccountPageId: (state, action) => {
            state.instagramAccountPageId = action.payload;
        },
        setInstagramUserId: (state, action) => {
            state.userId = action.payload;
        },
        setSelectedPageForPostCommentsView: (state, action) => {
            state.selectedPage = action.payload;
        },
        setPostFeeds: (state, action) => {
            state.postFeeds = action.payload;
        },
       
    }
});
export const getInstagramAccountPageId = (state) => state.instagram_comments.instagramAccountPageId;
export const getInstagramUserId = (state) => state.instagram_comments.userId;
export const getSelectedPageForPostCommentsView = (state) => state.instagram_comments.selectedPage;
export const getPostFeeds = (state) => state.instagram_comments.postFeeds;


export const {
    setInstagramAccountPageId,
    setInstagramUserId,
    setSelectedPageForPostCommentsView,
    setPostFeeds,
} = instagramCommentsSlice.actions;

export default instagramCommentsSlice.reducer;