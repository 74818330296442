import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleSignInIconFocused from "assets/images/btn_google_signin_dark_focus_web.png";
import GoogleSignInIconNormal from "assets/images/btn_google_signin_dark_normal_web.png";
import GoogleSignInIconPressed from "assets/images/btn_google_signin_dark_pressed_web.png";
import InfoBar from "components/common/InfoBar";
import React, { useState } from "react";
import { Alert, Button, Card, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import TelegramLoginButton from "react-telegram-login";
import { PlatformIdentifier, RoutePaths } from "../../../constants";
import { NoteContainer } from "./styles";
import TwitterConnectionPage from "./twitter/twitter_connection_page";
import { useSelector } from "react-redux";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { isFreeWorkspace } from "utils/workspace_utils";
import RSSConnectionPage from "./rss/rss_feed_connection_page";
import WordpressConnectionPage from "./wordpress/wordpress_connection_page";
import Flex from "components/common/Flex";

export default function PlatformConnectView(props) {
  const { canConnectSA } = props;
  const workspace = useSelector(getActiveWorkSpace);
  const isFreeWorkSpace = isFreeWorkspace(workspace);
  const { platform, processing, handleTelegramResponse, connectAccount } =
    props;
  const [googleSignInIcon, setGoogleSignInIcon] = useState(
    GoogleSignInIconNormal
  );
  const currentURL = window.location.href;

  const vowels = () => {
    return ["a", "e", "i", "o", "u"];
  };

  const startsWithAnyOf = (string, anyOf) => {
    if (!Array.isArray(anyOf)) {
      anyOf = anyOf.split("");
    }
    let hitFound = false;
    for (let char of anyOf) {
      hitFound = string.toLowerCase().startsWith(char.toString());
      if (hitFound) {
        break;
      }
    }
    return hitFound;
  };

  const responseTelegram = response => {
    console.log(response);
  };

  const limitReachedCard = (
    <Alert variant="warning" className="p-2 mb-4">
      <Flex>
        <FontAwesomeIcon icon="crown" className="fs-2" />
        <div className="ms-3 flex-1">
          <h4 className="alert-heading">Oops!!!</h4>
          You have reached the maximum number of connected social accounts for your current plan. Please upgrade to connect more accounts.
        </div>
      </Flex>
    </Alert>
  )


  return (
    <>
      {/* {platform.pro === true && isFreeWorkSpace ? (
        <div className="alert alert-warning d-flex align-items-center fs--1 mb-0">
          <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
          {platform.name} is only available for pro users. Please <Link to={`${RoutePaths.BILLING2}`} className=" me-1 ms-1 fw-bold"> upgrade </Link> your account to enjoy full access
        </div>
      ) : ( */}
      <Card>
        <Card.Body className="bg-light">
          {!canConnectSA && limitReachedCard}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 30,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                {/* <span style={{
                        fontSize: 30,
                        fontWeight: 600,
                        textAlign: 'center'
                    }} textAlign={'center'}>
                        {platform.name}
                    </span> */}
              </div>
              {platform.identifier === PlatformIdentifier.TELEGRAM ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 10,
                  }}
                >
                  {
                    (currentURL.includes("app.postly.ai")) ?
                      <TelegramLoginButton
                        dataOnauth={handleTelegramResponse}
                        botName="postly_bot"
                        widgetVersion={22}
                      /> : <Flex>
                        Coming soon...
                      </Flex>
                  }

                  {/* <LoginButton
                    botUsername={'intheblack_bot'}
                    onAuthCallback={(data, error) => {
                      if(error){
                        console.log("🚀 ~ PlatformConnectView ~ error:", error)
                      }
                      console.log(data, 'as data callback...');
                      // call your backend here to validate the data and sign in the user
                    }}
                  /> */}

                  <InfoBar
                    info={`Your Telegram account(s) <b>MUST</b> have a <b>username</b> for ${(currentURL === "app.postly.ai" || currentURL === "localhost") ? 'Postly' : 'this'} to Work!`}
                  />
                </div>
              ) : platform.identifier === PlatformIdentifier.YOUTUBE ||
                platform.identifier ===
                PlatformIdentifier.GOOGLE_MY_BUSINESS ||
                platform.identifier === PlatformIdentifier.BLOGGER ? (
                <img
                  onMouseEnter={() => {
                    if (processing) {
                      setGoogleSignInIcon(GoogleSignInIconPressed);
                    } else {
                      setGoogleSignInIcon(GoogleSignInIconFocused);
                    }
                  }}
                  onMouseLeave={() => {
                    if (!processing) {
                      setGoogleSignInIcon(GoogleSignInIconNormal);
                    } else {
                      setGoogleSignInIcon(GoogleSignInIconPressed);
                    }
                  }}
                  onClick={() => {
                    setGoogleSignInIcon(GoogleSignInIconPressed);
                    connectAccount();
                  }}
                  src={googleSignInIcon}
                  alt={"Google Sign In"}
                  style={{
                    cursor: "pointer",
                  }}
                />
              ) : platform.identifier === PlatformIdentifier.TWITTER ? (
                <TwitterConnectionPage props={props} />
              ) : platform.identifier === PlatformIdentifier.RSS_FEED ? (
                <RSSConnectionPage props={props} />
              ) : platform.identifier === PlatformIdentifier.WORDPRESS ? (
                <WordpressConnectionPage props={props} />
              ) : (
                <Button
                  variant={"primary"}
                  disabled={processing || !canConnectSA}
                  // isWorking={processing}
                  onClick={connectAccount}
                  style={{
                    color: "white",
                    minWidth: 200,
                    display: "flex",
                    fontSize: 20,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {processing ? "Please wait..." : "Connect"}
                </Button>
              )}
            </div>

            {platform.identifier === PlatformIdentifier.INSTAGRAM && (
              <>
                <Card
                  canHover={false}
                  style={{
                    width: "100%",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }}
                >
                </Card>
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
