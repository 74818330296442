import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import APIService from 'http/api_service';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

const Product = (props) => {
    const {
        product,
        addNewProduct,
        monthlyPrice,
        yearlyPrice,
        fetchAllProductsAndPrices,
        isLast
    } = props
    const [productName, setProductName] = useState(product?.name)
    const [productDescription, setProductDescription] = useState(product?.description)
    const [order, setOrder] = useState(product?.metadata?.order || 1)
    const [_monthlyPrice, _setMonthlyPrice] = useState(monthlyPrice)
    const [_yearlyPrice, _setYearlyPrice] = useState(yearlyPrice)
    const [currency, setCurrency] = useState(product?.prices[0]?.currency || 'usd')
    const [processingProductUpdate, setProcessingProductUpdate] = useState(false)
    const availableCurrency = ['usd', 'eur', 'gbp', 'aed', 'cad', 'idr', 'aud']
    const [includeFeatures, setIncludeFeatures] = useState(props?.includeFeatures || false)
    const [features, setFeatures] = useState(props?.features || [{ text: '', id: uuidv4() }])
    const [openPricingUpdatePrompt, setOpenPricingUpdatePrompt] = useState(false)
    const [oldPriceAction, setOldPriceAction] = useState('archive')
    const [activeStatus, setActiveStatus] = useState(product?.active)

    const updateProduct = (recreatePrice = false) => {
        if (!productName || !productDescription || !_monthlyPrice || !_yearlyPrice || !currency) {
            toast.error('Please fill all fields', { theme: 'colored' });
            return;
        }
        let featuresArray = [];
        if (includeFeatures) {
            featuresArray = features.map(x => x.text);
        }
        setProcessingProductUpdate(true);
        let requestBody = {
            name: productName,
            description: productDescription,
            monthly_price: _monthlyPrice,
            yearly_price: _yearlyPrice,
            currency: currency,
            features: featuresArray,
            includeFeatures: includeFeatures,
            prod: product,
            recreatePrices: recreatePrice,
            oldPriceAction: oldPriceAction,
            activeStatus,
            order
        }
        APIService.updateWhiteLabelProduct(product?.id, requestBody, (response, error) => {
            setProcessingProductUpdate(false);
            if (error) {
                toast.error(error.message, { theme: 'colored' });
                return;
            }
            toast.success(response.message, { theme: 'colored' });
            // fetchAllProductsAndPrices();
            window.location.reload();
        });
    }

    const displayFeatureControl = () => {
        return features.map((feature, index) => {
            const isLast = index === features.length - 1;
            return <Flex
                alignItems={'center'}
                gap={2}
            >
                <Form.Control
                    placeholder={`Feature ${index + 1}`}
                    type="text"
                    value={feature?.text}
                    onChange={(e) => {
                        let newFeatures = features;
                        setFeatures([...newFeatures.map(x => {
                            if (x.id === feature.id) {
                                return {
                                    ...x,
                                    text: e.target.value
                                }
                            }
                            return x;
                        }
                        )]);
                    }}
                />
                <Button
                    variant="danger"
                    size="sm"
                    title="Remove feature"
                    onClick={() => {
                        if (features.length === 1) {
                            toast.error('You must have at least one feature. You should turn off features if you do not wish to have features enabled.', { theme: 'colored' });
                            return;
                        }
                        let newFeatures = features;
                        setFeatures([...newFeatures.filter(x => x.id !== feature.id)]);
                    }}
                >
                    <FontAwesomeIcon
                        icon='times'
                    />
                </Button>
                {
                    isLast && <Button
                        variant="primary"
                        size="sm"
                        title="Add feature"
                        onClick={() => {
                            if (features?.length >= 5) {
                                toast.error('You can only add 5 features', { theme: 'colored' });
                                return;
                            }
                            let newFeatures = features;
                            setFeatures([...newFeatures, {
                                text: ``,
                                id: uuidv4()
                            }]);
                        }}
                    >
                        <FontAwesomeIcon
                            icon='plus'
                        />
                    </Button>
                }
            </Flex>
        })
    }

    return (
        <div
            className={'mb-3'}
        >
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-3">
                <Flex
                    direction={'column'}
                >
                    <Flex
                        gap={2}
                    >
                        <p className="mb-0 fs--1" style={{ fontWeight: 500 }}>
                            Product name
                        </p>
                        <SoftBadge
                            bg={product?.active ? 'success' : 'warning'}
                            className="">
                            {
                                product?.active ? 'Active' : 'Inactive'
                            }
                        </SoftBadge>
                    </Flex>
                    <p className="mb-0 fs--2">
                        This will be displayed on the invoices of your customers
                    </p>
                </Flex>
                <Flex
                >
                    <Form.Control
                        // style={{ width: 100 }}
                        placeholder="Product name"
                        type="text"
                        value={productName}
                        onChange={(e) => {
                            setProductName(e.target.value);
                        }}
                    />
                </Flex>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-3">
                <Flex
                    direction={'column'}
                >
                    <p className="mb-0 fs--1" style={{ fontWeight: 500 }}>
                        Product description
                    </p>
                    <p className="mb-0 fs--2">
                        This will be displayed on the invoices of your customers
                    </p>
                </Flex>
                <Flex
                >
                    <Form.Control
                        placeholder="Product description"
                        type="text"
                        value={productDescription}
                        onChange={(e) => {
                            setProductDescription(e.target.value);
                        }}
                    />
                </Flex>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-3">
                <Flex
                    direction={'column'}
                >
                    <p className="mb-0 fs--1" style={{ fontWeight: 500 }}>
                        Order number
                    </p>
                    <p className="mb-0 fs--2">
                        This is the order in which the product appears on the pick a plan page
                    </p>
                </Flex>
                <Flex
                >
                    <Form.Control
                        placeholder="Order number"
                        type="text"
                        value={order}
                        onChange={(e) => {
                            setOrder(e.target.value);
                        }}
                    />
                </Flex>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-3">
                <Flex
                    direction={'column'}
                >
                    <p className="mb-0 fs--1" style={{ fontWeight: 500 }}>Monthly subscription</p>
                    <p className="mb-0 fs--2">
                        Set the monthly subscription price
                    </p>
                </Flex>
                <Flex
                >
                    <Form.Control
                        style={{ width: 100 }}
                        type="number"
                        value={_monthlyPrice}
                        onChange={(e) => {
                            _setMonthlyPrice(e.target.value);
                        }}
                    />
                    <Form.Select
                        value={currency}
                        style={{ width: 100 }}
                        onChange={(e) => {
                            setCurrency(e.target.value);
                        }}>
                        {
                            availableCurrency?.map(x => {
                                return <option value={x}>{x?.toLocaleUpperCase()}</option>
                            })
                        }
                    </Form.Select>
                </Flex>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'center'} className="mb-3">
                <Flex
                    direction={'column'}
                >
                    <p className="mb-0 fs--1" style={{ fontWeight: 500 }}>Yearly subscription</p>
                    <p className="mb-0 fs--2">
                        Set the yearly subscription price
                    </p>
                </Flex>
                <Flex
                >
                    <Form.Control
                        style={{ width: 100 }}
                        type="number"
                        value={_yearlyPrice}
                        onChange={(e) => {
                            _setYearlyPrice(e.target.value);
                        }}
                    />
                    <Form.Select
                        value={currency}
                        style={{ width: 100 }}
                        onChange={(e) => {
                            setCurrency(e.target.value);
                        }}>
                        {
                            availableCurrency?.map(x => {
                                return <option value={x}>{x?.toLocaleUpperCase()}</option>
                            })
                        }
                    </Form.Select>
                </Flex>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'start'} className="mb-3">
                <Flex
                    direction={'column'}
                >
                    <Form.Check
                        checked={includeFeatures}
                        type="switch"
                        id={`include-features_${product?.id}`}
                        label={"Include features"}
                        className="mb-0 fs--1"
                        onChange={(e) => {
                            setIncludeFeatures(e.target.checked);
                        }}
                    />
                    <p className="mb-0 fs--2">
                        Add features to your product to make it more attractive (optional)
                    </p>
                </Flex>
                <Flex
                    direction={'column'}
                    style={{ gap: 10 }}
                >
                    {
                        includeFeatures ?
                            displayFeatureControl()
                            :
                            null
                    }
                </Flex>
            </Flex>
            <Flex justifyContent={'between'} alignItems={'start'} className="mb-3">
                <Flex
                    direction={'column'}
                >
                    <Form.Check
                        checked={activeStatus}
                        type="switch"
                        id={`active_status_${product?.id}`}
                        label={`Active status`}
                        className="mb-0 fs--1"
                        onChange={(e) => {
                            setActiveStatus(e.target.checked);
                        }}
                    />
                    <p className="mb-0 fs--2">
                        Add features to your product to make it more attractive (optional)
                    </p>
                </Flex>
                <Flex
                    direction={'column'}
                    style={{ gap: 10 }}
                >
                    {/* {
                        includeFeatures ?
                            displayFeatureControl()
                            :
                            null
                    } */}
                </Flex>
            </Flex>
            <Flex
                justifyContent={'end'}
                className='mt-3'
            >
                <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => {
                        // check if pricing has changed
                        if (monthlyPrice !== _monthlyPrice || yearlyPrice !== _yearlyPrice) {
                            setOpenPricingUpdatePrompt(true);
                            return;
                        }
                        updateProduct();
                    }}
                    disabled={processingProductUpdate}
                >
                    {
                        processingProductUpdate ? 'processing...' : 'Update product and prices'
                    }

                </Button>
            </Flex>
            <Modal show={openPricingUpdatePrompt}
                onHide={() => setOpenPricingUpdatePrompt(false)}
                onExit={() => setOpenPricingUpdatePrompt(false)}
                centered
                size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Important notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Flex className="mb-3">
                        Seems you wish to update the pricing of this product. However, we do not support updating the amount or currency of an existing price. If you continue, this will create a new price for this product and the old price will be archived.
                    </Flex>
                    {/* <Flex justifyContent={'between'} alignItems={'center'} className="mb-3">
                        <Flex
                            direction={'column'}
                        >
                            <p className="mb-0 fs--1" style={{ fontWeight: 500 }}>
                                Old price action
                            </p>
                            <p className="mb-0 fs--2">
                                What do you want to do with the old price?
                            </p>
                        </Flex>
                        <Flex>
                            <Form.Select
                                value={oldPriceAction}
                                onChange={(e) => {
                                    setOldPriceAction(e.target.value);
                                }}>
                                <option value={'archive'}>Archive old price</option>
                                <option value={'delete'}>Delete old price</option>
                            </Form.Select>
                        </Flex>
                    </Flex> */}
                    <Flex
                        justifyContent={'end'}
                        className='mt-3'
                        gap={2}
                    >
                        <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => {
                                setOpenPricingUpdatePrompt(false);
                            }}
                            disabled={processingProductUpdate}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => {
                                setOpenPricingUpdatePrompt(false);
                                updateProduct(true);
                            }}
                        >
                            Continue
                        </Button>
                    </Flex>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Product