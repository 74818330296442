import Flex from 'components/common/Flex'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'

const AccountWithoutConnectedPagesAndGroupsEdit = (props) => {
    const { account, setCloneData, cloneData, platform } = props
    const [checked, setChecked] = useState(account?.selected ?? false)
    const [initialChecked, setInitialChecked] = useState(false)

    useEffect(() => {
        setChecked(account?.selected)
    }, [cloneData])

    const allPagesOrGroupAreSelected = (platform) => {
        let foundOnePageOrGroupSelected = true;
        let array = platform?.accounts
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if (!element?.selected) {
                foundOnePageOrGroupSelected = false
                break
            }
        }
        return foundOnePageOrGroupSelected;
    }


    const handleSelection = () => {
        const _account = { ...account };
        _account.selected = checked;
        const _cloneData = [...cloneData];
        const _platform = { ...platform }
        let platformAccounts = []
        for (const platformAccount of _platform.accounts) {
            if (platformAccount._id === _account._id) {
                platformAccounts.push(_account)
                continue;
            }
            platformAccounts.push(platformAccount)
        }
        _platform.accounts = platformAccounts;
        _platform.selected = allPagesOrGroupAreSelected(_platform);
        let newCloneData = []
        for (const clone of _cloneData) {
            if (clone.identifier === _platform.identifier) {
                newCloneData.push(_platform);
                continue;
            }
            newCloneData.push(clone);
        }
        setCloneData(newCloneData);
    }

    useEffect(() => {
        if (!initialChecked) {
            setInitialChecked(true)
            return
        }
        handleSelection()
    }, [checked])


    return (
        <Flex direction={'column'} className={'gap-1'}>
            <Flex
                direction={'row'}
                className={'gap-3'}
                justifyContent={'between'}
                alignItems={'center'}
            >
                <Flex
                    direction={'row'}
                    className={'gap-3'}
                    justifyContent={'start'}
                    alignItems={'center'}
                >
                    <img src={account?.user_photo} alt="" height="24" width="24" style={{ borderRadius: "100%" }} />
                    <span>
                        {account?.user_name}
                    </span>
                </Flex>
                <Flex
                    direction={'row'}
                    className={'gap-3'}
                    justifyContent={'start'}
                    alignItems={'center'}
                >
                    <Form.Check
                        id={`${account?.name}-group-checkbox`}
                        type="checkbox"
                        name='groups'
                        checked={checked}
                        className={'mt-2'}
                        onChange={(e) => {
                            setChecked(e.target.checked)
                        }}

                    />

                </Flex>
            </Flex>
        </Flex>
    )
}

export default AccountWithoutConnectedPagesAndGroupsEdit