import { Line } from 'rc-progress';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getOverlayLoader, setOverlayLoader } from 'redux/slices/notification_slice';
import Flex from '../Flex';


const LoaderModal = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const overlayLoader = useSelector(getOverlayLoader)

    useEffect(() => {
        if (!overlayLoader) return;
        if (overlayLoader?.percentage > 99 || overlayLoader?.done) {
            dispatch(setOverlayLoader(null))
            if (overlayLoader?.navigator) {
                navigate(overlayLoader?.navigator)
            }
        }
    }, [overlayLoader])

    const displayMessages = () => {
        return <Modal.Body>
            <Flex
                direction={'column'}
            >
                <p>
                    {overlayLoader?.message ? `${overlayLoader?.message} ${overlayLoader?.percentage}%` : ''}
                </p>
                <Line percent={overlayLoader?.percentage || 0} strokeWidth={2} strokeColor="#2c7be5" />
            </Flex>
        </Modal.Body>
    }

    return (
        <div>
            <Modal
                size="md"
                centered
                show={overlayLoader ? true : false}
                onHide={() => null}
            >
                {displayMessages()}
            </Modal>
        </div>
    )

}

export default LoaderModal