import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import OneInbox from 'assets/animations/one_inbox.json';
import Flex from 'components/common/Flex';
import Lottie from 'lottie-react';

const ChatSetup = ({ connectAccounts }) => {

    return (
        <Card.Body
            className="text-center py-1" >
            <Row className="justify-content-center py-2">
                <Col xs={7} md={5}>
                    <Lottie animationData={OneInbox} />
                </Col>
            </Row>
            <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
                One Inbox, Unlimited Conversations!
            </h3>
            <p className="lead mb-3">
                Communicate with all your customers from different platforms<br /> using a single Inbox
            </p>
            <Flex alignItems={'center'} justifyContent={'center'}>
                <Button
                    variant="primary"
                    onClick={() => {
                        connectAccounts();
                    }}>
                    Get Started
                </Button>
            </Flex>
        </Card.Body>
    );
};

export default ChatSetup;
