/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import classNames from 'classnames';
import { RoutePaths, Strings } from '../constants';
import {
  canOpenExternalFilePickerDialog, canOpenPostDetailsDialog,
  getExternalFilePickerDialogOptions,
  getdraftCounter,
  getfailedCounter,
  gethasCalledFetchPosts,
  getpostsTypeCounter,
  getrecurringCounter,
  getscheduledCounter,
  setOpenExternalFilePickerDialog, setOpenPostDetailsDialog,
  setPostlyCloudTotalStorageLimit,
  setPostlyCloudUsedBytes,
  setdraftCounter,
  setfailedCounter,
  sethasCalledFetchPosts,
  setpostsTypeCounter,
  setrecurringCounter,
  setscheduledCounter
} from 'redux/slices/postslice';
import { Card, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ExternalFilePickerDialog from 'components/common/ExternalFilePickerDialog';
import usePubSub from 'pubsub';
import {
  canOpenWorkspaceSearch, getActiveWorkSpace, getOpenBulkWorkspaceCreator,
  getReloadPage, searchWorkSpace,
  setOpenBulkWorkspaceCreator
} from 'redux/slices/workspaceslice';
import WorkSpaceSearch from 'components/app/workspace-search/WorkSpaceSearch';
import FullScreenPostDetailsDialog from 'components/app/social/feed/FullScreenPostDetailsDialog';
import StringUtils from 'utils/string';
import firebase from '../firebase';
import '../App.css';
import Logo from 'components/common/Logo';
import APIService from 'http/api_service';
import { toast } from 'react-toastify';
import StorageEngine from '../utils/storage_engine';
import { getLoggedInUser, setLoggedInUser, setUserBillingUpdate } from 'redux/slices/user_slice';
import { getAllUsers, getReturningUsers, getUsersSignup, setActivePages, setAllUsers, setBotRequestsCount, setOnlineUsersCount, setReturningUsers, setTotalUsers, setTotalWorkSpaces, setUsersSignup } from 'redux/slices/admin_slice';
import { isAccountUnderAppSumo } from 'utils/user_utils';
import { setFreshNotificationPopup } from 'redux/slices/notification_slice';
import Flex from 'components/common/Flex';
import ToggleButton from 'components/navbar/vertical/ToggleButton';
import ActiveWorkspace from 'components/common/ActiveWorkspace';
import Divider from 'components/common/Divider';
import ActiveOrganizationsDropdown from 'components/app/organization-and-workspace-setup/organization/active_organizatons_dropdown';
import { darkTheme, lightTheme } from 'utils/theme';
import useInitializeOrganizations from 'components/app/organization-and-workspace-setup/hooks/useInitializeOrganizations';
import EmergencyNotice from 'components/common/EmergencyNotice';
import { isFreeWorkspace } from 'utils/workspace_utils';

const MainLayout = () => {

  const {
    addPubSubEventListener,
    putUserOnlineViaPubSub,
    removePubSubEventListener,
    socket
  } = usePubSub();
  const workSpace = useSelector(getActiveWorkSpace);
  const { hash, pathname } = useLocation();
  const { config: { isFluid, navbarPosition } } = useContext(AppContext);
  const loggedInUser = useSelector(getLoggedInUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openExternalFilePickerDialog = useSelector(canOpenExternalFilePickerDialog);
  const externalFilePickerDialogOptions = useSelector(getExternalFilePickerDialogOptions);
  const openWorkSpaceSearch = useSelector(canOpenWorkspaceSearch);
  const location = useLocation();
  const canReloadPage = useSelector(getReloadPage);
  const openBulkWorkspaceCreator = useSelector(getOpenBulkWorkspaceCreator);
  const openPostDialog = useSelector(canOpenPostDetailsDialog);
  const [navigateToDefault, setNavigateToDefault] = useState(false);
  const postsTypeCounter = useSelector(getpostsTypeCounter);
  const draftCounter = useSelector(getdraftCounter);
  const failedCounter = useSelector(getfailedCounter);
  const recurringCounter = useSelector(getrecurringCounter);
  const scheduledCounter = useSelector(getscheduledCounter);
  const hasCalledFetchPosts = useSelector(gethasCalledFetchPosts);
  const { config: { isDark } } = useContext(AppContext);
  const { initializeOrganizations } = useInitializeOrganizations();

  useEffect(() => {
    document.body.style.background = isDark ? darkTheme.background : lightTheme.background;
    return () => {
      document.body.style.background = '';
    };
  }, [isDark]);

  useEffect(() => {
    initializeOrganizations();
    checkAuthUser();
  }, []);

  const checkForComplimentaryPlan = () => {
    let requestBody = {
      workSpaceId: workSpace?._id
    }
    APIService.checkForComplimentaryPlan(workSpace?._id, requestBody, async (response, error) => {
      if (error) {
        return;
      }
    });
  }

  // TODO: Enable the below when you are about to launch.
  useEffect(() => {
    if (isFreeWorkspace(workSpace)) {
      // gift complimentary plan.
      checkForComplimentaryPlan();
    }
  }, [])


  // useEffect(() => {
  //   const landedOnAuthPage = localStorage.getItem('landed_on_auth_page');
  //   if (landedOnAuthPage) {
  //     localStorage.removeItem('landed_on_auth_page');
  //     // check if user is under ltd;
  //     if (isAccountUnderAppSumo(loggedInUser) && loggedInUser?.active_plan?.Key !== "LTD UPGRADE") {
  //       dispatch(setFreshNotificationPopup({
  //         show: true,
  //         message: `Get unlimited Bulk post, White-label and more AI credits to boost your Agency business, all for a one-time fee.`,
  //         header: ` Hi there 👋`,
  //         optionToDisableFutureNotifications: true,
  //         disableFutureNotificationsKey: Strings.MODAL_DEACTIVATION_FOR_LTD0X1,
  //         routeOnAcceptance: RoutePaths.BILLING_STRIPE,
  //         type: 'info'
  //       }))
  //     }
  //   }
  // }, [])

  useEffect(() => {
    if (navigateToDefault) {
      navigate(RoutePaths.DEFAULT);
    }
  }, [navigateToDefault]);

  useEffect(() => {
    if (openBulkWorkspaceCreator) {
      dispatch(searchWorkSpace(false));
      navigate(RoutePaths.BULK_WORKSPACES);
      dispatch(setOpenBulkWorkspaceCreator(false));
    }
  }, [openBulkWorkspaceCreator]);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    if (!loggedInUser) {
      authenticate();
      return;
    }
    checkSplashStatus();
    addPubSubEventListener(`${loggedInUser._id}-password-changed`, passwordChangedHandler);
    return () => {
      removePubSubEventListener(`${loggedInUser?._id}-password-changed`, passwordChangedHandler);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    let search = location.search;
    if (search.includes('billing')) {
      dispatch(searchWorkSpace(false));
    }
  }, [location]);

  // useEffect(() => {
  //   putUserOnlineViaPubSub();
  // }, []);

  useEffect(() => {
    validateSession();
  }, []);

  const checkAndRegisterLastQuery = async () => {
    let currentDate = new Date().toISOString();
    let lastQuery = await StorageEngine.getItem('_postly_l_query');
    lastQuery = JSON.parse(lastQuery);
    if (!lastQuery || lastQuery?.expireAt < currentDate) {
      updateLastQuery();
      let value = JSON.stringify({ expireAt: new Date(new Date().getTime() + 1000 * 60 * 60 * 24).toISOString() });
      StorageEngine.setItem('_postly_l_query', value);
    }
  }

  useEffect(() => {
    checkAndRegisterLastQuery()
  }, []);

  const checkAuthUser = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        firebase.auth().signInAnonymously().then(() => {
        }).catch((_error) => { });
      }
    });
  }

  const authenticate = async () => {
    await StorageEngine.clear();
    navigate(RoutePaths.DEFAULT);
  }

  const checkSplashStatus = async () => {
    let visitedSplash = await StorageEngine.getItem("visited_splash");
    if (!visitedSplash) {
      setNavigateToDefault(true);
    }
  }

  const fetchPostsFromRemoteSource = async () => {
    try {
      let response;
      response = await APIService.fetchAllWorkSpacePosts(
        workSpace["_id"],
        {},
        "all",
        "",
        0
      );
      let { data } = response;
      let allData = {
        pending: data?.filter((d) => d.pending_approval === true)?.length,
        posted: data?.filter((d) => d.posted === true)?.length,
      };
      dispatch(setpostsTypeCounter(allData));
    } catch (error) {
      toast.error(error, { theme: "colored" });
    }
  }


  const fetchScheduledPostsFromRemoteSource = async (receivedSkip) => {
    try {
      let response;
      response = await APIService.fetchAllWorkSpacePosts(
        workSpace["_id"],
        {},
        "scheduled",
        "",
        0
      );
      let { data } = response;
      dispatch(setscheduledCounter(data?.length));
    } catch (error) {
      toast.error(error, { theme: "colored" });
    }
  };


  const fetchDraftPostsFromRemoteSource = async (receivedSkip) => {
    // TODO: UNDER_MAINTENANCE 101
    return
    try {
      let response;
      response = await APIService.fetchAllWorkSpaceDraftContents(
        workSpace["_id"]
      );
      let { data } = response;
      dispatch(setdraftCounter(data?.length));
    } catch (error) {
      // toast.error(error, { theme: "colored" });
    }
  };

  const fetchFailedPostsFromRemoteSource = async () => {
    try {
      let response;
      response = await APIService.fetchAllWorkSpacePosts(
        workSpace["_id"],
        {},
        "failed",
        "",
        0
      );
      let { data } = response;
      dispatch(setfailedCounter(data?.length));
    } catch (error) {
      toast.error(error, { theme: "colored" });
    }
  }

  const fetchRecurringPostsFromRemoteSource = async () => {
    try {
      let response;
      response = await APIService.fetchAllWorkSpacePosts(
        workSpace["_id"],
        {},
        "recurring",
        "",
        0
      );
      let { data } = response;
      dispatch(setrecurringCounter(data?.length));
    } catch (error) {
      toast.error(error, { theme: "colored" });
    }
  };


  const fetchAllHandler = async () => {
    try {
      await Promise.all([fetchDraftPostsFromRemoteSource(), fetchPostsFromRemoteSource(), fetchScheduledPostsFromRemoteSource(), fetchRecurringPostsFromRemoteSource(), fetchFailedPostsFromRemoteSource()]);
    } catch (error) {
      console.log("error", error);
      // toast.error(error, { theme: "colored" });
    }
  }

  // useEffect(() => {
  //   if (!workSpace?._id) return;
  //   if (hasCalledFetchPosts === false && postsTypeCounter === null && scheduledCounter === null && failedCounter === null) {
  //     fetchAllHandler()
  //     dispatch(sethasCalledFetchPosts(true));
  //   }
  // }, [workSpace?._id])

  const passwordChangedHandler = async (data) => {
    let activeSessions = data?.sessions ?? [];
    try {
      const { data } = await APIService.fetchMyFingerPrint();
      if (!activeSessions.includes(data)) {
        await StorageEngine.clear();
        toast.info("A password changed was detected. Please reauthenticate", { theme: 'colored' });
        setTimeout(() => {
          navigate(RoutePaths.DEFAULT);
        }, 1000);
      }
    } catch (e) { }
  }

  const validateSession = async () => {
    try {
      const { data } = await APIService.fetchMyFingerPrint();
      const deviceFingerPrint = data;
      if (loggedInUser) {
        APIService.fetchLatestMe(async (response, error) => {
          if (error) {
            return;
          }
          const userLatestData = response.data;
          let activeDevices = userLatestData.sessions ?? [];
          if (!activeDevices.includes(deviceFingerPrint)) {
            await StorageEngine.clear();
            navigate(RoutePaths.DEFAULT);
          }
        });
      }
    } catch (e) { }
  }

  const updateLastQuery = async () => {
    APIService.updateLastQuery(async (response, error) => {
      if (error) {
        return;
      }
    });
  }

  const dropPostDetailsDialog = () => {
    let currentWindowLocation = window.location.href;
    let newLocation = StringUtils.substringBeforeLast(currentWindowLocation, "/");
    window.history.pushState("", "", newLocation);
    dispatch(setOpenPostDetailsDialog({ open: false }));
  }

  return (
    <div>
      <div
        className='force-refresh-postly-logo'
        style={{
          position: 'fixed',
          margin: 5,
          marginTop: 10,
          marginLeft: 4,
          left: 0,
          top: 0
        }}>
      </div>
      <Flex direction={'column'} className={'bg-200'}>
        <Card
          className='navbar-top rounded-0 mb-4 p-0 sticky-kit'
          style={{
            borderTop: 'none',
            borderTopWidth: 0,
            boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)'
          }}>
          <Flex className={'m-0 mx-3 p-0'} direction={'column'}>
            <Flex>
              <Flex alignItems={'center'}>
                <ToggleButton />
                <Flex alignItems={'center'}>
                  <ActiveOrganizationsDropdown at="navbar-vertical" width={35} height={27} />
                  <div className='bg-400 m-0 p-0' style={{ width: 30, height: 1 }} />
                  <ActiveWorkspace at="navbar-vertical" width={35} height={27} />
                </Flex>
              </Flex>
              <div className='flex-grow-1'>
                <NavbarTop />
              </div>
            </Flex>
          </Flex>

        </Card>
        <div className={isFluid ? 'container-fluid' : 'container'}>
          {
            (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
            (
              <NavbarVertical />
            )
          }
          <div className={classNames('content', { 'pb-0': false })}>
            <Modal
              show={openExternalFilePickerDialog}
              onHide={() => dispatch(setOpenExternalFilePickerDialog(false))}
              onExit={() => setOpenExternalFilePickerDialog(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton>
                <Modal.Title>{externalFilePickerDialogOptions?.externalFilePickerTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ExternalFilePickerDialog
                  options={externalFilePickerDialogOptions}
                  modalClose={() => {
                    dispatch(setOpenExternalFilePickerDialog(false));
                  }}
                />
              </Modal.Body>
            </Modal>
            <Modal
              show={openWorkSpaceSearch}
              onHide={() => dispatch(searchWorkSpace(false))}
              onExit={() => {
                dispatch(searchWorkSpace(false));
                if (canReloadPage) {
                  navigate(RoutePaths.DEFAULT);
                  window.location.reload();
                }
              }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
              <WorkSpaceSearch />
            </Modal>
            <Modal
              centered
              size='lg'
              show={openPostDialog.open}
              onHide={() => {
                dropPostDetailsDialog();
              }}
            >
              <FullScreenPostDetailsDialog
                postId={openPostDialog.data}
                timeToPost={openPostDialog.time_to_post}
                refresh={() => {
                }}
                modalClose={() => {
                  dropPostDetailsDialog();
                }}
              />
            </Modal>
            <Outlet />
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default MainLayout;
