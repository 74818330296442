import React, { useRef } from 'react'
import Flex from '../../../common/Flex'
import { Card, FloatingLabel, Form } from 'react-bootstrap'
import NewChatPlaceholder from './NewChatPlaceholder'
import InputBoxArea from './InputBoxArea'
import FineTuner from './FineTuner'

const languages = [
    "Default",
    "English",
    "Spanish",
    "French",
    "German",
    "Italian",
    "Portuguese",
    "Dutch",
    "Russian",
    "Chinese (Simplified)",
    "Chinese (Traditional)",
    "Japanese",
    "Korean",
    "Arabic",
    "Hindi",
    "Bengali",
    "Punjabi",
    "Urdu",
    "Turkish",
    "Hebrew",
    "Greek",
    "Vietnamese",
    "Thai",
    "Swahili",
    "Polish",
    "Romanian",
    "Czech",
    "Hungarian"
]

const writingStyles = [
    "Default",
    "Academic",
    "Analytical",
    "Argumentative",
    "Conversational",
    "Creative",
    "Educational",
    "Formal",
    "Informal",
    "Literary",
    "Narrative",
]

const toneStyle = [
    "Default",
    "Appreciative",
    "Assertive",
    "Awestruck",
    "Candid",
    "Relaxed",
    "Casual",
    "Cautionary",
    "Compassionate",
    "Critical",
    "Formal",
    "Humble",
    "Informative",
]

const outputFormat = [
    "Default",
    "Concise",
    "Step-by-step",
    "Extreme Details",
    "Essay",
    "Report",
    "Summary",
    "FAQ",
    "Interview",
    "Review"
]

const ConversationArea = (props) => {
    const { hasStartChat, chatContainerRef, handleClick } = props
    const textRef = useRef(null)
    return (
        <Flex
            direction={'column'}
            style={{
                width: '100%'
            }}
        >
            <Card
                style={{
                    height: '86vh',
                    maxHeight: '100vh',
                }}
                className="p-2 pt-3"
            >
                {
                    <NewChatPlaceholder
                        {...props}
                    />
                }
                {
                    <div className="chat-container">
                        <div ref={chatContainerRef} id="chat-container-id" className="chat-messages" style={!hasStartChat ? { paddingBottom: "24vh" } : {}}></div></div>
                }
                <FineTuner
                    languages={languages}
                    outputFormat={outputFormat}
                    toneStyle={toneStyle}
                    writingStyles={writingStyles}
                    {...props}
                />

                <InputBoxArea
                    {...props}
                />
            </Card>
        </Flex>
    )
}

export default ConversationArea