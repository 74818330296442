import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import './AddOnsStyles.css';

import { faPen } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUser } from "redux/slices/user_slice";


const LtdSpecialUpgradePlanUnEligible = (props) => {
    const { priceToggle, billingTag, hasActiveSubscription, updatableUrl, handleSubscriptionCancellation } = props

    const dispatch = useDispatch();
    const loggedInUser = useSelector(getLoggedInUser);
    const [currentPlan] = useState(loggedInUser.active_plan);
    const [cancellingSubscription, setCancellingSubscription] = useState(false);
    const [openSubscriptionCancelPrompt, setOpenSubscriptionCancelPrompt] =
        useState(false);
    const [openSocialAccountQuantityPicker, setOpenSocialAccountQuantityPicker] =
        useState(false);
    const [planType, setPlanType] = useState(0);
    const [formData, setFormData] = useState({
        numberOfSocialAccounts: "",
    });
    const handleBilling = props.handleBilling;
    const handleAddSubscriptionQuantity = props.handleAddSubscriptionQuantity;
    const [pricing, setPricing] = useState(props.pricing);

    const getPrice = () => {
        const price = props.price
        if (!priceToggle) {
            return <><span
                style={{
                    fontSize: 25,
                    fontWeight: 700
                }}
            >
                {price?.Month}
            </span><span
                style={{
                    fontSize: 13
                }}
            ></span></>
        }
        return <><span
            style={{
                fontSize: 25,
                fontWeight: 700
            }}
        >
            {price?.Year}
        </span><span
            style={{
                fontSize: 13
            }}
        ></span></>
    }

    const displayFeatures = props?.features?.map((feature, index) => {
        return <Flex
            key={index}
            style={{
                fontSize: 12
            }}
        >
            <FontAwesomeIcon icon={feature?.icon ?? 'check'} className="me-1 text-success" />
            <p
                style={{
                    fontSize: 12,
                    width: 180
                }}
            >
                {feature?.title}
            </p>
        </Flex>
    })
    return (
        <>
            <Card
                className={props?.highlighted ? 'bg-[#E8F1F2]' : ''}
                style={{
                    'position': 'relative'
                }}>
                <Card.Header
                    className={!props?.highlighted ? 'bg-light' : ''}
                    style={{
                        minWidth: 235,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Flex
                        gap={2}
                    >
                        <Flex
                            direction={'column'}
                        >
                            <h5>
                                {props?.title}
                            </h5>
                            <p
                                style={{
                                    fontSize: 12,
                                    color: '#072535',
                                    width: 300,
                                    fontWeight: 400
                                }}
                            >
                                {props?.subTitle}
                            </p>
                            <Button
                                onClick={handleSubscriptionCancellation}
                                variant="danger"
                                className="d-block w-50"
                                style={{
                                    fontSize: 11,
                                    padding: 4
                                }}
                            >
                                {props?.buttonText}
                            </Button>
                        </Flex>
                        {/* <Flex>
                            <div>
                                <span
                                    style={{
                                        color: props?.highlighted && 'black'
                                    }}
                                >
                                    <span
                                        style={{
                                            position: 'relative',
                                            top: '-10px',

                                        }}
                                    >
                                        {props?.currencySign}
                                    </span>
                                    {getPrice()}
                                </span>
                            </div>
                        </Flex> */}
                    </Flex>
                </Card.Header>
                <Card.Body>
                    <Flex
                        direction={'column'}
                        style={{
                            fontSize: 12
                        }}
                    >
                        {displayFeatures}
                    </Flex>
                </Card.Body>
            </Card>
        </>
    )
}

export default LtdSpecialUpgradePlanUnEligible