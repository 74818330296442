import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import FeedDropdown from 'components/app/social/feed/FeedDropdown'
import Avatar from 'components/common/Avatar'
import Flex from 'components/common/Flex'
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

const InstaFeedCardHeader = ({
    status,
    avatarSrc,
    time,
    name,
    share,
    location,
    privacy
  }) => {
  dayjs.extend(relativeTime);

  return (
    <Card.Header className="bg-light">
    <Row className="justify-content-between">
      <Col>
        <Flex>
          <Avatar size="2xl" src={avatarSrc} className={status} />
          <div className="flex-1 align-self-center ms-2">
            <p className="mb-1 lh-1">
              <Link className="fw-semi-bold" to="/user/profile">
                {name}
              </Link>
              {!!share && (
                <span className="ms-1">
                  shared {/^[aeiou]/g.test(share.toLowerCase()) ? 'an' : 'a'}{' '}
                  <a href="#!">{share}</a>
                </span>
              )}
            </p>
            <p className="mb-0 fs--1">
              {dayjs().to(dayjs(time))} • {location} •{' '}
              <FontAwesomeIcon
                icon={classNames({
                  users: privacy === 'friends',
                  lock: privacy === 'private',
                  'globe-americas': privacy === 'public'
                })}
              />
            </p>
          </div>
        </Flex>
      </Col>
      {/* <Col xs="auto">
        <FeedDropdown />
      </Col> */}
    </Row>
  </Card.Header>
  )
}

InstaFeedCardHeader.propTypes = {
    status: PropTypes.string,
    avatarSrc: PropTypes.string,
    time: PropTypes.string,
    name: PropTypes.string,
    share: PropTypes.string,
    location: PropTypes.string,
    privacy: PropTypes.string
  };

export default InstaFeedCardHeader