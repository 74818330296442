import { Button, Card, Modal } from "react-bootstrap";
import React, { cloneElement, useEffect, useState } from "react";
import { Crown } from "@phosphor-icons/react";
import Logo from "components/common/Logo";
import Login from "components/authentication/simple/Login";
import Section from "components/common/Section";
import Flex from "components/common/Flex";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getLoggedInUser } from "redux/slices/user_slice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { RoutePaths } from "../../constants";
import { useSelector } from 'react-redux';
import { isFreeWorkspace } from "utils/workspace_utils";

export const PremiumGuard = ({ titleIcon, title = "Upgrade to Premium", description = "Upgrade to premium to enjoy this feature", nextUrl, children, trigger, onTrigger }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const authenticatedUser = useSelector(getLoggedInUser);
    const activeWorkspace = useSelector(getActiveWorkSpace);
    const [showPopup, setShowPopup] = useState(false);
    const [showAuthPopup, setShowAuthPopup] = useState(false);
    const freeUser = authenticatedUser ? isFreeWorkspace(activeWorkspace) : true;

    const closeAuthPopup = () => {
        setShowAuthPopup(false);
    }

    const closePopup = () => {
        setShowPopup(false);
    }

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!authenticatedUser) {
            setShowAuthPopup(true);
            return;
        }
        if (trigger) {
            setShowPopup(true);
            return;
        }
        if (freeUser && !onTrigger) {
            setShowPopup(true);
        } else if (children.props.href) {
            const href = children.props.href;
            if (typeof href === 'string') {
                navigate(href);
            } else if (typeof href === 'object') {
                const { pathname, query } = href;
                if (pathname) {
                    const queryString = new URLSearchParams(query).toString();
                    const url = queryString ? `${pathname}?${queryString}` : pathname;
                    navigate(url);
                }
            }
        } else if (children.props.onClick) {
            children.props.onClick(e);
        }
    }

    useEffect(() => {
        if (showPopup) {
            if (onTrigger) {
                onTrigger();
            }
        }
    }, [showPopup]);

    const child = cloneElement(children, { onClick: handleClick });

    return (
        <>
            <div onClick={handleClick}>
                {child}
            </div>
            <Modal
                show={showPopup}
                onHide={closePopup}
                onExit={closePopup}
                centered
            >
                <Modal.Header closeButton className="border-0">
                    <div className="d-flex align-items-center">
                        {titleIcon}
                        <Modal.Title>{title}</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        navigate(RoutePaths.BILLING_STRIPE);
                    }} className="rounded-pill" variant="warning">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <Crown size={20} className="text-white" />
                            <span>{t('upgrade')}</span>
                        </div>
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="m-0 p-0" id={"premium"} onClick={() => setShowPopup(true)}></div>
            <Modal
                fullscreen
                show={showAuthPopup}
                onHide={closeAuthPopup}
                onExit={closeAuthPopup}
                centered
            >
                <Modal.Header closeButton className="border-0" />
                <Modal.Body>
                    <Section>
                        <Flex
                            alignItems={'center'}
                            justifyContent={'center'}>
                            <Flex
                                className={'mx-10'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                direction={'column'}>
                                <Logo onClick={() => setShowAuthPopup(false)} />
                                <Card className="mx-6">
                                    <Card.Body className="fs-10 fw-normal p-5">
                                        <Login
                                            inlineNextStep
                                            loosedSocials
                                            nextUrl={nextUrl}
                                            onDoneCallback={() => {
                                                window.location.reload();
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                            </Flex>
                        </Flex>
                    </Section>
                </Modal.Body>
            </Modal>
            <div id={"auth-popup"} className="m-0 p-0" onClick={() => setShowAuthPopup(true)}></div>
        </>
    );
};