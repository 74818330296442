import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmModal from 'components/common/ConfirmModal'
import Flex from 'components/common/Flex'
import { PlatformIdentifier } from 'constants'
import APIService from 'http/api_service'
import usePubSub from 'pubsub'
import React, { useState } from 'react'
import { Badge, Button, Card, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setPopupCustomAlert } from 'redux/slices/alertSlice'
import { setReconnectAccount } from 'redux/slices/platformSlice'
import { getLoggedInUser } from 'redux/slices/user_slice'
import { getActiveWorkSpace } from 'redux/slices/workspaceslice'
import TelegramLoginButton from 'telegram-login-button'
import { connectBlogger, connectFacebook, connectGMB, connectInstagram, connectLinkedIn, connectPinterest, connectReddit, connectRSSFeed, connectTelegram, connectThreads, connectTikTok, connectTwitter, connectWordpress, connectYoutube } from 'utils/platform_utils'
import { dispatchHandler } from 'utils/post_utils'
import StringUtils from 'utils/string'
import { hasPopupBlocker } from 'utils/url'
import { isFreeWorkspace } from 'utils/workspace_utils'
import BloggerProfile from './blogger/bloggerProfile'
import FacebookProfile from './facebook/facebookProfile'
import GMBProfile from './google_my_business/GMBProfile'
import InstagramProfile from './instagram/instagramProfile'
import LinkedInProfile from './linked_in/LinkedInProfile'
import PinterestProfile from './pinterest/pinterestProfile'
import RedditProfile from './reddit/redditProfile'
import RssProfile from './rss/rssProfile'
import { NoteContainer } from './styles'
import TelegramProfile from './telegram/TelegramProfile'
import ThreadsProfile from './threads/threadsProflle'
import TikTokProfile from './tiktok/TikTokProfile'
import TwitterProfile from './twitter/TwitterProfile'
import WordPressProfile from './wordpress/wordpressProfile'
import YoutubeProfile from './youtube/youtubeProfile'


const SocialPlatformItems = (props) => {
    const dispatch = useDispatch()
    const { addPubSubEventListener, removePubSubEventListener } = usePubSub();
    const { platform, setShowLess, showLess } = props
    const workSpace = useSelector(getActiveWorkSpace);
    const loggedInUser = useSelector(getLoggedInUser);
    const [selectAll, setSelectAll] = useState(false);
    const [pagesOrGroupIdsSelectedForAction, setPagesOrGroupIdsSelectedForAction] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [disconnecting, setDisconnecting] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [refreshPlatformAccounts, setRefreshPlatformAccounts] = useState(null);
    const [popupBlocked, setPopupBlocked] = useState(false);
    const [openTwitterConnection, setOpenTwitterConnection] = useState(false);
    const [openRssFeedConnection, setOpenRssFeedConnection] = useState(false);
    const [twitterApiKey, setTwitterApiKey] = useState("");
    const [twitterApiSecret, setTwitterApiSecret] = useState("");
    const [openTwitterApiKeysInput, setOpenTwitterApiKeysInput] = useState(false);
    const currentURL = window.location.href;
    // const setReconnectionOngoing = props?.setReconnectionOngoing
    // console.log("🚀 ~ ConnectedPlatformInfoHeader ~ platform:", platform)
    const [disconnectMsg, setDisconnectMsg] = useState("Disconnect account");
    const [displayDisconnectPrompt, setDisplayDisconnectPrompt] = useState(false);
    const [disconnectTitle, setDisconnectTitle] = useState();
    const [disconnectPrompt, setDisconnectPrompt] = useState();
    const [reconnectMsg, setReconnectMsg] = useState("Reconnect account");
    const [reconnectionClicked, setReconnectClicked] = useState(false);
    const [openRSSFeedInput, setOpenRSSFeedInput] = useState(false);
    const [feedName, setFeedName] = useState("");
    const [includeKeywords, setIncludeKeywords] = useState([]);
    const [excludeKeywords, setExcludeKeywords] = useState([]);
    const [feedUrl, setFeedUrl] = useState("");
    const [temporaryPageUpdateData, setTemporaryPageUpdateData] = useState(null);
    const platformNotEligibleForAutomaticReconnection = [
        PlatformIdentifier.TWITTER,
        PlatformIdentifier.TELEGRAM,
    ]
    const [addingAccount, setAddingAccount] = useState(false)
    const [disconnectingAccount, setDisconnectingAccount] = useState(false);
    const [refreshProfiles, setRefreshProfiles] = useState(false)
    const [clearAllChecksTrigger, setClearAllChecksTrigger] = useState(0);
    const [reconnectionOngoing, setReconnectionOngoing] = useState(false)

    const atLeastOneOfPagesOrGroupIdsSelectedForActionIsConnected = pagesOrGroupIdsSelectedForAction.some(page => page.connected);
    const allAccountsSelectedForActionAreConnected = pagesOrGroupIdsSelectedForAction.every(page => page.connected);

    const observeWindow = (win, platformIdentifier) => {
        let isPopupBlocked = hasPopupBlocker(win);
        if (isPopupBlocked) {
            setPopupBlocked(true);
            return;
        }
        let eventHandler = (data) => {
            setProcessing(false);
            if (!win.closed) {
                win.close();
            }
            if (data["oauth_status"] === "cancelled") {
                dispatchHandler(setPopupCustomAlert({
                    type: 'error',
                    message: 'Integration Cancelled',
                }));
            } else {
                dispatchHandler(setPopupCustomAlert({
                    type: 'success',
                    message: data["message"],
                }));
                // setRefreshPlatformAccounts(platformIdentifier);
                setRefreshProfiles(true)
            }
            removePubSubEventListener(`${workSpace["_id"]}auth`, eventHandler);
        };
        addPubSubEventListener(`${workSpace["_id"]}auth`, eventHandler);
        const timer = setInterval(() => {
            if (win.closed) {
                setProcessing(false);
                clearInterval(timer);
                removePubSubEventListener(`${workSpace["_id"]}auth`, eventHandler);
            }
        }, 500);
    };

    if (platform.disabled) {
        return <></>
    }
    const getConnectionExpiryTime = (last_connected_date, is_platform_connection_active, expireWindow) => {
        let staticExpireDate = (new Date() - new Date(last_connected_date));
        staticExpireDate = staticExpireDate ? expireWindow - parseInt((staticExpireDate / (1000 * 60 * 60 * 24))) : 0;
        if (!last_connected_date) {
            return {
                expired: true,
                dateValid: false,
                daysLeft: 0
            };
        }
        if (is_platform_connection_active) {
            return {
                expired: false,
                dateValid: true,
                daysLeft: staticExpireDate
            };
        }
        const today = new Date();
        if (today > new Date(last_connected_date)) {
            return {
                expired: true,
                dateValid: true,
                daysLeft: 0
            };
        }
        if (today < new Date(last_connected_date)) {
            return {
                expired: !is_platform_connection_active,
                dateValid: true,
                daysLeft: staticExpireDate
            };
        }
    };

    const managePagesOrGroupIdsSelectedForAction = (page) => {
        if (!page) return;
        if (Array.isArray(page)) {
            return;
        }
        let newPagesOrGroupIdsSelectedForAction = [...pagesOrGroupIdsSelectedForAction];
        let index = newPagesOrGroupIdsSelectedForAction.findIndex(p => p.id === page.id);
        if (index > -1) {
            newPagesOrGroupIdsSelectedForAction.splice(index, 1)
        } else {
            newPagesOrGroupIdsSelectedForAction.push(page)
        }
        setPagesOrGroupIdsSelectedForAction(newPagesOrGroupIdsSelectedForAction)
    }

    const addOrRemoveAllPagesOrGroupIdsSelectedForAction = (pages, add) => {

        if (add && pages?.length) {
            setPagesOrGroupIdsSelectedForAction([...pages])
        } else {
            setPagesOrGroupIdsSelectedForAction([])
        }
    }

    const connectMultiplePageOrGroup = () => {
        setProcessing(true);
        setConnecting(true);
        let currentSelectedProfile = selectedProfile;
        let reStructuredPagesOrGroupIdsSelectedForAction = pagesOrGroupIdsSelectedForAction.map(page => {
            let returningObj = {
                id: page?.id,
                name: page?.name,
                picture: page?.picture,
                user_id: page?.user_id,
                target: page?.target ?? platform.identifier,
                connected: false,
            }
            if (page?.is_page) {
                returningObj['is_page'] = page?.is_page
            }
            if (page?.self) {
                returningObj['self'] = page?.self
            }
            if (page?.display_name_prefixed) {
                returningObj['display_name_prefixed'] = page?.display_name_prefixed
            }
            if (page?.community_url) {
                returningObj['community_url'] = page?.community_url
            }
            if (page?.privacy) {
                returningObj['privacy'] = page?.privacy
            }
            if (page?.ig_user_id) {
                returningObj['ig_user_id'] = page?.ig_user_id
            }
            if (page?.business_account_username) {
                returningObj['business_account_username'] = page?.business_account_username
            }
            if (page?.instagram_business_account) {
                returningObj['instagram_business_account'] = page?.instagram_business_account
            }
            return returningObj;
        })
        let requestBody = {
            pagesOrGroups: reStructuredPagesOrGroupIdsSelectedForAction,
            platform_identifier: selectedProfile?.identifier,
            target: selectedProfile?.target,
            user_id: selectedProfile?.user_id,
        }
        APIService.connectMultiplePageOrGroup(workSpace['_id'], requestBody, (response, error) => {
            setProcessing(false);
            setConnecting(false);
            if (error) {
                dispatchHandler(setPopupCustomAlert({
                    type: 'error',
                    message: error,
                }));
                return;
            }
            let message = response['message'];
            dispatchHandler(setPopupCustomAlert({
                type: 'success',
                message: message,
            }));
            setTemporaryPageUpdateData({
                data: pagesOrGroupIdsSelectedForAction,
                changes: {
                    connected: true
                },
                currentSelectedProfile
            })
            setPagesOrGroupIdsSelectedForAction([])
            setClearAllChecksTrigger(prev => prev + 1);
        });
    }

    const disconnectMultiplePageOrGroup = () => {
        setProcessing(true);
        setDisconnecting(true);
        let currentSelectedProfile = selectedProfile;
        let reStructuredPagesOrGroupIdsSelectedForAction = pagesOrGroupIdsSelectedForAction.map(page => {
            let returningObj = {
                id: page?.id,
                name: page?.name,
                picture: page?.picture,
                user_id: page?.user_id,
                target: page?.target,
                connected: false,
            }
            if (page?.is_page) {
                returningObj['is_page'] = page?.is_page
            }
            if (page?.self) {
                returningObj['self'] = page?.self
            }
            if (page?.display_name_prefixed) {
                returningObj['display_name_prefixed'] = page?.display_name_prefixed
            }
            if (page?.community_url) {
                returningObj['community_url'] = page?.community_url
            }
            if (page?.privacy) {
                returningObj['privacy'] = page?.privacy
            }
            if (page?.ig_user_id) {
                returningObj['ig_user_id'] = page?.ig_user_id
            }
            if (page?.business_account_username) {
                returningObj['business_account_username'] = page?.business_account_username
            }
            if (page?.instagram_business_account) {
                returningObj['instagram_business_account'] = page?.instagram_business_account
            }
            return returningObj;
        })

        let requestBody = {
            pagesOrGroups: reStructuredPagesOrGroupIdsSelectedForAction,
            platform_identifier: selectedProfile?.identifier,
            target: selectedProfile?.target,
            user_id: selectedProfile?.user_id,
        }

        APIService.disconnectMultiplePageOrGroup(workSpace['_id'], requestBody, (response, error) => {
            setProcessing(false);
            setDisconnecting(false);
            if (error) {
                dispatchHandler(setPopupCustomAlert({
                    type: 'error',
                    message: error,
                }));
                return;
            }
            let message = response['message'];
            dispatchHandler(setPopupCustomAlert({
                type: 'success',
                message: message,
            }));
            // change all connected status of pages in pagesOrGroupIdsSelectedForAction to false
            setTemporaryPageUpdateData({
                data: pagesOrGroupIdsSelectedForAction,
                changes: {
                    connected: false
                },
                currentSelectedProfile
            })
            setPagesOrGroupIdsSelectedForAction([])
            setClearAllChecksTrigger(prev => prev + 1);
        });
    }

    const stamp_PRO_OnPlatform = (identifier) => {
        return false // removing pro status from free plan users for now...
        let targets = [PlatformIdentifier.LINKED_IN, PlatformIdentifier.GOOGLE_MY_BUSINESS, PlatformIdentifier.TWITTER, PlatformIdentifier.TELEGRAM, PlatformIdentifier.YOUTUBE, PlatformIdentifier.WORDPRESS, PlatformIdentifier.RSS_FEED, PlatformIdentifier.TIKTOK]
        const targetFound = targets?.find(target => target === identifier);
        if (isFreeWorkspace(workSpace) && targetFound) return true;
        return false
    }

    const howToConnectTwitterCard = (
        <Card
            canHover={false}
            style={{
                width: "100%",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
            }}
        >
            <NoteContainer>
                Please Note:
                <ListGroup as="ul" style={{ border: "none" }}>
                    <ListGroup.Item as="li" style={{ border: "none" }}>
                        <FontAwesomeIcon icon="check" className="me-1 text-success" />{" "}
                        Connect using your Twitter Developer API key.
                    </ListGroup.Item>
                    <ListGroup.Item as="li" style={{ border: "none" }}>
                        <FontAwesomeIcon icon="check" className="me-1 text-success" />{" "}
                        Access to the API key is free.
                    </ListGroup.Item>
                    <ListGroup.Item as="li" style={{ border: "none" }}>
                        <FontAwesomeIcon icon="check" className="me-1 text-success" />{" "}
                        Remember to create your project and app.
                    </ListGroup.Item>
                    <ListGroup.Item as="li" style={{ border: "none" }}>
                        <FontAwesomeIcon icon="check" className="me-1 text-success" /> Add
                        the Callback URL as required.
                    </ListGroup.Item>
                    <ListGroup.Item as="li" style={{ border: "none" }}>
                        <FontAwesomeIcon icon="check" className="me-1 text-success" />{" "}
                        Generate your API key and API secret key from the 'Consumer keys'
                        section.
                    </ListGroup.Item>
                    <ListGroup.Item as="li" style={{ border: "none" }}>
                        <FontAwesomeIcon icon="check" className="me-1 text-success" /> Use
                        the copy icon to directly transfer your keys from Twitter.
                    </ListGroup.Item>
                    <ListGroup.Item as="li" style={{ border: "none" }}>
                        <FontAwesomeIcon icon="check" className="me-1 text-success" /> For a
                        detailed guide and demo video:{" "}
                        {currentURL?.includes('app.postly.ai') ?
                            <a
                                href="https://postly.ai/twitter"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://postly.ai/twitter
                            </a> :
                            <>Contact support</>
                        }

                    </ListGroup.Item>
                </ListGroup>
            </NoteContainer>
        </Card>
    );
    const howToConnectRSSFeedCard = (
        <Card
            canHover={false}
            style={{
                width: "100%",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
            }}
        >
            <NoteContainer>
                Please Note:
                <ListGroup as="ul" style={{ border: "none" }}>
                    <ListGroup.Item as="li" style={{ border: "none" }}>
                        <FontAwesomeIcon icon="check" className="me-1 text-success" />
                        Connect multiple RSS feeds.
                    </ListGroup.Item>
                    <ListGroup.Item as="li" style={{ border: "none" }}>
                        <FontAwesomeIcon icon="check" className="me-1 text-success" />
                        An example feed format is like:{" "}
                        <a
                            href="https://techcrunch.com/feed"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://techcrunch.com/feed
                        </a>
                        .
                    </ListGroup.Item>
                    <ListGroup.Item as="li" style={{ border: "none" }}>
                        <FontAwesomeIcon icon="check" className="me-1 text-success" />{" "}
                        Proceed to the RSS feed page to schedule and manage your feeds.
                    </ListGroup.Item>
                    {
                        (currentURL === `app.postly.ai` || currentURL === `http://localhost:3000`) &&
                        <ListGroup.Item as="li" style={{ border: "none" }}>
                            <FontAwesomeIcon icon="check" className="me-1 text-success" />
                            Need more help? Check out the guide page:{" "}
                            <a
                                href="https://postly.ai/rss-feed"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://postly.ai/rss-feed
                            </a>
                        </ListGroup.Item>
                    }
                </ListGroup>
            </NoteContainer>
        </Card>
    );

    const handleTelegramResponse = (response) => {
        let { id: userId, photo_url: userPhoto, username } = response;
        connectTelegram(
            workSpace,
            userId,
            username,
            userPhoto,
            PlatformIdentifier.TELEGRAM,
            (response, error) => {
                setProcessing(false);
                if (error) {
                    setProcessing(false);
                    dispatchHandler(setPopupCustomAlert({
                        type: 'error',
                        message: error,
                    }));
                    return;
                }
                setRefreshPlatformAccounts(PlatformIdentifier.TELEGRAM);
            }
        );
    };

    const addNewAccount = (options) => {
        if (processing) {
            dispatchHandler(setPopupCustomAlert({
                type: 'error',
                message: `Please wait for the current process to complete`,
            }));
            return;
        }
        setProcessing(true);
        let platformIdentifier = platform.identifier;
        let platformName = platform.name;
        switch (platformIdentifier) {
            case PlatformIdentifier.FACEBOOK:
                connectFacebook(
                    workSpace,
                    platformName,
                    platformIdentifier,
                    (response, error) => {
                        setProcessing(false);
                        if (error) {
                            dispatchHandler(setPopupCustomAlert({
                                type: 'error',
                                message: error,
                            }));
                            return;
                        }
                        dispatchHandler(setPopupCustomAlert({
                            type: 'success',
                            message: error,
                        }));
                        setRefreshProfiles(true)
                        // setRefreshPlatformAccounts(platformIdentifier);
                    }
                );
                break;
            case PlatformIdentifier.INSTAGRAM:
                connectInstagram(
                    workSpace,
                    platformName,
                    platformIdentifier,
                    (response, error) => {
                        setProcessing(false);
                        if (error) {
                            dispatchHandler(setPopupCustomAlert({
                                type: 'error',
                                message: error,
                            }));
                            return;
                        }
                        dispatchHandler(setPopupCustomAlert({
                            type: 'success',
                            message: response,
                        }));
                        setRefreshProfiles(true)
                        // setRefreshPlatformAccounts(platformIdentifier);
                    }
                );
                break;
            case PlatformIdentifier.LINKED_IN:
                connectLinkedIn(
                    workSpace,
                    PlatformIdentifier.LINKED_IN,
                    (response, error) => {
                        if (error) {
                            console.log("🚀 ~ addNewAccount ~ error:", error)
                            setProcessing(false);
                            dispatchHandler(setPopupCustomAlert({
                                type: 'error',
                                message: error,
                            }));
                            return;
                        }
                        let win = window.open(response.data, "_blank");
                        observeWindow(win);
                    }
                );
                break;
            case PlatformIdentifier.THREADS:
                connectThreads(
                    workSpace,
                    PlatformIdentifier.THREADS,
                    (response, error) => {
                        if (error) {
                            console.log("🚀 ~ addNewAccount ~ error:", error)
                            setProcessing(false);
                            dispatchHandler(setPopupCustomAlert({
                                type: 'error',
                                message: error,
                            }));
                            return;
                        }
                        let win = window.open(response.data, "_blank");
                        observeWindow(win, PlatformIdentifier.THREADS);
                    }
                );
                break;
            case PlatformIdentifier.YOUTUBE:
                connectYoutube(
                    workSpace,
                    PlatformIdentifier.YOUTUBE,
                    (response, error) => {
                        if (error) {
                            setProcessing(false);
                            dispatchHandler(setPopupCustomAlert({
                                type: 'error',
                                message: error,
                            }));
                            return;
                        }
                        let win = window.open(response.data, "_blank");
                        observeWindow(win);
                    }
                );
                break;
            case PlatformIdentifier.TIKTOK:
                connectTikTok(
                    workSpace,
                    PlatformIdentifier.TIKTOK,
                    (response, error) => {
                        if (error) {
                            setProcessing(false);
                            dispatchHandler(setPopupCustomAlert({
                                type: 'error',
                                message: error,
                            }));
                            return;
                        }
                        let win = window.open(response.data, "_blank");
                        observeWindow(win);
                    }
                );
                break;
            case PlatformIdentifier.PINTEREST:
                connectPinterest(
                    workSpace,
                    PlatformIdentifier.PINTEREST,
                    (response, error) => {
                        if (error) {
                            setProcessing(false);
                            dispatchHandler(setPopupCustomAlert({
                                type: 'error',
                                message: error,
                            }));
                            return;
                        }
                        let win = window.open(response.data, "_blank");
                        observeWindow(win);
                    }
                );
                break;
            case PlatformIdentifier.GOOGLE_MY_BUSINESS:
                connectGMB(
                    workSpace,
                    PlatformIdentifier.GOOGLE_MY_BUSINESS,
                    (response, error) => {
                        if (error) {
                            setProcessing(false);
                            dispatchHandler(setPopupCustomAlert({
                                type: 'error',
                                message: error,
                            }));
                            return;
                        }
                        let win = window.open(response.data, "_blank");
                        observeWindow(win);
                    }
                );
                break;
            case PlatformIdentifier.REDDIT:
                connectReddit(
                    workSpace,
                    PlatformIdentifier.REDDIT,
                    (response, error) => {
                        if (error) {
                            setProcessing(false);
                            dispatchHandler(setPopupCustomAlert({
                                type: 'error',
                                message: error,
                            }));
                            return;
                        }
                        let win = window.open(response.data, "_blank");
                        observeWindow(win);
                    }
                );
                break;
            case PlatformIdentifier.WORDPRESS:
                connectWordpress(
                    workSpace,
                    PlatformIdentifier.WORDPRESS,
                    (response, error) => {
                        console.log("trying to connect wordpress");
                        if (error) {
                            setProcessing(false);
                            dispatchHandler(setPopupCustomAlert({
                                type: 'error',
                                message: error,
                            }));
                            return;
                        }
                        let win = window.open(response.data, "_blank");
                        observeWindow(win);
                    }
                );
                break;
            case PlatformIdentifier.BLOGGER:
                connectBlogger(
                    workSpace,
                    PlatformIdentifier.BLOGGER,
                    (response, error) => {
                        if (error) {
                            setProcessing(false);
                            dispatchHandler(setPopupCustomAlert({
                                type: 'error',
                                message: error,
                            }));
                            return;
                        }
                        let win = window.open(response.data, "_blank");
                        observeWindow(win);
                    }
                );
                break;
            case PlatformIdentifier.RSS_FEED:
                connectRSSFeed(
                    workSpace,
                    PlatformIdentifier.RSS_FEED,
                    options,
                    (response, error) => {
                        setProcessing(false);
                        if (error) {
                            dispatchHandler(setPopupCustomAlert({
                                type: 'error',
                                message: error,
                            }));
                            return;
                        }
                        if (response) {
                            dispatchHandler(setPopupCustomAlert({
                                type: 'success',
                                message: response?.message,
                            }));
                            window.location.reload();
                            return;
                        }
                    }
                );
                break;
            case PlatformIdentifier.TWITTER:
                connectTwitter(
                    workSpace,
                    PlatformIdentifier.TWITTER,
                    options,
                    (response, error) => {
                        setProcessing(false);
                        if (error) {
                            dispatchHandler(setPopupCustomAlert({
                                type: 'error',
                                message: error,
                            }));
                            return;
                        }
                        let win = window.open(response.data, "_blank");
                        observeWindow(win);
                    }
                );
                break;
            case PlatformIdentifier.TELEGRAM:
                break;
            default:
                setProcessing(false);
                break;
        }
    }

    const dayToExpire = getConnectionExpiryTime(platform?.last_possible_connection_date, platform.is_platform_connection_active, platform?.defaultExpireWindow);

    const populatePlatform = (platform) => {
        let generalProps = {
            platform: platform,
            showLess: showLess,
            setShowLess: setShowLess,
            selectAll: selectAll,
            clearAllChecksTrigger: clearAllChecksTrigger,
            managePagesOrGroupIdsSelectedForAction: managePagesOrGroupIdsSelectedForAction,
            addOrRemoveAllPagesOrGroupIdsSelectedForAction: addOrRemoveAllPagesOrGroupIdsSelectedForAction,
            connectMultiplePageOrGroup: connectMultiplePageOrGroup,
            setSelectedProfile: setSelectedProfile,
            selectedProfile: selectedProfile,
            refreshPlatformAccounts: refreshPlatformAccounts,
            setRefreshPlatformAccounts: setRefreshPlatformAccounts,
            removePlatform: removePlatform,
            refreshProfiles: refreshProfiles,
            setRefreshProfiles: setRefreshProfiles,
            setTemporaryPageUpdateData: setTemporaryPageUpdateData,
            temporaryPageUpdateData: temporaryPageUpdateData,
        }
        switch (platform?.identifier) {
            case PlatformIdentifier.FACEBOOK:
                return <FacebookProfile
                    {...generalProps}
                />
            case PlatformIdentifier.INSTAGRAM:
                return <InstagramProfile
                    {...generalProps}
                />
            case PlatformIdentifier.LINKED_IN:
                return <LinkedInProfile
                    {...generalProps}
                />
            case PlatformIdentifier.GOOGLE_MY_BUSINESS:
                return <GMBProfile
                    {...generalProps}
                />
            case PlatformIdentifier.REDDIT:
                return <RedditProfile
                    {...generalProps}
                />
            case PlatformIdentifier.PINTEREST:
                return <PinterestProfile
                    {...generalProps}
                />
            case PlatformIdentifier.YOUTUBE:
                return <YoutubeProfile
                    {...generalProps}
                />
            case PlatformIdentifier.TIKTOK:
                return <TikTokProfile
                    {...generalProps}
                />
            case PlatformIdentifier.TWITTER:
                return <TwitterProfile
                    {...generalProps}
                />
            case PlatformIdentifier.THREADS:
                return <ThreadsProfile
                    {...generalProps}
                />
            case PlatformIdentifier.TELEGRAM:
                return <TelegramProfile
                    {...generalProps}
                />
            case PlatformIdentifier.WORDPRESS:
                return <WordPressProfile
                    {...generalProps}
                />
            case PlatformIdentifier.BLOGGER:
                return <BloggerProfile
                    {...generalProps}
                />
            case PlatformIdentifier.RSS_FEED:
                return <RssProfile
                    {...generalProps}
                />
            default:
                return null
        }
    }

    const finalizePlatformRemoval = (action) => {
        if (action === "renew") {
            setReconnectionOngoing(true)
            dispatch(setReconnectAccount(selectedProfile.identifier))
            setReconnectMsg("Reconnecting...")
        } else {
            setDisconnectMsg("Disconnecting...");
        }
        setDisconnectingAccount(true);
        APIService.removeWorkSpacePlatform(workSpace['_id'], selectedProfile.identifier, selectedProfile.user_id, (response, error) => {
            setDisconnectingAccount(false);
            if (action === "renew") {
                setReconnectMsg("Reconnect account")
            } else {
                setDisconnectMsg("Disconnect account");
            }
            if (error) {
                if (action === "renew") {
                    setReconnectMsg("Reconnect account")
                    setReconnectionOngoing(false);
                } else {
                    setDisconnectMsg("Disconnect account");
                }
                dispatchHandler(setPopupCustomAlert({
                    type: 'error',
                    message: error,
                }));
                return;
            }

            let message = response['message'];
            if (action !== "renew") {
                dispatchHandler(setPopupCustomAlert({
                    type: 'success',
                    message: message,
                }));
            } else {
                addNewAccount();
            }
            setRefreshProfiles(true)
            // window.history.back();
        });
    }

    const removePlatform = (action, extras) => {
        if (action === 'renew') {
            setReconnectClicked(true)
        }
        if (selectedProfile.identifier === PlatformIdentifier.YOUTUBE) {
            setDisconnectTitle("IMPORTANT!");
            setDisconnectPrompt(`Please note that when you disconnect this YouTube account, we will also disconnect any <b>Google My Business</b> account(s) connected to the <b>${selectedProfile.user_name || extras?.user_name}</b> gmail account`);
            setDisplayDisconnectPrompt(true);
            return;
        }
        if (selectedProfile.identifier === PlatformIdentifier.GOOGLE_MY_BUSINESS) {
            setDisconnectTitle("IMPORTANT!");
            setDisconnectPrompt(`Please note that when you disconnect this Google My Business account, we will also disconnect any <b>YouTube</b> account(s) connected to the <b>${selectedProfile.user_name || extras?.user_name}</b> gmail account`);
            setDisplayDisconnectPrompt(true);
            return;
        }
        finalizePlatformRemoval(action);
    }

    const processAccountConnection = () => {
        if (!feedName && !feedUrl) {
            dispatchHandler(setPopupCustomAlert({
                type: 'error',
                message: "Feed name and URL are required",
            }));
            return;
        }
        const invalid_names = ['https://', 'http://'];
        for (let i = 0; i < invalid_names.length; i++) {
            let feedNameInvalid = feedName.startsWith(invalid_names[i]);
            if (feedNameInvalid) {
                dispatchHandler(setPopupCustomAlert({
                    type: 'error',
                    message: `Links can't be used as feed name`,
                }));
                return;
            }
        }
        addNewAccount({
            [PlatformIdentifier.RSS_FEED]: {
                displayName: feedName,
                url: feedUrl,
                keyword: {
                    includes: includeKeywords,
                    excludes: excludeKeywords,
                },
            },
        });
    }



    return (
        <Card
            className='mb-3'
        >
            <Card.Body>
                <Flex
                    gap={2}
                    alignItems='center'
                    justifyContent={'between'}

                >
                    <Flex
                        alignItems='center'
                        gap={2}
                    >
                        {
                            platform.svg ? <img
                                src={platform.svg}
                                alt={platform.name}
                                style={{
                                    width: 20,
                                    height: 20
                                }} /> :
                                <FontAwesomeIcon
                                    icon={platform.icon}
                                    style={{
                                        color: platform.color,
                                        fontSize: 20,
                                    }}
                                />
                        }
                        <p className="m-0"> {platform.name} </p>
                        {/* {(platform?.identifier === PlatformIdentifier.LINKED_IN || platform?.identifier === PlatformIdentifier.FACEBOOK || platform?.identifier === PlatformIdentifier.TIKTOK) &&
                            <Badge pill bg="200" className={`${dayToExpire?.expired && platform?.identifier !== PlatformIdentifier.LINKED_IN ? 'text-danger' : 'text-primary'} fs--2`}>
                                <FontAwesomeIcon icon="link" className="me-1" />
                                {
                                    dayToExpire?.expired ? `Connection expired` : `Connection expires in: ${dayToExpire?.daysLeft} days`
                                }
                            </Badge>
                        } */}
                        {
                            platform?.identifier === PlatformIdentifier.TELEGRAM ?
                                <TelegramLoginButton
                                    dataOnauth={handleTelegramResponse}
                                    botName="postly_bot"
                                    widgetVersion={22}
                                /> :
                                <Button
                                    style={{
                                        fontSize: 12
                                    }}
                                    disabled={addingAccount}
                                    onClick={() => {
                                        if (platform?.identifier === PlatformIdentifier.TWITTER) {
                                            setOpenTwitterConnection(true)
                                        } else if (platform?.identifier === PlatformIdentifier.RSS_FEED) {
                                            setOpenRssFeedConnection(true)
                                        } else {
                                            addNewAccount()
                                        }
                                    }}
                                    variant='outline-primary'>
                                    {
                                        addingAccount ? `Processing...` : 'Add account'
                                    }
                                </Button>
                        }
                        {
                            selectedProfile && !platformNotEligibleForAutomaticReconnection.includes(selectedProfile?.identifier) &&
                            <Button
                                style={{
                                    fontSize: 12
                                }}
                                disabled={reconnectionOngoing}
                                onClick={() => {
                                    removePlatform('renew');
                                }}
                                variant='outline-success'>
                                {
                                    !reconnectionOngoing ? `Reconnect ${StringUtils.trimTextToNth(selectedProfile?.user_name || '', 15)}` : `Reconnecting...`
                                }
                            </Button>
                        }
                        {
                            selectedProfile &&
                            <Button
                                style={{
                                    fontSize: 12
                                }}
                                disabled={disconnectingAccount}
                                onClick={() => {
                                    removePlatform();
                                }}
                                variant='outline-danger'>
                                {
                                    !disconnectingAccount ? `Disconnect ${StringUtils.trimTextToNth(selectedProfile?.user_name || '', 15)}` : `Disconnecting...`
                                }
                            </Button>
                        }
                    </Flex>
                    <Flex
                        gap={2}
                        alignItems={'center'}

                    >
                        <Form.Check
                            type="checkbox"
                            style={{
                                cursor: 'pointer',
                                position: 'relative',
                                top: 6

                            }}
                            label="Select all"
                            onChange={() => {
                                setSelectAll(!selectAll)
                            }}
                            checked={selectAll}
                        />
                        {
                            !atLeastOneOfPagesOrGroupIdsSelectedForActionIsConnected && pagesOrGroupIdsSelectedForAction?.length ?
                                <Button
                                    style={{
                                        fontSize: 12
                                    }}
                                    disabled={processing}
                                    onClick={connectMultiplePageOrGroup}
                                    variant='primary'>
                                    {
                                        connecting ? 'Connecting...' : 'Connect'
                                    }
                                </Button> : null
                        }
                        {
                            pagesOrGroupIdsSelectedForAction?.length && (allAccountsSelectedForActionAreConnected || atLeastOneOfPagesOrGroupIdsSelectedForActionIsConnected) ?
                                <Button
                                    style={{
                                        fontSize: 12
                                    }}
                                    disabled={processing}
                                    onClick={disconnectMultiplePageOrGroup}
                                    variant='danger'>
                                    {
                                        disconnecting ? 'Disconnecting...' : 'Disconnect'
                                    }
                                </Button> : null
                        }
                    </Flex>

                </Flex>
                {
                    populatePlatform(platform)
                }
            </Card.Body>
            <Modal show={openTwitterConnection}
                onHide={() => setOpenTwitterConnection(false)}
                onExit={() => setOpenTwitterConnection(false)}
                centered
            >
                <Modal.Body>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            position: "relative",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: 30,
                            }}
                        >
                            <span
                                style={{
                                    fontSize: 20,
                                    marginBottom: 20,
                                    fontWeight: 900,
                                    textAlign: "left",
                                }}
                            >
                                Consumer Keys
                            </span>

                            <Row className="mb-3 g-3">
                                <Form.Group as={Col} lg={12} controlId="password">
                                    <Form.Label>{"Enter your Twitter API Key"}</Form.Label>
                                    <Form.Control
                                        type={"password"}
                                        value={twitterApiKey}
                                        placeholder={"Twitter API Key"}
                                        onChange={(e) => {
                                            setTwitterApiKey(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} lg={12} controlId="password">
                                    <Form.Label>{"Enter your Twitter API Secret"}</Form.Label>
                                    <Form.Control
                                        type={"password"}
                                        value={twitterApiSecret}
                                        placeholder={"Twitter API Secret"}
                                        onChange={(e) => {
                                            setTwitterApiSecret(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                            <Button
                                variant={"primary"}
                                disabled={processing}
                                isWorking={processing}
                                onClick={() =>
                                    addNewAccount({
                                        [PlatformIdentifier.TWITTER]: {
                                            api_key: twitterApiKey,
                                            api_secret: twitterApiSecret,
                                        },
                                    })
                                }
                                style={{
                                    color: "white",
                                    minWidth: "100%",
                                    display: "flex",
                                    fontSize: 16,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {processing ? "Please wait..." : "Connect Twitter Account"}
                            </Button>
                        </div>

                        {howToConnectTwitterCard}
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openRssFeedConnection}
                onHide={() => setOpenRssFeedConnection(false)}
                onExit={() => setOpenRssFeedConnection(false)}
                centered
            >
                <Modal.Body>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            position: "relative",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: 30,
                            }}
                        >
                            <span
                                style={{
                                    fontSize: 20,
                                    marginBottom: 20,
                                    fontWeight: 900,
                                    textAlign: "left",
                                }}
                            >
                                Add RSS Feed
                            </span>

                            <Row className="mb-3 g-3">
                                <Form.Group as={Col} lg={12}>
                                    <Form.Label>{"Enter Feed Name"}</Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={feedName}
                                        placeholder={"Feed name"}
                                        onChange={(e) => {
                                            setFeedName(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} lg={12}>
                                    <Form.Label>{"Enter Feed URL"}</Form.Label>
                                    <Form.Control
                                        type={"text"}
                                        value={feedUrl}
                                        placeholder={"Feed URL"}
                                        onChange={(e) => {
                                            setFeedUrl(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                            <Button
                                variant={"primary"}
                                disabled={processing}
                                isWorking={processing}
                                onClick={processAccountConnection}
                                style={{
                                    color: "white",
                                    minWidth: "100%",
                                    display: "flex",
                                    fontSize: 16,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {processing ? "Adding feed..." : "Add"}
                            </Button>
                        </div>
                        {howToConnectRSSFeedCard}
                    </div>
                </Modal.Body>
            </Modal>
            <ConfirmModal
                open={displayDisconnectPrompt}
                onClose={() => {
                    setDisplayDisconnectPrompt(false);
                }}
                title={disconnectTitle}
                message={disconnectPrompt}
                cancelText='CANCEL'
                confirmText='CONTINUE'
                onCancel={() => {
                    setReconnectClicked(false)
                    setDisplayDisconnectPrompt(false);
                }}
                onConfirm={() => {
                    setDisplayDisconnectPrompt(false);
                    finalizePlatformRemoval(reconnectionClicked ? 'renew' : null);
                }}
            />

        </Card>
    )
}

export default SocialPlatformItems