import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppContext from "context/Context";
import React, { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme, lightTheme } from "utils/theme";
import { PlatformIdentifier } from "../../../../../constants";
import {
  getAltCancelTrigger,
  setAltCancelTrigger,
} from "../../../../../redux/slices/postslice";
import { findPlatformByIdentifier } from "../../../platforms";

function ThreadsFieldOptions(props, ref) {

  const {
    title, setAltValue,
    defaultVal, url,
    setOpenAltSelction,
    setCurrentUrlToCompare,
    altValue,
    setProcessAltSave,
    setCanOpenPopOver
  } =
    props;
  const altCancelTrigger = useSelector(getAltCancelTrigger);
  const dispatch = useDispatch();
  const [changesOccured, setChangesOccured] = useState(false);
  const twitterPlatform = findPlatformByIdentifier(PlatformIdentifier.TWITTER);
  const { config } = useContext(AppContext);

  const handleAltSave = () => {
    if (!changesOccured) {
      setCanOpenPopOver(false);
      setTimeout(() => {
        setCanOpenPopOver(true);
      }, 10);
      return;
    }
    setOpenAltSelction(false)
    setCurrentUrlToCompare(url)
    setAltValue(altValue)
    setProcessAltSave(true)
  };

  const handleAltCancel = () => {
    setCanOpenPopOver(false);
    setTimeout(() => {
      setCanOpenPopOver(true);
    }, 10);
    dispatch(setAltCancelTrigger(altCancelTrigger + 1));
  }

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        marginBottom: 10,
        borderRadius: 2,
        padding: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 2,
          paddingBottom: 5,
          paddingTop: 10,
          paddingLeft: 5,
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          width: "100%",
          alignItems: "center",
          gap: 5,
          marginBottom: 10,
        }}
      >
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: twitterPlatform.color,
          }}
        >
          <FontAwesomeIcon
            icon={twitterPlatform.icon}
            style={{
              color: "white",
              height: 12,
              width: 12,
            }}
          />
        </div>
        <h5
          type={"h5"}
          style={{
            fontSize: 15,
          }}
        >
          Edit Image Description
        </h5>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          gap: 3,
          alignItems: "center",
        }}
      >
        <p style={{ minWidth: 45, fontSize: 12 }}>
          Alt Text:
        </p>
        <Form.Control
          type={'text'}
          style={{
            flex: 1,
            fontSize: 11,
            backgroundColor:
              !config.isDark
                ? lightTheme.inputBackground
                : darkTheme.rootBackground,
          }}
          placeholder={title ?? "Enter an optional description for your photo"}
          value={defaultVal ?? ""}
          onChange={(e) => {
            setAltValue(e.target.value);
            setChangesOccured(true)
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "auto",
          display: "flex",
          marginTop: 15,
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 10
        }}
      >
        <Button
          onClick={handleAltCancel}
          id="alt-saver"
          variant="light"
        >
          Cancel
        </Button>
        <Button
          onClick={handleAltSave}
          id="alt-saver"
          variant="primary"
        >
          Save
        </Button>
      </div>
    </div>
  );
}
export default React.forwardRef(ThreadsFieldOptions);