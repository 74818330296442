import { useRef, useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal, Spinner } from "react-bootstrap";
import { BuildingOffice, Copy, PencilSimple, TrashSimple, Upload } from "@phosphor-icons/react";
import useOrganizationStore, { defaultOrganization } from "state/organization_store";
import { useTranslation } from "react-i18next";
import CopyLinkButton from "components/common/copylinkbutton";
import { Tooltip } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { getActiveWorkSpace, setActiveWorkspace } from "redux/slices/workspaceslice";
import { DateTime } from "luxon";
import Avatar from "components/common/Avatar";
import FalconLightBox from "components/common/FalconLightBox";
import APIService from "http/api_service";
import { toast } from "react-toastify";
import Flex from "components/common/Flex";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../../../constants";
import useWorkspaceSwitcher from "../../hooks/useWorkspaceSwitcher";
import { getLoggedInUser } from "redux/slices/user_slice";
import ConfirmModal from "components/common/ConfirmModal";
import useActiveOrganization from "../../hooks/useActiveOrganization";

export default function OrganizationGeneralSettings() {

    const { t } = useTranslation();
    const fileInputRef = useRef(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const allWorkspaces = useOrganizationStore(state => state.allWorkspaces);
    const setAllWorkspaces = useOrganizationStore(state => state.setAllWorkspaces);
    const organizationList = useOrganizationStore(state => state.organizationList);
    const setOrganizationList = useOrganizationStore(state => state.setOrganizationList);
    const activeWorkspace = useSelector(getActiveWorkSpace);
    const [activeOrganization, setActiveOrganization] = useActiveOrganization();

    const authenticatedUser = useSelector(getLoggedInUser);
    const switchWorkspace = useWorkspaceSwitcher(authenticatedUser);

    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(activeOrganization?.name);
    const [isSaving, setIsSaving] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [newAvatar, setNewAvatar] = useState(activeOrganization?.avatar);
    const [avatarToUpload, setAvatarToUpload] = useState();
    const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
    const [emailToDeleteWorkspace, setEmailToDeleteWorkspace] = useState('');
    const [deletingOrganization, setDeletingOrganization] = useState();
    const [openNextAvailableOrganizationPrompt, setOpenNextAvailableOrganizationPrompt] = useState(false);

    const handleCopyId = () => {
        navigator.clipboard.writeText(activeOrganization?._id);
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveName = () => {
        if (activeOrganization?.id === defaultOrganization?._id) {
            return;
        }
        setIsSaving(true);
        APIService.updateOrganization({ _id: activeOrganization?._id, name: newName }, (_, error) => {
            setIsSaving(false);
            setIsEditing(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let updatedOrganization = { ...activeOrganization, name: newName };
            setActiveOrganization(updatedOrganization);
            dispatch(setActiveWorkspace({ ...activeWorkspace, organization: updatedOrganization }));
        });
    };

    const handleDelete = () => {
        setDeletingOrganization(true);
        let organizationBeingDeleted = activeOrganization?._id;
        APIService.deleteOrganization(activeOrganization?._id, (_, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let organizations = [...organizationList].filter(org => org?._id !== activeOrganization?._id);
            setOrganizationList(organizations);
            if (organizations.length === 0) {
                navigate(RoutePaths.ORGS_AND_WORKSPACES);
                return;
            }
            let nextOrganization = organizations[0];
            let workspaces = [];
            for (let org of organizations) {
                if (org._id === defaultOrganization?._id) {
                    workspaces = allWorkspaces.filter(w => !w.organization);
                } else {
                    workspaces = allWorkspaces.filter(w => w.organization?._id === org?._id);
                }
                if (workspaces.length > 0) {
                    nextOrganization = org;
                    break;
                }
            }
            if (workspaces.length === 0) {
                setOpenNextAvailableOrganizationPrompt(true);
                return;
            }
            setOpenDeletePrompt(false);
            setDeletingOrganization(false);
            setActiveOrganization({ ...nextOrganization });
            dispatch(setActiveWorkspace({ ...workspaces[0], organization: nextOrganization }));
            setAllWorkspaces(allWorkspaces.filter(w => w.organization?._id !== organizationBeingDeleted));
            switchWorkspace(workspaces[0]._id);
        });
    };

    const handleEditAvatarClick = () => {
        fileInputRef.current.click();
    };

    const handleAvatarChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const newAvatarUrl = URL.createObjectURL(file);
            setNewAvatar(newAvatarUrl);
            setAvatarToUpload(file);
        }
    };

    const uploadSelectedAvatar = async () => {
        if (!avatarToUpload) {
            return;
        }
        setIsUploading(true);
        let logoUploadForm = new FormData();
        logoUploadForm.append("file", avatarToUpload);
        let response = await APIService.uploadNewFile(null, logoUploadForm, 0, null, 0);
        let result = response.data;
        let data = { _id: activeOrganization?._id, avatar: result };
        APIService.updateOrganization(data, (_, error) => {
            setIsUploading(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            toast.success(t('logo_updated_successfully'), { theme: 'colored' });
            let updatedOrganization = { ...activeOrganization, avatar: result };
            setActiveOrganization(updatedOrganization);
            dispatch(setActiveWorkspace({ ...activeWorkspace, organization: updatedOrganization }));
            setAvatarToUpload(undefined);
        });
    }

    const formattedCreationDate = DateTime.fromISO(activeOrganization?.created_at).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);

    return (
        <div className="w-100">
            <div className="position-relative d-flex align-items-center justify-content-center mb-3">
                {
                    newAvatar ?
                        <FalconLightBox image={newAvatar}>
                            <Avatar src={newAvatar} size="5xl" />
                        </FalconLightBox>
                        :
                        <BuildingOffice
                            weight='fill'
                            size={220}
                        />
                }
                {
                    isUploading &&
                    <Spinner animation="border" style={{ position: 'absolute' }} />
                }
                <Flex
                    alignItems={'center'}
                    className={'gap-1'}
                    style={{
                        position: 'absolute',
                        bottom: 20,
                        marginRight: 5
                    }}>
                    <Button
                        disabled={isUploading}
                        size="sm"
                        variant="dark"
                        className="fs--2 rounded-pill ms-2 border-300"
                        onClick={handleEditAvatarClick}
                    >
                        <Flex alignItems={'center'} className={'gap-1'}>
                            <span>{t('edit')}</span>
                            <PencilSimple weight="fill" />
                        </Flex>
                    </Button>
                    {
                        avatarToUpload &&
                        <Button
                            disabled={isUploading}
                            size="sm"
                            variant="dark"
                            className="fs--2 rounded-pill ms-2 border-300"
                            onClick={uploadSelectedAvatar}
                        >
                            <Flex alignItems={'center'} className={'gap-1'}>
                                <span>{t('upload_selection')}</span>
                                <Upload weight="fill" />
                            </Flex>
                        </Button>
                    }
                </Flex>
                <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleAvatarChange}
                />
            </div>
            <p className="text-muted small mb-3">{t('current_organization')}</p>
            <InputGroup className="mb-3">
                <InputGroup.Text className="fs--1 bg-transparent">ID</InputGroup.Text>
                <FormControl className="outline-none" value={activeOrganization?._id} readOnly />
                <CopyLinkButton disabled={activeOrganization?._id === defaultOrganization?._id} className={'shadow-none border-300'} variant={'falcon-default'} customIcon={<Copy />} defaultText={t('copy')} copiedText={t('organization_id_copied')} handleCopy={handleCopyId} />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text className="fs--1 bg-transparent">{t('name').toUpperCase()}</InputGroup.Text>
                {isEditing ? (
                    <>
                        <FormControl
                            className="outline-none"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                        <Button variant="outline" className="border-300" onClick={handleSaveName} disabled={isSaving}>
                            {
                                isSaving ? <Spinner as="span" animation="border" size="sm" /> :
                                    <span>{t('save')}</span>
                            }
                        </Button>
                    </>
                ) : (
                    <>
                        <FormControl className="outline-none" value={activeOrganization?.name} readOnly />
                        <Tooltip title={t('update_name_of_organization')}>
                            <Button disabled={activeOrganization?._id === defaultOrganization?._id} variant="outline" className="border-300" onClick={handleEditClick}>
                                <PencilSimple />
                            </Button>
                        </Tooltip>
                    </>
                )}
            </InputGroup>
            <hr className="bg-200 m-0 p-0" />
            <p className="text-muted small mt-3 text-center">{t('created_on')} {formattedCreationDate}</p>
            <hr className="bg-200 m-0 p-0" />
            <Flex alignItems={'center'} className={'mt-3'}>
                <span className="fs--1">{t('delete_current_organization')}</span>
                <div className="flex-grow-1" />
                <Button disabled={activeOrganization?._id === defaultOrganization?._id || deletingOrganization} size="sm" className="rounded-pill fs--1" variant="outline-danger" onClick={() => setOpenDeletePrompt(true)}>
                    <TrashSimple /> {deletingOrganization ? `${t('deleting')}...` : t('delete')}
                </Button>
            </Flex>
            <Modal show={openDeletePrompt}>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setOpenDeletePrompt(false);
                    }}>
                    <Modal.Header>
                        <Modal.Title>{`${t('delete_organization')}?`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Flex direction={'column'} className={'gap-3'}>
                            <span>{t('delete_organization_prompt')}</span>
                            <Form.Control
                                value={emailToDeleteWorkspace}
                                placeholder={t('type_in_your_email_address')}
                                onChange={(e) => {
                                    setEmailToDeleteWorkspace(e.target.value);
                                }}
                            />
                        </Flex>
                    </Modal.Body>
                    <Modal.Footer className="text-end">
                        <Flex className={'gap-2'}>
                            <Button size="sm" variant="falcon-default" onClick={() => setOpenDeletePrompt(false)}>{t('cancel')}</Button>
                            <Button type="submit" size="sm" variant="falcon-danger" disabled={emailToDeleteWorkspace !== authenticatedUser?.email} onClick={handleDelete}>{t('proceed')}</Button>
                        </Flex>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ConfirmModal
                open={openNextAvailableOrganizationPrompt}
                title={t('no_other_organization_with_workspaces_available')}
                message={t('no_more_organization_with_workspaces_found')}
                confirmText={t('continue')}
                cancelText={t('cancel')}
                onConfirm={() => {
                    setOpenNextAvailableOrganizationPrompt(false);
                    navigate(RoutePaths.ORGS_AND_WORKSPACES);
                }}
                onCancel={() => {
                    setOpenNextAvailableOrganizationPrompt(false);
                }}
            />
        </div>
    );
}