import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import loadable from '@loadable/component';
import { platforms } from 'components/app/platforms';
import ConfirmModal from 'components/common/ConfirmModal';
import Divider from 'components/common/Divider';
import Flex from 'components/common/Flex';
import { PlatformIdentifier } from 'constants';
import APIService from 'http/api_service';
import React, { useEffect, useState } from 'react';
import { Nav, Row, Spinner, Tab } from 'react-bootstrap';
import {useSelector} from "react-redux";
import { toast } from 'react-toastify';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { recommendUrlToUnblockPopup } from 'utils/url';



const LoadAccount = (identifier, data, connectAccount, processing, reloadPage) => {
    let Page = loadable(() => import(`../../chat/setup/${identifier}`), {
        fallback: <Spinner animation='border' size={20} />
    });
    return <Page  data={data} connectAccount={connectAccount} processing={processing} reloadPage={reloadPage} />
}

function findPlatformByIdentifier(refPlatorms, identifier) {
    let platformArrays = Object.entries(refPlatorms).map((entry) => entry);
    let platform = platformArrays.find((x) => x[1]['identifier'] === identifier);
    if (platform) {
        platform = { ...platform[1] };
    }
    return platform;
}

export const whatsApp = {
    name: "WhatsApp",
    color: "#25D366",
    icon: faWhatsapp,
    identifier: PlatformIdentifier.WHATSAPP,
    selected: false,
    metaFamily: true
}

const InstaPostConnector = ({reloadPage}) => {
    const workSpace = useSelector(getActiveWorkSpace);
    const messageables = ['facebook', 'instagram', 'twitter', 'linked_in'];
    const socialsForMessaging = [
        ...platforms.filter(x => messageables.includes(x.identifier)),
        whatsApp
    ].reverse().sort(x => x.metaFamily ? -1 : 0);
    const [activeTab, setActiveTab] = useState("instagram");
    const [fetchingConnectedAccounts, setFetchingConnectedAccounts] = useState();
    const [retrievedAccounts, setRetrievedAccounts] = useState();
    const [popupBlocked, setPopupBlocked] = useState(false);
    const [processing, setProcessing] = useState(false);
    const popUpUnblockHint = recommendUrlToUnblockPopup();



    const fetchConnectedAccounts = () => {
        setFetchingConnectedAccounts(true);
        APIService.fetchWorkSpacePlatforms(workSpace._id, (response, error) => {
            setFetchingConnectedAccounts(false);
            if (error) {
                if (error?.toLowerCase() === `WorkSpace ID is required`.toLowerCase()) {
                    return;
                  }
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data } = response;
            setRetrievedAccounts(data);
        });
    }

    useEffect(() => {
        fetchConnectedAccounts();
    }, []);



    const connectAccount = () => {
        alert("function called")
    }


  return (
    <Flex direction={'column'} className={'pt-3'}>
    {
        fetchingConnectedAccounts &&
        <Flex
            className={'gap-2 p-10'}
            alignItems={'center'}
            justifyContent={'center'}
            direction={'column'}>
            <Spinner animation="border" />
            <span>Please wait...</span>
        </Flex>
    }
    {
        !fetchingConnectedAccounts &&
        <Tab.Container
            id="social-accounts-setup-tabs"
            activeKey={activeTab}
            onSelect={(e) => {
                setActiveTab(e);
            }}
        >
            <Row sm={1} className={'px-3 py-0 mb-0 pb-0'}>
                <Nav variant="tabs" className="border-bottom-0 pb-0 mb-0 flex-row justify-content-center">
                    {
                        // socialsForMessaging?.filter(({ identifier }) => identifier === "facebook" || identifier === "twitter" || identifier === "instagram")?.map(x => {
                        socialsForMessaging?.filter(({ identifier }) => identifier === "instagram")?.map(x => {
                            return (
                                <Nav.Item className="mb-0 pb-0">
                                    <Flex
                                        className={'px-4 gap-2'}
                                        alignItems={'center'}
                                        justifyContent={'center'}>
                                        <FontAwesomeIcon className="p-0 m-0" icon={x.icon} color={x.color} />
                                        <Nav.Link className="p-0 m-0" eventKey={x.identifier}>{x.name}</Nav.Link>
                                    </Flex>
                                </Nav.Item>
                            )
                        })
                    }
                </Nav>
            </Row>
            <Divider className={'p-0 m-0'} />
            <Tab.Content
                style={{
                    overflowY: 'scroll',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}>
                <Tab.Pane eventKey={activeTab}>
                    {LoadAccount(activeTab, retrievedAccounts?.filter(x => x.identifier === activeTab), connectAccount, processing, reloadPage)}
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    }
    <ConfirmModal
        open={popupBlocked}
        title={"Popup Blocker Detected"}
        message={`Your browser is blocking our pop-ups. As a result you will not be able to connect your <b>${findPlatformByIdentifier(socialsForMessaging, activeTab)?.name}</b> account.\n\n${popUpUnblockHint ? popUpUnblockHint : 'Kindly disable pop-up blocking'} and try again.\n\nYou can re-enable pop-up blocking after connecting ${findPlatformByIdentifier(socialsForMessaging, activeTab)?.name} and other relevant platforms.\n\nThank you`}
        confirmText={"OK"}
        cancelText={"CLOSE"}
        onConfirm={() => {
            setPopupBlocked(false);
            setProcessing(false);
        }}
        onCancel={() => {
            setPopupBlocked(false);
            setProcessing(false);
        }}
    />
</Flex>
  )
}

export default InstaPostConnector