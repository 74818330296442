import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import './AddOnsStyles.css';
import ConfirmModal from 'components/common/ConfirmModal';
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getLoggedInUser } from "redux/slices/user_slice";
import {
    getBilledYearly,
    setSocialAccountQuantity,
} from "redux/slices/workspaceslice";
import { isLTD } from 'utils/ltd_utils';
import MonthlyYearlySwitch from './MonthlyYearlySwitch';
import { pricingDataNew } from "./pricingDataNew";
import { faPen } from '@fortawesome/free-solid-svg-icons';


const PlanCard = (props) => {
    const { data, priceToggle, billingTag, hasActiveSubscription, updatableUrl } = props

    const dispatch = useDispatch();
    const loggedInUser = useSelector(getLoggedInUser);
    const [currentPlan] = useState(loggedInUser.active_plan);
    const [cancellingSubscription, setCancellingSubscription] = useState(false);
    const [openSubscriptionCancelPrompt, setOpenSubscriptionCancelPrompt] =
        useState(false);
    const [openSocialAccountQuantityPicker, setOpenSocialAccountQuantityPicker] =
        useState(false);
    const [planType, setPlanType] = useState(0);
    const [formData, setFormData] = useState({
        numberOfSocialAccounts: "",
    });
    const handleBilling = props.handleBilling;
    const handleAddSubscriptionQuantity = props.handleAddSubscriptionQuantity;
    const [pricing, setPricing] = useState(props.pricing);
    const billedYearly = useSelector(getBilledYearly);

    const {
        id,
        subTitle,
        price,
        extraPrice,
        currencySign,
        periodical,
        buttonText,
        buttonTextForPayingCustomer,
        activePlanButtonText,
        badge,
        isFeatured,
        activePlan,
        billingFor,
        featureTitle,
        features,
        cancel_url,
        update_url,
        depreciated,
        offScreen,
        ...others
    } = props.pricing;


    const getPrice = () => {
        const price = data.price
        if (!billedYearly) {
            return <><span
                style={{
                    fontSize: 25,
                    fontWeight: 700
                }}
            >
                {price?.Month}
            </span><span
                style={{
                    fontSize: 13
                }}
            >/mo</span></>
        }
        return <><span
            style={{
                fontSize: 25,
                fontWeight: 700
            }}
        >
            {price?.Year}
        </span><span
            style={{
                fontSize: 13
            }}
        >/yr</span></>
    }

    const displayFeatures = data?.features?.map((feature, index) => {
        return <Flex
            key={index}
            style={{
                fontSize: 12
            }}
        >
            <FontAwesomeIcon icon={feature?.icon ?? 'check'} className="me-1 text-success" />
            <p
                style={{
                    fontSize: 12,
                    width: 180
                }}
            >
                {feature?.title}
            </p>
        </Flex>
    })

    const handleNumberOfSocualAccountChange = (event) => {
        const value = parseInt(event.target.value);
        dispatch(setSocialAccountQuantity(event.target.value));
        if (value >= 0) {
            setFormData({
                ...formData,
                numberOfSocialAccounts: value,
            });
        } else {
            setFormData({
                ...formData,
                numberOfSocialAccounts: "",
            });
        }
    };

    // const hashUpdatableUrl = () => {
    //     let cancelUrl = props.activePlanFromDB?.cancel_url;
    //     let updateUrl = props.activePlanFromDB?.update_url;
    //     if (updateUrl) {
    //         return updateUrl;
    //     }
    //     if (cancelUrl) {
    //         return cancelUrl.replace("cancel", "update");
    //     }
    //     return null;
    // };
    // const updatableUrl = hashUpdatableUrl();

    const cancelSubscription = (e) => {
        setCancellingSubscription(true);
        if (props.activePlanFromDB.cancel_url) {
            const cancelUrl = props.activePlanFromDB.cancel_url;
            window.open(cancelUrl, '_blank');
            setCancellingSubscription(false);
        } else {
            toast.info('You cannot cancel your subscription at the moment. Please contact support', { theme: "colored" });
            setCancellingSubscription(false);
        }
    };

    const selectedPlan = pricingDataNew[planType];

    useEffect(() => {
        setPricing(props.pricing);
    }, [props.pricing]);


    const totalAmountToBeCharged =
        formData.numberOfSocialAccounts *
        (billedYearly ? selectedPlan.extraPrice.Year : selectedPlan.extraPrice.Month);

    const handleClickOpenSocialAccountQuantityPicker = (planType) => {
        if (planType === 0) {
            setOpenSocialAccountQuantityPicker(true);
            setPlanType(0);
        } else if (planType === 1) {
            setOpenSocialAccountQuantityPicker(true);
            setPlanType(1);
        } else if (planType === 2) {
            setOpenSocialAccountQuantityPicker(true);
            setPlanType(2);
        }
    };

    if (offScreen) {
        return null
    }

    if (depreciated) {
        const isUserOnThisPlan = hasActiveSubscription?.Key === data?.plan;
        if (!isUserOnThisPlan) return null
    }

    return (
        <>
            <Card
                className={data?.highlighted ? 'bg-[#E8F1F2]' : ''}
                style={{
                    'position': 'relative'
                }}>
                <Card.Header
                    className={!data?.highlighted ? 'bg-light' : ''}
                    style={{
                        minWidth: 235,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Flex
                        gap={2}
                    >
                        <Flex
                            direction={'column'}
                        >
                            <h5>
                                {data?.title.replace(new RegExp('\\b' + 'Scheduler' + '\\b', 'g'), '')}
                            </h5>
                            <p
                                style={{
                                    fontSize: 12,
                                    color: '#777',
                                    fontWeight: 400
                                }}
                            >
                                {data?.subTitle}
                            </p>
                            {
                                hasActiveSubscription ? <Button
                                    disabled={true}
                                    // onClick={() => {
                                    //     if (others.plan) {
                                    //         // handleClickOpenSocialAccountQuantityPicker(id)
                                    //         props?.handleSubscriptionCancellation()
                                    //         // if (activePlan) {
                                    //         // } else {
                                    //         //     handleBilling(others);
                                    //         // }
                                    //     }
                                    // }}
                                    variant={"success"}
                                    // variant={data?.cancelText ? "danger" : "success"}
                                    className="d-block w-70"
                                    style={{
                                        fontSize: 11,
                                        padding: 5
                                    }}
                                >
                                    {/* {data?.cancelText ? data?.cancelText : 'Active'} */}
                                    {'Active'}
                                </Button> : <Button
                                    // disabled={hasActiveSubscription}
                                    onClick={() => {
                                        if (others.plan) {
                                            // if (activePlan) {
                                            //     handleClickOpenSocialAccountQuantityPicker(id)
                                            // } else {
                                            // }
                                            handleBilling(others);
                                        }
                                    }}
                                    variant="primary"
                                    className="d-block w-70"
                                    style={{
                                        fontSize: 11,
                                        padding: 5
                                    }}
                                >
                                    {loggedInUser?.active_plan?.Key === "Free" ?
                                        <>{data?.buttonText}</> : <>{data?.buttonTextForPayingCustomer}</>
                                    }
                                </Button>
                            }
                            {/* {
                                updatableUrl && <a
                                    href={updatableUrl}
                                    className={"text-decoration-none cursor-pointer mt-2"}
                                >
                                    <Flex
                                        className={"gap-1 text-warning"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        style={{
                                            fontSize: 11
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            style={{ width: 10, height: 10 }}
                                            icon={faPen}
                                        />
                                        <span style={{
                                            fontSize: 11
                                        }} className="">Update Payment Details</span>
                                    </Flex>
                                </a>
                            } */}
                        </Flex>
                        <Flex>
                            <div>
                                <span
                                    style={{
                                        color: data?.highlighted && 'black'
                                    }}
                                >
                                    <span
                                        style={{
                                            position: 'relative',
                                            top: '-10px',

                                        }}
                                    >
                                        {data?.currencySign}
                                    </span>
                                    {getPrice()}
                                </span>
                            </div>
                        </Flex>
                    </Flex>
                </Card.Header>
                <Card.Body>
                    <Flex
                        direction={'column'}
                        style={{
                            fontSize: 12
                        }}
                    >
                        {displayFeatures}
                    </Flex>
                </Card.Body>
                <ConfirmModal
                    open={openSubscriptionCancelPrompt}
                    onCancel={() => {
                        setOpenSubscriptionCancelPrompt(false);
                    }}
                    title="Cancel your subscription?"
                    message="This will cancel your current subscription and downgrade your account to the free plan."
                    cancelText="NO"
                    confirmText="CONTINUE"
                    onConfirm={() => {
                        setOpenSubscriptionCancelPrompt(false);
                        cancelSubscription();
                    }}
                />
                <Modal
                    show={openSocialAccountQuantityPicker}
                    onHide={() => setOpenSocialAccountQuantityPicker(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Upgrade
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            isLTD(currentPlan) ? (
                                <p>You are not eligible for this request</p>
                            ) :
                                (
                                    <>
                                        <MonthlyYearlySwitch />
                                        <hr />
                                        {planType !== 2 && (
                                            <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
                                                Your Active Plan: <b>{currentPlan["Name"]}</b>
                                            </span>
                                        )}
                                        <br />
                                        <span className="mt-0 fs-0 fs-sm-0 fs-lg-0 px-2">
                                            Your Number of Social Accounts: <b>{currentPlan["SocialAccounts"]}</b>
                                        </span>
                                        <br />
                                        {currentPlan["Name"] === "Free" && (
                                            <span className="mt--4 fs-0 fs-sm-0 fs-lg-0 px-2">
                                                Request:{" "}
                                                <b>
                                                    Upgrade to {selectedPlan.title}
                                                </b>
                                            </span>
                                        )}
                                        <br /> <br />
                                        <Flex direction={"column"} className={"gap-2"}>
                                            {
                                                planType !== 2 && (
                                                    <>
                                                        <Form.Group className="mt-3">
                                                            <Form.Label>
                                                                {" "}
                                                                How many social accounts do you want?
                                                            </Form.Label>
                                                            <Row className="align-items-center gx-0">
                                                                <Col
                                                                    xs
                                                                    md="auto"
                                                                    className="d-flex justify-content-start"
                                                                >
                                                                    <Form.Group controlId="numberOfSocialAccounts">
                                                                        <Form.Control
                                                                            type="number"
                                                                            value={formData.numberOfSocialAccounts}
                                                                            name="numberOfSocialAccounts"
                                                                            onChange={handleNumberOfSocualAccountChange}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col
                                                                    xs="auto"
                                                                    className="d-flex justify-content-start ml-10"
                                                                >
                                                                    <p className="mb-1 fs-0 fs-sm-0 fs-lg-0 px-2">
                                                                        x $
                                                                        {billedYearly
                                                                            ? selectedPlan.extraPrice.Year
                                                                            : selectedPlan.extraPrice.Month}
                                                                        /{billedYearly ? "yr" : "mo"} = $
                                                                        {totalAmountToBeCharged}/
                                                                        {billedYearly ? "yr" : "mo"}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <p className="mt-4 fs-0 fs-sm-0 fs-lg-0 px-2">
                                                            You can buy more social accounts at any time. You will
                                                            be charged a prorated fee for the billing cycle.
                                                        </p>
                                                    </>
                                                )}
                                            <div className="text-end">
                                                <Button
                                                    disabled={formData.numberOfSocialAccounts <= 0}
                                                    onClick={() => {
                                                        if (others.plan) {
                                                            handleAddSubscriptionQuantity(others);
                                                        }
                                                    }}
                                                    style={{ width: "100%" }}
                                                >
                                                    Pay now
                                                </Button>
                                            </div>
                                            <div className="text-center">
                                                <Button
                                                    onClick={() => {
                                                        setOpenSocialAccountQuantityPicker(false);
                                                    }}
                                                    variant={"outlined"}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Flex>
                                    </>
                                )}
                    </Modal.Body>
                </Modal>
            </Card>
        </>
    )
}

export default PlanCard