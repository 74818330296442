import Flex from 'components/common/Flex';
import html2canvas from "html2canvas";
import React, { useEffect, useRef } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { BiBarChart } from 'react-icons/bi';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    getAudienceData,
    getInsightData,
    getOverviewData,
    getPDFLoading,
    getPdfClicked,
    setCanvasComplete,
    setCanvasData,
    setPDFLoading,
    setPdfClicked,
} from "redux/slices/analytics_slice";
import { numberWithCommasFormat } from 'utils/analytics_utils';
import PageInsights from './page_insights/PageInsights';
import UsersByCountry from './users-by-country/UsersByCountry';

const ReportExportModal = (props) => {
    const dispatch = useDispatch();
    const metricRef = useRef(null);
    const insightRef = useRef(null);
    const overviewRef = useRef(null);
    const audienceRef = useRef(null);
    const moreInsightRef = useRef(null);
    const overviewData = useSelector(getOverviewData);
    const insightData = useSelector(getInsightData);
    const audienceData = useSelector(getAudienceData);
    const isPdfClicked = useSelector(getPdfClicked);
    const pdfLoading = useSelector(getPDFLoading);

    const handlePDFExport = async () => {
        const elementToCanvas = [
            overviewRef?.current || null,
            metricRef?.current || null,
            insightRef?.current || null,
            moreInsightRef?.current || null,
            audienceRef.current || null,
        ].filter((ref) => ref !== null);

        const canvasPromises = elementToCanvas.map(async (elem) => {
            const canvas = await html2canvas(elem);
            const width = canvas.width;
            const height = canvas.height;
            const dataUrl = canvas.toDataURL('image/png');
            return {
                dataUrl, height, width
            }
        });
        const resultArr = await Promise.all(canvasPromises);
        dispatch(setCanvasComplete(true));
        dispatch(setCanvasData(resultArr));
    };

    useEffect(() => {
        if (isPdfClicked) {
            dispatch(setPDFLoading(true));
            toast.success("PDF resource downloading", { theme: "colored" });
            setTimeout(async () => {
                await handlePDFExport();
            }, 500);
        }
    }, [isPdfClicked]);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='text-center'>
                        Export preview
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5">
                    <Button
                        disabled={isPdfClicked}
                        onClick={() => {
                            dispatch(setPdfClicked(true));
                        }}
                        style={{
                            backgroundColor: "#1e63fe",
                            minHeight: 35,
                            width: "100%"
                        }}
                        size="md"
                        className="mb-4"
                    >
                        <div className="d-flex justify-content-center align-items-center gap-2">
                            {pdfLoading &&
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true" />}
                            {isPdfClicked ? "Downloading..." : "Download pdf"}
                        </div>
                    </Button>

                    {overviewData?.length > 0 &&
                        <Row
                            ref={overviewRef}
                            className="mb-3"
                            style={{ backgroundColor: "none" }}>
                            <h5 className="mb-3 mt-2 text-black">Overview</h5>
                            {overviewData?.map(({ title, value }) => (
                                <Col lg={12} xxl={12} md={12} className="mb-4">
                                    <div style={{
                                        background: "transparent",
                                        border: "1px solid #000",
                                        borderRadius: "10px",
                                        padding: "10px",
                                        color: "#000"
                                    }}>
                                        <Flex justifyContent={"between"} alignItems={"center"} gap={2}>
                                            <div className='d-flex flex-column fw-bold'>
                                                <span style={{ fontSize: '0.85rem' }}>{title ?? "title"}</span>
                                                <span style={{ fontSize: '1.75rem' }}>{numberWithCommasFormat(parseInt(value)) || 0}</span>
                                            </div>
                                            <BiBarChart fontSize={"2.5rem"} />
                                        </Flex>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    }

                    <div ref={metricRef}>
                        {insightData && insightData?.length > 0 &&
                            insightData?.slice(0, 3).map(({ title, value }) => (
                                <Col lg={12} md={12}>
                                    <PageInsights title={title} insightData={value} />
                                </Col>
                            ))
                        }
                    </div>

                    {
                        insightData?.length > 3 &&
                        <div ref={insightRef}>
                            {insightData && insightData?.length &&
                                insightData?.slice(3, 6).map(({ title, value }) => (
                                    <Col lg={12} md={12}>
                                        <PageInsights title={title} insightData={value} />
                                    </Col>
                                ))}
                        </div>
                    }

                    {
                        insightData?.length > 6 &&
                        <div ref={moreInsightRef}>
                            {insightData && insightData?.length &&
                                insightData?.slice(6, insightData.length - 1).map(({ title, value }) => (
                                    <Col lg={12} md={12}>
                                        <PageInsights title={title} insightData={value} />
                                    </Col>
                                ))}
                        </div>
                    }

                    {
                        audienceData?.length > 0 &&
                        <div ref={audienceRef}>
                            {audienceData?.map(({ title, value }) => (
                                <Col md={12} xxl={4} className="mb-4">
                                    <UsersByCountry
                                        title={title}
                                        chartData={value}
                                    />
                                </Col>
                            ))}

                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReportExportModal;