import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useOrganizationStore from 'state/organization_store';

const OrganizationSelectionDropdown = ({ hideCreation = false, organizations = [], onSelect, onCreateNewOrganization }) => {

    const { t } = useTranslation();
    const selectedOrganization = useOrganizationStore(state => state.organizationBeingCreated);

    const handleSelect = (event) => {
        const organizationId = event.target.value;
        if (organizationId === 'createNew') {
            if (onCreateNewOrganization) {
                onCreateNewOrganization();
            }
            return;
        }
        const organization = organizations.find(org => org._id === organizationId);
        if (onSelect && organization) {
            onSelect(organization);
        }
    }

    return (
        <Form.Select
            value={selectedOrganization?._id}
            onChange={handleSelect}
            placeholder={t('select')}
            variant="falcon-default"
        >
            <option value="" disabled>{t('select_organization')}</option>
            {organizations.map((organization) => (
                <option
                    key={organization._id}
                    value={organization._id}
                >
                    {organization.name}
                </option>
            ))}
            {
                !hideCreation &&
                <option value="createNew">{`+ ${t('create_new_organization')}`}</option>
            }
        </Form.Select>
    );
};

export default OrganizationSelectionDropdown;
