import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    results: [],
    returningUsers: {
        daily: null,
        every_3_days: null,
        every_7_days: null,
        every_30_days: null,
        every_60_days: null,
        every_90_days: null,
        every_180_days: null,
        every_365_days: null
    },
    usersSignup: {
        daily: null,
        weekly: null
    },
    totalUsers: null,
    totalWorkSpaces: null,
    allUsers: [],
    onlineUsersCount: Array(25).fill(0),
    activePages: [],
    botRequestsCount:0,
}
export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        // setAIWriterResults: (state, action) => {
        //     state.results = action.payload;
        // },
        setReturningUsers: (state, action) => {
            state.returningUsers = action.payload;
        },
        setUsersSignup: (state, action) => {
            state.usersSignup = action.payload;
        },
        setTotalUsers: (state, action) => {
            state.totalUsers = action.payload;
        },
        setTotalWorkSpaces: (state, action) => {
            state.totalWorkSpaces = action.payload;
        },
        setAllUsers: (state, action) => {
            state.allUsers = action.payload;
        },
        setOnlineUsersCount: (state, action) => {
            state.onlineUsersCount = action.payload;
        },
        setActivePages: (state, action) => {
            state.activePages = action.payload;
        },
        setBotRequestsCount: (state, action) => {
            state.botRequestsCount = action.payload;
        },
    }
});
// export let getAIWriterResults = (state) => state.admin.results;
export let getReturningUsers = (state) => state.admin.returningUsers;
export let getUsersSignup = (state) => state.admin.usersSignup;
export let getTotalUsers = (state) => state.admin.totalUsers;
export let getTotalWorkSpaces = (state) => state.admin.totalWorkSpaces;
export let getAllUsers = (state) => state.admin.allUsers;
export let getOnlineUsersCount = (state) => state.admin.onlineUsersCount;
export let getActivePages = (state) => state.admin.activePages;
export let getBotRequestsCount = (state) => state.admin.botRequestsCount;

export let {
    // setAIWriterResults,
    setReturningUsers,
    setUsersSignup,
    setTotalUsers,
    setTotalWorkSpaces,
    setAllUsers,
    setOnlineUsersCount,
    setActivePages,
    setBotRequestsCount
} = adminSlice.actions;
export default adminSlice.reducer;