const feedContentData = [
  {
    title: "Hungary's Viktor Orban: Is one man blackmailing the EU?",
    link: "https://www.bbc.co.uk/news/world-europe-67735680?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The nationalist Hungarian PM was the only leader blocking EU support for Ukraine at a Brussels summit.",
    publishedDate: "Sat, 16 Dec 2023 03:35:45 GMT",
    categories: {},
  },
  {
    title: "Coral islands in Australia at risk of disappearing",
    link: "https://www.bbc.co.uk/news/world-australia-67735681?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The disappearance of the islands could affect Australia's maritime borders, a study says.",
    publishedDate: "Sat, 16 Dec 2023 08:40:39 GMT",
    categories: {},
  },
  {
    title: "Perry death an accident caused by ketamine - coroner",
    link: "https://www.bbc.co.uk/news/world-us-canada-67734397?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The Friends star was found unresponsive in the pool of his Los Angeles home in October.",
    publishedDate: "Fri, 15 Dec 2023 22:27:53 GMT",
    categories: {},
  },
  {
    title: "Rudy Giuliani must pay more than $148m over false election claims",
    link: "https://www.bbc.co.uk/news/world-us-canada-67723332?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Donald Trump's former personal lawyer was sued by ex-poll workers he accused of vote tampering.",
    publishedDate: "Fri, 15 Dec 2023 22:24:10 GMT",
    categories: {},
  },
  {
    title: "Shipping firms pause Red Sea journeys over attacks",
    link: "https://www.bbc.co.uk/news/world-middle-east-67731853?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Maersk temporarily stops its ship using the route - and a German company follows suit.",
    publishedDate: "Fri, 15 Dec 2023 23:06:18 GMT",
    categories: {},
  },
  {
    title: "Watch: Sun emits strongest solar flare in years",
    link: "https://www.bbc.co.uk/news/world-67734790?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Space agency Nasa captured the burst that temporarily disrupted radio communication on Earth.",
    publishedDate: "Sat, 16 Dec 2023 04:31:31 GMT",
    categories: {},
  },
  {
    title:
      "Ukraine grenade incident: Councillor tosses explosives into meeting",
    link: "https://www.bbc.co.uk/news/world-europe-67729343?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Twenty-six people were wounded when the man launched an attack during a budget debate.",
    publishedDate: "Fri, 15 Dec 2023 18:42:38 GMT",
    categories: {},
  },
  {
    title: "Baby found alive in tree after Tennessee tornado",
    link: "https://www.bbc.co.uk/news/world-us-canada-67732126?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Mother had been “pretty sure“ four-month-old was killed when his bassinet was sucked up into storm.",
    publishedDate: "Fri, 15 Dec 2023 17:42:09 GMT",
    categories: {},
  },
  {
    title:
      "British teen Alex Batty left mother to avoid Finland move - French officials",
    link: "https://www.bbc.co.uk/news/uk-67732226?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Alex Batty was constantly on the move since going missing in 2017, French officials say.",
    publishedDate: "Fri, 15 Dec 2023 17:16:43 GMT",
    categories: {},
  },
  {
    title: "Baby owl found living in family Christmas tree",
    link: "https://www.bbc.co.uk/news/world-us-canada-67733828?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The Kentucky family says the bird went undetected for four days because it blended into the tree's branches.",
    publishedDate: "Fri, 15 Dec 2023 19:04:02 GMT",
    categories: {},
  },
  {
    title: "Beijing subway crash leaves 102 with broken bones",
    link: "https://www.bbc.co.uk/news/world-asia-china-67724238?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The impact of the collision, which occurred during heavy snowfall, caused some carriages to detach.",
    publishedDate: "Fri, 15 Dec 2023 09:15:51 GMT",
    categories: {},
  },
  {
    title: "Melania Trump makes rare speech at citizenship ceremony",
    link: "https://www.bbc.co.uk/news/world-us-canada-67733956?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The former first lady, originally from Slovenia, described the path to US citizenship as 'arduous'.",
    publishedDate: "Fri, 15 Dec 2023 19:14:38 GMT",
    categories: {},
  },
  {
    title: "Russian gas giant Gazprom makes £39m profit in North Sea",
    link: "https://www.bbc.co.uk/news/business-67720198?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The state-owned Russian energy firm earned £39m from its Dutch-UK gas field in 2022, accounts show.",
    publishedDate: "Fri, 15 Dec 2023 00:02:39 GMT",
    categories: {},
  },
  {
    title: "How Israel jails hundreds of Palestinians without charge",
    link: "https://www.bbc.co.uk/news/world-middle-east-67600015?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Human rights groups say Israel is abusing a security law inherited from the British to strip due process.",
    publishedDate: "Fri, 15 Dec 2023 06:00:15 GMT",
    categories: {},
  },
  {
    title: "Nikhil Gupta: India man accused of US murder plot appeals to court",
    link: "https://www.bbc.co.uk/news/world-asia-india-67725935?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Nikhil Gupta has been accused by the US government of conspiring to assassinate a Sikh separatist leader.",
    publishedDate: "Fri, 15 Dec 2023 11:12:08 GMT",
    categories: {},
  },
  {
    title: "Taliban sending abused Afghan women to prison - UN",
    link: "https://www.bbc.co.uk/news/world-asia-67724424?at_medium=RSS&at_campaign=KARANGA",
    content:
      'The Taliban says it\'s done for their "protection", but the UN says it is harmful to the survivors.',
    publishedDate: "Fri, 15 Dec 2023 07:38:24 GMT",
    categories: {},
  },
  {
    title: "Watch: King and Elton John remember Barry Humphries",
    link: "https://www.bbc.co.uk/news/world-australia-67724680?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Humphries was celebrated for his comedic “genius” but also kindness, at a state memorial in Sydney.",
    publishedDate: "Fri, 15 Dec 2023 05:38:22 GMT",
    categories: {},
  },
  {
    title: "Curb Your Enthusiasm: comedy show to end after 24 years",
    link: "https://www.bbc.co.uk/news/world-us-canada-67723937?at_medium=RSS&at_campaign=KARANGA",
    content:
      "HBO's longest running comedy series starring Larry David will end next year.",
    publishedDate: "Fri, 15 Dec 2023 04:45:38 GMT",
    categories: {},
  },
  {
    title:
      "Lucy Williamson: Hamas support soars in West Bank - but full uprising can still be avoided",
    link: "https://www.bbc.co.uk/news/world-middle-east-67708116?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Calls by Hamas for an uprising in the West Bank have come and gone, despite rising support for the group.",
    publishedDate: "Sat, 16 Dec 2023 00:21:05 GMT",
    categories: {},
  },
  {
    title:
      "Israel-Gaza war: UN aid worker describes nightmare journey to al-Ahli Hospital",
    link: "https://www.bbc.co.uk/news/world-middle-east-67732347?at_medium=RSS&at_campaign=KARANGA",
    content:
      "A British aid worker tells the BBC his humanitarian convoy was delayed for hours by the Israeli military.",
    publishedDate: "Fri, 15 Dec 2023 21:11:30 GMT",
    categories: {},
  },
  {
    title: "Hamas hostages: Stories of the people taken from Israel",
    link: "https://www.bbc.co.uk/news/world-middle-east-67053011?at_medium=RSS&at_campaign=KARANGA",
    content:
      "It is thought more than 100 Israelis are still being held hostage in Gaza after the 7 October attacks.",
    publishedDate: "Fri, 15 Dec 2023 20:00:00 GMT",
    categories: {},
  },
  {
    title: "Israel-Gaza war: What is the price of peace?",
    link: "https://www.bbc.co.uk/news/world-middle-east-67701700?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The BBC's Jeremy Bowen asks Israeli and Palestinian activists about their hopes for resolving the conflict.",
    publishedDate: "Thu, 14 Dec 2023 06:01:30 GMT",
    categories: {},
  },
  {
    title: "Israeli soldiers' mosque behaviour condemned by IDF",
    link: "https://www.bbc.co.uk/news/world-middle-east-67719820?at_medium=RSS&at_campaign=KARANGA",
    content:
      "A Jewish festival song was filmed sung from the minaret of a mosque in the West Bank city of Jenin.",
    publishedDate: "Thu, 14 Dec 2023 17:09:02 GMT",
    categories: {},
  },
  {
    title: "Joshua Mollel: Tanzania says student killed in Israel by Hamas",
    link: "https://www.bbc.co.uk/news/world-africa-67713520?at_medium=RSS&at_campaign=KARANGA",
    content:
      "It was initially reported that Joshua Mollel had been taken hostage by Hamas, along with a colleague.",
    publishedDate: "Thu, 14 Dec 2023 08:56:36 GMT",
    categories: {},
  },
  {
    title:
      "Israel determined to finish Gaza operation despite civilian suffering",
    link: "https://www.bbc.co.uk/news/world-middle-east-67691219?at_medium=RSS&at_campaign=KARANGA",
    content:
      "A senior military adviser tells the BBC Israel will need more than a few more weeks to destroy Hamas.",
    publishedDate: "Tue, 12 Dec 2023 13:04:01 GMT",
    categories: {},
  },
  {
    title:
      "Eyal Waldman: Israeli tech billionaire hopes for peace despite daughter's killing",
    link: "https://www.bbc.co.uk/news/world-middle-east-67679634?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Eyal Waldman's youngest daughter was killed in the Supernova music festival massacre on 7 October.",
    publishedDate: "Tue, 12 Dec 2023 06:00:34 GMT",
    categories: {},
  },
  {
    title:
      "MK Strinda: Yemen's rebel Houthis hit Norwegian tanker with missile",
    link: "https://www.bbc.co.uk/news/world-middle-east-67691216?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The Houthis have vowed to stop ships heading to Israel, but its owners say it was going to Italy.",
    publishedDate: "Tue, 12 Dec 2023 11:39:07 GMT",
    categories: {},
  },
  {
    title: "Timelapse shows Geminid meteor shower in full splendour",
    link: "https://www.bbc.co.uk/news/world-us-canada-67722017?at_medium=RSS&at_campaign=KARANGA",
    content:
      "At least 120 meteors could be seen per hour when the celestial event reached its peak earlier this week.",
    publishedDate: "Sat, 16 Dec 2023 00:35:09 GMT",
    categories: {},
  },
  {
    title:
      "King Charles laughs as Archbishop of Canterbury forgets words in Coronation rehearsal",
    link: "https://www.bbc.co.uk/news/uk-67731988?at_medium=RSS&at_campaign=KARANGA",
    content:
      'The Archbishop of Canterbury described the moment as a "glitch" in exclusive footage from an upcoming documentary.',
    publishedDate: "Sat, 16 Dec 2023 00:03:22 GMT",
    categories: {},
  },
  {
    title: "Watch: Bear dodges skiers on California slopes",
    link: "https://www.bbc.co.uk/news/world-us-canada-67722456?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The bear escaped collision-free and is said to have reunited with its mother on the other side of the trail.",
    publishedDate: "Thu, 14 Dec 2023 19:01:00 GMT",
    categories: {},
  },
  {
    title: "Japan: People left dangling as rollercoaster stops mid-ride",
    link: "https://www.bbc.co.uk/news/world-asia-67721301?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The rollercoaster, which reaches heights of 150ft (46m), came to an emergency stop at a Japan theme park.",
    publishedDate: "Thu, 14 Dec 2023 22:44:54 GMT",
    categories: {},
  },
  {
    title: "Bull spotted galloping down New Jersey train tracks",
    link: "https://www.bbc.co.uk/news/world-us-canada-67723281?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Morning commuters were met with delays after the long-horned animal made an appearance on the tracks.",
    publishedDate: "Thu, 14 Dec 2023 21:14:02 GMT",
    categories: {},
  },
  {
    title: "The moment Oprah's portrait for national gallery is unveiled",
    link: "https://www.bbc.co.uk/news/world-us-canada-67708719?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The media mogul's painting will hang beside other American icons at the National Portrait Gallery.",
    publishedDate: "Wed, 13 Dec 2023 19:47:31 GMT",
    categories: {},
  },
  {
    title: "Putin: Russia can't exist without sovereignty",
    link: "https://www.bbc.co.uk/news/world-europe-67714359?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The president vows to strengthen Russia's sovereignty as he starts a lengthy press conference.",
    publishedDate: "Thu, 14 Dec 2023 09:33:59 GMT",
    categories: {},
  },
  {
    title: "Moment elephant smashes van window in search of food in Sri Lanka",
    link: "https://www.bbc.co.uk/news/world-67710141?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The driver remains remarkably calm, as the elephant pushes past him to reach food in the back seat.",
    publishedDate: "Wed, 13 Dec 2023 19:41:43 GMT",
    categories: {},
  },
  {
    title:
      "The mountain wilderness where British teen Alex Batty lived for years",
    link: "https://www.bbc.co.uk/news/world-europe-67734560?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Since disappearing in 2017, the 17-year-old has reportedly been living in a commune near the French town of Quillan.",
    publishedDate: "Sat, 16 Dec 2023 05:12:17 GMT",
    categories: {},
  },
  {
    title: "Biden risks Democrats' fury over deal on border and Ukraine",
    link: "https://www.bbc.co.uk/news/world-us-canada-67632727?at_medium=RSS&at_campaign=KARANGA",
    content:
      "To get a Ukraine aid deal, the president may have to reward political enemies while angering allies.",
    publishedDate: "Sat, 16 Dec 2023 00:13:27 GMT",
    categories: {},
  },
  {
    title: "DR Congo election: Why one star refuses to release election songs",
    link: "https://www.bbc.co.uk/news/world-africa-67650407?at_medium=RSS&at_campaign=KARANGA",
    content:
      "As usual, DR Congo's election period has brought a burst of political music by huge stars.",
    publishedDate: "Sat, 16 Dec 2023 01:56:30 GMT",
    categories: {},
  },
  {
    title: "What has Hungary's Orban got against Ukraine?",
    link: "https://www.bbc.co.uk/news/world-europe-67725570?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The Hungarian leader says his position is pragmatic as he argues Ukraine cannot win the war.",
    publishedDate: "Fri, 15 Dec 2023 17:25:19 GMT",
    categories: {},
  },
  {
    title: "Lockerbie bombing: The ultimate detective story?",
    link: "https://www.bbc.co.uk/news/uk-scotland-67659046?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The bombing of Pan Am Flight 103 in 1988 was the deadliest terrorist attack carried out in the UK.",
    publishedDate: "Fri, 15 Dec 2023 23:00:49 GMT",
    categories: {},
  },
  {
    title:
      "Tren Maya: Mexico's Yucatán mega train - world beating or environmental disaster?",
    link: "https://www.bbc.co.uk/news/world-latin-america-67638183?at_medium=RSS&at_campaign=KARANGA",
    content:
      "Mexico's president says the Tren Maya will bring tourism and development to the jungle. But at what cost?",
    publishedDate: "Fri, 15 Dec 2023 10:31:36 GMT",
    categories: {},
  },
  {
    title: "Africa's week in pictures: 8-14 December 2023",
    link: "https://www.bbc.co.uk/news/world-africa-67715933?at_medium=RSS&at_campaign=KARANGA",
    content:
      "A selection of the best photos from the African continent and beyond.",
    publishedDate: "Fri, 15 Dec 2023 03:04:26 GMT",
    categories: {},
  },
  {
    title: "Ukraine war: How TikTok fakes pushed Russian lies to millions",
    link: "https://www.bbc.co.uk/news/world-europe-67687449?at_medium=RSS&at_campaign=KARANGA",
    content:
      "The BBC uncovers a vast Russia-based network of fake TikTok accounts pushing disinformation about Ukraine.",
    publishedDate: "Fri, 15 Dec 2023 00:51:06 GMT",
    categories: {},
  }
];

export { feedContentData };
