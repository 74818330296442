import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import createMarkup from 'helpers/createMarkup';
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { getSelectedPageForPostCommentsView } from 'redux/slices/instagramComments_slice';
import { useSelector } from "react-redux"
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import av3 from 'assets/img/team/3.jpg';
import { toast } from 'react-toastify';
import APIService from 'http/api_service';


const InstaComment = ({ user, id, avatarSrc, name, content, postTime }) => {
  dayjs.extend(relativeTime);

    const selectedPageForPostCommentsView = useSelector(getSelectedPageForPostCommentsView);

    //state hook that'll handle display comment reply input field.
    const [showReplyInput, setShowReplyInput] = useState(false);
    //write state to handle the reply comment input field
    const [comment, setComment] = useState('');
    //write state for if coment is being submited.
    const [isSubmitting, setIsSubmitting] = useState(false);



    const submitComment = (id) => {
        setIsSubmitting(true)
        const requestBody = {
            commentId: id,
            replyText: comment,
            accessToken: selectedPageForPostCommentsView?.accessToken,
        };
        //write an if condition that'll check if the request body object keys has value.
        if (!requestBody.replyText) {
            toast.error('Please enter a comment', { theme: "colored" });
            setIsSubmitting(false);
            return;
        }

        APIService.replyInstagramComment(requestBody, (data, error) => {
            if (error) {
                toast.error(error, { theme: "colored" })
                console.log("🚀 ~ APIService.getUserInstagramPosts ~ error:", error);
                setIsSubmitting(false);
                return;
            } else {
                console.log("🚀 ~ APIService.getUserInstagramPosts ~ data:", data?.data)
                toast.success("Reply sent successfully...", { theme: "colored" })
                setComment("")
                setIsSubmitting(false);
                return;
            }
        });

        // const feed = feeds.find(feed => feed.id === id);
        // feed.details.reactions.comment = true;
        // feed.details.comments = [newComment, ...comments];
        // instaFeedDispatch({ type: 'UPDATE', payload: { id, feed } });
        // setComment('');
    };
    return (
        <Flex className="mt-3">
            <Avatar fallBackName={name} src={avatarSrc} size="xl" />
            <div className="flex-1 ms-2 fs--1">
                <p className="mb-1 bg-200 rounded-3 p-2">
                    <span className="text-primary fw-semi-bold">
                        {name}
                    </span>
                    <span
                        className="ms-1"
                        dangerouslySetInnerHTML={createMarkup(content)}
                    />
                </p>
                <div className="px-2">
                    <span onClick={() => setShowReplyInput(!showReplyInput)} className='cursor-pointer text-primary'>Reply</span> • {dayjs().to(dayjs(postTime))}
                    {showReplyInput && <div>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            submitComment(id)
                        }}>
                            <Flex alignItems="center" gap={1} className="border-top border-200 pt-3">
                                <Avatar src={user?.avatarSrc || av3} size="xl" />
                                <Form.Control
                                    type="text"
                                    className="rounded-pill fs--1"
                                    placeholder="Write a comment..."
                                    value={comment}
                                    onChange={e => setComment(e.target.value)}
                                    disabled={isSubmitting}
                                />
                                <Button style={{ fontSize: "11px" }} type="submit">{!isSubmitting ? "Reply" : "Submitting"}</Button>
                            </Flex>
                        </Form>
                    </div>}
                </div>
            </div>
        </Flex>
    )
}

export default InstaComment