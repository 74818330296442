import { useContext, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import ChatContentBodyIntro from "./ChatContentBodyIntro";
import Message from "./Message";
import SimpleBarReact from "simplebar-react";
import ThreadInfo from "./ThreadInfo";
import { ChatContext } from "context/Context";
import { formatDate } from "./MessageTextArea";

const ChatContentBody = ({ thread }) => {
  let lastDate = null;
  const messagesEndRef = useRef();
  const {
    scrollToBottom,
    setScrollToBottom,
    activeRoomId,
    conversations,
    getUser,
  } = useContext(ChatContext);
  const { messages } =
    conversations?.find(({ room_id }) => room_id === activeRoomId) ?? {};
  // const currentUserMessages = useSelector(getCurrentUserMessages)
  const user = useMemo(() => getUser(activeRoomId), [activeRoomId]);

  // const user = currentUserMessages?.length !== 0 ? getUser(activeRoomId) : getUser(thread);
  const chatContent = useMemo(() => {
    if (activeRoomId) {
      return messages;
    } else {
      return [];
    }
  }, [activeRoomId, messages]);

  useEffect(() => {
    // let timeoutId;

    if (scrollToBottom) {
      // Scroll to the bottom after a delay
      setTimeout(() => {
        if (messagesEndRef?.current) {
          messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
      setScrollToBottom(false);
    }
  }, [scrollToBottom, setScrollToBottom]);

  return (
    <div className="chat-content-body" style={{ display: "inherit" }}>
      {/* <ThreadInfo thread={thread} isOpenThreadInfo={true} /> */}
      <SimpleBarReact style={{ height: "100%" }}>
        {activeRoomId && (
          <div className="chat-content-scroll-area">
            <ChatContentBodyIntro user={user} />
            {chatContent
              ?.filter(({ message }) => !message?.reactions)
              ?.map(
                (
                  { message, time, sender, status, read, reactions },
                  messageIndex
                ) => {
                  const timestamp = formatDate(time);

                  return (
                    <div key={messageIndex}>
                      {lastDate !== timestamp?.date && (
                        <div className="text-center fs--2 text-500">
                          {timestamp?.date}
                        </div>
                      )}
                      {(() => {
                        lastDate = timestamp?.date;
                      })()}
                      <Message
                        message={message}
                        senderUserId={sender}
                        time={timestamp}
                        status={read ? "seen" : status}
                        isGroup={thread.room_type === "group"}
                        reactions={reactions}
                      />
                    </div>
                  );
                }
              )}

            <div ref={messagesEndRef} />
          </div>
        )}
      </SimpleBarReact>
    </div>
  );
};

ChatContentBody.propTypes = {
  thread: PropTypes.object.isRequired,
};

export default ChatContentBody;