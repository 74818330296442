import { PlatformIdentifier } from 'constants';
import React from 'react'
import Facebook from './facebook';
import LinkedIn from './linkedIn';
import Twitter from './twitter';
import Google from './google';
import Threads from './threads';
import Reddit from './reddit';
import Instagram from './instagram';
import YouTube from './youtube';
import TikTok from './tikTok';
import Telegram from './telegram';
import Pinterest from './pinterest';
import Wordpress from './wordpress';
import Blogger from './blogger';

const PostPreviewerOnSpecificPlatform = ({data = {}}) => {

  const renderActivePreview = (identifier) => {
    switch (identifier) {
      case PlatformIdentifier.FACEBOOK:
        return <Facebook />;
      case PlatformIdentifier.LINKED_IN:
        return <LinkedIn />;
      case PlatformIdentifier.GOOGLE_MY_BUSINESS:
        return <Google />;
      case PlatformIdentifier.TWITTER:
        return <Twitter />;
      case PlatformIdentifier.THREADS:
        return <Threads />;
      case PlatformIdentifier.REDDIT:
        return <Reddit />;
      case PlatformIdentifier.INSTAGRAM:
        return <Instagram />;
      case PlatformIdentifier.YOUTUBE:
        return <YouTube />;
      case PlatformIdentifier.TIKTOK:
        return <TikTok />;
      case PlatformIdentifier.TELEGRAM:
        return <Telegram />;
      case PlatformIdentifier.PINTEREST:
        return <Pinterest />;
      case PlatformIdentifier.WORDPRESS:
        return <Wordpress />;
      case PlatformIdentifier.BLOGGER:
        return <Blogger />;
      default:
        return null;
    }
  }

  if (!data?.platform) return null;

  return (
    <div>{
      renderActivePreview(data?.platform)
    }</div>
  )
}

export default PostPreviewerOnSpecificPlatform