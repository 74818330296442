import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createDateWithTimeZone } from "utils/time";
import DatePicker from "react-datepicker";
import TimeZoneSelector from "components/common/TimeZoneSelector";
import Flex from "components/common/Flex";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { setTimeZoneToPostFeedContent } from "redux/slices/postslice";

const ConfigureFeedTimezone = (props) => {
  const {
    generateTimeClause,
    timeZoneToPost,
    timeToPost,
    setTimeToPost,
    timeChanged,
    setTimeChanged,
  } = props;
  const dispatch = useDispatch();

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 20,
      }}
    >
      <div
        className="mb-5"
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
          color: "#78909C",
        }}
      >
        <FontAwesomeIcon
          style={{
            color: "#78909C",
          }}
          icon={faStopwatch}
        />
        <span
          style={{
            color: "#78909C",
            fontSize: 14,
          }}
        >
          Will Post on{" "}
          {generateTimeClause(
            createDateWithTimeZone(timeToPost, timeZoneToPost, true)
          )}
        </span>
      </div>
      <h6>Pick Date &amp; Time</h6>
      <DatePicker
        minDate={new Date()}
        filterTime={filterPassedTime}
        selected={timeToPost}
        onChange={(date) => {
          setTimeToPost(date);
          setTimeChanged(true);
        }}
        formatWeekDay={(day) => day.slice(0, 3)}
        className="form-control"
        placeholderText="Select Date & Time"
        timeIntervals={1}
        dateFormat="MM/dd/yyyy h:mm aa"
        showTimeSelect
        fixedHeight
      />
      <div
        className="mt-5"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
        }}
      >
        <h6>Pick Time Zone(Optional)</h6>
        <TimeZoneSelector
          defaultTimeZone={timeZoneToPost}
          onChange={(result) => {
            console.log(result, "time-zone-to-post");
            dispatch(setTimeZoneToPostFeedContent(result));
            setTimeChanged(true);
          }}
        />
      </div>
      <Flex
        className={"mt-4"}
        alignItems={"flex-end"}
        justifyContent={"flex-end"}
        style={{
          gap: 10,
          alignSelf: "flex-end",
        }}
      ></Flex>
    </div>
  );
};

export default ConfigureFeedTimezone;
