import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import { useEffect, useState } from "react";
import {
    Button, Form, Modal,
    Spinner
} from "react-bootstrap";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import APIService from "../../../../../http/api_service";
import AudienceGroupListItem from "./audience_group_list_item";
import AudienceLiStEdit from "./audience_list_edit";

const AudienceGroupsView = ({ initialValue, handleSelectedAudienceGroup, modalClose, setOpenAudienceGroupsView, platforms }) => {

    const workSpace = useSelector(getActiveWorkSpace);
    const [audienceGroups, setAudienceGroups] = useState([]);
    const [selectedAudienceGroup, setSelectedAudienceGroup] = useState(initialValue);
    const [openDeleteAudienceGroupPrompt, setOpenDeleteAudienceGroupPrompt] = useState(false);
    const [canDisplayDeletePrompt, setCanDisplayDeletePrompt] = useState(true);
    const [groupToDelete, setGroupToDelete] = useState();
    const [groupsUnderDelete, setGroupsUnderDelete] = useState({});
    const [clearingAllGroups] = useState(false);
    const [fetchingAudienceGroups, setFetchingAudienceGroups] = useState(false);
    const [openEditAudienceGroupModal, setOpenEditAudienceGroupModal] = useState(false);
    const [activeGroupData, setActiveGroupData] = useState([]);
    const [mergedActiveGroupWithPlatformsData, setMergedActiveGroupWithPlatformsData] = useState({});
    const [cloneData, setCloneData] = useState()
    const [updatingAudienceGroup, setUpdatingAudienceGroup] = useState(false);
    const [checkedForEditSelectAll, setCheckedForEditSelectAll] = useState(false);
    const [isEditingAudienceName, setIsEditingAudienceName] = useState(false);
    const [newAudienceName, setNewAudienceName] = useState(mergedActiveGroupWithPlatformsData?.name);

    const destroyAllEditStates = () => {
        setCloneData(undefined);
        setMergedActiveGroupWithPlatformsData({});
        setActiveGroupData([]);
        setOpenEditAudienceGroupModal(false);
    }

    const truncateText = (text, length) => {
        if (!text) return ''
        if (text.length > length) {
            return text.substring(0, length) + '...';
        }
        return text;
    }

    const mergeTargets = (audiencePlatforms) => {
        let _platform = structuredClone(platforms);
        for (let index = 0; index < _platform.length; index++) {
            let element = _platform[index];
            let platformIdentifier = element.identifier;
            let targetFound = audiencePlatforms.find(x => x.identifier === platformIdentifier);
            if (targetFound) {
                if (targetFound?.selected) {
                    element['selected'] = true;
                } else {
                    element['selected'] = false;
                }
                if (targetFound?.accounts) {
                    let _accounts = [...element.accounts];
                    for (let accountIndex = 0; accountIndex < element.accounts.length; accountIndex++) {
                        let eachPlatformAccounts = element.accounts[accountIndex];
                        const accountFound = targetFound.accounts.find(x => (x._id === eachPlatformAccounts._id ?? x.user_id === eachPlatformAccounts.user_id));
                        if (accountFound) {
                            eachPlatformAccounts['selected'] = accountFound.selected;
                            _accounts[accountIndex] = eachPlatformAccounts;
                        }
                    }

                    for (let index = 0; index < _accounts.length; index++) {
                        const element_accounts = _accounts[index];
                        if (element_accounts?.connected_pages_and_groups) {
                            let connectedPagesAndGroups = [...element_accounts?.connected_pages_and_groups];
                            for (let pageOrGroupIndex = 0; pageOrGroupIndex < element_accounts?.connected_pages_and_groups.length; pageOrGroupIndex++) {
                                let eachPageOrGroup = element_accounts.connected_pages_and_groups[pageOrGroupIndex];
                                const pageOrGroupFound = targetFound.accounts?.find((e) => (e?._id === element_accounts?._id || e?.user_id === element_accounts?.user_id))?.connected_pages_and_groups?.find((e) => (e?.id === eachPageOrGroup?.id))
                                if (pageOrGroupFound?.selected) {
                                    eachPageOrGroup['selected'] = pageOrGroupFound.selected;
                                    connectedPagesAndGroups[pageOrGroupIndex] = eachPageOrGroup;
                                }
                            }
                            element_accounts.connected_pages_and_groups = connectedPagesAndGroups;
                        }
                        _accounts[index] = element_accounts;
                    }
                    element.accounts = _accounts;
                }
            }
            _platform[index] = element;
        }
        return _platform;
    }

    const processActiveGroupEdit = async (data) => {
        let _platformsData = data?.data ?? [];
        const newGroupData = mergeTargets(_platformsData ?? []);
        setCloneData(newGroupData)
        setMergedActiveGroupWithPlatformsData({ ...data, data: _platformsData });
        setNewAudienceName(data?.name);
        setTimeout(() => {
            setOpenEditAudienceGroupModal(true);
        }, 1000);
    }

    const fetchAudienceGroups = () => {
        setFetchingAudienceGroups(true);
        APIService.fetchAudienceGroups(workSpace._id, (response, error) => {
            setFetchingAudienceGroups(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                modalClose();
                return;
            }
            let { data } = response;
            setAudienceGroups(data);
        });
    }

    const deleteAudienceGroup = (group) => {
        if (groupsUnderDelete[group._id]) {
            return;
        }
        setGroupsUnderDelete({
            ...groupsUnderDelete,
            [group._id]: true
        })
        APIService.deleteAudienceGroup(workSpace._id, group._id, (response, error) => {
            setGroupsUnderDelete((prevGroups) => {
                delete prevGroups[group._id];
                return { ...prevGroups };
            });
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { message } = response;
            toast.success(message, { theme: 'colored' });
            let groupsCopy = [...audienceGroups];
            let groupCopy = groupsCopy.find(x => x._id === group._id);
            let indexOfGroup = groupsCopy.indexOf(groupCopy);
            groupsCopy.splice(indexOfGroup, 1);
            setAudienceGroups(groupsCopy);
            if (groupCopy.name === selectedAudienceGroup?.name) {
                handleSelectedAudienceGroup(null);
            }
        });
    }

    const updateAudienceGroup = () => {
        let requestBody = {
            ...mergedActiveGroupWithPlatformsData
        }
        requestBody.data = cloneData;
        requestBody.name = newAudienceName;
        setUpdatingAudienceGroup(true)
        APIService.updateAudienceGroup(workSpace._id, requestBody._id, requestBody, (response, error) => {
            if (error) {
                setUpdatingAudienceGroup(false)
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { message } = response;
            toast.success(message, { theme: 'colored' });
            destroyAllEditStates();
            fetchAudienceGroups();
            setUpdatingAudienceGroup(false)
        });

    }

    const handleEditSelectOrUnselectAllOperation = (checked) => {
        let newCloneData = [...cloneData]
        newCloneData.forEach((platform) => {
            platform.selected = checked
            platform.accounts.forEach((account) => {
                account.selected = checked
                if (!account.connected_pages_and_groups) return
                account.connected_pages_and_groups.forEach((page) => {
                    page.selected = checked
                })
            })
        })
        setCloneData(newCloneData)
    }


    useEffect(() => {
        fetchAudienceGroups();
    }, []);


    return (
        <Flex direction={'column'} className={'gap-5'}>
            <h4>Pick an Audience Group</h4>
            <Form
                style={{
                    overflowY: 'auto',
                    maxHeight: 400
                }}
                onChange={(e) => {
                    let group = audienceGroups.find(x => x.name === e.target.value);
                    setSelectedAudienceGroup(group);
                }}>
                {
                    fetchingAudienceGroups &&
                    <Flex alignItems={'center'} justifyContent={'center'}>
                        <Spinner
                            as={'span'}
                            role="status"
                            animation='border'
                            size="sm"
                            aria-hidden="true"
                        />
                    </Flex>
                }
                {
                    audienceGroups.length > 0 && audienceGroups.map((x, index) => {
                        return <AudienceGroupListItem
                            key={index}
                            item={x}
                            selectedAudienceGroup={selectedAudienceGroup}
                            processActiveGroupEdit={processActiveGroupEdit}
                            setActiveGroupData={setActiveGroupData}
                            canDisplayDeletePrompt={canDisplayDeletePrompt}
                            setGroupToDelete={setGroupToDelete}
                            setOpenDeleteAudienceGroupPrompt={setOpenDeleteAudienceGroupPrompt}
                            deleteAudienceGroup={deleteAudienceGroup}
                            clearingAllGroups={clearingAllGroups}
                            groupsUnderDelete={groupsUnderDelete}
                        />
                    })}
            </Form>
            {
                selectedAudienceGroup &&
                <Button
                    onClick={() => {
                        let realGroup = audienceGroups.find(x => x.name === selectedAudienceGroup?.name);
                        setSelectedAudienceGroup(realGroup);
                        handleSelectedAudienceGroup(realGroup);
                        modalClose();
                    }}>Continue
                </Button>
            }
            <Modal
                show={openEditAudienceGroupModal}
                centered={false}
                onHide={() => {
                    if (!updatingAudienceGroup) {
                        destroyAllEditStates();
                        setOpenEditAudienceGroupModal(false)
                    }
                }

                }
                onExit={() => {
                    if (!updatingAudienceGroup) {
                        destroyAllEditStates();
                        setOpenEditAudienceGroupModal(false)
                    }
                }}
            >
                <Modal.Header>
                    <Modal.Title
                        style={{ width: '100%' }}
                    >
                        <Flex
                            direction={'row'}
                            className={'gap-5'}
                            justifyContent={'between'}
                            alignItems={'center'}
                        >
                            {
                                isEditingAudienceName ?

                                    <Form.Control
                                        type={'text'}
                                        value={newAudienceName || activeGroupData?.name}
                                        onKeyDown={(e) => e.key === 'Enter' && setIsEditingAudienceName(false)}
                                        placeholder={"Your new audience name"}
                                        onChange={e => {
                                            setNewAudienceName(e.target.value)
                                        }}
                                    />
                                    :
                                    <Flex
                                        direction={'row'}
                                        className={'gap-2'}
                                        justifyContent={'start'}
                                        alignItems={'center'}
                                    >
                                        <div>{truncateText(newAudienceName, 20) || truncateText(activeGroupData?.name, 20)}</div>
                                        <FontAwesomeIcon
                                            onClick={() => {
                                                setIsEditingAudienceName(true)
                                            }}
                                            className='primary'
                                            icon={faPencil}
                                            size={18}
                                            title="Edit Audience Name"
                                            cursor={'pointer'}
                                        />
                                    </Flex>
                            }
                            {
                                isEditingAudienceName ?
                                    <Flex
                                        gap={2}
                                    >
                                        {
                                            updatingAudienceGroup ? null :
                                                <Button
                                                    disabled={updatingAudienceGroup}
                                                    onClick={() => {
                                                        setNewAudienceName(activeGroupData?.name);
                                                        setIsEditingAudienceName(false)
                                                    }}>
                                                    Cancel
                                                </Button>
                                        }
                                        <Button
                                            disabled={updatingAudienceGroup}
                                            onClick={() => {
                                                updateAudienceGroup()
                                            }}>
                                            {
                                                updatingAudienceGroup ? 'Updating...' : 'Okay'
                                            }
                                        </Button>
                                    </Flex>
                                    :
                                    <Form.Check
                                        id={`select-all-group-checkbox`}
                                        type="checkbox"
                                        name='groups'
                                        label="Select All"
                                        className={'mt-2'}
                                        onChange={(e) => {
                                            handleEditSelectOrUnselectAllOperation(e.target.checked)
                                        }}
                                    />
                            }
                        </Flex>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AudienceLiStEdit
                        activeGroupData={mergedActiveGroupWithPlatformsData}
                        setActiveGroupData={setActiveGroupData}
                        setOpenAudienceGroupsView={setOpenAudienceGroupsView}
                        cloneData={cloneData}
                        setCloneData={setCloneData}
                        destroyAllEditStates={destroyAllEditStates}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            updateAudienceGroup()
                        }}
                        disabled={updatingAudienceGroup}
                    >
                        {
                            updatingAudienceGroup ? 'Updating...' : 'Update'
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={openDeleteAudienceGroupPrompt}
                centered={false}
                onHide={() => setOpenDeleteAudienceGroupPrompt(false)}
                onExit={() => setOpenDeleteAudienceGroupPrompt(false)}
            >
                <Modal.Header>
                    <Modal.Title>Delete Audience Group?</Modal.Title>
                    <Modal.Body>
                        Are you certain about deleting this audience group?
                    </Modal.Body>
                </Modal.Header>
                <Modal.Footer>
                    <Flex
                        style={{ width: '100%' }}
                        className={'gap-2'}
                        alignItems={'center'}>
                        <Form.Check
                            className='p-0 m-0 ms-3 cursor-pointer'
                            type='checkbox'
                            id='deleteAudiencePrompt'
                            name='deleteAudiencePrompt'
                            defaultChecked={false}
                            checked={!canDisplayDeletePrompt}
                            onChange={(e) => {
                                setCanDisplayDeletePrompt(!e.target.checked);
                            }}
                            label={"Don't show this again"}
                        />
                        <div style={{ flex: 1 }}></div>
                        <Button
                            onClick={() => setOpenDeleteAudienceGroupPrompt(false)}>NO</Button>
                        <Button
                            onClick={() => {
                                setOpenDeleteAudienceGroupPrompt(false);
                                deleteAudienceGroup(groupToDelete);
                            }}>YES, DELETE
                        </Button>
                    </Flex>
                </Modal.Footer>
            </Modal>
        </Flex>
    )

}
export default AudienceGroupsView;