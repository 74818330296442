import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import APIService from 'http/api_service';
import usePubSub from 'pubsub';
import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getTimeZoneToPostContent } from 'redux/slices/postslice';
import { createDateWithTimeZone } from 'utils/time';

const PostBacklogCorrector = (props) => {
    const { addPubSubEventListener, removePubSubEventListener } = usePubSub();
    const timeZone = useSelector(getTimeZoneToPostContent);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [processing, setProcessing] = useState(false);
    const [renderingDone, setRenderingDone] = useState(false);
    const [promptConfirmationNotice, setPromptConfirmationNotice] = useState(false);
    const [ongoingOperationData, setOngoingOperationData] = useState(null);
    const [checkingForCurrentOperation, setCheckingForCurrentOperation] = useState(true);
    const [ongoingOperationPresent, setOngoingOperationPresent] = useState(false);

    useEffect(() => {
        let postBacklogEventListener = (response) => {
            let { error, message } = response;
            if (error) {
                console.log("CR_stamp 👀 ~ file: post_backlog_corrector.js:33 ~ workSpacesEventListener ~ error:", error)
                return;
            }
            setOngoingOperationData(response)
            if (checkingForCurrentOperation) {
                setCheckingForCurrentOperation(false)
            }
            if (response?.operationOngoing) {
                setOngoingOperationPresent(true)
            } else {
                setOngoingOperationPresent(false)
            }
        }
        addPubSubEventListener(`report_post_backlog_progress`, postBacklogEventListener);
        return () => {
            removePubSubEventListener(`report_post_backlog_progress`, postBacklogEventListener);
        }
    }, []);

    const clearPostBacklogs = () => {
        let requestBody = {
            start_date: startDate,
            end_date: endDate,
            instantPublishing: false,
        }
        setProcessing(true)
        APIService.clearPostBacklogs(requestBody, (response, error) => {
            setProcessing(false)
            if (error) {
                toast.error(error, { theme: 'colored' });;
                return;
            }
            if (response) {
                let { data } = response;
                toast.success(response.message, { theme: 'colored' });
                return;
            }
        })
    }

    const getMaxDate = () => {
        let date = new Date();
        date.setMinutes(date.getMinutes() - 10);
        return date;
    }

    const isEndDateTimeGreaterThan10MinsAgo = () => {
        if (!renderingDone) return;
        console.log(endDate, 'endDate')
        let date = new Date();
        date.setMinutes(date.getMinutes() - 9);
        if (endDate.getTime() > date.getTime()) {
            toast.error(
                `🙄 Abeg dey try calm down. Select time way dey more than 10 mins ago.`,
                { theme: 'colored' });
            let newDate = createDateWithTimeZone(
                getMaxDate(),
                timeZone,
                true
            )?.toString()
            setEndDate(new Date(newDate))
        }
    }

    useEffect(() => {
        isEndDateTimeGreaterThan10MinsAgo()
        if (!renderingDone) {
            setRenderingDone(true)
        }
    }, [endDate])

    useEffect(() => {
        let timeout = setTimeout(() => {
            if (checkingForCurrentOperation) {
                setCheckingForCurrentOperation(false)
            }
            clearTimeout(timeout)
        }
            , 30000)
    }, [])


    const checkingForCurrentOperationDisplay = () => {
        return (
            <Flex className={'gap-2 mb-3'}
                alignItems={'center'}
                justifyContent={'center'}
                direction={'column'}>
                <Spinner animation='border' />
                <span>
                    Checking for ongoing operation, Please wait...
                </span>
            </Flex>

        )
    }

    const ongoingOperationDisplay = () => {
        return (
            <Flex className={'gap-2 mb-3'}
                alignItems={'center'}
                justifyContent={'center'}
                direction={'column'}>
                <Spinner animation='border' />
                <p>
                    An ongoing operation is currently in progress. We have cleared {ongoingOperationData?.success} out of {ongoingOperationData?.total} posts.
                </p>
            </Flex>
        )
    }

    const mainDisplay = () => {
        return <>
            <p className="pb-2">
                Select the date range to clear post backlogs.
            </p>
            <Flex
                alignItems={"center"}
                className="mb-3">
                <Flex>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                            setStartDate(date);
                        }}
                        formatWeekDay={(day) => day.slice(0, 3)}
                        className="form-control"
                        placeholderText="Select Date & Time"
                        timeIntervals={1}
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeSelect
                    />
                </Flex>
                <FontAwesomeIcon
                    className='ms-2 me-2'
                    icon="minus"
                    style={{
                        color: "#78909C",
                    }}
                />
                <Flex>
                    <DatePicker
                        maxDate={getMaxDate()}
                        selected={endDate}
                        onChange={(date) => {
                            setEndDate(date);
                        }}
                        formatWeekDay={(day) => day.slice(0, 3)}
                        className="form-control"
                        placeholderText="Select Date & Time"
                        timeIntervals={1}
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeSelect
                    />
                </Flex>
            </Flex>

            <Flex
                justifyContent={"end"}
            >
                <Button
                    disabled={processing}
                    className="mb-3"
                    variant={'primary'}
                    onClick={() => {
                        setPromptConfirmationNotice(true)
                    }}>
                    {
                        processing ? "Clearing post backlogs..." : "Proceed"
                    }
                </Button>
            </Flex>
        </>
    }

    return (
        <Card className="hh-md-100">
            <FalconCardHeader
                title="Clear post backlogs"
                titleTag="h6"
                className="pb-0"
                endEl={
                    <Flex>
                        {/* <Form.Select
                            size="sm"
                            value={selectedOption}
                            onChange={(e) => {
                                setSelectedOption(e.target.value)
                            }}
                            className="me-2"
                        >
                            {filterOptions.map((entry, index) => (
                                <option value={index} key={entry}>
                                    {convertSnakeCaseToSentence(entry)}
                                </option>
                            ))}
                        </Form.Select> */}
                        {/* <CardDropdown /> */}
                    </Flex>
                }
            />

            <Card.Body className="fs--1 light pb-0">
                {
                    checkingForCurrentOperation ? checkingForCurrentOperationDisplay() : ongoingOperationPresent ? ongoingOperationDisplay() : mainDisplay()
                }
            </Card.Body>
            <Modal
                show={promptConfirmationNotice}
                onHide={() => {
                    setPromptConfirmationNotice(false)
                }}
                centered
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>
                        Are you sure you want to clear post backlogs?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Flex
                        alignItems={"center"}
                        className={"gap-2"}
                        justifyContent={"center"}
                        direction={"column"}
                    >
                        <p>
                            This action will clear all post backlogs from the selected date range and cannot be undone. Are you sure you want to proceed?
                        </p>
                    </Flex>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setPromptConfirmationNotice(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            clearPostBacklogs()
                            setPromptConfirmationNotice(false)
                        }}
                    >
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    )
}

export default PostBacklogCorrector