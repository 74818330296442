import APIService from "http/api_service";
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Form, Button, Toast } from 'react-bootstrap';

export const toPascalCase = (string) => {
    return `${string}`
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(
            new RegExp(/\s+(.)(\w*)/, 'g'),
            ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
        )
        .replace(new RegExp(/\w/), s => s.toUpperCase());
}

export default function BotRequests() {

    const [botRequests, setBotRequests] = useState([]);
    const botUsernameRef = useRef();
    const botTokenRef = useRef();
    const [canFetchRequests, setCanFetchRequests] = useState(true);
    const [submittingRequest, setSubmittingRequest] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    useEffect(() => {
        if (canFetchRequests) {
            APIService.fetchPendingBotRequests({}, (response, error) => {
                setCanFetchRequests(false);
                if (response) {
                    setBotRequests(response.data);
                } else {
                    setBotRequests([]);
                }
            })
        }
    }, [canFetchRequests]);

    const BotData = React.forwardRef((props, ref) => {
        const { title, meta } = props;
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 20
                }}>
                <h5>{title}:</h5>
                <span>{meta}
                </span>
            </div>
        )
    });

    const provisionBot = (request) => {
        let requestBody = {
            name: request.name,
            description: request.description,
            about: request.about,
            targetPlatformUserIds: request.targetPlatformUserIds,
            logo: request.logo,
            workSpaceId: request.workSpace,
            initiatorId: request.initiatorId,
            botRequestId: request._id
        };
        if (!request.refBot) {
            let botUsername = botUsernameRef.current.value;
            let botToken = botTokenRef.current.value;
            if (!botUsername || !botToken) {
                return;
            }
            requestBody = {
                ...requestBody,
                username: botUsernameRef.current.value,
                token: botTokenRef.current.value,
            }
        } else {
            requestBody = {
                ...requestBody,
                username: request.refBot.username,
                token: request.refBot.token,
                ...request
            }
        }
        setSubmittingRequest(true);
        APIService.upsertBot(requestBody, (response, error) => {
            setSubmittingRequest(false);
            if (error) {
                setErrorMsg(error);
                return;
            }
            window.location.reload();
        });
    }

    const generateScript = (request) => {
        if (!request.refBot) {
            return (
                <div>
                    <h5 style={{ paddingLeft: 0 }}>Instructions</h5>
                    <code style={{
                        color: '#455A64'
                    }}>
                        <ol
                            style={{
                                padding: 10,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 8
                            }}>
                            <li>Add <b><a href="https://t.me/botfather" target={'_blank'}>@BothFather</a></b> to your chat list on Telegram if not already added and then click start if not already started</li>
                            <li>Create a new bot using the command <b>/newbot</b></li>
                            <li>Enter <b>{request.name}</b> as the <b>name</b> of the Bot</li>
                            <li>Enter <b>{request.name.replace(/\s/g, '').toLowerCase()}_bot</b> or <b>{toPascalCase(request.name.replace(/\s/g, ''))}Bot</b> which ever is available as the <b>username</b> of the Bot</li>
                            <li>Now, enter the command <b>/mybots</b> and select the newly created bot</li>
                            <li>Click on <b>Edit Bot</b></li>
                            <li>Select the available options one after the other and update the bot with the user provided data on the left</li>
                            <li>Please note that the name of the bot is different from its username. E.g Postly's Bot username is <b>postly_bot</b> but the name that shows up in messages is the bot's name which is just <b>Postly</b></li>
                            <li>When done with the updates. Now finally copy the <b>username</b> of the bot into the first box below. Once again, the username of a bot is the name that comes up whenever you send the <b>/mybots</b> command. Also, ensure there are no characters at the start and end of the bot username and <b>Don't</b> include the <b>@</b> sign when copying.</li>
                            <li>Now, issue the <b>/mybots</b> command one more time and select the <b>API Token Option</b>. Copy the token and paste it in the second box below</li>
                            <li>Finally, you may now hit the <b>Create Bot For User</b> button to complete the process</li>
                            <li><b>DO NOT</b> Click on the button below unless you have performed all the operations above if not you may loose the initial details of the bot. If that happens you are OYO</li>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 10
                                }}>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        provisionBot(request);
                                    }}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 10,
                                        paddingLeft: 0,
                                        marginLeft: 0
                                    }}>
                                    <Form.Control ref={botUsernameRef} placeholder="Enter the final bot username here." />
                                    <Form.Control ref={botTokenRef} placeholder="Enter the generated bot token here. Cross Check before pasting!" />
                                    <Button
                                        disabled={submittingRequest}
                                        variant="primary"
                                        type="submit"
                                        className="w-100">
                                        {submittingRequest ? 'Provisioning Bot...' : 'Create Bot For User'}
                                    </Button>
                                </Form>
                            </div>
                        </ol>
                    </code>
                </div >
            );
        }
        return (
            <div>
                <h5 style={{ paddingLeft: 0 }}>Update Instructions</h5>
                <code style={{
                    color: '#455A64'
                }}>
                    <ol
                        style={{
                            padding: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 8
                        }}>
                        <li>Issue <b>/mybots</b> command to @Botfather</li>
                        <li>Select <b>{request.refBot.username}</b> from the list</li>
                        <li>Select <b>Edit Info</b></li>
                        <li>Update the bot with the provided info on the left one after the other.</li>
                        <li>When done, simply click the <b>UPDATE</b> button below to inform the client that the bot has been updated with the provided data.</li>
                        <div>
                            <Button
                                onClick={(e) => {
                                    provisionBot(request);
                                }}
                                disabled={submittingRequest}
                                variant="primary"
                                type="submit"
                                className="w-100">
                                {submittingRequest ? 'Updating Bot...' : 'Update'}
                            </Button>
                        </div>
                    </ol>
                </code>
            </div>
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
            <h1
                style={{
                    paddingTop: 10
                }}>Bot Requests
            </h1>
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                {
                    botRequests.length > 0 &&
                    <Accordion>
                        {
                            botRequests.map((x, index) => {
                                return (
                                    <Accordion.Item key={x._id} eventKey={`${index}`}>
                                        <Accordion.Header>
                                            <span dangerouslySetInnerHTML={{ __html: `${x.message}` }}>

                                            </span>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '100%',
                                                    gap: 20
                                                }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '90%',
                                                    }}>
                                                    <h5> Logo</h5>
                                                    <img src={x.logo} width={200} height={200}
                                                        style={{
                                                            width: 400,
                                                            height: 400,
                                                            objectFit: 'cover',
                                                            marginBottom: 10,
                                                            borderRadius: 10
                                                        }}
                                                    />
                                                    {
                                                        x.refBot &&
                                                        <BotData title={'Username'} meta={x.refBot.username} />
                                                    }
                                                    <BotData title={'Name'} meta={x.name} />
                                                    <BotData title={'Description'} meta={x.description} />
                                                    <BotData title={'About'} meta={x.about} />
                                                </div>
                                                <div
                                                    style={{
                                                        height: '600px',
                                                        width: 2,
                                                        marginLeft: 30,
                                                        marginRight: 10,
                                                        background: '#B0BEC5'
                                                    }}>
                                                </div>
                                                {generateScript(x)}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })
                        }
                    </Accordion>
                }
            </div>
            <Toast onClose={() => setErrorMsg(null)} show={errorMsg !== null} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">Error</strong>
                </Toast.Header>
                <Toast.Body>{errorMsg}</Toast.Body>
            </Toast>
        </div>
    );
}