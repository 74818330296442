import { Line } from 'rc-progress';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getOverlayLoader, setOverlayLoader } from 'redux/slices/notification_slice';
import Flex from '../Flex';
import { clearPopupCustomAlert, getPopupCustomAlert } from 'redux/slices/alertSlice';
import SoftBadge from '../SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AiOutlineClose } from 'react-icons/ai';

const AlertModal = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const overlayLoader = useSelector(getOverlayLoader)
    const popupCustomAlert = useSelector(getPopupCustomAlert)
    const [showPopup, setShowPopup] = useState(false)
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        if (popupCustomAlert?.length) {
            !showPopup && setShowPopup(true);
            setMessages((prevMessages) => [
                ...prevMessages,
                ...popupCustomAlert.filter(alert =>
                    !prevMessages.some(
                        existing => existing.message === alert.message && existing.type === alert.type
                    )
                ).map(alert => ({ message: alert.message, type: alert.type, prefix: alert.prefix }))
            ]);
            dispatch(clearPopupCustomAlert());
        }
    }, [popupCustomAlert])

    const getBg = (type) => {
        switch (type) {
            case 'error':
                return 'danger'
            case 'success':
                return 'success'
            case 'warning':
                return 'warning'
            default:
                return 'primary'
        }
    }

    const getIcon = (type) => {
        switch (type) {
            case 'error':
                return 'times-circle'
            case 'success':
                return 'check-circle'
            case 'warning':
                return 'exclamation-triangle'
            default:
                return 'info-circle'

        }
    }

    const mapOutMessages = (messages) => {
        if (!messages || messages.length === 0) return null
        return messages.map((m, index) => {
            return <Flex
                // direction={'column'}
                gap={2}
            >
                <SoftBadge
                    pill
                    bg={getBg(m?.type)}
                    className='cursor-pointer'
                    wrapText={true}
                >
                    {m?.prefix ? `${m?.prefix}: ` : `Notification: `}  {`   ${m?.message}`}
                </SoftBadge>
            </Flex>
            // return <SoftBadge pill bg={getBg(m?.type)}
            //     className='me-2 cursor-pointer'>
            //     {/* <FontAwesomeIcon
            //         icon={getIcon(m?.type)}
            //         className="me-1"
            //     /> */}
            //     {m?.message}
            // </SoftBadge>
        })
    }

    const displayMessages = () => {
        return <Modal.Body>
            <Flex
                direction={'column'}
                gap={2}
            >
                {mapOutMessages(messages)}
            </Flex>
            {/* <Line percent={overlayLoader?.percentage || 0} strokeWidth={2} strokeColor="#2c7be5" /> */}
        </Modal.Body>
    }

    return (
        <div>
            <Modal
                size="md"
                // size="lg"
                centered
                show={showPopup}
                onHide={() => {
                    setMessages([]);
                    setShowPopup(false);
                }}
            >
                <Modal.Header>
                    <span
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <Modal.Title>
                                Notifications 🔔
                            </Modal.Title>
                        </div>
                        <Modal.Title>
                            <AiOutlineClose
                                cursor={"pointer"}
                                onClick={() => {
                                    setMessages([]);
                                    setShowPopup(false);
                                }}
                            />
                        </Modal.Title>
                    </span>
                </Modal.Header>
                {displayMessages()}
                {/* <Modal.Footer style={{ flexDirection: "column" }}>
                    <Flex justifyContent={"flex-start"} alignItems={"center"} gap={10}>
                        <Button
                            variant="danger"
                            onClick={() => {
                                setShowPopup(false)
                            }}>
                            Close
                        </Button>
                    </Flex>
                </Modal.Footer> */}
            </Modal>
        </div>
    )
}

export default AlertModal