import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "components/common/ConfirmModal";
import Flex from "components/common/Flex";
import InfoBar from "components/common/InfoBar";
import PageHeader from "components/common/PageHeader";
import RichInfoBar from "components/common/RichInfoBar";
import usePubSub from "pubsub";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, ListGroup, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TelegramLoginButton from "react-telegram-login";
import { Buffer } from 'buffer';
import { toast } from "react-toastify";
import {
  getRefreshConnectedFeed,
  setRefreshConnectedFeed,
} from "redux/slices/postslice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { userCanPerformActionInWorkspace } from "utils/workspace_utils";
import {
  PERMISSION_VIEW_AND_MANAGE_PLATFORMS,
  PlatformIdentifier,
} from "../../../../constants";
import APIService from "../../../../http/api_service";
import {
  getConnectedSocialAccountStat,
  getDisconnectedPlatforms,
  getReconnectAccount,
  getRedirectBack,
  setDisconnectedPlatforms,
  setReconnectAccount,
  setRedirectBack,
} from "../../../../redux/slices/platformSlice";
import {
  canConnectExtraSocialAccounts,
  connectBlogger,
  connectFacebook,
  connectGMB,
  connectInstagram,
  connectLinkedIn,
  connectPinterest,
  connectRSSFeed,
  connectReddit,
  connectTelegram,
  connectThreads,
  connectTikTok,
  connectTwitter,
  connectWordpress,
  connectYoutube,
  platformConnectionGuides,
} from "../../../../utils/platform_utils";
import {
  hasPopupBlocker,
  recommendUrlToUnblockPopup,
} from "../../../../utils/url";
import { findPlatformByIdentifier } from "../index";
import PlatformConnectView from "../platform_connect_view";
import RSSConnectionPage from "../rss/rss_feed_connection_page";
import TwitterConnectionPage from "../twitter/twitter_connection_page";
import ConnectedAccount from "./connected_account";
import { NoteContainer } from "../styles";
import WordpressConnectionPage from "../wordpress/wordpress_connection_page";
import { getCurrentHostnameDomain } from 'utils/domain';

export default function ConnectedAccounts(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { identifier } = useParams();
  const platform = findPlatformByIdentifier(identifier);
  const workSpace = useSelector(getActiveWorkSpace);
  const refreshFeed = useSelector(getRefreshConnectedFeed);
  const [fetchingAccounts, setFetchingAccounts] = useState(true);
  const [fetchError, setFetchError] = useState();
  const [connectedAccounts, setConnectedAccounts] = useState();
  const [processing, setProcessing] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  const [popupBlocked, setPopupBlocked] = useState(false);
  const popUpUnblockHint = recommendUrlToUnblockPopup();
  const disconnectedPlatforms = useSelector(getDisconnectedPlatforms);
  const reconnectAccount = useSelector(getReconnectAccount);
  const { addPubSubEventListener, removePubSubEventListener } = usePubSub();
  const hostnameDomain = getCurrentHostnameDomain();
  const currentURL = window.location.href;
  const [disableAllButtons, setDisableAllButtons] = useState(false);
  const [canConnectSA, setCanConnectSA] = useState(undefined);
  const connectedSocialAccountStat = useSelector(getConnectedSocialAccountStat)

  const checkSocialAccountsSlot = async () => {
    const stat = await canConnectExtraSocialAccounts()
    setCanConnectSA(stat)
  }

  useEffect(() => {
    checkSocialAccountsSlot()
  }, [connectedSocialAccountStat]);

  const extractTextAndLink = (inputString) => {
    // Regular expression pattern to match text between {{ and }} after link:
    var pattern = /{{link:(.*?)}}/;

    // Executing the regular expression and capturing the desired text
    var match = pattern.exec(inputString);

    // Checking if there's a match
    if (match !== null) {
      // Extracted text after link:
      var extractedText = match[1];

      // Split the input string by the match
      var parts = inputString.split(pattern);

      // Text before the link
      var textBeforeLink = parts[0];
      // Text after the link
      var textAfterLink = parts[2];

      return {
        text: textBeforeLink + textAfterLink,
        link: extractedText,
      };
    } else {
      // No match found, return input string as text
      return {
        text: inputString,
        link: null,
      };
    }
  };

  const displayConnectionGuides = () => {
    if (!platformConnectionGuides[platform.identifier]) return null;
    return (
      <Card
        canHover={false}
        style={{
          width: "100%",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        <NoteContainer>
          Please Note:
          {
            platform.identifier === PlatformIdentifier.FACEBOOK &&
            <InfoBar
              info={`Meta has discontinued posting on Facebook Groups, kindly use the SHARE feature to share your published posts to Facebook Groups and other channels. ${hostnameDomain === "app.postly.ai" || hostnameDomain === "http://localhost:3000" ? 'Learn more from https://www.postly.ai/share-post' : 'Contact support'}`}
            />
          }
          <ListGroup as="ul" style={{ border: "none" }}>
            {platformConnectionGuides[platform.identifier].map(
              (item, index) => {
                const { text, link } = extractTextAndLink(item.label);
                // if(item?.ignoreForWhiteLabel && currentURL !== "app.postly.ai" ){
                //   return null
                // }
                return (
                  <ListGroup.Item
                    as="li"
                    style={{ border: "none" }}
                    key={item.id}
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      className="me-1 text-success"
                    />{" "}
                    {text}{" "}
                    {link && (
                      <>
                        {currentURL === "app.postly.ai" || currentURL === "http://localhost:3000" ?
                          <Button
                            variant="link"
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ padding: 0, fontSize: 14 }}
                          >
                            {link}
                          </Button> : "Contact support"}
                      </>
                    )}
                  </ListGroup.Item>
                );
              }
            )}
          </ListGroup>
        </NoteContainer>
      </Card>
    );
  };

  const observeWindow = (win, platformIdentifier) => {
    let isPopupBlocked = hasPopupBlocker(win);
    if (isPopupBlocked) {
      setPopupBlocked(true);
      return;
    }
    let eventHandler = (data) => {
      setProcessing(false);
      if (!win.closed) {
        win.close();
      }
      if (data["oauth_status"] === "cancelled") {
        toast.error("Integration Cancelled", { theme: "colored" });
      } else {
        toast.success(data["message"], { theme: "colored" });
        fetchConnectedAccounts(true, platformIdentifier);
      }
      removePubSubEventListener(`${workSpace["_id"]}auth`, eventHandler);
    };
    addPubSubEventListener(`${workSpace["_id"]}auth`, eventHandler);
    const timer = setInterval(() => {
      if (win.closed) {
        setProcessing(false);
        clearInterval(timer);
        removePubSubEventListener(`${workSpace["_id"]}auth`, eventHandler);
      }
    }, 500);
  };

  const fetchConnectedAccounts = (redirectToPagesAndGroups = false, redirectFurtherBack = false) => {
    setFetchingAccounts(true);
    setFetchError(null);
    APIService.fetchConnectedAccounts(
      workSpace["_id"],
      platform.target ?? platform.identifier,
      (response, error) => {
        setFetchingAccounts(false);
        if (error) {
          setFetchError(error);
          return;
        }
        let { data } = response;
        setFetchError(null);
        if (data && data.length > 0) {
          data = data.map((account, index) => {
            let { user_id, user_name, user_photo, ...platform } =
              findPlatformByIdentifier(account.target);
            let newAccount = { ...account, ...platform };
            return newAccount;
          });
        }
        setConnectedAccounts(data);
        dispatch(setRefreshConnectedFeed(false));
        if (redirectToPagesAndGroups) {
          let lastIndex = data.length - 1;
          let latestAccount = data[lastIndex];
          if (latestAccount) {
            const hexString = Buffer.from(JSON.stringify(latestAccount)).toString("hex")
            if (hexString) {
              if (redirectFurtherBack) {
                dispatch(setRedirectBack(true))
              }
              navigate(`./${hexString}`);
            }
          }
        }
      }
    );
  };

  useEffect(() => {
    if (platform) {
      if (fetchingAccounts) {
        fetchConnectedAccounts();
      }
    }
  }, []);

  useEffect(() => {
    if (refreshFeed) {
      fetchConnectedAccounts();
    }
  }, [refreshFeed]);

  useEffect(() => {
    if ((disconnectedPlatforms ?? []).length > 0) {
      let disconnectedPlatformsCopy = [...disconnectedPlatforms];
      let issuedPlatformWithIdentifier = disconnectedPlatformsCopy.filter(
        (issueItem) => issueItem.identifier !== platform.identifier
      );
      if (issuedPlatformWithIdentifier.length > 0) {
        dispatch(setDisconnectedPlatforms(issuedPlatformWithIdentifier));
      } else {
        dispatch(setDisconnectedPlatforms(null));
      }
    }
  }, [connectedAccounts]);

  const addNewAccount = (options) => {
    console.log("hitted");
    if (processing) {
      return;
    }
    setProcessing(true);
    let platformIdentifier = platform.identifier;
    let platformName = platform.name;
    switch (platformIdentifier) {
      case PlatformIdentifier.FACEBOOK:
        connectFacebook(
          workSpace,
          platformName,
          platformIdentifier,
          (response, error) => {
            setProcessing(false);
            if (error) {
              toast.error(error, { theme: "colored" });
              return;
            }
            toast.success(response, { theme: "colored" });
            fetchConnectedAccounts(true);
          }
        );
        break;
      case PlatformIdentifier.INSTAGRAM:
        connectInstagram(
          workSpace,
          platformName,
          platformIdentifier,
          (response, error) => {
            setProcessing(false);
            if (error) {
              toast.error(error, { theme: "colored" });
              return;
            }
            toast.success(response, { theme: "colored" });
            fetchConnectedAccounts(true);
          }
        );
        break;
      case PlatformIdentifier.THREADS:
        connectThreads(
          workSpace,
          PlatformIdentifier.THREADS,
          (response, error) => {
            if (error) {
              console.log("🚀 ~ addNewAccount ~ error:", error)
              setProcessing(false);
              toast.error(error, { theme: "colored" });
              return;
            }
            let win = window.open(response.data, "_blank");
            observeWindow(win, PlatformIdentifier.THREADS);
          }
        );
        break;
      case PlatformIdentifier.TWITTER:
        connectTwitter(
          workSpace,
          PlatformIdentifier.TWITTER,
          options,
          (response, error) => {
            setProcessing(false);
            if (error) {
              toast.error(error, { theme: "colored" });
              return;
            }
            let win = window.open(response.data, "_blank");
            observeWindow(win);
          }
        );
        break;
      case PlatformIdentifier.YOUTUBE:
        connectYoutube(
          workSpace,
          PlatformIdentifier.YOUTUBE,
          (response, error) => {
            if (error) {
              setProcessing(false);
              toast.error(error, { theme: "colored" });
              return;
            }
            let win = window.open(response.data, "_blank");
            observeWindow(win);
          }
        );
        break;
      case PlatformIdentifier.TIKTOK:
        connectTikTok(
          workSpace,
          PlatformIdentifier.TIKTOK,
          (response, error) => {
            if (error) {
              setProcessing(false);
              toast.error(error, { theme: "colored" });
              return;
            }
            let win = window.open(response.data, "_blank");
            observeWindow(win);
          }
        );
        break;
      case PlatformIdentifier.LINKED_IN:
        connectLinkedIn(
          workSpace,
          PlatformIdentifier.LINKED_IN,
          (response, error) => {
            if (error) {
              console.log("🚀 ~ addNewAccount ~ error:", error)
              setProcessing(false);
              toast.error(error, { theme: "colored" });
              return;
            }
            let win = window.open(response.data, "_blank");
            observeWindow(win);
          }
        );
        break;
      case PlatformIdentifier.PINTEREST:
        connectPinterest(
          workSpace,
          PlatformIdentifier.PINTEREST,
          (response, error) => {
            if (error) {
              setProcessing(false);
              toast.error(error, { theme: "colored" });
              return;
            }
            let win = window.open(response.data, "_blank");
            observeWindow(win);
          }
        );
        break;
      case PlatformIdentifier.GOOGLE_MY_BUSINESS:
        connectGMB(
          workSpace,
          PlatformIdentifier.GOOGLE_MY_BUSINESS,
          (response, error) => {
            if (error) {
              setProcessing(false);
              toast.error(error, { theme: "colored" });
              return;
            }
            let win = window.open(response.data, "_blank");
            observeWindow(win);
          }
        );
        break;
      case PlatformIdentifier.REDDIT:
        connectReddit(
          workSpace,
          PlatformIdentifier.REDDIT,
          (response, error) => {
            if (error) {
              setProcessing(false);
              toast.error(error, { theme: "colored" });
              return;
            }
            let win = window.open(response.data, "_blank");
            observeWindow(win);
          }
        );
        break;
      case PlatformIdentifier.WORDPRESS:
        connectWordpress(
          workSpace,
          PlatformIdentifier.WORDPRESS,
          (response, error) => {
            console.log("trying to connect wordpress");
            if (error) {
              setProcessing(false);
              toast.error(error, { theme: "colored" });
              return;
            }
            let win = window.open(response.data, "_blank");
            observeWindow(win);
          }
        );
        break;
      case PlatformIdentifier.BLOGGER:
        connectBlogger(
          workSpace,
          PlatformIdentifier.BLOGGER,
          (response, error) => {
            if (error) {
              setProcessing(false);
              toast.error(error, { theme: "colored" });
              return;
            }
            let win = window.open(response.data, "_blank");
            observeWindow(win);
          }
        );
        break;
      case PlatformIdentifier.RSS_FEED:
        connectRSSFeed(
          workSpace,
          PlatformIdentifier.RSS_FEED,
          options,
          (response, error) => {
            setProcessing(false);
            if (error) {
              toast.error(error, { theme: "colored" });
              return;
            }
            if (response) {
              toast.success(response?.message, { theme: "colored" });
              window.location.reload();
              return;
            }
          }
        );
        break;
      default:
        break;
    }
  };

  useLayoutEffect(() => {
    if (!reconnectAccount) return
    if (reconnectAccount !== platform.identifier) {
      dispatch(setReconnectAccount(null))
      return
    }
    dispatch(setReconnectAccount(null))
    addNewAccount();
  }, [])


  const handleTelegramResponse = (response) => {
    console.log("🚀 ~ handleTelegramResponse ~ response:", response)
    let { id: userId, photo_url: userPhoto, username } = response;
    connectTelegram(
      workSpace,
      userId,
      username,
      userPhoto,
      PlatformIdentifier.TELEGRAM,
      (response, error) => {
        if (error) {
          setProcessing(false);
          toast.error(error, { theme: "colored" });
          return;
        }
        setProcessing(false);
        setShowCustom(false);
        fetchConnectedAccounts(true);
      }
    );
  };

  const customView = () => {
    if (platform.identifier === PlatformIdentifier.TELEGRAM) {
      return (
        <TelegramLoginButton
          dataOnauth={handleTelegramResponse}

          botName="postly_bot"
        />
      );
    }
    return " ";
  };

  const NewAccountConnectionView = React.forwardRef((props, ref) => {
    const { setOpenTwitterApiKeysInput, setOpenRSSFeedInput, setWordpressConnectOption, canConnectSA } = props;
    if (
      !userCanPerformActionInWorkspace(
        workSpace,
        PERMISSION_VIEW_AND_MANAGE_PLATFORMS
      )
    ) {
      return null;
    }
    return (
      <Card
        onClick={(e) => {
          if (platform.identifier === PlatformIdentifier.TELEGRAM) {
            setShowCustom(true);
          } else {
            if (setOpenTwitterApiKeysInput) {
              setOpenTwitterApiKeysInput(true);
              return;
            }
            if (setOpenRSSFeedInput) {
              setOpenRSSFeedInput(true);
              return;
            }
            if (setWordpressConnectOption) {
              setWordpressConnectOption(true);
              return;
            }
            addNewAccount();
          }
        }}
        elevation={5}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          justifyContent: "center",
          minHeight: 180,
          minWidth: 180,
          cursor: "pointer",
        }}
      >
        {showCustom ? (
          customView()
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              disabled={!canConnectSA}
              variant="light"
              className="rounded-circle"
              style={{
                width: 60,
                height: 60,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {processing ? (
                <Spinner
                  as={"span"}
                  role="status"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                />
              ) : (
                <FontAwesomeIcon icon={"plus"} size={"2x"} />
              )}
            </Button>
            <span
              style={{
                fontSize: 14,
                padding: 20,
                fontWeight: "bold",
                maxWidth: 180,
                textAlign: "center",
              }}
              textAlign={"center"}
            >
              {processing
                ? "Please wait..."
                : `Add another ${platform.name} account`}
            </span>
          </div>
        )}
      </Card>
    );
  });

  const handleConnectionViewRendering = () => {
    if (fetchingAccounts) {
      return (
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          className={"vh-100"}
        >
          <Spinner animation="border" size={40} />
        </Flex>
      );
    }

    if (platform.identifier === PlatformIdentifier.TWITTER) {
      let props = {
        processing,
        platform,
        connectAccount: addNewAccount,
        handleTelegramResponse,
        connectedAccounts,
        NewAccountConnectionView,
      }
      return <TwitterConnectionPage
        props={props}
      />
    }

    if (platform.identifier === PlatformIdentifier.WORDPRESS) {
      let props = {
        processing,
        platform,
        connectAccount: addNewAccount,
        handleTelegramResponse,
        connectedAccounts,
        NewAccountConnectionView,
        setProcessing,
        fetchingAccounts,
      }
      return <WordpressConnectionPage
        props={props}
      />
    }

    if (platform.identifier === PlatformIdentifier.RSS_FEED) {
      let props = {
        processing,
        platform,
        connectAccount: addNewAccount,
        handleTelegramResponse,
        connectedAccounts,
        NewAccountConnectionView,
        setProcessing,
        fetchingAccounts,
      };
      return <RSSConnectionPage props={props} />;
    }

    if (
      (connectedAccounts ?? []).length < 1 &&
      !fetchingAccounts &&
      userCanPerformActionInWorkspace(
        workSpace,
        PERMISSION_VIEW_AND_MANAGE_PLATFORMS
      )
    ) {
      return (
        <PlatformConnectView
          processing={processing}
          platform={platform}
          connectAccount={addNewAccount}
          handleTelegramResponse={handleTelegramResponse}
          reconnectAccount={reconnectAccount}
          canConnectSA={canConnectSA}
        />
      );
    }
    if ((connectedAccounts ?? []).length > 0) {
      return (
        <Flex direction={"column"} justifyContent={"center"}>
          <Flex
            alignItems={"center"}
            className={"gap-3 pt-2"}
            justifyContent={"center"}
            wrap={"wrap"}
          >
            {connectedAccounts.map((account, index) => {
              return (
                <div key={account._id} id={account._id}>
                  <ConnectedAccount workSpace={workSpace} account={account} />
                </div>
              );
            })}
            <NewAccountConnectionView />
          </Flex>

          {platform.identifier === PlatformIdentifier.TELEGRAM && (
            <InfoBar
              info={`Your Telegram account(s) <b>MUST</b> have a <b>username</b> for ${(currentURL === "app.postly.ai" || currentURL === "http://localhost:3000") ? 'Postly' : 'this'} to Work!`}
            />
          )}
        </Flex>
      );
    }
  };

  return (
    <div>
      {!platform ? (
        <span>Nothing here</span>
      ) : (
        <Flex direction={"column"} justifyContent={"center"}>
          <ConfirmModal
            open={popupBlocked}
            title={"Popup Blocker Detected"}
            message={`Your browser is blocking our pop-ups. As a result you will not be able to connect your <b>${platform.name
              }</b> account.\n\n${popUpUnblockHint
                ? popUpUnblockHint
                : "Kindly disable pop-up blocking"
              } and try again.\n\nYou can re-enable pop-up blocking after connecting ${platform.name
              } and other relevant platforms.\n\nThank you`}
            confirmText={"OK"}
            cancelText={"CLOSE"}
            onConfirm={() => {
              setPopupBlocked(false);
              setProcessing(false);
            }}
            onCancel={() => {
              setPopupBlocked(false);
              setProcessing(false);
            }}
          />
          <PageHeader
            title={
              <Flex alignItems={"center"} className={"gap-2"}>
                {/* <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    color: platform.color,
                                    alignSelf: 'center',
                                    background: '#dde7f5',
                                    marginRight: 5,
                                    width: 40,
                                    height: 40,
                                    borderRadius: 100,
                                    justifyContent: 'center'
                                }}
                                > */}
                {platform.svg ? (
                  <img
                    src={platform.svg}
                    alt={platform.name}
                    style={{
                      width: 30,
                      height: 30,
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={platform.icon}
                    style={{
                      color: platform.color,
                      fontSize: 30,
                    }}
                  />
                )}
                {/* </div> */}
                {/* <h5>{platform.name}</h5> */}
              </Flex>
            }
            titleTag="h5"
            className="mb-3"
          >
            <p className="fs--1 mt-1">
              {(connectedAccounts ?? []).length > 0
                ? "Your connected accounts"
                : "Connect your account(s) to start posting"}
            </p>
          </PageHeader>
          {handleConnectionViewRendering()}
          {/* Adding connection guides here... */}
          <br />
          <br />
          {displayConnectionGuides()}
          {fetchError && (
            <RichInfoBar
              title={"Oh snap!"}
              message={fetchError}
              variant={"danger"}
              action={fetchConnectedAccounts}
              actionMessage={"Try Again"}
            />
          )}
        </Flex>
      )}
    </div>
  );
}
