import Flex from 'components/common/Flex'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import AccountWithoutConnectedPagesAndGroupsEdit from './account_without_connect_pages_and_groups_edit'
import ConnectedPagesAndGroupsEdit from './connected_pages_and_groups_edit'

const AudienceAccountEdit = (props) => {
    const { platform, setCloneData, cloneData } = props
    const [checked, setChecked] = useState(platform?.selected ?? false)
    const [openPlatformExpansion, setOpenPlatformExpansion] = useState(false)

    const handleMarkAllChildren = (platform, checked) => {
        let newPlatform = structuredClone(platform)
        newPlatform.selected = checked
        newPlatform.accounts.forEach(account => {
            account.selected = checked
            if (account.connected_pages_and_groups) {
                account.connected_pages_and_groups.forEach(page => {
                    page.selected = checked
                })
            }
        })

        let newCloneData = cloneData.map(item => {
            if (item.name === newPlatform.name) {
                return newPlatform
            } else {
                return item
            }
        })

        setCloneData(newCloneData)
        
    }

    useEffect(() => {
        setChecked(platform?.selected)
    }, [cloneData])

    return (
        <Flex direction={'column'} className={'gap-1'}>
            <Flex
                direction={'row'}
                className={'gap-5'}
                justifyContent={'between'}
                alignItems={'center'}
            >
                <Form.Check
                    id={`${platform?.name}-group-checkbox`}
                    type="checkbox"
                    name='groups'
                    label={platform?.name}
                    checked={checked}
                    className={'mt-2'}
                    onChange={(e) => {
                        setChecked(e.target.checked)
                        handleMarkAllChildren(platform, e.target.checked)
                    }}

                />

                {
                    openPlatformExpansion ?
                        <IoIosArrowUp
                            size={20}
                            cursor={'pointer'}
                            onClick={() => {
                                setOpenPlatformExpansion(!openPlatformExpansion)
                            }}
                        /> : <IoIosArrowDown
                            size={20}
                            cursor={'pointer'}
                            onClick={() => {
                                setOpenPlatformExpansion(!openPlatformExpansion)
                            }}
                        />
                }
            </Flex>
            {
                openPlatformExpansion && platform?.accounts?.map((account, index) => {
                    if (account?.connected_pages_and_groups) {
                        return <ConnectedPagesAndGroupsEdit
                            key={index}
                            connected_pages_and_groups={account?.connected_pages_and_groups}
                            setCloneData={setCloneData}
                            account={account}
                            cloneData={cloneData}
                            platform={platform}
                        />
                    } else {
                        return <AccountWithoutConnectedPagesAndGroupsEdit
                            key={index}
                            account={account}
                            setCloneData={setCloneData}
                            cloneData={cloneData}
                            platform={platform}
                        />
                    }
                })
            }
        </Flex>
    )
}

export default AudienceAccountEdit