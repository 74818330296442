import React, { useEffect, useState } from "react";
import { Button, Card, FormControl, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import Flex from "components/common/Flex";
import Avatar from "components/common/Avatar";
import { useTranslation } from "react-i18next";
import StringUtils from "utils/string";
import { Tooltip } from "@mui/material";
import { defaultOrganization } from "state/organization_store";
import APIService from "http/api_service";
import { useSelector } from 'react-redux';
import { getLoggedInUser } from "redux/slices/user_slice";
import useActiveOrganization from "../../hooks/useActiveOrganization";

export default function OrganizationMemberSettings() {

    const { t } = useTranslation();
    const authenticatedUser = useSelector(getLoggedInUser);
    const [activeOrganization] = useActiveOrganization();
    const [searchQuery, setSearchQuery] = useState("");
    const [memberWorkspaces, setMemberWorkspaces] = useState([]);
    const [organizationMembers, setOrganizationMembers] = useState([]);
    const [fetchingOrganizationMembers, setFetchingOrganizationMembers] = useState(false);
    const [fetchError, setFetchError] = useState();

    const name = (member) => {
        if (member._id === authenticatedUser?._id) {
            return t('you');
        }
        let fullName = (`${member?.first_name ?? ''} ${member?.last_name ?? ''}`).trim();
        if (!fullName) {
            return member?.email.split('@')[0] ?? t('member');
        }
        return fullName;
    }

    const filteredMembers = organizationMembers.filter(member =>
        name(member).toLowerCase().includes(searchQuery.toLowerCase()) ||
        member?.occupation?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const fetchMemberWorkspaces = (memberId) => {
        let memberWorkspaces = organizationMembers.filter(member => member._id === memberId).map(member => member.workspaceMemberships).flat().map(entry => entry.workspace);
        setMemberWorkspaces(memberWorkspaces);
    }

    const renderPopOver = (memberId) => {
        return (
            <Popover id={`popover-${memberId}`}>
                <Popover.Header as="h3">{t('workspaces')}</Popover.Header>
                <Popover.Body>
                    <>
                        {memberWorkspaces.length > 0 ? (
                            <ul className="mb-0">
                                {memberWorkspaces.map((workspace) => (
                                    <li key={workspace._id}>{workspace?.name}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>{t('no_workspaces')}</p>
                        )}
                    </>
                </Popover.Body>
            </Popover>
        );
    }

    const fetchOrganizationMembers = () => {
        if (activeOrganization?._id === defaultOrganization?._id) {
            return;
        }
        setFetchingOrganizationMembers(true);
        setFetchError(null);
        APIService.fetchOrganizationMembers(activeOrganization._id, (response, error) => {
            setFetchingOrganizationMembers(false);
            if (error) {
                setFetchError({ error, func: fetchOrganizationMembers });
                return;
            }
            let { data } = response;
            setOrganizationMembers(data);
        });
    }

    useEffect(() => {
        fetchOrganizationMembers();
    }, []);

    return (
        <Flex direction={'column'} className={'gap-3 w-100'}>
            <Flex direction={'column'} className={'mx-1 gap-2'}>
                {
                    organizationMembers.length !== 0 &&
                    <h6>{t('members_of_current_organization')}</h6>
                }
                {
                    organizationMembers.length !== 0 &&
                    <FormControl
                        placeholder={t('search')}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                }
            </Flex>
            <Flex direction={'column'}>
                <Flex
                    alignItems="center"
                    className="w-100 gap-2 flex-wrap">
                    {filteredMembers.map((member) => (
                        <Card key={member._id} className="text-center" style={{ minHeight: 300, width: 195 }}>
                            <Card.Body className="p-1 d-flex flex-column align-items-center justify-content-center">
                                <Avatar
                                    src={member.avatar || "placeholderImageUrl"}
                                    size="4xl"
                                    className="mb-4"
                                />
                                <Card.Title className="mb-2 fs-1">
                                    {StringUtils.highlightMatches(name(member), searchQuery)}
                                </Card.Title>
                                <Card.Text
                                    className="fs--1"
                                >
                                    {StringUtils.highlightMatches(member?.occupation ?? '', searchQuery)}
                                </Card.Text>
                                <OverlayTrigger
                                    trigger="click"
                                    overlay={renderPopOver(member._id)}
                                    onEnter={() => fetchMemberWorkspaces(member._id)}
                                    rootClose
                                >
                                    <Card.Text
                                        className="fs--1 fw-semi-bold bg-info text-white px-3 py-1 rounded-pill cursor-pointer">
                                        <Tooltip title={t('click_to_view')}>
                                            <Card.Text className="fs--1 fw-semi-bold bg-info text-white px-3 py-1 rounded-pill cursor-pointer">
                                                {t('number_of_workspaces_member_is_in', { count: (member?.workspaceMemberships ?? []).length })}
                                            </Card.Text>
                                        </Tooltip>
                                    </Card.Text>
                                </OverlayTrigger>
                            </Card.Body>
                        </Card>
                    ))}
                </Flex>
                {
                    fetchingOrganizationMembers &&
                    <Flex alignItems={'center'} justifyContent={'center'} className={'py-4 h-100'}>
                        <Spinner animation="border" />
                    </Flex>
                }
                {
                    fetchError &&
                    <Flex
                        direction={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <span>{fetchError?.error}</span>
                        <Button
                            size="sm"
                            onClick={() => {
                                if (fetchError?.func) {
                                    fetchError.func();
                                }
                            }}>{t('retry')}
                        </Button>
                    </Flex>
                }
            </Flex>
        </Flex>
    );
}