import React, { useState, useEffect } from 'react';
import BulkContentImagesPreview from './bulk_content_images_preview';
import BulkContentVideoPreview from './bulk_content_video_preview';
import APIService from 'http/api_service';
import BulkContentEditTextArea from './bulk_content_edit_textarea';
import BulkContentDatePreview from './bulk_content_date_preview';
import { useNavigate } from 'react-router-dom';
import { RoutePaths, BulkContentUploadKnowlegdeLink, PlatformIdentifier } from '../../../../../constants';
import { platforms as TemplatePlatforms, findPlatformByIdentifier } from '../../../../app/platforms';
import StringUtils from 'utils/string';
import clone from 'just-clone';
import BulkContentPlatformsPreview from './bulk_content_platforms_preview';
import { toast } from 'react-toastify';
import {
    isUploadingBulkContent,
    setUploadingBulkContent,
    setUploadableBulkContent,
    getUploadableBulkContent
} from '../../../../../redux/slices/postslice';
import { useSelector, useDispatch } from 'react-redux';
import PinterestOptionsField from '../pinterest_field_options';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import RedditFieldOptions from '../reddit_options_field';
import GoogleMyBusinessOptions from '../gmb_options_field';
import YouTubeOptionsField from '../youtube_options_field';
import InstagramOptionsField from '../instagram_options_field';
import { Button, Card, Modal, ProgressBar } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import FalconCardHeader from 'components/common/FalconCardHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import usePubSub from 'pubsub';
import Flex from 'components/common/Flex';
import { IoExpand } from 'react-icons/io5';
import BulkContentPostPreviewFullscreen from './bulk_content_post_preview_fullscreen';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { getLoggedInUser } from 'redux/slices/user_slice';
import TiktokOptionsField from '../tiktok_field_options';
import FacebookOptionsField from '../facebook_options_field';

export default function BulkContentPostPreview(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        data,
        modalClose,
        pickNewFile
    } = props;
    const uploadableContent = useSelector(getUploadableBulkContent);
    const [loadingPlatforms, setLoadingPlatforms] = useState(true);
    const [platformsLoadError, setPlatformsLoadError] = useState();
    const [connectedPlatforms, setConnectedPlatforms] = useState([]);
    const workSpace = useSelector(getActiveWorkSpace);
    const [columns, setColumns] = useState([]);
    const [noPlatformConnected, setNoPlatformConnected] = useState(false);
    const [canPreview, setCanPreview] = useState(false);
    const [filePreviewErrorMessage, setFilePreviewErrorMessage] = useState('Oops! something went wrong previewing that file. Kindly review and pick again');
    const uploadingBulkContent = useSelector(isUploadingBulkContent);
    const [canLoadColumns, setCanLoadColumns] = useState(false);
    const [openCreationSuccessDialog, setOpenCreationSuccessDialog] = useState(false);
    const loggedInUser = useSelector(getLoggedInUser);
    const { addPubSubEventListener, removePubSubEventListener } = usePubSub();
    const [openFullScreen, setOpenFullScreen] = useState(false);

    useEffect(() => {
        let workSpaceEventListener = (response) => {
            let { error, data } = response;
            if (error) {
                dispatch(setUploadingBulkContent(false));
                if (error?.includes('OVERRIDE TO SUCCESS MESSAGE:')) {
                    let message = error.split('OVERRIDE TO SUCCESS MESSAGE:')[1];
                    toast.success(message, { theme: 'colored' });
                    return;
                }
                toast.error(error, { theme: 'colored' });
                return;
            }
            let currentBatchId = localStorage.getItem("current_batch_id");
            if (data.data.batch_id === currentBatchId) {
                dispatch(setUploadingBulkContent(false));
                setOpenCreationSuccessDialog(true);
                setOpenFullScreen(false);
                localStorage.removeItem('current_batch_id');
                localStorage.setItem('user_just_uploaded_bulk_content', true);
            }
        }
        addPubSubEventListener(`${workSpace._id}_post_status`, workSpaceEventListener);
        return () => {
            removePubSubEventListener(`${workSpace._id}_post_status`, workSpaceEventListener);
        }
    }, []);


    const convertTagsToJSON = (tagsString) => {
        if (!tagsString || tagsString.trim().length === 0) {
            return [];
        }
        const tagsArray = tagsString.split(',').map(tag => tag.trim());

        const jsonTags = tagsArray.map(tag => {
            return {
                id: tag,
                text: tag
            };
        });
        return jsonTags;
    }

    const prepareColumns = () => {
        let highestLength = 0;
        let highestItem;
        for (let i = 0; i < uploadableContent.length; i++) {
            let objectAtI = uploadableContent[i];
            let objLength = Object.keys(objectAtI).length;
            if (objLength > highestLength) {
                highestLength = objLength;
                highestItem = objectAtI;
            }
        }
        let bulkContentColumns = [];
        if (highestItem) {
            let objectKeys = Object.keys(highestItem);
            if (objectKeys.includes('caption')) {
                bulkContentColumns.push(
                    {
                        name: 'Caption',
                        Header: 'Caption',
                        accessor: 'caption',
                        sortable: true,
                        reorder: true,
                        Cell: rowData => {
                            let { item_index, caption } = rowData.row.original;
                            let index = item_index ?? 0;
                            rowData.row.original = uploadableContent[index];
                            return (
                                caption ? <BulkContentEditTextArea
                                    rowIndex={index}
                                    property="caption"
                                /> : <div></div>
                            );
                        }
                    }
                );
            }
            if (objectKeys.includes('message')) {
                bulkContentColumns.push(
                    {
                        Header: 'Message',
                        accessor: 'message',
                        sortable: true,
                        reorder: true,
                        Cell: rowData => {
                            let { item_index, message } = rowData.row.original;
                            let index = item_index ?? 0;
                            rowData.row.original = uploadableContent[index];
                            return (
                                message ? <BulkContentEditTextArea
                                    rowIndex={index}
                                    property="message"
                                /> : <div></div>
                            )
                        }
                    }
                );
            }
            if (objectKeys.includes('image_url')) {
                bulkContentColumns.push(
                    {
                        Header: 'Image URL',
                        accessor: 'image_url',
                        sortable: true,
                        reorder: true,
                        Cell: rowData => {
                            let { item_index, image_url } = rowData.row.original;
                            let index = item_index ?? 0;
                            rowData.row.original = [...uploadableContent][index];
                            return (
                                image_url ? <BulkContentImagesPreview
                                    rowIndex={index}
                                /> : <div></div>
                            )
                        }
                    }
                );
            }
            if (objectKeys.includes('video_url')) {
                bulkContentColumns.push(
                    {
                        Header: 'Video URL',
                        sortable: true,
                        accessor: 'video_url',
                        reorder: true,
                        Cell: rowData => rowData.row.original.video_url ? <BulkContentVideoPreview row={rowData.row.original} /> : <div></div>
                    }
                )
            }
            if (objectKeys.includes('publish_facebook_post_under_feeds')) {
                let InstagramColumn = bulkContentColumns.find(x => x.name === 'Facebook');
                let InstagramColumnData = {
                    Header: 'Facebook',
                    sortable: true,
                    accessor: 'publish_facebook_post_under_feeds',
                    reorder: true,
                    Cell: rowData => {
                        let { item_index } = rowData.row.original;
                        let index = item_index ?? 0;
                        return (rowData.row.original.publish_facebook_post_under_feeds)
                            ? <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minWidth: 200,
                                    justifyContent: 'center',
                                }}>
                                <FacebookOptionsField
                                    bulkContentMode
                                    rowIndex={index}
                                />
                            </div> :
                            <div></div>
                    }
                };
                if (!InstagramColumn) {
                    bulkContentColumns.push(InstagramColumnData);
                }
            }

            if ((objectKeys.includes('publish_facebook_video_under_reels') && objectKeys.includes('video_url'))) {
                let InstagramColumn = bulkContentColumns.find(x => x.name === 'Facebook');
                let InstagramColumnData = {
                    Header: 'Facebook',
                    sortable: true,
                    accessor: 'publish_facebook_video_under_reels',
                    reorder: true,
                    Cell: rowData => {
                        let { item_index } = rowData.row.original;
                        let index = item_index ?? 0;
                        return (rowData.row.original.publish_facebook_video_under_reels)
                            ? <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minWidth: 200,
                                    justifyContent: 'center',
                                }}>
                                <FacebookOptionsField
                                    bulkContentMode
                                    rowIndex={index}
                                />
                            </div> :
                            <div></div>
                    }
                };
                if (!InstagramColumn) {
                    bulkContentColumns.push(InstagramColumnData);
                }
            }
            if ((objectKeys.includes('publish_instagram_video_under_reels') && objectKeys.includes('video_url'))
                || (objectKeys.includes('publish_instagram_video_under_stories') && objectKeys.includes('video_url'))) {
                let InstagramColumn = bulkContentColumns.find(x => x.name === 'Instagram');
                let InstagramColumnData = {
                    Header: 'Instagram',
                    sortable: true,
                    accessor: 'publish_instagram_video_under_reels',
                    reorder: true,
                    Cell: rowData => {
                        let { item_index } = rowData.row.original;
                        let index = item_index ?? 0;
                        return (rowData.row.original.publish_instagram_video_under_reels
                            || rowData.row.original.publish_instagram_video_under_stories)
                            ? <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minWidth: 200,
                                    justifyContent: 'center',
                                }}>
                                <InstagramOptionsField
                                    bulkContentMode
                                    rowIndex={index}
                                />
                            </div> :
                            <div></div>
                    }
                };
                if (!InstagramColumn) {
                    bulkContentColumns.push(InstagramColumnData);
                }
            }
            if (objectKeys.includes('publish_instagram_image_under_feeds') && objectKeys.includes('image_url')) {
                bulkContentColumns.push({
                    Header: 'Instagram',
                    sortable: true,
                    accessor: 'publish_instagram_image_under_feeds',
                    reorder: true,
                    Cell: rowData => {
                        let { item_index } = rowData.row.original;
                        let index = item_index ?? 0;
                        return (
                            rowData.row.original.publish_instagram_image_under_feeds
                                ?
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        minWidth: 200,
                                        justifyContent: 'center',
                                    }}>
                                    <InstagramOptionsField
                                        mediaType="image"
                                        bulkContentMode
                                        rowIndex={index}
                                    />
                                </div>
                                : <div></div>
                        )
                    }
                })
            }
            if (objectKeys.includes('publish_facebook_image_under_stories') && objectKeys.includes('image_url')) {
                bulkContentColumns.push({
                    Header: 'Facebook',
                    sortable: true,
                    accessor: 'publish_facebook_image_under_stories',
                    reorder: true,
                    Cell: rowData => {
                        let { item_index } = rowData.row.original;
                        let index = item_index ?? 0;
                        return (
                            rowData.row.original.publish_facebook_image_under_stories
                                ?
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        minWidth: 200,
                                        justifyContent: 'center',
                                    }}>
                                    <FacebookOptionsField
                                        mediaType="image"
                                        bulkContentMode
                                        rowIndex={index}
                                    />
                                </div>
                                : <div></div>
                        )
                    }
                })
            }
            if (objectKeys.includes('publish_instagram_image_under_stories') && objectKeys.includes('image_url')) {
                bulkContentColumns.push({
                    Header: 'Instagram',
                    sortable: true,
                    accessor: 'publish_instagram_image_under_stories',
                    reorder: true,
                    Cell: rowData => {
                        let { item_index } = rowData.row.original;
                        let index = item_index ?? 0;
                        return (
                            rowData.row.original.publish_instagram_image_under_stories
                                ?
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        minWidth: 200,
                                        justifyContent: 'center',
                                    }}>
                                    <InstagramOptionsField
                                        mediaType="image"
                                        bulkContentMode
                                        rowIndex={index}
                                    />
                                </div>
                                : <div></div>
                        )
                    }
                })
            }
            if (objectKeys.includes('tiktok_title')
                || objectKeys.includes('allow_comment_on_tiktok')
                || objectKeys.includes('viewing_audience_on_tiktok')
                || objectKeys.includes('allow_stitch_on_tiktok')) {
                let tiktokColumn = bulkContentColumns.find(x => x.name === 'TikTok');
                let tiktokColumnData = {
                    Header: 'TikTok',
                    sortable: true,
                    accessor: 'tiktok_title',
                    reorder: true,
                    Cell: rowData => {
                        let { item_index } = rowData.row.original;
                        let index = item_index ?? 0;
                        return (rowData.row.original.tiktok_title
                            || rowData.row.original.allow_comment_on_tiktok
                            || rowData.row.original.viewing_audience_on_tiktok
                            || rowData.row.original.allow_stitch_on_tiktok)
                            ? <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minWidth: 200,
                                    justifyContent: 'center',
                                }}>
                                <TiktokOptionsField
                                    bulkContentMode
                                    rowIndex={index}
                                />
                            </div> :
                            <div></div>
                    }
                };
                if (!tiktokColumn) {
                    bulkContentColumns.push(tiktokColumnData);
                }
            }
            if (objectKeys.includes('pinterest_title')
                || objectKeys.includes('pinterest_destination_link')
                || objectKeys.includes('pinterest_alt_text')) {
                let pinterestColumn = bulkContentColumns.find(x => x.name === 'Pinterest');
                let pinterestColumnData = {
                    Header: 'Pinterest',
                    sortable: true,
                    accessor: 'pinterest_title',
                    reorder: true,
                    Cell: rowData => {
                        let { item_index } = rowData.row.original;
                        let index = item_index ?? 0;
                        return (rowData.row.original.pinterest_title
                            || rowData.row.original.pinterest_destination_link
                            || rowData.row.original.pinterest_alt_text)
                            ? <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minWidth: 200,
                                    justifyContent: 'center',
                                }}>
                                <PinterestOptionsField
                                    bulkContentMode
                                    rowIndex={index}
                                />
                            </div> :
                            <div></div>
                    }
                };
                if (!pinterestColumn) {
                    bulkContentColumns.push(pinterestColumnData);
                }
            }
            if (objectKeys.includes('youtube_video_title')
                || objectKeys.includes('youtube_video_tags')
                || objectKeys.includes('youtube_video_comment')
                || objectKeys.includes('youtube_video_embeddable')) {
                let YouTubeColumn = bulkContentColumns.find(x => x.name === 'YouTube');
                let YouTubeColumnData = {
                    Header: 'YouTube',
                    sortable: true,
                    accessor: 'youtube_video_title',
                    reorder: true,
                    Cell: rowData => {
                        let { item_index } = rowData.row.original;
                        let index = item_index ?? 0;
                        return (rowData.row.original.youtube_video_title
                            || rowData.row.original.youtube_video_tags
                            || rowData.row.original.youtube_video_comment
                            || rowData.row.original.youtube_video_embeddable)
                            ? <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minWidth: 200,
                                    justifyContent: 'center',
                                }}>
                                <YouTubeOptionsField
                                    bulkContentMode
                                    rowIndex={index}
                                />
                            </div> :
                            <div></div>
                    }
                };
                if (!YouTubeColumn) {
                    bulkContentColumns.push(YouTubeColumnData);
                }
            }
            if (objectKeys.includes('reddit_title')) {
                bulkContentColumns.push({
                    Header: 'Reddit',
                    accessor: 'reddit_title',
                    sortable: true,
                    reorder: true,
                    Cell: rowData => {
                        let { item_index } = rowData.row.original;
                        let index = item_index ?? 0;
                        rowData.row.original = uploadableContent[index];
                        return (
                            rowData.row.original.reddit_title
                                ?
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        minWidth: 200,
                                        justifyContent: 'center',
                                    }}>
                                    <RedditFieldOptions
                                        bulkContentMode
                                        rowIndex={index}
                                    />
                                </div>
                                : <div></div>
                        )
                    }
                })
            }

            if (objectKeys.includes('google_my_business_post_type')) {
                let gmbColumn = bulkContentColumns.find(x => x.name === 'Google My Business');
                let gmbColumnData = {
                    Header: 'Google My Business',
                    sortable: true,
                    accessor: 'google_my_business_post_type',
                    reorder: true,
                    Cell: rowData => {
                        let { item_index } = rowData.row.original;
                        let index = item_index ?? 0;
                        return (
                            rowData.row.original.google_my_business_post_type
                                ?
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        minWidth: 200,
                                        justifyContent: 'center',
                                    }}>
                                    <GoogleMyBusinessOptions
                                        bulkContentMode
                                        rowIndex={index}
                                    />
                                </div>
                                : <div></div>
                        )
                    }
                };
                if (!gmbColumn) {
                    bulkContentColumns.push(gmbColumnData);
                }
            }


            if (objectKeys.includes('instagram_first_comment')) {
                bulkContentColumns.push({
                    Header: 'Instagram First Comment',
                    sortable: true,
                    accessor: 'instagram_first_comment',
                    reorder: true,
                    Cell: rowData => {
                        let { item_index, instagram_first_comment } = rowData.row.original;
                        let index = item_index ?? 0;
                        rowData.row.original = uploadableContent[index];
                        return (
                            instagram_first_comment ? <BulkContentEditTextArea
                                rowIndex={index}
                                property="instagram_first_comment"
                            /> : <div></div>
                        )
                    }
                })
            }
            if (objectKeys.includes('due_date')) {
                bulkContentColumns.push({
                    Header: 'Due Date*',
                    sortable: true,
                    accessor: 'due_date',
                    reorder: true,
                    Cell: rowData => {
                        let { item_index } = rowData.row.original;
                        let index = item_index ?? 0;
                        rowData.row.original = uploadableContent[index];
                        return (
                            <BulkContentDatePreview
                                rowIndex={index}
                            />
                        )
                    }
                });
            }
            if (objectKeys.includes('platforms')) {
                bulkContentColumns.push(
                    {
                        Header: 'Target Platforms*',
                        sortable: true,
                        accessor: 'platforms',
                        reorder: true,
                        Cell: rowData => {
                            let { item_index } = rowData.row.original;
                            let index = item_index ?? 0;
                            rowData.row.original = uploadableContent[index];
                            return (
                                <BulkContentPlatformsPreview
                                    rowIndex={index}
                                    modalClose={modalClose}
                                />
                            )
                        }
                    }
                );
            }
            setLoadingPlatforms(false);
        }
        return bulkContentColumns;
    }

    const convertWordpressTagsToArrayFormat = (tags) => {
        if (!tags) return [];
        let splittedTags = tags.split(',');
        return splittedTags;
    }

    const reformedContent = (content) => {
        let newContent = JSON.parse(JSON.stringify(content));
        let freshData = [];
        for (let contentRow of newContent) {
            let {
                pinterest_title,
                pinterest_destination_link,
                pinterest_alt_text,
                google_my_business_post_type,
                google_my_business_event_title,
                google_my_business_call_to_action_button,
                google_my_business_call_to_action_url,
                google_my_business_event_start_date_and_time,
                google_my_business_event_end_date_and_time,
                google_my_business_offer_title,
                google_my_business_offer_start_date_and_time,
                google_my_business_offer_end_date_and_time,
                google_my_business_offer_coupon_code,
                google_my_business_offer_redeem_url,
                google_my_business_offer_terms_and_conditions,
                youtube_video_title,
                notify_youtube_subscribers,
                youtube_video_privacy,
                youtube_video_embeddable,
                youtube_video_made_for_kids,
                youtube_video_tags,
                youtube_video_thumbnail_url,
                youtube_video_comment,
                publish_facebook_image_under_stories,
                publish_facebook_post_under_feeds,
                publish_facebook_video_under_reels,
                publish_instagram_video_under_reels,
                instagram_first_comment,
                publish_instagram_image_under_stories,
                publish_instagram_image_under_feeds,
                publish_instagram_video_under_stories,
                instagram_video_cover_thumb_offset,
                wordpress_title,
                wordpress_password,
                wordpress_tags,
                viewing_audience_on_tiktok,
                allow_comment_on_tiktok,
                allow_duet_on_tiktok,
                allow_stitch_on_tiktok,
                tiktok_title,
                ...others
            } = contentRow;
            let gmbData = {};
            if (google_my_business_post_type) {
                let gmbDataOptions = {
                    gmbPostType: google_my_business_post_type,
                    gmbEventTitle: google_my_business_event_title ?? google_my_business_offer_title,
                    gmbEventSchedule: {
                        startTime: google_my_business_event_start_date_and_time ?? google_my_business_offer_start_date_and_time,
                        endTime: google_my_business_event_end_date_and_time ?? google_my_business_offer_end_date_and_time
                    },
                    gmbCTAActionType: google_my_business_call_to_action_button?.replace(" ", "_"),
                    gmbCTAActionUrl: google_my_business_call_to_action_url,
                    gmbCouponCode: google_my_business_offer_coupon_code,
                    gmbCouponRedeemURL: google_my_business_offer_redeem_url,
                    gmbTermsAndConditions: google_my_business_offer_terms_and_conditions,
                    gmbSummary: contentRow.message ?? ""
                }
                gmbData.gmb_data = gmbDataOptions
            }
            let reusablePostData = {
                youTubeVideoTitle: youtube_video_title,
                youTubeVideoTags: convertTagsToJSON(youtube_video_tags),
                youTubeVideoPrivacy: youtube_video_privacy?.toLowerCase(),
                youTubeVideoEmbeddable: ["no", "No", "NO"].includes(youtube_video_embeddable) ? false : youtube_video_embeddable,
                youTubeVideoMadeForKids: ["no", "No", "NO"].includes(youtube_video_made_for_kids) ? false : youtube_video_made_for_kids,
                notifyYouTubeSubscribers: notify_youtube_subscribers === "Yes" ? true : notify_youtube_subscribers,
                youTubeVideoThumbnail: youtube_video_thumbnail_url,
                youTubeVideoComment: youtube_video_comment,
                pinterestTitle: pinterest_title,
                pinterestDestinationLink: pinterest_destination_link,
                pinterestAltText: pinterest_alt_text,
                publishIGVideoAsReel: ["no", "No", "NO"].includes(publish_instagram_video_under_reels) ? false : publish_instagram_video_under_reels,
                isNormalFacebookPost: ["no", "No", "NO"].includes(publish_facebook_post_under_feeds) ? false : publish_facebook_post_under_feeds,
                isFacebookPostReel: ["no", "No", "NO"].includes(publish_facebook_video_under_reels) ? false : publish_facebook_video_under_reels,
                isFacebookPostStory: ["no", "No", "NO"].includes(publish_facebook_image_under_stories) ? false : publish_facebook_image_under_stories,
                publishIGVideoAsStories: ["no", "No", "NO"].includes(publish_instagram_video_under_stories) ? false : publish_instagram_video_under_stories,
                publishIGImageAsStories: ["no", "No", "NO"].includes(publish_instagram_image_under_stories) ? false : publish_instagram_image_under_stories,
                publishIGImageToFeed: ["no", "No", "NO"].includes(publish_instagram_image_under_feeds) ? false : publish_instagram_image_under_feeds,
                wordpressTitle: wordpress_title,
                firstComment: {
                    firstCommentText: instagram_first_comment,
                    id: StringUtils.generateRandomString(10)
                },
                tiktokViewSetting: viewing_audience_on_tiktok === 'public to everyone' ? 'PUBLIC_TO_EVERYONE' : viewing_audience_on_tiktok === 'mutual fellow friends' ? 'MUTUAL_FELLOW_FRIENDS' : 'SELF_ONLY',
                tiktokAllowComment: ["no", "No", "NO"].includes(allow_comment_on_tiktok) ? false : allow_comment_on_tiktok,
                tiktokAllowDuet: ["no", "No", "NO"].includes(allow_duet_on_tiktok) ? false : allow_duet_on_tiktok,
                tiktokAllowStitch: ["no", "No", "NO"].includes(allow_stitch_on_tiktok) ? false : allow_stitch_on_tiktok,
                wordpressPassword: wordpress_password,
                wordpressTags: convertWordpressTagsToArrayFormat(wordpress_tags),
                tiktokTitle: tiktok_title,

                igVideoCoverOffset: instagram_video_cover_thumb_offset ?? 0,
                createdBy: {
                    first_name: loggedInUser.first_name,
                    last_name: loggedInUser.last_name,
                    avatar: loggedInUser.avatar,
                    _id: loggedInUser._id,
                    email: loggedInUser.email
                },


                ...gmbData,
                ...others
            };
            freshData.push({ ...reusablePostData });
        }
        return freshData;
    }

    const refinePlatformsToPushTwitterAsLastToPublish = async (arr) => {
        try {
            if (!arr || !arr.length) {
                return [];
            }
            let array = [...arr];
            let finalArr = [];
            for (let index = 0; index < array.length; index++) {
                let element = array[index];
                let _platforms = element.platforms;
                let twitterPlatform = _platforms.find((x) => x.identifier === PlatformIdentifier.TWITTER);
                if (twitterPlatform) {
                    _platforms = _platforms.filter((x) => x.identifier !== PlatformIdentifier.TWITTER);
                    _platforms.push(twitterPlatform);
                }
                let newElement = { ...element, platforms: _platforms };
                finalArr.push(newElement);
            }
            return finalArr;
        } catch (e) {
            return arr
        }
    };

    const uploadBulkContent = async () => {
        if (uploadingBulkContent) {
            return;
        }
        dispatch(setUploadingBulkContent(true));
        let batchId = StringUtils.generateRandomString(64);
        window.localStorage.setItem('current_batch_id', batchId);
        const _uploadableContent = await refinePlatformsToPushTwitterAsLastToPublish(uploadableContent);
        let leanContent = _uploadableContent.map(x => {
            let y = { ...x };
            let yPlatforms = y.platforms.map(pEntry => {
                let { icon, color, ...others } = pEntry;
                return { ...others };
            })
            y.platforms = yPlatforms;
            return { ...y };
        });
        let cleanedContent = reformedContent(leanContent);
        let bulkContentPayload = {
            data: {
                'batch_id': batchId,
                content: cleanedContent
            }
        };

        const bulkContentStringified = JSON.stringify(bulkContentPayload);
        const bulkContentBlob = new Blob([bulkContentStringified], {
            type: 'application/json;charset=utf-8'
        });
        let fileSize = 0;
        let requestBody = new FormData();
        let bulkContentUploadFile = new File([bulkContentBlob], `${StringUtils.generateRandomString(10)}.json`);
        requestBody.append('file', bulkContentUploadFile);
        let response = await APIService.uploadNewFile(workSpace._id, requestBody, fileSize, null, 1);
        let result = response.data;

        // dispatch(setUploadingBulkContent(false));
        // return;

        setTimeout(async () => {
            await APIService.uploadBulkContent(workSpace._id, { bulk_content_url: result }, (response, error) => {
                if (error) {
                    dispatch(setUploadingBulkContent(false));
                    if (error?.includes('OVERRIDE TO SUCCESS MESSAGE:')) {
                        let message = error.split('OVERRIDE TO SUCCESS MESSAGE:')[1];
                        toast.info(message, { theme: 'colored' });
                        return;
                    }
                    toast.error(error, { theme: 'colored' });
                    return;
                }
                let { message } = response;
                toast.success(message, { theme: 'colored' });
            });
        }, 3000);
    }

    useEffect(() => {
        let dataEntries = Object.entries(data).map((entry) => entry);
        if (dataEntries.length > 0) {
            let expectedDataFormat = dataEntries[0][1];
            if (expectedDataFormat) {
                let expectationArr = [];
                let caption = expectedDataFormat['caption'];
                if (caption) {
                    expectationArr.push(caption);
                }
                let message = expectedDataFormat['message'];
                if (message) {
                    expectationArr.push(message);
                }
                let imageUrl = expectedDataFormat['image_url'];
                if (imageUrl) {
                    expectationArr.push(imageUrl);
                }
                let dueDate = expectedDataFormat['due_date'];
                if (dueDate) {
                    expectationArr.push(dueDate);
                }
                let platforms = expectedDataFormat['platforms'];
                if (!platforms || !dueDate) {
                    setFilePreviewErrorMessage(`Due Date or Platforms Column was not provided. Visit <a href=${BulkContentUploadKnowlegdeLink} style='color:blue;' rel='noreferrer' target={'_blank'}>Here</a> to learn why`);
                    setCanPreview(false);
                    setLoadingPlatforms(false);
                    return;
                }
                let dueDateTest = new Date(dueDate);
                if (isNaN(dueDateTest.getTime())) {
                    setFilePreviewErrorMessage(`Sorry, your date formats are wrong. Be explicit about the time. Visit <a href=${BulkContentUploadKnowlegdeLink} style='color:blue;' rel='noreferrer' target={'_blank'}>Here</a> to learn why`);
                    setCanPreview(false);
                    setLoadingPlatforms(false);
                    return;
                }
                expectationArr.push(platforms);
                // TODO: Come back to this place when you are done...UNCOMMENT
                if (expectationArr.length < 3) {
                    setFilePreviewErrorMessage(`Total Number of Columns too short. Visit <a href=${BulkContentUploadKnowlegdeLink} rel='noreferrer' target={'_blank'}>Here</a> to learn why`);
                    setCanPreview(false);
                    setLoadingPlatforms(false);
                    return;
                }
                let validData = [...data].filter(entry => entry.platforms && entry.due_date);
                // TODO: Come back to this place when you are done...UNCOMMENT
                validData = validData?.filter((x) => {
                    var dateString = x?.due_date;
                    var givenDate = new Date(dateString);
                    var currentTime = new Date();
                    var futureTime = new Date(currentTime.getTime() + 5 * 60000);
                    if (givenDate > currentTime && givenDate > futureTime) {
                        return x
                    }
                })
                if (validData.length < 4) {
                    setFilePreviewErrorMessage(`Oops! Scheduling is only allowed for future events. Right now, there are just ${validData.length} rows left after filtering, which is less than the required 4.`);
                    setCanPreview(false);
                    setLoadingPlatforms(false);
                } else {
                    dispatch(setUploadableBulkContent(validData));
                    setCanPreview(true);
                }
            }
        }
    }, [data]);

    useEffect(() => {
        if (canPreview && uploadableContent) {
            APIService.fetchWorkSpacePlatforms(workSpace._id, (response, error) => {
                if (error) {
                    setLoadingPlatforms(false);
                    setPlatformsLoadError(error);
                    return;
                }
                if (response) {
                    let { data = [] } = response;
                    if (data.length > 0) {
                        data = data.map((x) => {
                            x.selected = true
                            let pages = x.connected_pages_and_groups;
                            if (pages) {
                                for (let page of pages) {
                                    page.selected = true;
                                }
                            }
                            return x;
                        });
                        setConnectedPlatforms(data);
                    } else {
                        setLoadingPlatforms(false);
                        setNoPlatformConnected(true);
                    }
                } else {
                    setLoadingPlatforms(false);
                }
            });
        }
    }, [canPreview]);

    const removePlatformFromBulk = (platformIdentifier, uploadableContent) => {
        let workingUploadableContent = [...uploadableContent];
        for (let i = 0; i < workingUploadableContent.length; i++) {
            let uploadableContentEntry = structuredClone(workingUploadableContent[i]);
            let platforms = uploadableContentEntry.platforms;
            // platforms is a string of platforms seprated by comma
            if (typeof platforms === 'string') {
                let platformToRemove = platformIdentifier
                let regex = new RegExp('\\b' + platformToRemove + '\\b', 'i');
                let refinedPlatforms = platforms.replace(regex, '');
                uploadableContentEntry.platforms = refinedPlatforms;
            }
            // platforms is an array of platforms
            else if (Array.isArray(platforms)) {
                platforms = platforms.filter((x) => x !== findPlatformByIdentifier(platformIdentifier)?.name);
                uploadableContentEntry.platforms = platforms;
            }
            workingUploadableContent[i] = uploadableContentEntry;
        }
        return workingUploadableContent;
    }

    const postProcessBulkContent = () => {
        let refinedUploadableContent = removePlatformFromBulk('', uploadableContent);
        // let refinedUploadableContent = removePlatformFromBulk(PlatformIdentifier.PINTEREST, uploadableContent);
        let workingUploadableContent = [];
        for (let i = 0; i < refinedUploadableContent.length; i++) {
            let uploadableContentEntry = JSON.parse(JSON.stringify(refinedUploadableContent[i]));
            uploadableContentEntry.item_index = i;
            if (!uploadableContentEntry._id) {
                let uniqueRowId = StringUtils.generateRandomString(64);
                uploadableContentEntry._id = uniqueRowId;
                uploadableContentEntry.id = uniqueRowId;
            }
            if (typeof uploadableContentEntry.platforms === 'string') {
                uploadableContentEntry.intialPlatformsString = uploadableContentEntry.platforms;
            }
            let platformsForRow = clone(getTargetPlatformsForRow(uploadableContentEntry.platforms, uploadableContentEntry.intialPlatformsString));
            for (let i = 0; i < platformsForRow.length; i++) {
                let platform = clone(platformsForRow[i]);
                if (!platform._id) {
                    platform._id = StringUtils.generateRandomString(24);
                }
                if (!platform.update_identifier) {
                    platform.update_identifier = StringUtils.generateRandomString(64);
                }
                if (platform.connected_pages_and_groups) {
                    for (let page of platform.connected_pages_and_groups) {
                        page.selected = true;
                    }
                }
                platformsForRow[i] = platform;
            }
            uploadableContentEntry.platforms = platformsForRow;
            if (!uploadableContentEntry.post_group_identifier) {
                uploadableContentEntry.post_group_identifier = StringUtils.generateRandomString(64);
            }
            if (uploadableContentEntry.image_url) {
                uploadableContentEntry.image_url = uploadableContentEntry.image_url.toString().split(",");
            }
            if (uploadableContentEntry.video_url) {
                uploadableContentEntry.video_url = uploadableContentEntry.video_url.split(",")[0];
            }
            uploadableContentEntry.due_date = new Date(uploadableContentEntry.due_date).toString()
            workingUploadableContent.push(uploadableContentEntry);
        }
        dispatch(setUploadableBulkContent(workingUploadableContent));
        setCanLoadColumns(true);
    }

    useEffect(() => {
        if (canLoadColumns) {
            setColumns(prepareColumns);
        }
    }, [canLoadColumns]);

    useEffect(() => {
        if (connectedPlatforms.length > 0) {
            postProcessBulkContent();
        }
    }, [connectedPlatforms]);

    const getTargetPlatformsForRow = (platforms, platformTargetNames) => {
        if (typeof platforms !== 'string') {
            if (platforms === null) {
                return;
            }
            platforms = platforms.map((x) => x.name.trim()).join(",");
        }
        let targetPlatformsForRow = platforms.split(",");
        targetPlatformsForRow = Array.from(new Set(targetPlatformsForRow));
        platformTargetNames = platformTargetNames.split(",").map((x) => x.toLowerCase().trim());
        targetPlatformsForRow = targetPlatformsForRow.map((x) => x.toLowerCase().trim());
        let resolvedPlatforms = clone(connectedPlatforms.filter((platform) => targetPlatformsForRow.includes(platform.name.toLowerCase().trim())));
        resolvedPlatforms = resolvedPlatforms.map((x) => {
            x.connected = true;
            x.selected = true;
            return x;
        });
        let resolvedPlatformNames = resolvedPlatforms.map((x) => (x.name).toLowerCase().replace('_', ''));
        let unconnectedPlatforms = TemplatePlatforms.filter((x) => !resolvedPlatformNames.includes(x.name.toLowerCase().trim())
            && platformTargetNames.includes(x.name.toLowerCase().trim()));
        let finalTargetPlatforms = [...resolvedPlatforms, ...unconnectedPlatforms];
        return finalTargetPlatforms;
    }

    if (openFullScreen) {
        return <BulkContentPostPreviewFullscreen
            setOpenFullScreen={setOpenFullScreen}
            uploadableContent={uploadableContent}
            uploadingBulkContent={uploadingBulkContent}
            pickNewFile={pickNewFile}
            uploadBulkContent={uploadBulkContent}
            columns={columns}
            loadingPlatforms={loadingPlatforms}
            platformsLoadError={platformsLoadError}
            noPlatformConnected={noPlatformConnected}
            modalClose={modalClose}
            canPreview={canPreview}
            filePreviewErrorMessage={filePreviewErrorMessage}
            setOpenCreationSuccessDialog={setOpenCreationSuccessDialog}
            openCreationSuccessDialog={openCreationSuccessDialog}
        />
    }

    return (
        <div>
            {
                loadingPlatforms &&
                <Card>
                    <Card.Body>
                        <div
                            style={{
                                minHeight: 150,
                                paddingTop: 75,
                                paddingLeft: '25%'
                            }}>
                            <ProgressBar label={"Processing file"} animated now={45} />
                        </div>
                    </Card.Body>
                </Card>
            }
            {
                !loadingPlatforms && !platformsLoadError &&
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            padding: 10,
                        }}>
                        <div
                            style={{
                                flex: 1
                            }}
                        />
                        <Button
                            icon="close"
                            iconSize={24}
                            variant="empty"
                            onClick={() => {
                                modalClose();
                            }}
                        />
                    </div>
                    {
                        !loadingPlatforms && noPlatformConnected && canPreview &&
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: 400,
                                gap: 20,
                                fontSize: 18
                            }}>
                            <h4>No Connected Platforms found</h4>
                            <span>Connect at least one platform before uploading bulk content</span>
                            <Button
                                variant={'primary'}
                                onClick={() => {
                                    modalClose();
                                    navigate(RoutePaths.PLATFORMS);
                                }}>Connect Platforms
                            </Button>
                        </div>
                    }
                    {
                        columns.length > 0 &&
                        <AdvanceTableWrapper
                            columns={columns}
                            data={uploadableContent}
                            pagination
                            perPage={10}
                        >
                            {
                                <Card>
                                    <FalconCardHeader
                                        title="Posts to Create"
                                        endEl={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: 10
                                                }}>
                                                <Button
                                                    type="button"
                                                    icon="plus"
                                                    size="sm"
                                                    className="ms-2"
                                                    variant="falcon-default"
                                                    onClick={() => {
                                                        pickNewFile();
                                                    }}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: 1, alignItems: 'center'
                                                        }}>
                                                        <FontAwesomeIcon icon={'plus'} />
                                                        <span className="d-none d-sm-inline-block ms-1">Pick a New CSV File</span>
                                                    </div>
                                                </Button>
                                                <Button
                                                    disabled={uploadingBulkContent || uploadableContent.length < 4}
                                                    size="sm"
                                                    className="ms-2"
                                                    variant="falcon-primary"
                                                    onClick={() => {
                                                        uploadBulkContent();
                                                    }}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: 1, alignItems: 'center'
                                                        }}>
                                                        <FontAwesomeIcon icon={faPaperPlane} />
                                                        <span className="d-none d-sm-inline-block ms-1">{uploadingBulkContent ? "Scheduling Posts..." : "Schedule Posts"}</span>
                                                    </div>
                                                </Button>
                                                <Button
                                                    size="sm"
                                                    className="ms-2"
                                                    variant="falcon-primary"
                                                    onClick={() => {
                                                        setOpenFullScreen(true);
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: 1, alignItems: 'center'
                                                        }}>
                                                        <IoExpand size={20} />
                                                    </div>
                                                </Button>
                                            </div>
                                        }
                                    ><span className='fs--1'>Min number of rows must be 4</span></FalconCardHeader>
                                    <Card.Body className="p-0">
                                        <AdvanceTable
                                            table
                                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                                            rowClassName="align-middle white-space-nowrap"
                                            tableProps={{
                                                size: 'sm',
                                                striped: true,
                                                className: 'fs--1 mb-0 overflow-hidden'
                                            }}
                                        />
                                    </Card.Body>
                                    <Card.Footer>
                                        <AdvanceTableFooter
                                            rowCount={uploadableContent.length}
                                            table
                                            rowInfo
                                            navButtons
                                            rowsPerPageSelection
                                        />
                                    </Card.Footer>
                                </Card>
                            }

                        </AdvanceTableWrapper>
                    }
                    {
                        columns.length > 0 &&
                        <div
                            style={{
                                display: 'flex',
                                alignSelf: 'flex-end',
                                margin: 20,
                                gap: 30,
                                alignItems: 'center'
                            }}>
                            {
                                uploadableContent.length < 3 &&
                                <span style={{
                                    color: 'red'
                                }}>Oops! You must have a minimum of <b>three (3)</b> rows</span>
                            }
                        </div>
                    }
                </div >
            }
            {
                ((!loadingPlatforms && platformsLoadError) || !canPreview) &&
                <Card>
                    <Card.Body>
                        <div
                            style={{
                                height: 400,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 15,
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <FontAwesomeIcon icon={faInfoCircle} className={'fs-5 text-danger'} />
                                <span style={{ maxWidth: 400, textAlign: 'center' }} dangerouslySetInnerHTML={{
                                    __html: filePreviewErrorMessage
                                }}></span>
                                <Button
                                    className='mt-2'
                                    onClick={() => {
                                        modalClose();
                                    }}>CLOSE
                                </Button>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            }
            <Modal
                backdrop="static"
                show={openCreationSuccessDialog}
                onHide={() => setOpenCreationSuccessDialog(false)}
                onExit={() => setOpenCreationSuccessDialog(false)}
                centered={false}>
                <Modal.Header>
                    <Modal.Title>
                        Posts Scheduled
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Flex
                        direction={'column'}
                        className={'gap-3'}>
                        <span className='text-success'>{"Your posts have been successfully scheduled to the targeted platforms!"}</span>
                    </Flex>
                </Modal.Body>
                <Modal.Footer>
                    <Flex
                        className={'gap-1'}
                        alignItems={'center'}>
                        <Button
                            variant={'default'}
                            onClick={() => {
                                setOpenCreationSuccessDialog(false);
                            }}>Not Now
                        </Button>
                        <Button
                            variant={'warning'}
                            onClick={() => {
                                setOpenCreationSuccessDialog(false);
                                navigate(RoutePaths.POSTS, { replace: true });
                                window.location.reload(true); // This performs a hard refresh so new data can be fetched
                            }}>Go Home
                        </Button>
                    </Flex>
                </Modal.Footer>
            </Modal>
        </div>
    );
}