

import FalconCardHeader from 'components/common/FalconCardHeader'
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import APIService from 'http/api_service';
import React, { useContext, useState } from 'react'
import { Button, Card, Col, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { getActiveWorkSpace, setActiveWorkspace, setActiveWorkspacePlannerSettings } from 'redux/slices/workspaceslice';
import Divider from 'components/common/Divider';



const ContentPlannerCalendarSettings = () => {
    const dispatch = useDispatch();
    const workSpace = useSelector(getActiveWorkSpace);
    const { config } = useContext(AppContext);



    const [savingChanges, setSavingChanges] = useState(false);
    const [changesSaved, setChangesSaved] = useState(false);
    const [requestBody, setRequestBody] = useState({
        showWeekends: workSpace?.moreOptionsSettings?.showWeekends,
        showWeeknumbers: workSpace?.moreOptionsSettings?.showWeeknumbers,
        calendarStartDay: workSpace?.moreOptionsSettings?.calendarStartDay,
        // disableWeekend: workSpace?.schedulingAndCalendarSettings?.disableWeekend,
        // applyToAllworkspaces: workSpace?.schedulingAndCalendarSettings?.applyToAllworkspaces,
        // displayWeeknumber: workSpace?.schedulingAndCalendarSettings?.displayWeeknumber,

    })


    //fetch workspace function handler
    const fetchWorkspaceHandler = () => {
        APIService.fetchWorkSpace(workSpace?._id, (response, error) => {
            if (error) {
                return;
            }
            if (response) {
                const latestWorkspaceData = response.data;
                dispatch(setActiveWorkspace({ ...latestWorkspaceData }));
            }
        });
    }


    //handler function to update workspace content planner settings.
    const updateWorkspaceContentPlannerSettingsHandler = (newRequestBody) => {
        setSavingChanges(true);
        setChangesSaved(false);
        APIService.updateWorkspaceMoreOptionsSettings(workSpace?._id, newRequestBody, async (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                setSavingChanges(false);
                return;
            }
            const { message, data } = response;
            APIService.fetchWorkSpace(workSpace?._id, (response, error) => {
                if (error) {
                    return;
                }
                if (response) {
                    let responseData = response['data'];
                    let workSpace = responseData['workspace'];
                    workSpace['role'] = responseData['role'];
                    workSpace['value'] = workSpace['name'];
                    workSpace['title'] = responseData['title'];
                    workSpace['label'] = workSpace['name'];
                    workSpace['workspace_owner'] = responseData['workspace_owner'];
                    dispatch(setActiveWorkspace({ ...workSpace }));
                    setSavingChanges(false);
                    setChangesSaved(true);
                }
            });

        })
    }


    const applyToAllWorkspaceSettingsHandler = () => {
        setSavingChanges(true);
        setChangesSaved(false);
        APIService.applyToAllWorkspaces(workSpace?._id, requestBody, "more_options_settings", async (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                setSavingChanges(false);
                return;
            }
            const { message, data } = response;
            APIService.fetchWorkSpace(workSpace?._id, (response, error) => {
                if (error) {
                    return;
                }
                if (response) {
                    let responseData = response['data'];
                    let workSpace = responseData['workspace'];
                    workSpace['role'] = responseData['role'];
                    workSpace['value'] = workSpace['name'];
                    workSpace['title'] = responseData['title'];
                    workSpace['label'] = workSpace['name'];
                    workSpace['workspace_owner'] = responseData['workspace_owner'];
                    dispatch(setActiveWorkspace({ ...workSpace }));
                    setSavingChanges(false);
                    setChangesSaved(true);
                    toast.success(message, { theme: "colored" })
                }
            });
        })
    }


    return (
        <Card id="planner_calendar_setting" className="mb-3">
            <FalconCardHeader title="More options" endEl={
                <>
                    {
                        savingChanges &&
                        <Flex className={'gap-2'}>
                            <Spinner
                                animation='border'
                                size={'sm'}
                                variant={'primary'}
                            />
                            <p
                                style={{
                                    fontSize: 12,
                                    color: '#B0BEC5'
                                }}
                            >
                                Saving changes...
                            </p>
                        </Flex>
                    }
                    {
                        changesSaved &&
                        <p
                            style={{
                                fontSize: 12,
                                color: '#9CCC65',
                            }}
                        >
                            Changes Saved
                        </p>
                    }
                </>
            } />
            <Card.Body className="bg-light">
                <Form.Group>
                    <Col>
                        <>
                        <FloatingLabel controlId="floatingSelect" label="Start week on" style={{background: config.isDark ? "#121e2d" : "#f1f3f4"}}>
                                <Form.Select value={requestBody?.calendarStartDay ?? "Sunday"} size="sm" aria-label="Floating label select example"
                                onChange={(e) => {
                                    const newRequestBody = { ...requestBody, calendarStartDay: e.target.value};
                                    setRequestBody(newRequestBody);
                                    updateWorkspaceContentPlannerSettingsHandler(newRequestBody);
                                }}
                                disabled={savingChanges}
                                >
                                <option value="Sunday">Sunday</option>
                                <option value="Monday">Monday</option>
                                <option value="Saturday">Saturday</option>
                                </Form.Select>
                            </FloatingLabel>
                        </>
                        <br />
                        <>
                            <Form.Check
                                id='show_weekends'
                                checked={requestBody?.showWeekends ?? true}
                                label="Show weekends"
                                onChange={(e) => {
                                    const newRequestBody = { ...requestBody, showWeekends: e.target.checked};
                                    setRequestBody(newRequestBody);
                                    updateWorkspaceContentPlannerSettingsHandler(newRequestBody);
                                }}
                                disabled={savingChanges}
                            />
                        </>
                        <>
                            <Form.Check
                                id={"display-week-numbers-of-planner"}
                                label={"Show week numbers"}
                                checked={requestBody?.showWeeknumbers ?? true}
                                onChange={(e) => {
                                    const newRequestBody = { ...requestBody, showWeeknumbers: e.target.checked };
                                    setRequestBody(newRequestBody);
                                    updateWorkspaceContentPlannerSettingsHandler(newRequestBody);
                                }}
                                disabled={savingChanges}
                            />
                        </>
                        <Divider />
                        <Flex style={{ width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                            <p>Apply this change to all workspaces</p>
                            <Button onClick={applyToAllWorkspaceSettingsHandler}
                                disabled={savingChanges}
                            >{"Apply"}</Button>
                        </Flex>
                    </Col>
                </Form.Group>
            </Card.Body>
        </Card>
    )
}

export default ContentPlannerCalendarSettings