import {
    faFileCsv,
    faFileExcel,
    faFilePdf
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import { saveAs } from "file-saver";
import APIService from 'http/api_service';
import csvDownload from "json-to-csv-export";
import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    getCanvasComplete,
    getCanvasData,
    getDatePeriod,
    getSelectedPageForAnalytics,
    setCanvasComplete,
    setPdfClicked,
    setOpenExportModal,
    setPDFLoading,
    getPDFLoading,
} from "redux/slices/analytics_slice";
import { isFreeUser } from "utils/user_utils";
import { getLoggedInUser } from "redux/slices/user_slice";
import * as XLSX from "xlsx";
import { PlatformIdentifier } from "../../../constants";
import EmailExportField from "./EmailExportField";
import { toast } from "react-toastify";

const ReportExportHeader = forwardRef((props, ref) => {
    const {
        identifier,
        data,
        audienceData,
    } = props;

    const dispatch = useDispatch();
    const loggedInUser = useSelector(getLoggedInUser);
    const datePeriod = useSelector(getDatePeriod);
    const selectedPage = useSelector(getSelectedPageForAnalytics);
    const canvasData = useSelector(getCanvasData);
    const isCanvasComplete = useSelector(getCanvasComplete);
    const pdfLoading = useSelector(getPDFLoading);
    const [emailExport, setEmailExport] = useState(false);
    const [initialData, setInitialData] = useState([]);
    const [audience, setAudience] = useState([]);
    const [reportTitle] = useState(`${identifier}-report`);
    const DEFAULT_LOGO = `https://api.postly.ai/v1/logo`;
    const DEFAULT_WORKSPACE_NAME = `POSTLY`;

    const [exportItems] = useState([
        {
            icon: faFileCsv,
            type: "csv",
            name: "CSV"
        },
        {
            icon: faFileExcel,
            type: "excel",
            name: "Excel"
        },
        {
            icon: faFilePdf,
            type: "pdf",
            name: "PDF"
        }
    ]);

    const handleExportReport = async (exportType) => {
        let columnHeader;
        if (identifier === PlatformIdentifier.INSTAGRAM) {
            columnHeader = ["impressions", "reach", "visits", "email_clicks", "phone_call_clicks", "website_clicks"];
        } else if (identifier === PlatformIdentifier.LINKED_IN) {
            columnHeader = ["followers", "impressions", "comments", "likes", "shares"];
        } else if (identifier === PlatformIdentifier.FACEBOOK) {
            columnHeader = ["page_likes", "impressions", "new_likes", "visits"];
        } else if (identifier === PlatformIdentifier.YOUTUBE) {
            columnHeader = ["views", "comments", "likes", "unlikes", "average_watch_time", "average_view_duration", "new_subscribers", "lost_subscribers", "impressions", "click_through_rate"];
        };

        let columnData = [];
        if (initialData?.length && Array.isArray(initialData)) {
            for (let item of initialData) {
                let maxItem = columnHeader[0];
                for (let i = 0; i < item[maxItem]?.length; i++) {
                    let _item = item[maxItem][i];
                    let transformedItem = {};
                    for (let key of columnHeader) {
                        transformedItem[key] = item[key][i]?.value;
                    }
                    let date = _item["end_time"] ?? _item["date"];
                    transformedItem["date"] = moment(date).format("MM-DD-YYYY");
                    columnData.push(transformedItem);
                }
            };
            columnHeader.push("date");

            if (exportType === "csv") {
                const dataToConvert = {
                    data: columnData,
                    filename: reportTitle,
                    delimiter: ",",
                    headers: columnHeader,
                };
                csvDownload(dataToConvert);
            }

            if (exportType === "excel") {
                const fileType =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const ws = XLSX.utils.json_to_sheet(columnData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, {
                    bookType: "xlsx",
                    type: "array",
                });
                const data = new Blob([excelBuffer], { type: fileType });
                saveAs(data, `${reportTitle}.xlsx`);
            }

            if (exportType === "pdf") {
                dispatch(setOpenExportModal(true));
            }
        }
    }

    useEffect(() => {
        if (isCanvasComplete && canvasData?.length) {
            let { since, until } = datePeriod;
            since = moment(since).format('MMMM D, YYYY');
            until = moment(until).format('MMMM D, YYYY');
            const report_logo = isFreeUser ? DEFAULT_LOGO : loggedInUser?.brand_logo;
            const requestBody = {
                startDate: since,
                endDate: until,
                identifier,
                logo: report_logo,
                free_user: isFreeUser ? true : false,
                brand_name: loggedInUser?.brand_name ?? null,
                data: [...canvasData],
                ...selectedPage
            };
            APIService.generateReport(requestBody, (response, error) => {
                if (error) {
                    dispatch(setPdfClicked(false));
                    dispatch(setPDFLoading(false));
                    return;
                }
                if (response) {
                    const { pdfURL, fileName } = response?.data ?? {};
                    const downloadLink = document.createElement("a");
                    downloadLink.href = pdfURL;
                    downloadLink.download = fileName ?? "report.pdf";

                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    document.body.removeChild(downloadLink);
                    toast.success("PDF report downloaded", { theme: "colored" });
                    dispatch(setPdfClicked(false));
                    dispatch(setPDFLoading(false));
                }
            })
        }
        return () => canvasData?.length && dispatch(setCanvasComplete(false));
    }, [canvasData, datePeriod, identifier, isCanvasComplete, selectedPage]);

    useEffect(() => {
        setInitialData(data);
        setAudience(audienceData);
    }, [data, audienceData]);

    return (
        <>
            {emailExport &&
                <EmailExportField
                    show={emailExport}
                    onHide={() => setEmailExport(false)}
                />}
            <Row>
                <Col className="mb-0">
                    <Flex justifyContent={"end"}>
                        <Dropdown>
                            <Dropdown.Toggle
                                id="dropdown-basic"
                                style={{
                                    backgroundColor: "#1e63fe",
                                    minHeight: 35
                                }}>
                                Export
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {exportItems.map(({ name, icon, type }) => (
                                    <Dropdown.Item onClick={() => {
                                        handleExportReport(type);
                                    }}>
                                        <Flex justifyContent="between" alignItems="center">
                                            <span>{name}</span>
                                            <FontAwesomeIcon icon={icon} />
                                        </Flex>
                                    </Dropdown.Item>
                                ))}

                                {/* <Dropdown.Item onClick={() => {
                                    setEmailExport(true);
                                }}>
                                    <Flex justifyContent="between" alignItems="center">
                                        <span>Email</span>
                                        <FontAwesomeIcon icon={faMessage} />
                                    </Flex>
                                </Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Flex>
                </Col>
            </Row >
        </>
    )
})

export default ReportExportHeader;