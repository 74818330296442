import React, { useEffect, useState } from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import Flex from 'components/common/Flex';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RoutePaths, Strings } from '../constants';
import APIService from 'http/api_service';
import { cachePosts, setSelectedPostType } from 'redux/slices/postslice';
import { toast } from 'react-toastify';
import {
    setFlipToChildPages,
    setSwitchMode
} from 'redux/slices/authSlice';
import { Button, Modal } from 'react-bootstrap';
import PaymentFailedAnimation from '../assets/animations/payment_failed.json';
import Lottie from 'lottie-react';
import {
    getActiveWorkSpace,
    setActiveWorkspace,
    setCachedWorkspaces
} from 'redux/slices/workspaceslice';
import {
    getAltUser,
    getAltUsers,
    getLoggedInUser,
    setAltUser,
    setAltUsers,
    setLoggedInUser
} from 'redux/slices/user_slice';
import { useSelector } from 'react-redux';
import useOrganizationStore from 'state/organization_store';
import useInitializeOrganizations from 'components/app/organization-and-workspace-setup/hooks/useInitializeOrganizations';

export const attachOccupationToWorkspace = (loggedInUser, firstWorkspace) => {
    if (loggedInUser) {
        if (loggedInUser.occupation) {
            if (!firstWorkspace.title) {
                firstWorkspace.title = loggedInUser.occupation;
            }
        }
    }
}

const SplashLayout = () => {

    const altableUser = useSelector(getAltUser);
    const altableUsers = useSelector(getAltUsers);
    const loggedInUser = useSelector(getLoggedInUser);
    const activeWorkspace = useSelector(getActiveWorkSpace);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pausedAccount, setPausedAccount] = useState();
    const setAllWorkspaces = useOrganizationStore(state => state.setAllWorkspaces);
    const setActiveOrganization = useOrganizationStore(state => state.setActiveOrganization);
    const { initializeOrganizations } = useInitializeOrganizations();

    useEffect(() => {
        setActiveOrganization(activeWorkspace?.organization);
    }, [activeWorkspace]);

    const reconcileAltUsersAndReload = (users) => {
        dispatch(setLoggedInUser({ ...altableUser }));
        dispatch(setAltUsers([...users]));
        dispatch(cachePosts([]));
        dispatch(setSwitchMode(false));
        dispatch(setFlipToChildPages(false));
        dispatch(setAltUser(null));
        window.location.reload();
    }

    const checkAndOrganizeSwitchableAccounts = () => {
        localStorage.clear();
        let newAltableUsers = [...(altableUsers ?? [])];
        let existingAltableUser = newAltableUsers.find(x => x._id === altableUser._id);
        if (!existingAltableUser) {
            newAltableUsers.unshift(altableUser);
            reconcileAltUsersAndReload(newAltableUsers)
        } else {
            reconcileAltUsersAndReload(altableUsers)
        }
    }

    const initApp = () => {
        initializeOrganizations();
        const forceRefreshDataRequestAvailable = localStorage.getItem(Strings.REFRESH_USER_AND_WORKSPACE_DATA);
        const activeWorkSpaceIsInvalid = !activeWorkspace || Object.keys(activeWorkspace).length === 0;
        if (!activeWorkSpaceIsInvalid && !forceRefreshDataRequestAvailable) {
            if (altableUser) {
                if (altableUser._id !== loggedInUser._id) {
                    checkAndOrganizeSwitchableAccounts();
                    return;
                }
            }
            APIService.fetchWorkSpace(activeWorkspace['_id'], (response, error) => {
                if (error) {
                    if (error.toLowerCase().includes('belong here')) {
                        dispatch(setActiveWorkspace(null));
                        navigate(RoutePaths.NOT_FOUND);
                    } else {
                        if (error.toLowerCase().includes("Workspace not found".toLowerCase())) {
                            dispatch(setActiveWorkspace(null));
                            navigate(RoutePaths.DEFAULT);
                            window.location.reload();
                        }
                    }
                    return;
                }
                if (response) {
                    let responseData = response['data'];
                    let workSpace = responseData['workspace'];
                    workSpace['role'] = responseData['role'];
                    workSpace['value'] = workSpace['name'];
                    workSpace['title'] = responseData['title'];
                    workSpace['label'] = workSpace['name'];
                    workSpace['workspace_owner'] = responseData['workspace_owner'];
                    attachOccupationToWorkspace(loggedInUser, workSpace);
                    dispatch(setActiveWorkspace({ ...workSpace }));
                }
            });
            fetchUserWorkSpaces();
        } else {
            if (altableUser) {
                if (altableUser._id !== loggedInUser._id) {
                    checkAndOrganizeSwitchableAccounts();
                    return;
                }
            }
            fetchUserWorkSpaces();
        }
    }

    const fetchUserWorkSpaces = () => {
        const forceRefreshDataRequestAvailable = localStorage.getItem(Strings.REFRESH_USER_AND_WORKSPACE_DATA);
        APIService.fetchLatestMe((latestMeResponse, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                if (error.toLowerCase().includes('Invalid credentials provided'.toLowerCase())) {
                    window.localStorage.clear();
                    setTimeout(() => {
                        navigate(RoutePaths.LOGIN);
                    }, 100);
                }
                return;
            }
            let latestUserData = latestMeResponse?.data;
            if (!latestUserData) {
                localStorage.clear();
                dispatch({ type: 'RESET' });
                return;
            }
            dispatch(setLoggedInUser({ ...latestUserData }));
            APIService.checkUserWorkSpaces(loggedInUser['api_key'], async (userStateResponse, userStateError) => {
                if (userStateError) {
                    if (!latestUserData.paused) {
                        toast.error(userStateError, { theme: 'colored' });
                    }
                    localStorage.clear();
                    setTimeout(() => {
                        if (latestUserData.paused) {
                            setPausedAccount({ data: latestUserData, message: userStateError });
                        } else {
                            window.location.reload();
                        }
                    }, 500);
                    return;
                }
                let workSpaceData = userStateResponse.data;
                if (!workSpaceData) {
                    dispatch(setLoggedInUser({
                        ...loggedInUser,
                        has_workspaces: false,
                    }));
                    navigate(RoutePaths.ORGS_AND_WORKSPACES);
                } else {
                    APIService.fetchUserWorkSpaces((response, error) => {
                        if (error) {
                            toast.error(error, {
                                theme: 'colored'
                            });
                            return;
                        }
                        if (response) {
                            let responseData = response['data'].filter(x => x.workspace);
                            if (responseData.length === 0) {
                                navigate(RoutePaths.ORGS_AND_WORKSPACES);
                                return;
                            }
                            responseData = responseData.map((e) => {
                                let workSpace = e['workspace'];
                                workSpace['role'] = e['role'];
                                workSpace['title'] = e['title'];
                                workSpace['value'] = workSpace['name'];
                                workSpace['label'] = workSpace['name'];
                                return workSpace;
                            });
                            let uniqueWorkSpaces = [];
                            for (let workSpaceEntry of responseData) {
                                let existingWorkSpaceEntry = uniqueWorkSpaces.find((x) => x['name'] === workSpaceEntry['name']);
                                if (!existingWorkSpaceEntry) {
                                    uniqueWorkSpaces.push(workSpaceEntry);
                                }
                            }
                            let lastViewedWorkspace = loggedInUser.last_viewed_workspace;
                            let firstActive;
                            if (lastViewedWorkspace) {
                                firstActive = responseData.find(x => x._id === lastViewedWorkspace);
                            }
                            if (firstActive) {
                                if (firstActive.archived) {
                                    firstActive = responseData.find(x => !x.archived);
                                }
                            } else {
                                firstActive = responseData.find(x => !x.archived);
                            }
                            if (!firstActive) {
                                navigate(RoutePaths.ORGS_AND_WORKSPACES);
                                return;
                            }
                            dispatch(setCachedWorkspaces(uniqueWorkSpaces));
                            setAllWorkspaces(uniqueWorkSpaces);
                            dispatch(setSelectedPostType("all"));
                            if (!activeWorkspace && !forceRefreshDataRequestAvailable) {
                                if (!firstActive || Object.keys(firstActive)?.length === 0) {
                                    firstActive = uniqueWorkSpaces[0];
                                }
                                dispatch(setActiveWorkspace(firstActive));
                                APIService.updateLastViewedWorkSpace(firstActive._id, (_res, _err) => {
                                    window.location.reload();
                                });
                            } else {
                                if (activeWorkspace.archived) {
                                    dispatch(setActiveWorkspace(null));
                                    window.location.reload();
                                    return;
                                }
                                let _activeWorkspace = activeWorkspace
                                if (!_activeWorkspace || Object.keys(_activeWorkspace)?.length === 0) {
                                    _activeWorkspace = uniqueWorkSpaces[0];
                                }
                                dispatch(setActiveWorkspace(_activeWorkspace));
                                APIService.updateLastViewedWorkSpace(_activeWorkspace._id, (res, err) => {
                                    const freshLogin = localStorage.getItem("_postly_fresh_login");
                                    if (forceRefreshDataRequestAvailable) {
                                        dispatch(setCachedWorkspaces([]));
                                        dispatch(setActiveWorkspace(null));
                                        localStorage.removeItem(Strings.REFRESH_USER_AND_WORKSPACE_DATA);
                                        window.location.reload();
                                    }
                                    else if (!freshLogin) {
                                        localStorage.setItem("_postly_fresh_login", true);
                                        window.location.reload();
                                    } else {
                                        localStorage.removeItem("_postly_fresh_login");
                                        navigate(RoutePaths.POSTS);
                                    }
                                })
                            }
                        }
                    });
                }
            });
        });
    }

    useEffect(() => {
        localStorage.setItem("visited_splash", true);
        if (loggedInUser) {
            let altableUsersCopy = [...(altableUsers ?? [])];
            let user = altableUsersCopy.find(x => x._id === loggedInUser._id);
            if (!user) {
                altableUsersCopy.unshift(loggedInUser);
                dispatch(setAltUsers([...altableUsersCopy]));
            }
            initApp();
        } else {
            navigate(RoutePaths.LOGIN);
        }
    }, []);

    return (
        <Flex className="min-vh-100" alignItems={'center'} justifyContent={'center'}>
            <Flex alignItems={'center'} justifyContent={'center'}>
                <PulseLoader color="#2c7be5" />
            </Flex>
            <Modal
                backdrop={'static'}
                show={pausedAccount}
                onHide={() => setPausedAccount(null)}
                onExit={() => {
                    setPausedAccount(null);
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                    <Modal.Title>Account Temporarily Paused</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Flex direction={'column'} className={'p-5'} alignItems={'center'} justifyContent={'center'}>
                        <Lottie
                            animationData={PaymentFailedAnimation}
                            loop={false}
                        />
                        <p
                            style={{
                                textAlign: 'center'
                            }}>{pausedAccount?.message}
                        </p>
                        <Button
                            variant='falcon-warning'
                            onClick={() => {
                                window.location = pausedAccount?.data?.active_plan?.update_url
                            }}>Update Payment Information
                        </Button>
                    </Flex>
                </Modal.Body>
            </Modal>
        </Flex>
    );
};

export default SplashLayout;
