import { faFemale, faMale } from '@fortawesome/free-solid-svg-icons';
import APIService from 'http/api_service';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getDatePeriod,
  getOpenExportModal,
  getPageId,
  setAudienceData,
  setDatePeriod,
  setInsightData,
  setOpenExportModal,
  setOverviewData
} from 'redux/slices/analytics_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { convertToTitleFormat } from 'utils/platform_utils';
import DateSelection from '../../DateSelection/DateSelection';
import FacebookOverview from '../../Overview';
import PlatformHeader from '../../PlatformHeader';
import ExportReportHeader from '../../ReportExportHeader';
import ReportExportModal from "../../ReportExportPreview";
import Audience from '../../audience/Audience';
import TopPosts from '../../top-posts/TopPosts';
import Flex from 'components/common/Flex';

const FacebookAnalyticsPage = (props) => {
  const pageId = useSelector(getPageId);
  const dispatch = useDispatch();
  const { account, platform } = props;
  const workSpace = useSelector(getActiveWorkSpace);
  const datePeriod = useSelector(getDatePeriod);
  const openExportModal = useSelector(getOpenExportModal);
  const [regionLoading, setRegionLoading] = useState(false);
  const [postLoading, setPostsLoading] = useState(false);
  const [regionData, setRegionData] = useState([]);
  const [isPageInsightLoading, setPageInsightLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [pages, setPages] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [pageInsightData, setPageInsightData] = useState([]);
  const [regionTempData, setRegionTempData] = useState([]);
  const [pageInsightValue, setPageInsightValue] = useState({});
  const [pageProfileStats, setPageProfileStats] = useState([]);
  const [pageLikes, setPageLikes] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [audienceReport, setAudienceReport] = useState([]);
  const [isLoadingProfileStats, setIsLoadingProfileStats] = useState(false); 
  const [canvasData, setCanvasData] = useState();
  const demographicErrorMessage = `You need at least 100 followers to view this report`;

  useEffect(() => {
    let { since, until } = datePeriod;
    if (!(since && until)) {
      since = moment().utc().subtract(28, "days").startOf("day").format();
      until = moment().utc().endOf("day").format();
      dispatch(setDatePeriod({ since, until }));
    }
  }, [datePeriod]);

  useEffect(() => {
    APIService.fetchPagesAndGroups(workSpace, account.identifier, account.user_id, (response, error) => {
      if (error) {
        toast.error(error, { theme: "colored" });
        return;
      }
      const { data } = response;
      let facebookPages = [];
      for (let page of data) {
        if (page?.is_page && page?.connected) {
          facebookPages.push(page);
        }
      }
      setPages(facebookPages);
    });
  }, [account]);

  const fetchTopPosts = () => {
    setPostsLoading(true);
    let requestBody = {
      page_id: pageId,
      post_type: "feed",
    };
    APIService.fetchTopPosts(workSpace._id, account.user_id, requestBody, platform.identifier, (response, error) => {
        if (error) {
          setPostsLoading(false);
          // toast.error(error, { theme: "colored" }); // Removed this toast to avoid confusion
          return;
        }
        setPostsLoading(false);
        const { data } = response;
        const post = data["post"] ?? [];
        setPosts(post);
      }
    );
  };

  useEffect(() => {
    if (!pageId) {
      return;
    }
    fetchTopPosts();
  }, [pageId]);

  const fetchUsersByRegion = () => {
    setRegionLoading(true);
    dispatch(setAudienceData([]));
    const requestBody = { page_id: pageId };
    APIService.fetchUsersByCountry(
      workSpace._id, account.user_id, requestBody, platform.identifier, (response, error) => {
        if (error) {
          setRegionLoading(false);
          return;
        }
        setRegionLoading(false);
        const responseData = response?.data ?? [];
        if (!responseData || responseData?.length === 0) {
          return;
        };
        const [responseItem] = responseData;
        const insightKeys = ["audience_country", "audience_city"];
        const gender = responseItem["audience_gender"];
        const pageDemographicData = insightKeys.map((key) => {
          const insightData = responseItem[key];
          setAudienceReport((prev) => {
            if (!Array.isArray(prev)) {
              return [{ title: key, value: insightData }];
            }
            const existingIndex = prev.findIndex((item) => item.title === key);
            if (existingIndex !== -1) {
              return prev;
            }
            return [...prev, { title: key, value: insightData }];
          });

          const newData = Object.entries(insightData || {}).reduce(
            (acc, [key, value]) => {
              acc.key.push(key);
              acc.data.push(value);
              return acc;
            },
            { key: [], data: [] }
          );
          return {
            title: convertToTitleFormat(key),
            value: Object.values(newData),
          };
        });

        const followersByGender = [
          {
            icon: faMale,
            label: "Men",
            color: "primary",
            value: gender.male,
          },
          {
            icon: faFemale,
            label: "Women",
            color: "success",
            value: gender.female,
          },
        ];
        setRegionData(pageDemographicData);
        setGenderData(followersByGender);
        setRegionTempData(responseData);
        dispatch(setAudienceData(pageDemographicData));
      }
    );
  };

  useEffect(() => {
    if (!pageId) {
      return;
    }
    fetchUsersByRegion();
  }, [pageId]);

  const fetchPageInsights = () => {
    dispatch(setInsightData([]));
    let { since, until } = datePeriod;
    const requestBody = {
      since: since,
      until: until,
      page_id: pageId,
    };
    setPageInsightLoading(true);
    APIService.fetchPageInsights(workSpace._id, account.user_id, requestBody, platform.identifier,
      (response, error) => {
        if (error) {
          setPageInsightLoading(false);
          return;
        }
        setPageInsightLoading(false);
        const responseData = response["data"][0] ?? [];
        if (responseData) {
          setReportData([responseData]);
          const insightKeys = [
            "page_likes",
            "impressions",
            "new_likes",
            "visits",
          ];
          let totalValues = {};
          let pageInsightValue = {};
          const pageInsightValues = insightKeys.map((key) => {
            if (responseData?.hasOwnProperty(key)) {
              pageInsightValue[key] = responseData[key];
              let values = pageInsightValue[key]?.length ? pageInsightValue[key] : [];
              values = values[values.length - 2]['value'];
              totalValues[key] = values;
              let getPageLikes = pageInsightValue['page_likes'];
              const valuesArray = getPageLikes.map(obj => obj.value);
              const maxValue = Math.max(...valuesArray);
              setPageLikes(maxValue);
            }
            else {
              pageInsightValue[key] = [];
            };

            return {
              title: convertToTitleFormat(key),
              value: pageInsightValue[key]
            };
          });
          setPageInsightValue({ ...totalValues });
          setPageInsightData(pageInsightValues);
          dispatch(setInsightData(pageInsightValues));
        }
      }
    );
  };

  useEffect(() => {
    if (!pageId) {
      return;
    }
    fetchPageInsights();
  }, [pageId, datePeriod]);

  const fetchPageProfileStats = () => {
    dispatch(setOverviewData([]));
    setIsLoadingProfileStats(true); 
    APIService.fetchPageProfileOverview(workSpace._id, account.user_id, { page_id: pageId },
      platform.identifier, (response, error) => {
        if (error) {
          setIsLoadingProfileStats(false); 
          const msg = error?.message; 
          return;
        }
        if(response?.data == null) { 
          console.log("No data found"); 
        }
        const responseData = response?.data ?? {};
        let profileFields = ['followers'];
        let profileData = profileFields.map((key) => ({
          title: convertToTitleFormat(key),
          value: responseData[key]?.value,
          description: responseData[key]?.description
        }));
        console.log("🚀 ~ profileData ~ profileData:", profileData)
        setPageProfileStats(profileData);
        setIsLoadingProfileStats(false);
        dispatch(setOverviewData(profileData));
      });
  }

  useEffect(() => {
    if (!pageId) {
      return;
    }
    fetchPageProfileStats();
  }, [pageId]);


  return (
    <>
      <div>
        <PlatformHeader pages={pages} platform={platform} account={account} />
        <Row className="g-3 mb-3">
          <Col xxl={8}>
            <DateSelection />
          </Col>

          <Col lg={12} xxl={12}>
            <ExportReportHeader
              identifier={platform.identifier}
              data={reportData}
              canvasData={canvasData}
              account={account}
            />
          </Col>

          {pageProfileStats?.length > 0 &&
            <Row>
              <h5 className="mb-3 mt-2">Overview</h5>
              {pageProfileStats?.map((stats) => (
                <Flex gap={3} wrap={"wrap"}>
                  <FacebookOverview title={stats?.title} value={stats?.value} isLoadingProfileStats={isLoadingProfileStats} statDescription={stats?.description} />
                </Flex>
              ))}
            </Row>
          }

          <Col xxl={12} md={12} className="mt-5">
            <Audience
              values={pageInsightValue}
              chartData={pageInsightData}
              loading={isPageInsightLoading}
              identifier={platform.identifier}
              className="mb-3"
            />
          </Col>

          {/* Comment this code due to deprecations */}
          {/* <Col md={12} xxl={4}>
            <FollowersByGender
              title="Followers By Gender"
              data={genderData}
              regionLoading={regionLoading}
              emptyDataMessage={demographicErrorMessage}
              tempData={regionTempData}
            />
          </Col> */}

          {/* {!regionLoading &&
            regionData.map(({ title, value }) => {
              return (
                <Col md={12} xxl={4} className="mb-4">
                  <UsersByCountry
                    title={title}
                    chartData={value}
                    loading={regionLoading}
                    emptyDataMessage={demographicErrorMessage}
                    reportData={audienceReport}
                  />
                </Col>
              )
            })} */}

          <Col lg={12} xxl={8}>
            <TopPosts
              tableData={posts}
              loading={postLoading}
              identifier={platform.identifier}
            />
          </Col>
        </Row>
      </div>

      {openExportModal &&
        <ReportExportModal
          show={openExportModal}
          onHide={() => dispatch(setOpenExportModal(false))}
        />}
    </>
  );
};

export default FacebookAnalyticsPage;
