import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";

const EmergencyNotice = (props) => {
    const {
        notice = `You may experience issues with accessing your dashboard. We are currently working on fixing the issue. Thank you for your patience.`
    } = props;
    return (
        <Alert variant="warning" className="text-center mt-2">
            <strong className="fs--1">
                <FontAwesomeIcon
                    icon="exclamation-triangle"
                    className="me-1" />
                <span className="fs--1">
                    {notice}
                </span>
            </strong>
        </Alert>
    );
};

export default EmergencyNotice;