/* eslint-disable no-use-before-define */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import BasicECharts from 'components/common/BasicEChart';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getReturningUsers } from 'redux/slices/admin_slice';
import { useSelector } from 'react-redux';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const getOptions = (data, label) => ({
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    formatter: '{b0} : {c0}',
    transitionDuration: 0,
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1
  },
  xAxis: {
    type: 'category',
    data: label,
    boundaryGap: false,
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  yAxis: {
    type: 'value',
    splitLine: { show: false },
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  series: [
    {
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        borderRadius: 10
      },
      barWidth: '5px',
      itemStyle: {
        borderRadius: 10,
        color: getColor('primary')
      },
      data,
      z: 10
    }
  ],
  grid: { right: 5, left: 10, top: 0, bottom: 0 }
});

const ReturningUsers = ({ data, width, amountClassName }) => {
  const returningUsers = useSelector(getReturningUsers)
  const [weeklyReturningData, setWeeklyReturningData] = useState({ data: [0, 0, 0, 0, 0, 0, 0], label: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] })
  const [totalReturningUsers, setTotalReturningUsers] = useState(0)
  const [filterOptions, setFilterOptions] = useState(['daily', 'every_3_days', 'every_7_days', 'every_30_days', 'every_60_days', 'every_90_days', 'every_180_days', 'every_365_days']);
  const [selectedOption, setSelectedOption] = useState(0);
  const fallBackValues = [
    { count: 0, day: "Sunday" },
    { count: 0, day: "Monday" },
    { count: 0, day: "Tuesday" },
    { count: 0, day: "Wednesday" },
    { count: 0, day: "Thursday" },
    { count: 0, day: "Friday" },
    { count: 0, day: "Saturday" }
  ]

  function reorderArrayByToday(arr, ignore = false) {
    if (ignore) return arr;
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const todayIndex = new Date().getDay();
    const todayDay = daysOfWeek[todayIndex];
    if (!todayDay) return arr;
    const index = arr?.findIndex(item => item?.day === todayDay);
    const beforeToday = arr.slice(index + 1);
    const fromToday = arr.slice(0, index + 1);
    return beforeToday.concat(fromToday);
  }

  function formatNumber(num) {
    // if (num >= 1000) {
    //     return (num / 1000).toFixed(1) + 'k';
    // }
    return num?.toString();
  }

  const convertSnakeCaseToSentence = (str) => {
    return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }

  const constructWeeklyReturningUsersData = () => {
    let _data = [];
    let _label = [];
    let index = parseInt(selectedOption)
    let indexValue = filterOptions[index]
    let _returningUser = returningUsers[indexValue] ? reorderArrayByToday(returningUsers[indexValue]) : reorderArrayByToday(fallBackValues, true)
    for (const _returningUser of _returningUser) {
      _data.push(_returningUser.count)
      _label.push(_returningUser?.day)
    }
    setWeeklyReturningData({
      data: _data,
      label: _label
    })
    const total = _data.reduce((a, b) => a + b, 0)
    setTotalReturningUsers(formatNumber(total))
  }

  useEffect(() => {
    if (!returningUsers) return;
    constructWeeklyReturningUsersData();
  }, [returningUsers, selectedOption])

  return (
    <Card className="h-md-100">
      {/* <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">
          Returning users
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>Calculated according to last week's sales</Tooltip>
            }
          >
            <span>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>
        </h6>
      </Card.Header> */}
      <FalconCardHeader
        title="Returning users"
        titleTag="h6"
        className="pb-0"
        endEl={
          <Flex>
            <Form.Select
              size="sm"
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e.target.value)
              }}
              className="me-2"
            >
              {filterOptions.map((entry, index) => (
                <option value={index} key={entry}>
                  {convertSnakeCaseToSentence(entry)}
                </option>
              ))}
            </Form.Select>
            {/* <CardDropdown /> */}
          </Flex>
        }
      />

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        <div>
          <h2
            className={classNames(
              amountClassName,
              'mb-1 text-700 fw-normal lh-1'
            )}
          >
            {totalReturningUsers}
          </h2>
          <SoftBadge pill bg="success" className="me-2 fs--2">
            {/* +3.5% */}
            weekly
          </SoftBadge>
        </div>
        <BasicECharts
          echarts={echarts}
          options={getOptions(weeklyReturningData?.data, weeklyReturningData?.label)}
          style={{ width: width || '8.5rem', height: 60 }}
        />
      </Card.Body>
    </Card>
  );
};

ReturningUsers.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.string,
  amountClassName: PropTypes.string
};

export default ReturningUsers;
