import Flex from "components/common/Flex";
import { RoutePaths } from "constants";
import React, { useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { AiFillFolderAdd } from "react-icons/ai";
import { FaRegFileVideo } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DocumentItem = ({
  doc,
  setDocumentsSelected,
  documentsSelected,
  setCheckBoxMasterInstructor,
  checkBoxMasterInstructor,
  moveToFolderModal,
  convertBytes,
  setFolderToMoveId,
  selectAllInitiated,
  setSelectAllInitiated,
  selectedMediaType
}) => {
  const navigate = useNavigate();
  const checkboxRef = useRef()
  const [popUpPreviewModal, setPopUpPreviewModal] = useState(false);

  useEffect(() => {
    if (!selectAllInitiated) return;
    if (selectAllInitiated === 'all') {
      checkboxRef.current.checked = true
      setSelectAllInitiated(null)
      return
    } else {
      checkboxRef.current.checked = false
      setSelectAllInitiated(null)
    }
  }, [selectAllInitiated])



  const handleCheckBoxMasterInstructions = () => {
    if (checkBoxMasterInstructor === 'Clear all selected items.') {
      checkboxRef.current.checked = false
      setCheckBoxMasterInstructor('')
    }
  }

  useEffect(() => {
    handleCheckBoxMasterInstructions()
  }, [checkBoxMasterInstructor])

  const convertedSize = convertBytes(doc?.size);

  const handleChange = async (e) => {
    if (moveToFolderModal) {
      e.target.checked ? setFolderToMoveId(doc?._id) : setFolderToMoveId('');
      return;
    }
    if (e.target.checked) {
      setDocumentsSelected([...documentsSelected, doc]);
    } else {
      setDocumentsSelected(
        documentsSelected.filter((selectedDoc) => selectedDoc._id !== doc._id)
      );
    }
  };

  const displayFilesHandler = () => {
    if (doc.type !== 'folder') {
      setPopUpPreviewModal(true)
      return
    }
    navigate(`${RoutePaths.POSTLY_CLOUD}/${doc?._id}`);
  }

  const handleSelection = (e, isFolder) => {
    if (moveToFolderModal && !isFolder) {
      checkboxRef.current.checked = false
      return toast.info('Select a folder to move to', { theme: 'colored' })
    }
    handleChange(e)
  }

  return (
    <tr style={{
      width: "10%"
    }}>
      <td
        style={{
          width: "80%",
          fontSize: "14px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Form.Check
          type="checkbox"
          style={{
            width: '20px',
            height: '20px',
            marginTop: 10,
          }}
          label=""
          onChange={(e) => {
            handleSelection(e, doc?.type === 'folder');
          }}
          ref={checkboxRef}
        />
        <div
          onClick={displayFilesHandler}
          style={{ cursor: "pointer", width: "100%" }}
        >
          {doc?.type === "folder" ? (
            <AiFillFolderAdd color="#a1c9f7" size={30} />
          ) : doc?.type?.includes("image/") ? (
            <img
              src={doc?.url}
              alt="file_picture"
              style={{ width: "30px", height: "30px" }}
            />
          ) : (
            <FaRegFileVideo />
          )}

          <span style={{ marginLeft: "10px" }}>{doc?.name}</span>
        </div>
      </td>
      <td style={{ width: "10%", fontSize: "12px" }}>
        {doc?.type}
      </td>
      <td style={{ width: "10%", fontSize: "12px" }}>
        {doc?.type !== 'folder' ? convertedSize?.value : 'N/A'}
      </td>
      <td
        style={{
          width: "10%",

          fontSize: "14px",
          position: "relative",
        }}
      >
      </td>
      <Modal
        size="md"
        centered
        show={popUpPreviewModal}
        onHide={() => setPopUpPreviewModal(false)}
        onExit={() => setPopUpPreviewModal(false)}
      >
        <div style={{ padding: "0px" }}>
          <div style={{ border: "1px solid #bfc9d7" }}>
            <Modal.Header>
              <Flex
                className={"gap-2"}
                direction={"column"}
              >
                <div>File name: {doc?.name}</div>
                <div>File type: {doc?.type}</div>
                <div>File size: {convertedSize?.value}</div>
              </Flex>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "inherit",
                  borderRadius: "0px",
                  cursor: "pointer",
                  marginTop: "10px",
                  gap: 5,
                  maxHeight: 400,
                }}
              >
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >

                  {
                    doc?.type?.includes("image") ? <img src={doc?.url} alt="preview" style={{ width: '100%', height: "100%", maxHeight: 350 }} /> : doc?.type?.includes("video") ? <video src={doc?.url} alt="preview" style={{ width: '100%', height: "100%", maxHeight: 350 }} controls /> : <span>Preview not available</span>
                  }
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className={"btn btn-primary"}
                onClick={() => {
                  navigator.clipboard.writeText(doc?.url)
                  toast.info('Link copied to clipboard', { theme: 'colored' })
                }}
              >
                Copy Link
              </button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    </tr>
  );
};

export default DocumentItem;
