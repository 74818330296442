import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import useOrganizationStore, { defaultOrganization } from "state/organization_store";
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const useActiveOrganization = () => {
    const activeWorkspace = useSelector(getActiveWorkSpace);

    const { activeOrganization: activeOrganizationFromStore, setActiveOrganization } = useOrganizationStore(state => ({
        activeOrganization: state.activeOrganization,
        setActiveOrganization: state.setActiveOrganization
    }));

    const activeOrganization = useMemo(() => {
        return activeOrganizationFromStore
            ?? (activeWorkspace?.organization ? activeWorkspace.organization : defaultOrganization);
    }, [activeOrganizationFromStore, activeWorkspace]);

    return [activeOrganization, setActiveOrganization];
};

export default useActiveOrganization;
