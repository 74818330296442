import FalconDropzone from 'components/common/FalconDropzone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Flex from 'components/common/Flex';
import { isIterableArray } from 'helpers/utils';
import Avatar from 'components/common/Avatar';
import useOrganizationStore from 'state/organization_store';

export default function OrganizationLogoPicker() {

    const { t } = useTranslation();
    const inputId = 'organization-being-created-logo-picker';
    const organization = useOrganizationStore(state => state.organizationBeingCreated);
    const setOrganizationBeingCreated = useOrganizationStore(state => state.setOrganizationBeingCreated);

    const handleAvatarClick = () => {
        let element = document.getElementById(inputId);
        if (element) {
            element.click();
        }
    }

    return (
        <Flex alignItems={'center'} className={'flex-wrap gap-2'}>
            {
                organization?.avatar &&
                <Flex
                    className='w-100'
                    alignItems={'center'}
                    justifyContent={'center'}
                    onClick={handleAvatarClick}
                >
                    <Avatar
                        size="4xl"
                        rounded='2'
                        className='flex-grow-1 cursor-pointer'
                        src={
                            typeof organization?.avatar === 'string' ? organization.avatar : isIterableArray(organization?.avatar) ? organization?.avatar[0]?.base64 || organization?.avatar[0]?.src : ''
                        }
                    />
                </Flex>
            }
            <FalconDropzone
                inputId={inputId}
                className={'flex-grow-1'}
                files={organization?.avatar}
                onChange={files => {
                    let organizationCpy = organization ?? {};
                    setOrganizationBeingCreated({ ...organizationCpy, avatar: files });
                }}
                multiple={false}
                accept="image/*"
                placeholder={
                    <>
                        <Flex
                            justifyContent="center"
                        >
                            <img src={cloudUpload} alt="" width={25} className="me-2" />
                            <p className="fs-9 mb-0 text-700">
                                {t('optional_organization_logo')}
                            </p>
                        </Flex>
                        <p className="mb-0 w-75 mx-auto text-400">
                            {t('image_min_300x300', { file: t('logo').toLowerCase() })}
                        </p>
                    </>
                }
            />
        </Flex>
    )
}
