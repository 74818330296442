import APIService from "http/api_service";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getAutoShortenerStatus,
  setIsShortening
} from "redux/slices/links_shortener_slice";
import { getPublishablePostTextBody, setPublishablePostTextBody } from "redux/slices/post_text_body_slice";
import {
  getJustAddedSpecialCharacterToPostTextBody,
  setJustAddedSpecialCharacterToPostTextBody,
} from "redux/slices/postslice";
import "../feed/compose-placeholder.css";

const ComposeTextArea = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { placeholder, setPostTextBodyLocalValue, postTextBodyLocalValue } = props;
  const config = props.config;
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [juggler, setJuggler] = useState(false);
  const [minRows, setMinRows] = useState(1);
  const [beganTyping, setBeganTyping] = useState(false); //REFER TO: /project-wiki/entries.md-#1
  const justAddedSpecialCharacterToPostTextBody = useSelector(
    getJustAddedSpecialCharacterToPostTextBody
  );
  const postTextBody = useSelector(getPublishablePostTextBody);
  // console.log("🚀 ~ ComposeTextArea ~ postTextBody:", postTextBody)
  const urlPattern = /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
  const autoShortenedStatus = useSelector(getAutoShortenerStatus);


  // Wrapper function to convert callback-based API to Promise-based
  const shortenLinkPromise = (url) => {
    try {
      dispatch(setIsShortening(true));
      return new Promise((resolve, reject) => {
        APIService.shortenLink({ full_link: url }, "yes", (response, error) => {
          if (error) {
            toast.error(error);
            dispatch(setIsShortening(false));
            reject(error);
          } else {
            // console.log("resolving new link...")
            resolve(response.data); // Assuming the response contains the shortened URL
            // toast.success("Link Shortened");
            dispatch(setIsShortening(false));
          }
        });
      });
    } catch (error) {
      toast.error(error);
      dispatch(setIsShortening(false));
    }
  };

  //function to execute link shortener action
  const shortenUrls = async (value) => {
    console.log("shortening...")
    if (isLink(value) && autoShortenedStatus) {
      // console.log("able to shorten...")
      if (!isPostlyLink(value)) {
        let response = await shortenLinkPromise(value);
        if (response.endsWith('/')) {
          response = response.slice(0, -1);
        }
        let replacer = postTextBodyLocalValue;
        let newPostTextBody = replacer.replace(value, response);
        // console.log("🚀 ~ shortenUrls ~ newPostTextBody:", newPostTextBody)
        setJuggler(!juggler);
        setPostTextBodyLocalValue(newPostTextBody);
        dispatch(setPublishablePostTextBody(newPostTextBody));
        dispatch(setPublishablePostTextBody(newPostTextBody));
        return response;
      }
    }
  };

  const isLink = (text) => {
    const urlPattern = /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
    return urlPattern.test(text);
  };

  const isPostlyLink = (text) => {
    return text.includes("https://postly.click");
  };

  const handleDispatchValueWhenUserStopTyping = async (value, instant) => {
    if (autoShortenedStatus) {
      const urlRegex = /\b(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?\b/g;
      let urls = value.match(urlRegex);
      if (urls) {
        urls = urls.filter(url => !url.includes("https://postly.click"))
        if (urls?.length) {
          let newValue = urls.find((u) => !u.includes("https://postly.click"));
          if (typingTimeout) {
            clearTimeout(typingTimeout);
          }
          (
            setTimeout(() => {
              shortenUrls(newValue);
            }, 500)
          );
          return;
        }
      }
    }

    // if (!beganTyping) {
    //   setBeganTyping(true);
    // }

    if (instant) {
      dispatch(setPublishablePostTextBody(value));
    } else {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          // console.log("🚀 ~ setTimeout ~ dispatch(setPublishablePostTextBody(value));:")
          dispatch(setPublishablePostTextBody(value));
        }, 500)
      );
    }

  };

  useEffect(() => {
    if (justAddedSpecialCharacterToPostTextBody) {
      dispatch(setJustAddedSpecialCharacterToPostTextBody(false));
    }
    if (!beganTyping) return;
    handleDispatchValueWhenUserStopTyping(postTextBodyLocalValue);
  }, [postTextBodyLocalValue]);

  useEffect(() => {
    if (beganTyping) return;
    setPostTextBodyLocalValue(postTextBody, true);
  }, [postTextBody]);

  useEffect(() => {
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);


  //TODO: fix double link not passing text to the previews because of auto shorten-link toggle being on.

  // useEffect(() => {
  //   handleDispatchValueWhenUserStopTyping(postTextBody)
  // }, [autoShortenedStatus]);


  const handleCursorChange = (event) => {
    const position = event.target.selectionStart;
    props?.setPostTextCursorPosition(position);
  };

  return (
    <>
      <div
        onClick={() => {
          ref.current?.focus();
        }}
        style={{
          backgroundColor: config.isDark ? "#0b1727" : "#fff",
          minHeight: 100,
        }}
      >
        {/*<TextareaAutoSize
          id="compose-box"
          minRows={minRows}
          ref={ref}
          as="textarea"
          placeholder={placeholder}
          className={`shadow-none resize-none border-0 ${config.isDark ? "border-x" : ""
            } px-card border-200`}
          onChange={(e) => {
            let value = e.target.value;
            console.log("🚀 ~ ComposeTextArea ~ value:", value)
            if (value.length <= 6000) {
              handleDispatchValueWhenUserStopTyping(value);
            }
          }}
          style={{
            width: "100%",
            outline: "none",
            whiteSpace: "pre-wrap",
            display: "inherit",
            background: config.isDark ? "#0b1727" : "#fff",
            color: "inherit",
            fontSize: "inherit",
          }}
          maxRows={20}
        />*/}

        <Form.Control
          as="textarea"
          rows={8}
          placeholder={placeholder}
          value={postTextBodyLocalValue}
          className={`shadow-none resize-none border-0 pb-${props?.paddingBottom ?? 0}`}
          // className={`shadow-none resize-none border-0 ${config.isDark ? "border-x" : ""
          //   } px-card border-200`}
          onChange={(e) => {
            let value = e.target.value;
            if (!beganTyping) {
              setBeganTyping(true);
            }
            if (value.length <= 6000) {
              setPostTextBodyLocalValue(value);
            }
            handleCursorChange(e)
          }}
          onKeyUp={handleCursorChange}
          onMouseUp={handleCursorChange}
        // onChange={(e) => {
        //   if (value.length <= 6000) {
        //     handleDispatchValueWhenUserStopTyping(e.target.value);
        //   }
        // }}
        />
      </div>
    </>
  );
});

export default ComposeTextArea;
