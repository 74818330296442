import React, { useReducer } from 'react'
import { instaFeedReducer } from 'reducers/instaFeedReducer';
import rawFeeds from 'data/feed';
import PropTypes from 'prop-types';
import { InstaFeedContext } from 'context/Context';


const InstaFeedProvider = ({children}) => {

  const [feeds, instaFeedDispatch] = useReducer(instaFeedReducer, []);

  return (
    <InstaFeedContext.Provider value={{ feeds, instaFeedDispatch }}>
      {children}
    </InstaFeedContext.Provider>

  )
}

InstaFeedProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default InstaFeedProvider