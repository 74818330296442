import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import APIService from 'http/api_service';
import { RoutePaths, Strings } from '../../../../constants';
import { attachOccupationToWorkspace } from 'layouts/SplashLayout';
import { freeUpLocallyCachedPlatforms } from 'components/app/social/feed/CreatePost';
import { setActiveWorkspace, setCachedWorkspaces, setSwitchingWorkspace } from 'redux/slices/workspaceslice';
import { cachePosts } from 'redux/slices/postslice';
import { useLocation } from 'react-router-dom';

const useWorkspaceSwitcher = (loggedInUser) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const switchWorkspace = (to) => {
        dispatch(setSwitchingWorkspace(true));
        APIService.fetchWorkSpace(to, (response, error) => {
            if (error) {
                toast.error(error, { theme: 'colored' });
                navigate(RoutePaths.DEFAULT);
                return;
            }
            let responseData = response['data'];
            let workSpace = responseData['workspace'];
            workSpace['role'] = responseData['role'];
            workSpace['value'] = workSpace['name'];
            workSpace['title'] = responseData['title'];
            workSpace['label'] = workSpace['name'];
            workSpace['workspace_owner'] = responseData['workspace_owner'];
            if (workSpace.archived) {
                toast.error("Sorry, unable to switch to an archived workspace");
                navigate(RoutePaths.POSTS);
                return;
            }
            attachOccupationToWorkspace(loggedInUser, workSpace);
            freeUpLocallyCachedPlatforms();

            APIService.updateLastViewedWorkSpace(workSpace._id, (_res, _err) => {
                dispatch(setActiveWorkspace({ ...workSpace }));
                dispatch(setCachedWorkspaces([]));
                dispatch(cachePosts([]));
                const currentPath = location.pathname;
                const updatedPath = currentPath.replace(/\/[a-fA-F0-9]{24}(?=\/|$)/, `/${to}`);
                navigate(updatedPath, { replace: true });
                dispatch(setSwitchingWorkspace(false));
                localStorage.setItem(Strings.ACTIVE_WORKSPACE, JSON.stringify(workSpace));
                window.location.reload();
            });
        });
    };

    return switchWorkspace;
};

export default useWorkspaceSwitcher;
