import APIService from 'http/api_service';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAccountUserId, setPageId } from 'redux/slices/analytics_slice';
import { getActiveWorkSpace } from 'redux/slices/workspaceslice';
import { PlatformIdentifier, RoutePaths } from '../../../../../constants';
import Flex from '../../../../common/Flex';
import { findPlatformByIdentifier } from '../../../platforms';
import AccountHeader from '../../AccountHeader';
import InstagramAnalyticsPage from './instagram_page';
import useProtectedRoute from 'hooks/useProtectedRoute';

const InstagramAnalytics = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = useSelector(getAccountUserId);
    const [fetchingAccounts, setFetchingAccounts] = useState(false);
    const [connectedAccounts, setConnectedAccounts] = useState();
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const workSpace = useSelector(getActiveWorkSpace);
    const platform = findPlatformByIdentifier(PlatformIdentifier.INSTAGRAM);
    const { status } = useProtectedRoute(['free'], `${RoutePaths.BILLING_STRIPE}/#schedulerSection001`)

    const fetchConnectedAccounts = () => {
        // if (WorkSpaceManager.isFreeWorkspace()) {
        //     dispatch(setPopupUpgradeAlert(true))
        //     return;
        // }
        setFetchingAccounts(true);
        APIService.fetchConnectedAccounts(workSpace['_id'], platform.identifier, (response, error) => {
            setFetchingAccounts(false);
            if (error) {
                toast.error(error, { theme: 'colored' });
                return;
            }
            let { data } = response;
            if (!data.length) {
                toast.error(`Please connect ${platform.identifier} account to view analytics`, { theme: 'colored' });
                return;
            }
            if (data && data.length > 0) {
                let refinedData = data.filter((x) => x?.user_id === userId);
                refinedData = refinedData.map((account, index) => {
                    let { user_id, user_name, user_photo, ...platform } = findPlatformByIdentifier(account.target);
                    let newAccount = { ...account, ...platform };
                    return newAccount;
                })
                setConnectedAccounts(refinedData);
            }
        })
    }

    useEffect(() => {
        if (platform) {
            fetchConnectedAccounts();
            dispatch(setPageId(null));
        }
    }, [userId]);

    return (
        <>
            <AccountHeader platform={platform} />
            <div>
                {fetchingAccounts &&
                    <Flex
                        alignItems={'center'}
                        justifyContent={'center'}
                        className={'vh-100'}
                    >
                        <Spinner animation='border' size={40} />
                    </Flex>}
                {connectedAccounts && !fetchingAccounts && connectedAccounts.map((account, index) => {
                    return <div
                        key={account._id}
                        id={account._id}>
                        <InstagramAnalyticsPage
                            account={account}
                            platform={platform} />
                    </div>
                })}
                {/* <ConfirmModal
                    open={openUpgradeDialog}
                    title={"Action requires Upgrade"}
                    message={"Sorry, Analytics is not avaliable to free users. Upgrade your plan to use this feature. Thank you!"}
                    confirmText={"UPGRADE"}
                    cancelText={"CANCEL"}
                    onCancel={() => {
                        setOpenUpgradeDialog(false);
                    }}
                    onConfirm={() => {
                        setOpenUpgradeDialog(false);
                        navigate(`${RoutePaths.BILLING_STRIPE}`);
                    }}
                /> */}
            </div>
        </>
    )
}

export default InstagramAnalytics;