import styled, { css } from "styled-components";

// export const Divider = styled.div`
// flex: 1;
// height: 1px;
// display: flex;
// justify-content: center;
// align-items: center;
// background-color: lightgrey;
// opacity: 0.5;
// flex:1
// `;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  border-top: 1px solid #cbd0d6;
  /* margin-top: 10px; */
  padding-top: 10px;
`;
