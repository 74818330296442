
const onPageSocketRegistryInitializer = (socket, event, callback) => {
    socket.on(event, callback);
}

const onPageSocketRegistryDestroyer = (socket, event) => {
    socket.off(event);
}

export {
    onPageSocketRegistryInitializer,
    onPageSocketRegistryDestroyer
}