import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VerifiedBadge from "assets/images/connected_badge.svg";
import AppContext from "context/Context";
import APIService from "http/api_service";
import React, { useContext, useState } from "react";
import { Alert, Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import { getTagForPages, svgWhiteList } from "utils/gen";

export default function RSSConnectedAccount(props) {
  const dispatch = useDispatch(); 
  const workSpace = useSelector(getActiveWorkSpace);
  const { account } = props;
  const { config } = useContext(AppContext);
  let connectedPagesAndGroups = account.connected_pages_and_groups;
  if (connectedPagesAndGroups) {
    connectedPagesAndGroups = connectedPagesAndGroups.filter(
      (x) => x.target === account.target
    );
  }
  const [displayNameAsAvatar, setDisplayNameAsAvatar] = useState(false);
  const [isFeedDeleting, setIsFeedDeleting] = useState(false);
  const [feedId] = useState(account?._id);

  const connectedPagesCount = () => {
    let connectedPagesLength = (connectedPagesAndGroups ?? []).length;
    if (connectedPagesLength === 0) {
      return null;
    }
    return `${connectedPagesAndGroups.length} connected ${getTagForPages(
      account,
      connectedPagesAndGroups.length > 1
    )}`;
  };

  const removeExistingFeed = () => {
    setIsFeedDeleting(true);
    APIService.deleteRSSFeed(workSpace["_id"], feedId, (response, error) => {
      if (error) {
        setIsFeedDeleting(true);
        toast.error(error, { theme: "colored" });
        return;
      }
      toast.success(response?.message, { theme: "colored" });
      window.location.reload();
      setIsFeedDeleting(false);
    });
  };

  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        justifyContent: "center",
        minHeight: 180,
        minWidth: 180,
        cursor: "pointer",
      }}
    >
      {svgWhiteList(account) ? (
        <img
          src={account?.svg}
          alt=""
          style={{
            width: 30,
            height: 30,
            position: "absolute",
            top: 15,
            left: 15,
          }}
        />
      ) : (
        <FontAwesomeIcon
          icon={account.icon}
          style={{
            color: account.color,
            fontSize: 30,
            position: "absolute",
            top: 15,
            left: 15,
          }}
        />
      )}
      <img
        src={VerifiedBadge}
        alt=""
        style={{
          position: "absolute",
          right: 10,
          top: 10,
          width: 25,
          height: 25,
        }}
      />
      <div
        className="rounded-circle"
        style={{
          width: 60,
          height: 60,
          borderWidth: 2,
          borderColor: "#999",
          borderRadius: 200,
        }}
      >
        <div
          style={{
            width: 60,
            height: 60,
            backgroundColor: "#EEEEEE",
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {displayNameAsAvatar ? (
            <span
              style={{
                fontSize: 40,
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
                alignSelf: "center",
              }}
            >
              {account.user_name.charAt(0).toString().toUpperCase()}
            </span>
          ) : account.user_photo ? (
            <img
              src={account.user_photo}
              alt={account.user_name}
              onError={() => {
                setDisplayNameAsAvatar(true);
              }}
              style={{
                width: 60,
                height: 60,
                borderRadius: 100,
              }}
            />
          ) : (
            <span
              style={{
                fontSize: 40,
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
                alignSelf: "center",
              }}
            >
              {account.user_name.charAt(0).toString().toUpperCase()}
            </span>
          )}
        </div>
      </div>
      <span
        style={{
          fontSize: 16,
          maxLines: 1,
          textOverflow: "ellipsis",
          padding: 10,
          fontWeight: "bold",
        }}
        textAlign={"center"}
      >
        <span>{`${account.user_name ?? ""}`}</span>
      </span>
      {connectedPagesCount() && (
        <Alert
          className="rounded-pill fs--2 p-0 px-2 py-1 mx-1"
          key={"info"}
          variant={"info"}
        >
          {connectedPagesCount()}
        </Alert>
      )}
      <Button
        variant={"danger"}
        onClick={removeExistingFeed}
        isWorking={isFeedDeleting}
        disabled={isFeedDeleting}
        style={{
          color: "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isFeedDeleting ? "Please wait..." : "Remove"}
      </Button>
    </Card>
  );
}
