import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularButton from "components/common/circularbutton";
import AppContext from "context/Context";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { getBloggerTitle } from "redux/slices/postslice";
import { getMediaArrangement } from "redux/slices/publishable_media_arrangement_slice";
import RichEditor from "./rich_editor";
import {
  BodyArea,
  EditContainer,
  ParentSection,
  PreviewContentArea,
  PreviewHeader,
  Wrapper,
} from "./styles/blogger";
import { Info } from "./styles/facebook";
import { displayPlatformIcon } from "utils/platform_utils";
import { PlatformIdentifier } from "constants";
import Flex from "components/common/Flex";

const Blogger = (props) => {
  const { viewOnly } = props;
  const { config } = useContext(AppContext);
  const postTitle = useSelector(getBloggerTitle);
  const media_items_arrangement = useSelector(getMediaArrangement);
  const [openRichTextEditor, setOpenRichTextEditor] = useState(false);
  const [bodyText, setBodyText] = useState(null);
  const bodyRef = useRef(null);
  const [currentDate, setCurrentDate] = useState();

  const isHtml = (str) => {
    const regex = /(<([^>]+)>)/gi;
    return regex.test(str);
  };

  const initialBodyText = () => {
    const wordpressBodyText = media_items_arrangement?.wordpress?.text;
    const isHtmlCode = isHtml(wordpressBodyText);
    if (isHtmlCode) {
      setBodyText(wordpressBodyText);
    } else {
      setBodyText(`<div>${wordpressBodyText}</div>`);
    }
  };

  useEffect(() => {
    initialBodyText();
  }, []);

  useEffect(() => {
    const date = dayjs().format("MMMM DD, YYYY");
    setCurrentDate(date);
  }, []);

  const handleSave = (value) => {
    setBodyText(value);
    const oldData = { ...media_items_arrangement };
    const newData = {
      ...oldData,
      blogger: { ...oldData.blogger, text: value },
    };
    setOpenRichTextEditor(!openRichTextEditor);
  };

  const handleCancel = () => {
    setOpenRichTextEditor(!openRichTextEditor);
  };

  return (
    <>
      <ParentSection>
        <Wrapper>
          <PreviewContentArea
            style={{
              borderColor: `${!config.isDark ? "#ededed" : "transparent"}`,
            }}
            mainBgColor={!config.isDark ? "white" : "#0b1727"}
            theme={!config.isDark ? "1px 1px 3px #949494" : "none"}
          >
            <Flex
              gap={2}
              alignContent={"start"}
              alignItems={"start"}
              style={{
                width: '100%',
                padding: '2%'
              }}
            >
              {displayPlatformIcon(PlatformIdentifier.BLOGGER)}
              <Info>
                <span
                  type={"p"}
                  style={{
                    fontSize: 9,
                    fontWeight: "600",
                    color: `${!config.isDark ? "#050505" : "#E4E6EB"}`,
                  }}
                >
                  Blogger Preview
                </span>
              </Info>
            </Flex>
            <PreviewHeader>
              <div>
                <>
                  {postTitle ? (
                    <h2
                      className="text-capitalize text-center px-2"
                      style={{ fontSize: "18px", fontWeight: "800" }}
                    >
                      {postTitle}{" "}
                    </h2>
                  ) : (
                    <p
                      style={{
                        fontSize: 9,
                        fontStyle: "italic",
                      }}
                    >
                      No Title. Add title in blogger options below.
                    </p>
                  )}
                </>
                <div style={{
                  fontSize: 9,
                }}>{currentDate}</div>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faShareAlt}
                  style={{ fontSize: "12px" }}
                />
              </div>
            </PreviewHeader>
            <BodyArea dangerouslySetInnerHTML={{ __html: bodyText }}></BodyArea>
          </PreviewContentArea>
        </Wrapper>
        {
          !viewOnly &&
          <EditContainer style={{ justifyContent: "flex-start" }}>
            <CircularButton
              editBgColor={!config.isDark ? "#e0e0e0" : "#333333"}
              onClick={() => setOpenRichTextEditor(!openRichTextEditor)}
              title="Edit Post Text"
            >
              <FaEdit size={20} />
            </CircularButton>
          </EditContainer>
        }
      </ParentSection>
      {openRichTextEditor ? (
        <RichEditor
          ref={bodyRef}
          setBodyText={setBodyText}
          bodyText={bodyText}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      ) : null}
    </>
  );
};

export default Blogger;
