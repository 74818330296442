import { findPlatformByIdentifier } from "components/app/platforms";
import Flex from "components/common/Flex";
import { PremiumGuard } from "components/common/PremiumGuard";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlatformFirstComment,
  setPlatformFirstComment,
} from "redux/slices/postslice";
import { getActiveWorkSpace } from "redux/slices/workspaceslice";
import StringUtils from "utils/string";
import { isFreeWorkspace } from "utils/workspace_utils";

const PlatformFirstComment = (props) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const platformFirstComment = useSelector(getPlatformFirstComment);
  const workSpace = useSelector(getActiveWorkSpace);
  const [firstCommentText, setFirstCommentText] = useState();
  const userHasFreeWorkspace = isFreeWorkspace(workSpace);

  useEffect(() => {
    const existingCommentIndex = platformFirstComment?.findIndex(
      (x) => x.identifier === props.identifier
    );
    if (existingCommentIndex !== -1) {
      setFirstCommentText(platformFirstComment?.existingCommentIndex?.firstCommentText || "");
    }
  }, [props?.identifier]);

  const handleOnChange = (value) => {
    setFirstCommentText(value);
    const newComment = {
      identifier: props.identifier,
      id: StringUtils.generateRandomString(10),
      firstCommentText: value,
    };
    const existingCommentIndex = platformFirstComment?.findIndex(
      (x) => x.identifier === props.identifier
    );
    if (existingCommentIndex !== -1) {
      const updatedComments = [...platformFirstComment];
      updatedComments[existingCommentIndex] = {
        ...updatedComments[existingCommentIndex],
        firstCommentText: value,
      };
      dispatch(setPlatformFirstComment(updatedComments));
    } else {
      dispatch(setPlatformFirstComment([...platformFirstComment, newComment]));
    }
  }

  const getLabel = () => {
    let platform = findPlatformByIdentifier(props.identifier);
    return t('platform_first_comment', { platform: platform.name });
  }

  return (
    <PremiumGuard>
      <Flex key={props.identifier}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Form.Group>
            <Form.Label>{getLabel()}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              style={{
                padding: "10px",
                width: "100%",
              }}
              placeholder="Type first comment"
              onChange={(e) => {
                handleOnChange(e.target.value);
              }}
              value={firstCommentText}
            />
          </Form.Group>
        </div>
      </Flex>
    </PremiumGuard>
  );
};

export default PlatformFirstComment;
